import React from 'react';
import { Link } from 'react-router-dom';
import prioryou from '../../assets/default/prioryou.png';

function Logo() {

 return (
     <div>
      <Link to="/nature" target="_blank" rel="noreferrer">
        <img src={prioryou} alt="Nature"></img>
      </Link>
     </div>
 )
}


export default Logo;
