import React from 'react';
import { useState,useEffect } from 'react';
import { useSelector,useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import * as actionCreators from '../../../../redux/actions/actionCreators';
import './Pooldetaildesc.css';
import defaultProfile from '../../../../assets/default/user.png';
import Poolpostbox from './Poolpostbox';
import Poolcommentlist from './Poolcommentlist';
import { IoMdShareAlt } from 'react-icons/io';
import Takeaway from '../../homepage-maincontainer/Takeaway/takeaway';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function Pooldetaildesc(props) {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userdetails = useSelector((state) => state.userReducer.userdetails);
  const topicDetails = useSelector((state) => state.poolDetailsReducer.topicDetails);

  const handleShare = () => {
    dispatch(actionCreators.showShareBox(true));
  }

  const handleAccount = (userid) => {

     if(userdetails.userid) {
         navigate(`/homepage/account/${userid}`)
      } else {
         navigate(`/account/${userid}`)
      }

  }


return (

<div className="Pooldetaildesc-container">

   <div className="Pooldetaildesc-wrapper">

     <Helmet>
        <title>{topicDetails?.topic}</title>
        <meta property="og:title" content={topicDetails?.topic} />
        <meta property="og:image" content={topicDetails?.profile_image ? topicDetails.profile_image : defaultProfile} />
        <meta property="description" content={topicDetails?.description} />
     </Helmet>


     <div className="Pooldetails-profile" onClick={() => handleAccount(topicDetails.userid)}>
         <div className="Pooldetails-profileimg"
           style={{
              backgroundImage:topicDetails?.profile_image ? `url("${topicDetails.profile_image}")` : `url("${defaultProfile}")`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              maxWidth:'100%',
              maxHeight:'100%'
            }} />

         <div className="Pooldetails-profile-empty">  </div>
         <div  className="Pooldetails-profile-name">
          {topicDetails?.username}
        </div>
     </div>

    <div className="Pooldetails-empty"> </div>

     <div className="Pooldetails-desctable">

        <div className="Pooldetails-topicdetails">
          <div className="Pooldetails-topic">Topic</div>
          <div className="Pooldetails-topicname">{topicDetails?.topic}</div>
        </div>

        <div className="Pooldetails-topicdetails">
          <div className="Pooldetails-topic">With</div>
          <div className="Pooldetails-topicname">{topicDetails?.yourname}</div>
        </div>

        <div className="Pooldetails-topicdetails">
          <div className="Pooldetails-topic">Want</div>
          <div className="Pooldetails-topicname">{topicDetails?.noofpartner} learning friends</div>
        </div>

        <div className="Pooldetails-topicdetails">
          <div className="Pooldetails-topic">For</div>
          <div className="Pooldetails-topicname">{topicDetails?.noofdays} days</div>
        </div>

        <div className="Pooldetails-topicdetails">
          <div className="Pooldetails-topic">Start from</div>
          <div className="Pooldetails-topicname">{topicDetails?.startDate} @ {topicDetails?.startTime}</div>
        </div>

        <div className="Pooldetails-topicdetails">
          <div className="Pooldetails-topic">Timezone</div>
          <div className="Pooldetails-topicname">{topicDetails?.timezone}</div>
        </div>

     </div>

     <div style={{height:10}}> </div>


      <div className="Pooldetaildesc-share" onClick={() => handleShare()}>
        <div className="Pooldetaildesc-btn">
          <div className="Pooldetail-shareicon"><IoMdShareAlt/></div>
          <div className="Pooldetail-sharetxt">
             share
          </div>
        </div>
      </div>


    <div style={{height:10}}> </div>

     <div className="Pooldetaildesc-comment">
        <b>Description:</b>
     </div>

     <div className="Pooldetails-empty"> </div>

     <div className="Pooldetaildesc-desc">
        {topicDetails?.description}
     </div>

     <div className="Pooldetails-empty"> </div>

     <div className="Pooldetaildesc-comment">
        <b>Comments:</b>
     </div>

     <div className="Pooldetails-empty"> </div>

     <div className="Pooldetaildesc-pstbox">
        <Poolpostbox superuser={topicDetails?.userid} topicId={topicDetails?.topicId} />
        <div className="Pooldetails-empty"> </div>
        <Poolcommentlist  />
    </div>

   </div>


</div>

  )
}

export default Pooldetaildesc;
