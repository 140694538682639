import React from 'react';
import './Pbimg.css';


function Pbimg(props) {

   return (

      <div className="Pbimg-wrapper">
          <div className="Pbimg-c2">
            <div className="Pbimg-c3">
              <div className="Pbimg-c4">
                <div className="Pbimg-c5">
                  <p className="topic"><strong>Prior</strong></p>
                  <p className="line"><strong><span className="topica">&#47;&#47;</span> </strong></p>
                  <p className="topic"><strong>You</strong></p>
                </div>
               </div>
            </div>
          </div>
      </div>

   )
 }

export default Pbimg;
