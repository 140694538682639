import './Leavesqaud.css';
import React, { useEffect,useState,useRef } from 'react';
import { useSelector,useDispatch } from "react-redux";
import {useNavigate} from 'react-router-dom';
import { RiCloseFill } from 'react-icons/ri';
import * as actionCreators from '../../../../redux/actions/actionCreators';
import { getUserSmarttag } from '../../../../redux/thunk/post/fetchdata';
import { leavesquadupdate } from '../../../../redux/thunk/squad/fetchdata';

function Leavesqaud() {

  const myref = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userdetails = useSelector((state) => state.userReducer.userdetails);
  const squaddetails = useSelector((state) => state.squadReducer.leavesquad);

  const handleClick = (event) => {
    if (myref.current && !myref.current.contains(event.target)) {

      let leaveSquadObj = {
         leavesquadflag:false
      }

      dispatch(actionCreators.leavefromSquad(leaveSquadObj));

     }
  };

  const confirmLeave = async () => {

    let squadObj = {
      userid:squaddetails.userid,
      topicid:squaddetails.topicid,
      removed_by:squaddetails.removed_by
    }

    const response = await leavesquadupdate(squadObj);

      if(response.STATUSCODE === 0) {
          navigate('/')
       }

     let leaveSquadObj = {
        leavesquadflag:false
     }

     dispatch(actionCreators.leavefromSquad(leaveSquadObj));
  }

  const handleClose = () => {

    let leaveSquadObj = {
       leavesquadflag:false
    }

    dispatch(actionCreators.leavefromSquad(leaveSquadObj));
  }

  useEffect(async() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick, false);
    };
  },[]);

  return (
    <div className="Leavesqaud-wrapper" >
       <div ref={myref} className="Leavesqaud-container">
          <div className="Leavesqaud-title">
             <div className="Leavesqaud-close-text"><strong>Leave Squad ?</strong></div>
             <div className="Leavesqaud-close" onClick={() => handleClose()}>
               <RiCloseFill />
             </div>
           </div>
          <div className="Leavesqaud-name">
             <p>Are you sure you want to leave squad ?</p>
           </div>
           <div className="Leavesqaud-btn-height"> </div>
          <div className="Leavesqaud-btn-wrapper">
             <div className="Leavesqaud-btn" onClick={() => confirmLeave()}>Leave</div>
          </div>
       </div>
    </div>

  )
}



export default Leavesqaud;
