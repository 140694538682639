import './Profile.css';
import React, { useState,useEffect } from 'react';
import Profiledetails from './Profiledetails';
import Profilepicture from './Profilepicture';
import { profileDetail, profileDetailupdate } from '../../../../redux/thunk/user/fetchdata';
import { Routes,Route,useNavigate,NavLink } from "react-router-dom";
import { BsArrowLeft } from 'react-icons/bs';
import { BsArrowLeftShort } from 'react-icons/bs';
import Navbar from '../../homepage-topnavbar/Homepage-topnavbar';
import Yourpool from './Yourpool';
import { delCookie } from '../../../../services/CookieService';

function Profile() {

  const navigate = useNavigate();
  const [userDetail,setUserDetail] = useState("");
  const [username,setUsername] = useState("");
  const [firstname,setFirstname] = useState("");
  const [lastname,setLastname] = useState("");
  const [location,setLocation] = useState("");
  const [gender,setGender] = useState("");
  const [timezone,setTimezone] = useState("");
  const [sterotype,setSterotype] =  useState("");

  const [bio,setBio] = useState("");
  const [tempbio,setTempbio] = useState("");
  const [profileimage,setProfileimage] = useState("");
  const [errflag,setErrflag] = useState(false);
  const [editFlag,seteditFlag] = useState(true);


  const [spinnerFlag,setSpinnerFlag] = useState(false);

  const handleTimezone = (value) => {
    setTimezone(value)
  }

  const handleSterotype = (value) => {
    setSterotype(value)
  }

  const handleBackarrow = () => {
     navigate('/homepage')
  }

  const handleSpinnerflag = () => {
     setSpinnerFlag(true);
  }

  const handleUsername = (value) => {
     setUsername(value);
  }

  const handleFirstname = (value) => {
     setFirstname(value);
  }

  const handleLastname = (value) => {
     setLastname(value);
  }

  const handleBio = (value) => {
     setTempbio(value);
  }

  const handleGender = (value) => {
     setGender(value);
  }

  const handleLocation = (value) => {
    setLocation(value);
  }

  const handleEditFlag = () => {
    seteditFlag(false);
  }

  const refershProfile = async() => {

     const responseData = await profileDetail();

     if(responseData.STATUSCODE == 0) {
         setUserDetail(responseData.PAYLOAD);
     }
  }

  const saveProfiledetails = async () => {

     seteditFlag(true);
     setSpinnerFlag(true);

     let usrName = username.trim();

   if(usrName.length < 2) {
        setErrflag(true);
        seteditFlag(false);
        setSpinnerFlag(false);
    } else {

      let profileObj = {
         username:username,
         firstname:firstname.trim(),
         lastname:lastname.trim(),
         gender:gender,
         bio:tempbio,
         location:location,
         timezone:timezone,
         sterotype:sterotype
      }

     const responseData = await profileDetailupdate(profileObj);

    switch (responseData.STATUSCODE) {

      case 0:
          setErrflag(false);
          setSpinnerFlag(false);
          break;
      case 1:
          alert(responseData.MESSAGE);
          setSpinnerFlag(false);
          break;
      case 2:
          delCookie("uaid");
          navigate('/');
          break;

      default:
        alert(responseData.MESSAGE);
        setSpinnerFlag(false);
    }


  }

  }

  useEffect(async() => {

     window.scrollTo(0, 0);

     const responseData = await profileDetail();

       switch (responseData.STATUSCODE) {
         case 0:
             setUserDetail(responseData.PAYLOAD);
             break;
         case 1:
             alert(responseData.MESSAGE);
             break;
         case 2:
             delCookie("uaid");
             navigate('/');
             break;
         default:
           alert(responseData.MESSAGE);
       }


  }, []);


  useEffect(() => {
      setUsername(userDetail.username == null ? "" : userDetail.username);
      setFirstname(userDetail.firstname == null ? "" : userDetail.firstname);
      setLastname(userDetail.lastname == null ? "" : userDetail.lastname);
      setGender(userDetail.gender == null ? "" : userDetail.gender);
      setBio(userDetail.bio == null ? "" : userDetail.bio);
      setLocation(userDetail.location == null ? "" :userDetail.location);
      setProfileimage(userDetail.profile_image == null ? "" :userDetail.profile_image);
      setTempbio(userDetail.bio);
      setTimezone(userDetail.timezone);
      setSterotype(userDetail.sterotype)
  }, [userDetail]);


return (

  <div className="Profilecontainer">

   <div className="Subprofilecontainer">

     <div className="Profiletitle">
       <div className="Profiletitlearrow" onClick={handleBackarrow} ><BsArrowLeftShort/></div>
       <div className="Profiletitletext">&nbsp;Profile & settings</div>
     </div>

      <div className="Profiletitlesubmenu">

        <div>
         <NavLink className={(navData) => (navData.isActive ? 'Profilenavitema' : 'Profilenavitem')} to="" end>Details</NavLink>
        </div>

        <div className="Profilemptyspace"></div>

        <div>
         <NavLink className={(navData) => (navData.isActive ? 'Profilenavitema' : 'Profilenavitem')} to="profilepicture">Profile picture</NavLink>
        </div>

        <div className="Profilemptyspace"></div>

        <div>
         <NavLink className={(navData) => (navData.isActive ? 'Profilenavitema' : 'Profilenavitem')} to="yourpool">Your Pool</NavLink>
        </div>

      </div>

       <div>
          <Routes>
            <Route path="/"
             element={<Profiledetails username={username}
                      firstname={firstname}
                      lastname={lastname}
                      bio={bio}
                      tempbio={tempbio}
                      gender={gender}
                      location={location}
                      timezone={timezone}
                      sterotype={sterotype}
                      sterotype={sterotype}
                      handleUsername={handleUsername}
                      handleFirstname={handleFirstname}
                      handleLastname={handleLastname}
                      handleBio={handleBio}
                      handleGender={handleGender}
                      handleLocation={handleLocation}
                      handleSpinnerflag={handleSpinnerflag}
                      handleTimezone={handleTimezone}
                      handleSterotype={handleSterotype}
                      saveProfiledetails={saveProfiledetails}
                      spinnerFlag={spinnerFlag}
                      errflag={errflag}
                      editFlag={editFlag}
                      handleEditFlag={handleEditFlag}
              />}>

            </Route>
            <Route path="profilepicture" element={<Profilepicture profileimage={profileimage} refershProfile={refershProfile}  />}></Route>
            <Route path="yourpool/*" element={<Yourpool />}> </Route>
          </Routes>
       </div>

      </div>

   </div>

  )
}

export default Profile;
