import './Distimeline.css';

import React from 'react';
import { useState, useEffect } from 'react';
import { useParams,useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from "react-redux";
import { HiHashtag } from 'react-icons/hi';
import { BsArrowLeftShort } from 'react-icons/bs';
import { getTimelineDetails } from '../../../redux/thunk/active/fetchdata';


function Distimeline(props) {

 const params = useParams();
 const dispatch = useDispatch();
 const navigate = useNavigate();

 const topicDetails = useSelector((state) => state.poolDetailsReducer.topicDetails);
 const userdetails = useSelector((state) => state.userReducer.userdetails);

 const [timeline,setTimeline] = useState("");
 const [templine,setTempline] = useState("");
 const [editarray,setEditarray] = useState("");
 const [show,setShow] = useState([]);
 const [editflag,setEditflag] = useState(false);

const noPaste = (event) => {

  var ediv = document.getElementById(event.target.id);

   setTimeout(function() {

      var str1 = ediv.innerText.replace(/\n/g,"");
      var str2 = ediv.innerHTML.replace(/<br>/g,"");

       if (str1 != str2) {
           ediv.innerText = ediv.innerText;
        }

   }, 1);


  if(event.dataTransfer !== undefined ) {

    if(event.dataTransfer.dropEffect === "copy") {
        event.preventDefault();
     }
  }

}

const handleTitleChange = (event,timelineId,index) => {

  var newData ={
     date:templine[index].date,
     description:templine[index].description,
     timelineId:templine[index].timelineId,
     title:event.target.innerHTML,
     topic:templine[index].topic,
     topicId:templine[index].topicId,
     userid:templine[index].userid
 };

   let tmp = templine;
   tmp[index] = newData;

   setTempline([...tmp]);

}

const handleDescChange = (event,timelineId,index) => {
  templine[index].description = event.target.innerHTML;
}

const handleExpand = (index) => {

   if(show.includes(index)) {

     const tempindex = show.indexOf(index);

     let temparray = [...show];

      if (tempindex > -1) {
        temparray.splice(tempindex, 1);
      }

      setShow([...temparray]);

   } else {
       let temp = [];
       temp.push(index);
       setShow([...temp,...show]);
   }

}

const handleTopic = (topicid) => {
   navigate(`/homepage/pool/pooldetails/${topicid}`)
}

const handleBack = () => {
   navigate(-1);
}

 useEffect (async() => {

     if(topicDetails != null) {
       const response = await getTimelineDetails(topicDetails.topicId);

       setTimeline(response.PAYLOAD);
       setTempline(response.PAYLOAD);
     }

     window.scrollTo(0, 0);
     document.addEventListener("paste", noPaste);
     document.addEventListener("drop", noPaste);
   return () => {
       document.removeEventListener("paste",noPaste);
       document.removeEventListener("drop",noPaste);
     };

  }, [topicDetails])

 return (
   <div className="distm-wrapper">

   <div className="distm-container">

     <div className="disdetails-arrow">
        <div className="disdetails-titlearrow" onClick={() => handleBack()}>
          <BsArrowLeftShort/>
       </div>
     </div>

     <h4 className="dis-list-title">Timeline</h4>

     <div className="dis-outerwrapper">

      <div className="tm-outercontainer">
       {timeline && timeline.map((element,index) => {

         let tmstyle;

          tmstyle = "tm-collapsible";

          let value = timeline.length - 1;

          if(value == index && !show.includes(index)) {
             tmstyle = "tm-collapsible-dis";
          }

        return (

        <div className="tm-listitem" key={index} onClick={editflag ? null : () => handleExpand(index)}>

           <div className={tmstyle} >

              <div className="tm-topicday">{index}</div>

              <div className="tm-topicdate">
                <div>{element.date}</div>


              </div>


              <div id={`titleId-${index}`} className={
                editarray.includes(index) ? "tmp-title" : "tmp-title-edit"}
                  contentEditable={editarray.includes(index) ?
                  true : false} data-placeholder="Title"
                  onInput={(event) => handleTitleChange(event,element.timelineId,index)}
                  dangerouslySetInnerHTML={{__html: element.title }} >
               </div>

           </div>

           <div className={show.includes(index) ? "tm-content-max":"tm-content"}>

             <div id={`descId-${index}`} className={
               editarray.includes(index) ? "tmp-desc" : "tmp-desc-edit"}
               contentEditable={editarray.includes(index) ?
                true : false} data-placeholder ="Description" onInput={(event) => handleDescChange(event,element.timelineId,index)}
               dangerouslySetInnerHTML={{__html: element.description }} >
              </div>

              <div style={{height:20}}> </div>

           </div>

        </div>

      );

       })}
      </div>

     </div>

   </div>


   </div>
  )

}

export default Distimeline;
