import React from 'react';
import { useState, useEffect,useCallback,useRef } from 'react';
import { Routes, Link, Route } from "react-router-dom";
import { useSelector,useDispatch } from "react-redux";
import * as actionCreators from '../../../redux/actions/actionCreators';
import { getpoolSearch } from '../../../redux/thunk/search/fetchdata';
import Pbimg from '../homepage-maincontainer/Poolrequest/Pbimg';
import Poollist from '../pool/Pooldetail/Poollist';
import Doublebounce from '../../../spinner/double-bounce/Doublebounce';
import './Searchpool.css';

function Searchpool(props) {

  const observer = useRef();
  const dispatch = useDispatch();

  const searchTerm = useSelector((state) => state.searchReducer.searchTerm);
  const searchPage = useSelector((state) => state.searchReducer.searchPage);
  const seahasMore = useSelector((state) => state.searchReducer.seahasMore);
  const searchTemp = useSelector((state) => state.searchReducer.searchTemp);
  const searchPool = useSelector((state) => state.searchReducer.searchPool);

  const lastPoolElementRef = useCallback(node => {

    if (observer.current) observer.current.disconnect()
       observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && seahasMore) {
           dispatch(actionCreators.addSearchPoolPage(searchTemp));
       }
    })

      if(node) {
          observer.current.observe(node)
       }
  })

   useEffect(async () => {
        let lim = 5;
        let limit;

        limit = searchPage * lim;

     if(limit > searchPool.length && seahasMore == true) {

        const result = await getpoolSearch(searchPage,lim,searchTerm);

        if(result) {
          let ft = result.PAYLOAD[0];

          if(ft.next == undefined) {
            let payload = result.PAYLOAD.slice(1);
            dispatch(actionCreators.addSearchPool(payload));
            dispatch(actionCreators.addSearchPoolHasmore(false));
          } else {
            let payload = result.PAYLOAD.slice(1);

             if(payload.length > 0) {
                dispatch(actionCreators.addSearchPool(payload));
                dispatch(actionCreators.addSearchPoolTemp(ft.next.page));
             } else {
                dispatch(actionCreators.addSearchPoolHasmore(false));
             }

          }
     }
      }

   },[searchTerm,searchPage]);

     return (

       <div className="Searchpool-wrapper">
          {searchPool?.map((element,index) => {
           return (
             <div key={index} ref={lastPoolElementRef} >
                <Poollist element={element} />
              </div>
            );
          })}

          {seahasMore ?
            <div className="browselist-spinner">
              <Doublebounce />
            </div> : null }
       </div>

     )

}

export default Searchpool;
