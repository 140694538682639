import './Videowindow.css';
import { useSelector } from "react-redux";

function Videowindow() {

  const roomstatus = useSelector((state) => state.roomReducer.roomflag);

  return (
    <div className="videowrapper">
      <div className="videocontainer">
        <div className="main-wrapper">

          <div className="noise-wrapper">
              <div className="title">
             <h1>{roomstatus ? "Join Group Discussion"
                 : <div className="videotext"> 
                     <div>Start Group</div>
                     <div>Discussion</div>
                    </div>
                 }</h1>
              </div>

             <div className="noise">
             </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default Videowindow;
