import * as actionTypes from "../actions/actionTypes";

const initialState = {
    team:[]
};

const teamReducer = (state = initialState, action) => {

  switch (action.type) {

    case actionTypes.SET_TEAM:

      return {
        ...state,
        team:action.payload
      };

    case actionTypes.ADD_MEMBER:

         const newArray = [...state.team];

         let index = newArray.findIndex(item => item.enrolleduserId == action.payload);

         if(index != -1) {
            newArray[index].onlineusr = action.payload;
         }

        return {
            ...state,
            team:newArray
        };

    case actionTypes.REMOVE_MEMBER:

      const nextArray = [...state.team];

      let nextindex = nextArray.findIndex(item => item.enrolleduserId == action.payload);

        if(nextindex != -1) {
          nextArray[nextindex].onlineusr = "";
        }

        return {
            ...state,
            team:nextArray
        };

    case actionTypes.DEL_TEAM_MEMBER:


        let tempdelArray = [...state.team];

        let tempdelindex = tempdelArray.findIndex(item => item.enrolleduserId == action.payload);

        if(tempdelindex != -1) {
          tempdelArray.splice(tempdelindex,1)
        }


          return {
            ...state,
            team:tempdelArray
          };

    default:
      return state;
  }
};

export default teamReducer;
