import './Homepage-topnavbar-menu.css';
import React, { useState,useEffect,useRef,useContext } from 'react';
import * as actionCreators from "../../../../redux/actions/actionCreators";

import { logout } from '../../../../redux/thunk/gateway/fetchdata';
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from "react-redux";
import {  SocketContext  } from "../../../../context/SocketContext";

import { AiOutlinePoweroff } from 'react-icons/ai';
import { BsGear } from 'react-icons/bs';
import { BsEmojiSunglasses } from 'react-icons/bs';
import { GoMegaphone } from 'react-icons/go';
import { FaUser } from 'react-icons/fa';

import defaultprofile from '../../../../assets/default/user.png';

function Homepagetopnavbarmenu(props) {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { socket } = useContext(SocketContext);
  const userdetails = useSelector((state) => state.userReducer.userdetails);

  const [animFlag,setAnimFlag] = useState(false);
  const [flag,setFlag] = useState(false);
  const menuref = useRef(null);

  const handleProfile = () => {
      props.showProfile();
      props.displaymenu();
  }

  const handleRequest = () => {
     props.showRequest();
     props.displaymenu();
  }

  const closeMenu = (event) => {
    setAnimFlag(false);
    setFlag(true);
    event.stopPropagation();
    event.preventDefault();
  }

  const handleAccount = (userid) => {
     props.showAccount(userid);
     props.displaymenu();
  }

  const handleMenu = (event) => {
      if (menuref.current && !menuref.current.contains(event.target)) {
          props.displaymenu();
        }
  }

  const handleLogout = async () => {

    const responseData = await logout();

     if(responseData.success == 1) {

         socket?.emit("end");

         dispatch(actionCreators.addUserDetails(""));
         dispatch(actionCreators.addAuthUser(false));
         navigate('/');
      } else {
         console.log("error");
     }

  }

  const handleSetting = () => {
    navigate('/homepage/setting');
     props.displaymenu();
  }

  useEffect(() => {

    setAnimFlag(true);
    document.addEventListener("mousedown", handleMenu);

    return () => {
      document.removeEventListener("mousedown", handleMenu, false);
     };

  }, []);

   useEffect(() => {
     if(flag) {
       setTimeout(function(){
              props.displaymenu();
        }, 500);
     }
   },[flag])

  return (
     <div ref={menuref} className={animFlag ? "Homepage-topnavbar-menu-navpanel-menu-am":
        "Homepage-topnavbar-menu-navpanel-menu"
       }>

       <div className="Homepage-topnavbar-menuheader-wrapper" onClick={() => handleAccount(userdetails.userid)}>

         <div className="Homepage-topnavbar-menuheader">

            <div className="Homepage-topnavbar-profilewrapper">
             <div className="Homepage-topnavbar-profile" style={{
                backgroundImage:userdetails && userdetails.profile_image ? `url("${userdetails.profile_image}")` : `url("${defaultprofile}")`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                maxWidth:'100%',
                maxHeight:'100%',
             }} > </div>
            </div>


            <div className="Homepage-topnavbar-defaultname">
                {userdetails.username}
             </div>

             <div onClick={closeMenu} className="Homepage-topnavbar-menu-close">
               &times;
             </div>

         </div>

       </div>

        <div className="Homepage-topnavbar-menulist">

             <div onClick={() => handleProfile()} className="Homepage-topnavbar-menu-wrapper">
                 <span className="Homepage-topnavbar-menu-menuprofileicon"><FaUser /></span>
                 <span className="Homepage-topnavbar-menu-menuprofile">Profile</span>
             </div>

             <div onClick={() => handleRequest()} className="Homepage-topnavbar-menu-wrapper">
                 <span className="Homepage-topnavbar-menu-menuprofileicon"><GoMegaphone /></span>
                 <span className="Homepage-topnavbar-menu-menuprofile">Request</span>
             </div>

             <div className="Homepage-topnavbar-menu-wrapper" onClick={handleSetting}>
                 <span className="Homepage-topnavbar-menu-menuprofileicon"><BsGear/></span>
                 <span className="Homepage-topnavbar-menu-menuprofile">Setting</span>
             </div>

             <div className="Homepage-topnavbar-menu-wrapper" onClick={handleLogout}>
                 <span className="Homepage-topnavbar-menu-menuprofileicon"><AiOutlinePoweroff/></span>
                 <span className="Homepage-topnavbar-menu-menuprofile">Logout</span>
             </div>

        </div>


     </div>
  )
}

export default Homepagetopnavbarmenu;
