import './Pooldetails.css';
import React from 'react';
import { useEffect,useState } from 'react';
import * as actionCreators from "../../../redux/actions/actionCreators";
import defaultProfile from '../../../assets/default/user.png';
import priorpool from '../../../assets/default/priorpool.png';
import { useDispatch,useSelector } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { BsArrowLeftShort } from 'react-icons/bs';
import Pooldetaildesc from './Pooldetail/Pooldetaildesc';
import { getTopicDetails } from '../../../redux/thunk/topic/fetchdata';
import Pbimg from '../homepage-maincontainer/Poolrequest/Pbimg';
import Poolrequest from './Pooldetail/Poolrequest';
import Poolreqcancel from './Pooldetail/Poolreqcancel';
import Buttonspiner from '../../../spinner/button-spiner/Buttonspiner';
import Classicspiner from '../../../spinner/classic-spiner/Classicspiner';
import Distimeline from './Distimeline';

function Pooldetails() {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authUser = useSelector((state) => state.authReducer.authflag);

  const currentLocation = useLocation();
  const params = useParams();

  const [showrequest,setShowrequest] = useState(false);
  const [cancelrequest,setCancelrequest] = useState(false);
  const [startbtn,setStartbtn] = useState(false);
  const [loading,setLoading] = useState(false);
  const [loadingpage,setLoadingpage] = useState(true);
  const [timelineflag,setTimelineflag] = useState(false);

  const userdetails = useSelector((state) => state.userReducer.userdetails);
  const topicDetails = useSelector((state) => state.poolDetailsReducer.topicDetails);

  const handleBack = () => {
    navigate(-1);
  }

  const handleBacktm = () => {
    setTimelineflag(false)
  }

  const reloadRequest = async () => {

    const response = await getTopicDetails(params.picId);

     if(response.STATUSCODE == 0) {
        dispatch(actionCreators.setTopicDetails(response.PAYLOAD));
        setLoading(false);
     }
  }

  const handleLoading = () => {
    setLoading(true)
  }

  const handleStartbtn = () => {
    setStartbtn(true);
  }

  const hideRequest = () => {
    setShowrequest(false)
  }

  const hideCancel = () => {
    setCancelrequest(false)
  }

  const handleCancel = () => {
     setCancelrequest(true)
  }

  const showTimeline = () => {

    if(authUser) {
       navigate("/homepage/pool/distimeline");
     } else {
       navigate("/signin", { state: { path: currentLocation.pathname}})
    }

  }

  const showProfile = (userid) => {

     if(userdetails.userid) {
         navigate(`/homepage/account/${userid}`)
      } else {
         navigate(`/account/${userid}`)
      }

  }

  const handleStart = () => {

     if(authUser) {
         setShowrequest(true);
       } else {
         navigate("/signin", { state: { path: currentLocation.pathname}})
      }

   }

  const handleRequest = () => {
    navigate(`/homepage/pool/request`);
    dispatch(actionCreators.restUserPool());
  }

  useEffect (async () => {

    window.scrollTo(0, 0);

     dispatch(actionCreators.resetComment());
     dispatch(actionCreators.setTopicDetails(null));

     if(params.picId) {

     const response = await getTopicDetails(params.picId);

     if(response.STATUSCODE == 0) {
        dispatch(actionCreators.setTopicDetails(response.PAYLOAD));
        setLoadingpage(false);
     } else {
        navigate("/homepage/nomatch");
     }

   }

   }, [params.picId])


  return (
     <div className="pooldetailscontainer">

      {loadingpage ?
         <div className="pooldetails-spinner">
           <Classicspiner/>
          </div>
        :
         <div>

          <div className="pooldetailswrapper">

             <div className="pooldetails-arrow">
                <div className="pooldetails-titlearrow" onClick={() => handleBack()}><BsArrowLeftShort/></div>
             </div>

             <div className="pooldetails-title-panel">
               {topicDetails?.topic_image ?
                <img src={topicDetails.topic_image} className="pooldetails-img" />
                : <img src={priorpool} className="pooldetails-img" /> }
             </div>

             <div>&nbsp;</div>

        {topicDetails?.noofpartner ==  topicDetails?.enrolledusers.length ?

           <div>Member is Full</div> :

            <>
        {topicDetails?.userid !== userdetails.userid ?
            <>

            {topicDetails?.status.status == "approved" ?
                <p>Approved</p>
              :
             <>
              {topicDetails?.status.status == "pending" ?
             <div className="pooldetails-btn" onClick={() => handleCancel()}>
                  {loading ? <Buttonspiner/> :
                   <div>Cancel</div> }
             </div> :

              <div className="pooldetails-btn" onClick={() => handleStart()}>
                  {loading ? <Buttonspiner/> :
                   <div>
                     <div>Start now</div>
                   </div> }
              </div> }

             </>

             }
            </>

           : null  }
           </>

        }


             {topicDetails?.noofpartner == topicDetails?.enrolledusers.length ?
               null  :
                <>
                 {topicDetails?.userid == userdetails.userid ?
                   <div className="pooldetails-btn" onClick={() => handleRequest()}>
                     Send Request
                    </div>
                 : null }
                </>
              }



              <div>&nbsp;</div>
              <div className="pooldetails-member-title">
                 Joint Member
              </div>


              <div className="pooldetails-member-wrapper">

                {topicDetails?.enrolledusers.map((item,index) => {

                  return (
                   <div key={index}>
                     <div className="pooldetails-member-team" onClick={() => showProfile(item.enrolledusersdetails.PAYLOAD.userid,userdetails.userid)}
                       style={{
                          backgroundImage:item.enrolledusersdetails.PAYLOAD.profile_image ? `url("${item.enrolledusersdetails.PAYLOAD.profile_image}")` : `url("${defaultProfile}")`,
                          backgroundPosition:'center',
                          backgroundSize:'cover',
                          backgroundRepeat:'no-repeat',
                          maxWidth:'100%',
                          maxHeight:'100%'
                       }}
                     >
                       </div>
                    </div>
                    )
                  })}

              </div>

              <div className="pooldetails-membercount">
                {topicDetails?.enrolledusers.length} / {topicDetails?.noofpartner}
              </div>

              <div>&nbsp;</div>

              <div className="prequest-timeline" onClick={() => showTimeline()}>
                 TimeLine
               </div>

          </div>

          <div className="pooldetailswrapperbuffer"> </div>

            {/* Pool detail */}


              <div className="pooldetails-desc">
                <Pooldetaildesc />

           </div>

        {showrequest ?

          <Poolrequest hideRequest={hideRequest}
            handleStartbtn={handleStartbtn}
            reloadRequest={reloadRequest}
            handleLoading={handleLoading}
            touserid={null}
            />
            : null }

        {cancelrequest ?

          <Poolreqcancel
            hideCancel={hideCancel}
            handleStartbtn={handleStartbtn}
            reloadRequest={reloadRequest}
            handleLoading={handleLoading}
            />

          : null }

         </div> }

      {timelineflag ?
        <Distimeline  handleBacktm={handleBacktm} />
       : null }

     </div>
  )
}

export default Pooldetails;
