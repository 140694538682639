import React, { useRef,useState,useEffect } from 'react';
import { useSelector,useDispatch } from "react-redux";
import './Squadreport.css';
import { BiCircle } from 'react-icons/bi';
import { BiCheckCircle } from 'react-icons/bi';
import { MdOutlineClose } from 'react-icons/md';
import { reptUserComment,reptUserReply } from '../../../../redux/thunk/comment/fetchdata';
import * as actionCreators from '../../../../redux/actions/actionCreators';
import { reportsquad } from '../../../../redux/thunk/squad/fetchdata';


function Squadreport() {

   const [flagindex,setFlagindex] = useState(null);
   const [reportcomment,setReportcomment] = useState("");
   const squaddetails = useSelector((state) => state.squadReducer.reportsquad);


   const myRef = useRef(null);
   const dispatch = useDispatch();

   const array = ['Harassment','Spam','Hate Speech','Violence',
                  'Suicide or Self-Harm','Misinformation',
                  'Terrorism','Sexually Explicit Content']

   const handleCheck = (index,element) => {
        setFlagindex(index)
        setReportcomment(element);
   }

   const handleCancel = () => {

     let reportobj = {
       reportsquadflag:false
     }

     dispatch(actionCreators.reportSquadfor(reportobj));
   }

   const handleSubmit = async() =>  {

       let reportobj = {
         topicid:squaddetails.topicid,
         reportedby:squaddetails.byuserid,
         reportedfor:squaddetails.foruserid,
         report_text:reportcomment
       }

      const response = await reportsquad(reportobj);

      let reobj = {
        reportsquadflag:false
      }

      dispatch(actionCreators.reportSquadfor(reobj));

   }

   const handleClick = (event) => {

     if (myRef.current && !myRef.current.contains(event.target)) {
       let reportobj = {
         reportsquadflag:false
       }
       dispatch(actionCreators.reportSquadfor(reportobj));
      }

   };

   useEffect(() => {
      document.addEventListener("mousedown", handleClick);
    return () => {
       document.removeEventListener("mousedown", handleClick, false);
     };
   }, []);


  return (
    <div className="Squad-modal">
      <div ref={myRef} id="rptid" className="Squad-modal_content">

        <div className="Squad-title">
          <div>Report</div>
          <div className="Squad-close" onClick={handleCancel}>
            <MdOutlineClose/>
          </div>
        </div>

        <div className="Squad-line"> </div>

      {array.map((element,index) => {
          let tempIndex = index + 1;
        return (
        <>
         <div className="Squad-wrapper">
             <div className="Squad-icon" onClick={() => handleCheck(tempIndex,element)}>
               {tempIndex == flagindex ? <BiCheckCircle/> : <BiCircle />  }
                <div className="Squad-text"><small>&nbsp;{element}</small></div>
             </div>
           </div>
          <br/>
        </>
        )
      })}

        <div className="Squad-btn-wrapper">
         {flagindex ?
           <button className="Squad-btn" onClick={() => handleSubmit()}>
              Submit
           </button>
           :
           <button  disabled className="Squad-btn-disable">
              Submit
           </button>
          }
        </div>

       </div>
    </div>
  )
}

export default Squadreport;
