import './Browserpool.css';
import React from 'react';
import { Routes,Route } from "react-router-dom";
import Browselist from './Browselist';
import Pooldetails from './Pooldetails';
import Poolnotify from './Poolnotify/Poolnotify';
import Distimeline from './Distimeline';
import Request from './Request';

function Browserpool() {

  return (
     <div className="browserpoolcontainer">
          <Routes>
              <Route path="/" element={<Browselist />}> </Route>
              <Route path="/pooldetails/:picId/*" element={<Pooldetails />}></Route>
              <Route path="/notifydetails/:picId" element={<Poolnotify />}></Route>
              <Route path="/distimeline" element={<Distimeline />}></Route>
              <Route path="/request/*" element={<Request />}></Route>
         </Routes>
     </div>
  )
}

export default Browserpool;
