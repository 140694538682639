import React, { useState,useEffect,useRef } from 'react';
import { SlMenu } from 'react-icons/sl';
import Popupmenu from './Popupmenu';
import l1 from '../../../assets/logo/l1.png';
import './Topnavigation.css';

function Topnavigation() {

  const [menuFlag,setmenuFlag] = useState(false);

   const handleMenu = () => {
      setmenuFlag(true);
   }

   const closeMenu = () => {
      setmenuFlag(false);
   }

    return (
      <div className="Topnavigation-header">
       <nav className="Topnavigation-navbar">

              {/* logo */}
          <a href="/" className="Topnavigation-logo font-face-gm">
                 prior
                  <img className="Topnavigation-logoline-img" src={l1} />
               you
          </a>

              {/*Topnavigation menu */ }
          <div className="Topnavigation-cmenu">

             <div> </div>

                {/* Menu */}
             <div className="Topnavigation-menu">
                <div>
                   <a href="/howitwork" className="Topnavigation-btn">How it works</a>
                </div>
                <div>
                   <a href="/pool" className="Topnavigation-btn">Pool</a>
                </div>
                <div> </div>
             </div>

             <div> </div>

                 {/* Right Menu */}
             <div className="Topnavigation-gateway">

                <div></div>
                <div></div>
                <div></div>


                <div className="Topnavigation-joinnow">
                  <a href="signin" className="Topnavigation-btn">Login</a>
                </div>

                <div>&nbsp;</div>

                <div className="Topnavigation-signin">
                  <a href="register" className="Topnavigation-sign">Get Started</a>
               </div>

               <div className="Topnavigation-menupopu" onClick={() => handleMenu()}>
                 <SlMenu />
               </div>


             </div>

          </div>

       </nav>

      {menuFlag ?
       <div className="Mainbody-pool-popupmenu">
         <Popupmenu closeMenu={closeMenu} />
       </div> : null }

    </div>

    )
}

export default Topnavigation;
