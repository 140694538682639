import React, { useState,useEffect,useRef } from 'react';
import { useSelector } from "react-redux";
import Sterotype from './Sterotype';
import './Welcome.css';
import prioryou from  '../../../assets/logo/prioryou.jpg';

function Welcome() {

  const username = useSelector((state) => state.welReducer.username);

  const [startFlag,Setstartflag] = useState(false);

  const handleGetStarted = () => {
     Setstartflag(true);
  }


  return (
      <div className="Welcome-wrapper">
       {startFlag ? <Sterotype /> :
         <div className="Welcome-container">

            <div> </div>

            <div className="Welcome-container-header">
               <div className="Welcome-container-title">
                  Welcome to
               </div>
               <div><img src={prioryou} /></div>
            </div>

             <div className="Welcome-container-title">
                 {username}
             </div>

            <div className="Welcome-container-footer">
               <div className="Welcome-container-getstarted" onClick={() => handleGetStarted()}>
                  Get Started
                </div>
            </div>

            <div> </div>

         </div> }
      </div>
  );
}

export default Welcome;
