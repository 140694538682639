import React, { useState,useEffect,useRef } from 'react';
import { RiCloseFill } from 'react-icons/ri';
import { useDispatch,useSelector } from "react-redux";
import './Otpscreen.css';
import { resendOtp,verifyuserotp,sendUserOtp,sendUserEmailOtp } from '../../../../redux/thunk/gateway/fetchdata';
import { useNavigate } from 'react-router-dom';
import { verifyemailotp,sendEmailOtp,verifyuserupdatemailopt } from '../../../../redux/thunk/setting/fetchdata';
import * as actionCreators from "../../../../redux/actions/actionCreators";
import Classicspiner from '../../../../spinner/classic-spiner/Classicspiner';
import { v4 as uuidV4 } from "uuid";

function Otpscreen(props) {

  const otpRef = useRef();
  const [timer, setTimer] = useState(30);
  const [sendFlag,setSendflag] = useState(false);
  const [otpValue,setOtpValue] = useState("");
  const [otperror,setOtperror] = useState(false);
  const [verifyOtpId,setVerifyOtpId] = useState("");

  const [otpspinner,setOtpspinner] = useState(false);

  const emailid = useSelector((state) => state.otpReducer.emailid);
  const verifyid = useSelector((state) => state.otpReducer.verifyid);
  const currentemail = useSelector((state) => state.otpReducer.currentemail);
  const userdetails = useSelector((state) => state.userReducer.userdetails);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  function countdown() {
    setTimeout(() => {
      let time = timer - 1;
      if(time < 10) {
       time = "0"+time;
      }
      setTimer(time);
    }, 1000);
  }

  const startTimer = async() => {

    let timestamp = Date.now();

    let verifyId = uuidV4() + "-" + timestamp;

     let userObj = {
       userid:userdetails.userid,
       verifyid:verifyId,
       email:emailid,
       userName:userdetails.username
     }

      setOtpspinner(true);
      setTimer(30);
      setSendflag(false);

   if(emailid == currentemail) {
      const responseData = await sendUserOtp(userObj);

       if(responseData.STATUSCODE == 0) {
           setTimer(30);
           setSendflag(false);
           setVerifyOtpId(verifyId);
       }

    } else {
      const responseData = await sendUserEmailOtp(userObj);

        if(responseData.STATUSCODE == 0) {
           setTimer(30);
           setSendflag(false);
           setVerifyOtpId(verifyId);
        }

    }



      setOtpspinner(false);


  }

  const handleCancel = () => {

    let submitobj = {
      currentemail:"",
      emailId:"",
      otpFlag:false,
      verifyid:"",
      updateflag:false
    }

    dispatch(actionCreators.setOtpFlag(submitobj));
  }

  const handleOtpChange = (e) => {
    const otp = (e.target.validity.valid) ? e.target.value : otpValue;
    setOtpValue(otp);
  }

  const handleConfirm = async () => {

    if(otpValue.length > 0) {

      let verifyobj = {
          email:emailid,
          userid:userdetails.userid,
          verifyid:verifyOtpId,
          otp:otpValue,
          currentemail:currentemail
      }


    if(verifyobj.email == verifyobj.currentemail) {

      const response = await verifyemailotp(verifyobj);

        if(response.STATUSCODE == 0) {

          let submitobj = {
            currentemail:"",
            emailId:"",
            otpFlag:false,
            verifyid:"",
            updateflag:false
          }

          dispatch(actionCreators.updatEmailVerfication(1));
          dispatch(actionCreators.setOtpFlag(submitobj));

         } else {
            setOtperror(response.MESSAGE);
        }

    } else {

      const response = await verifyuserupdatemailopt(verifyobj);

        if(response.STATUSCODE == 0) {

          let submitobj = {
            currentemail:"",
            emailId:"",
            otpFlag:false,
            verifyid:"",
            updateflag:false
          }

          let emailobj = {
            isEmailVerified:1,
            useremail:verifyobj.email
          }

          dispatch(actionCreators.updateUserEmail(emailobj));
          dispatch(actionCreators.setOtpFlag(submitobj));

         } else {
            setOtperror(response.MESSAGE);
        }


    }



    }
  }

  useEffect(() => {
       countdown();
    },[]);

  useEffect(() => {
    if(timer != "00") {
      countdown();
    } else {
      setSendflag(true);
    }
  },[timer]);

  useEffect(() => {
     setVerifyOtpId(verifyid);
  },[verifyid])

  return (

  <div className="Opt-backdrop">
    <div className="Otpscreen-wrapper">
       <div className="Otpscreen-container">
          <div className="Otpscreen-header">
             <div className="Otpscreen-header-right">
              <RiCloseFill />
             </div>
             <div className="Otp-header-center">
                Enter confirmation code
             </div>
             <div className="Otpscreen-header-left" onClick={() => handleCancel()}>
              <RiCloseFill />
             </div>
          </div>

          <div className="Otpscreen-body">

            <div className="Otpscreen-body-up">
              <div className="Otp-body-text">
                <div style={{padding:'4px'}}>Enter confirmation code</div>
                <div style={{padding:'4px'}}>sent to your email</div>
                <div style={{padding:'4px'}}><strong>{emailid}</strong></div>
               </div>
              <div>&nbsp;</div>
              <div>&nbsp;</div>
                 <div>
                   <input className="Otpscreen-input" type="text" placeholder="OTP"
                    value={otpValue} pattern="[0-9]*" onChange={(event) => handleOtpChange(event)} />
                </div>
            </div>

             <div className="Otpscreen-body-down">
               <div className="Otpscreen-error">
                 &nbsp;{otperror}
               </div>
               <div>&nbsp;</div>
               <div className="Otp-confirm-btn" onClick={() => handleConfirm()}>
                  Confirm
                </div>


               <div className="Otp-screen-timer">
                 00:{timer}
                </div>
                <div>&nbsp;</div>
               <div>
                <div>Didn't receive OTP ?</div>

                <div className="Otp-resend-wrapper">

               {sendFlag ?
                 <span className="Otp-resend" onClick={() => startTimer()}>
                    &nbsp;Resend code
                  </span> :
                  <span className="Otp-resend-dis">
                     &nbsp;Resend code
                  </span> }

                  </div>

                </div>
             </div>

          </div>
          <div className="Otpscreen-footer">
             <div></div>
          </div>
       </div>

       {otpspinner ?
        <div className="Otpscreen-spinner">
           <Classicspiner />
        </div> : null }

    </div>
  </div>
  );
}

export default Otpscreen;
