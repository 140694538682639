import React from 'react';
import { useRef,useEffect,useContext,useState } from 'react';
import { deactivateAccount } from '../../../../redux/thunk/setting/fetchdata';
import { Routes,Route,useNavigate,NavLink } from "react-router-dom";
import { useSelector,useDispatch } from "react-redux";
import { RiCloseFill } from 'react-icons/ri';
import { BsArrowLeftShort } from 'react-icons/bs';
import * as actionCreators from "../../../../redux/actions/actionCreators";
import {  SocketContext  } from "../../../../context/SocketContext";
import { logout } from '../../../../redux/thunk/gateway/fetchdata';
import './Deactiveconfirm.css';

function Deactivepopup(props) {

   const [password,setPassword] = useState("");
   const { socket } = useContext(SocketContext);
   const userdetails = useSelector((state) => state.userReducer.userdetails);

   const navigate = useNavigate();
   const myref = useRef(null);
   const dispatch = useDispatch();

   const handleBackarrow = () => {
      navigate(-1)
   }

   const handleClick = (event) => {

     if (myref.current && !myref.current.contains(event.target)) {

      }
   };

   const handleClose = () => {

   }

   const handleDeactivate = async() => {

   }

   const deactivateaccn = async () => {

     const response = await deactivateAccount(userdetails.userid);

       if(response.STATUSCODE == 0) {

         const responseData = await logout();

          if(responseData.success == 1) {

              socket?.emit("end");

              dispatch(actionCreators.addAuthUser(false));
              dispatch(actionCreators.decAccforuser(true));
              dispatch(actionCreators.addUserDetails(""));

              setTimeout(function(){
                navigate('/Deactivatedpage');
              }, 100);


           } else {
              console.log("error");
          }


       }

   }

   const handlechange = (event) => {
     setPassword(event.target.value)
   }

   useEffect(() => {

     document.addEventListener("mousedown", handleClick);

     return () => {
       document.removeEventListener("mousedown", handleClick, false);
     };

  }, []);

  return (
    <div className="deactivateaccount">

     <div className="setting-empspace"> </div>

     <div className="changepassword-container">

      <div className="Profiletitle">
         <div className="Profiletitlearrow" onClick={handleBackarrow} ><BsArrowLeftShort/></div>
         <div style={{width:10}}> </div>
         <div className="Profiletitletext">back</div>
      </div>

      <div className="deactivepopup-head-title">Confirm Deactivate</div>

      <div style={{height:20}}> </div>

      <p className="deactivepopup-title"><strong>Enter Your Password</strong></p>

      <div style={{height:10}}> </div>

     <div className="changepassword-body">
       <div className="changepassword-body-inner">

      <input className="changepassword-inp" type="password" value={password} onChange={(event) => handlechange(event)}  />

        <div style={{height:10}}> </div>

     <div className="deactivateaccount-text">
       <button className="deactivate-btn" onClick={() => deactivateaccn()}>
         Confirm Deactivate
       </button>
     </div>

      </div>
     </div>

     </div>

    </div>
  );
}

export default Deactivepopup;
