import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './fonts/VanillaExtractRegular.ttf';
import './fonts/vanillaextractregular-webfont.woff';
import './fonts/vanillaextractregular-webfont.woff2';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

import { Provider } from 'react-redux';
import { SocketProvider } from "./context/SocketContext";
import { PersistGate } from 'redux-persist/integration/react';
import { store,persistor }  from './redux/store';
import { Helmet, HelmetProvider } from 'react-helmet-async';

ReactDOM.render(

   <Provider store={store}>
    <PersistGate persistor={persistor}>
       <BrowserRouter>
         <SocketProvider>
            <HelmetProvider>
             <App />
            </HelmetProvider>
         </SocketProvider>
       </BrowserRouter>
      </PersistGate>
    </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
