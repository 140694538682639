import React, { useEffect,useContext } from 'react';
import { Routes, Link, Route } from 'react-router-dom';
import { useSelector,useDispatch } from "react-redux";
import {  SocketContext  } from "../../../context/SocketContext";
import { getTeamDetails } from '../../../redux/thunk/active/fetchdata';

import Resources from './Resources/Resources';
import Timeline from './Timeline/Timeline';
import Team from './Team/Team';
import Startcall from './Startcall/Startcall';
import Searchbar from './SearchBar/SearchBar';
import Homescreen from './Homescreen/Homescreen';
import Postpriview from './Post/Postpriview';
import Postsearch from './Post/Postsearch';
import Smarttag from './Smarttag/Smarttag';
import Prequest from './Poolrequest/Prequest';

import defaultprofile from '../../../assets/default/user.png';
import activeicon from '../../../assets/menu/activeicon.png';
import timelineicon from '../../../assets/menu/timelineicon.png';
import squadicon from '../../../assets/menu/squadicon.png';
import smarttagicon  from '../../../assets/menu/smarttagicon.png';
import startcallicon from '../../../assets/menu/startcallicon.png';
import assetsicon from '../../../assets/menu/assetsicon.png';

import * as actionCreators from '../../../redux/actions/actionCreators';

import Ac from './Active/Ac';
import './Active.css';
import './Homepagemaincontainer.css';

function Active() {

  const dispatch = useDispatch();
  const { socket } = useContext(SocketContext);

  const topicobj = useSelector((state) => state.activeReducer.topicid);

  useEffect(async() => {

    window.scrollTo(0, 0);

     if(topicobj?.topicid) {

       const response = await getTeamDetails(topicobj.topicid);

       if(response.STATUSCODE == 0) {
         dispatch(actionCreators.setTeam(response.PAYLOAD));
       }

    }


  },[topicobj])

  useEffect(() => {

    if(socket) {

     socket.on("getOnlineUser", (data) => {
        if(data.userid) {
          dispatch(actionCreators.addMember(data.userid));
        }
     });

     socket.on("getOffineUser", (userid) => {
       if(userid) {
          dispatch(actionCreators.rmvMember(userid.userid));
       }
     });

     socket.on("del-userpost", (data) => {
         dispatch(actionCreators.delUserpostlist(data.postid));
      })

   }

 },[socket])


  return (

  <div className="Homepagemainwrapper">

      { /* left navigation */ }

     <div className="Homepagemain-leftnav">
      <div className="leftnav">

       <div className="leftnav-hgt" />

       <div className="Homepagemain-btn">
        <Link className="linkbtn" to="">
          <img src={activeicon} className="acimg" />&nbsp;Active
        </Link>
       </div>

       <div className="leftnav-hgt" />

       <div className="Homepagemain-btn">
        <Link className="linkbtn" to="timeline">
          <img src={timelineicon} className="acimg"  /> &nbsp;Timeline
        </Link>
       </div>

        <div className="leftnav-hgt" />

        <div className="Homepagemain-btn">
        <Link className="linkbtn" to="Startcall">
          <img src={startcallicon} className="acimg" />&nbsp;Discussion
        </Link>
        </div>

        <div className="leftnav-hgt" />

        <div className="Homepagemain-btn">
         <Link className="linkbtn" to="team">
           <img src={squadicon} className="acimg" />&nbsp;Squad
         </Link>
        </div>

      <div className="leftnav-hgt" />

      <div className="Homepagemain-btn">
        <Link className="linkbtn" to="resources">
          <img src={assetsicon} className="acimg" />&nbsp;Assets
        </Link>
      </div>

      <div className="leftnav-hgt" />

      <div className="Homepagemain-btn">
        <Link className="linkbtn" to="smarttag">
          <img src={smarttagicon} className="acimg" />&nbsp;SmartTag
        </Link>
      </div>

    </div>

     </div>

      { /* Center div */ }

      <div className="Homepage-center">

        <div className="leftnav1">

           <div className="Homepagemain-btn">
           <Link className="linkbtn" to="">
             <img className="acimgcenter" src={activeicon}  />
           </Link>
           <div className="acimgicon">Active</div>
           </div>


           <div className="Homepagemain-btn">
           <Link className="linkbtn" to="timeline">
             <img className="acimgcenter" src={timelineicon}   />
           </Link>
           <div className="acimgicon">Timeline</div>
           </div>


          <div className="Homepagemain-btn">
             <Link className="linkbtn" to="Startcall">
              <img className="acimgcenter" src={startcallicon}  />
             </Link>
             <div className="acimgicon">Discussion</div>
          </div>


           <div className="Homepagemain-btn">
              <Link className="linkbtn" to="team">
                <img  className="acimgcenter" src={squadicon}  />
              </Link>
              <div className="acimgicon">Squad</div>
           </div>


           <div className="Homepagemain-btn">
              <Link className="linkbtn" to="resources">
               <img className="acimgcenter" src={assetsicon}  />
              </Link>
             <div className="acimgicon">Assets</div>
           </div>


           <div className="Homepagemain-btn">
              <Link className="linkbtn" to="smarttag">
                <img className="acimgcenter" src={smarttagicon}  />
              </Link>
              <div className="acimgicon">SmartTag</div>
           </div>


        </div>

      </div>


      <div>
        <Routes>
          <Route path="/" element={<Ac />}> </Route>
          <Route path="team" element={<Team />}></Route>
          <Route path="Homescreen" element={<Homescreen />}></Route>
          <Route path="resources" element={<Resources />}></Route>
          <Route path="smarttag" element={<Smarttag />}></Route>
          <Route path="startcall/*" element={<Startcall />}></Route>
          <Route path="timeline" element={<Timeline />}></Route>
          <Route path="poolrequest" element={<Prequest />}></Route>
          <Route path="preview" element={<Postpriview />}></Route>
          <Route path="postsearch" element={<Postsearch />}></Route>
          <Route path="preview/:topicid/*" element={<Postpriview />}></Route>
        </Routes>
      </div>

      { /* Right Div */ }
      <div className="Homepage-right"> </div>

  </div>

  )
}

export default Active;
