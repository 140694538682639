import './Successnotify.css';
import { IoIosCloseCircleOutline } from 'react-icons/io';

function Successnotify(props) {

  const handleClose = () => {
     props.closeFlashMsg();
  }

  return (
     <div className="success-notify-wrapper">
        <div className="success-notify-item">
           <div className="success-notify-item-msg">
               <div>{props.notifyMsg}</div>
            </div>
            <div className="success-notify-close" onClick={handleClose}>
              <IoIosCloseCircleOutline />
            </div>
        </div>
     </div>
    )

  }

export default Successnotify;
