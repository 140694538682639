import './Poolpostbox.css';
import React, { useState,useEffect,useContext } from 'react';
import * as actionCreators from "../../../../redux/actions/actionCreators";
import { addUserComment,getUserComment } from '../../../../redux/thunk/comment/fetchdata';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {  SocketContext  } from "../../../../context/SocketContext";
import { TiArrowRightThick } from 'react-icons/ti';
import { ImCross } from 'react-icons/im';
import { v4 as uuidV4 } from "uuid";
import defaultprofile from '../../../../assets/default/user.png';

function Poolpostbox(props) {

  const [userComment,setuserComment] = useState("");
  const [postBoxdisable,setpostBoxdisable] = useState(true);

  const authUser = useSelector((state) => state.authReducer.authflag);
  const userdetails = useSelector((state) => state.userReducer.userdetails);
  const { socket } = useContext(SocketContext);

  const currentLocation = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleTextarea = (event) => {
      setuserComment(event.target.innerHTML);
  }

  const handleTextareaClick = () =>  {

     if(authUser) {
        } else {
          navigate("/signin", { state: { path: currentLocation.pathname}})
       }
   }

   const handlePost = async(topicId,superuser) => {

      setpostBoxdisable(true);
      document.getElementById("postid").innerHTML = "";
      setuserComment("");

      var userCommentStr = userComment.replace(/^(\ ?<div><br><(\/)?div>\ ?)+|(\ ?<div><br><(\/)?div>\ ?)+$/, '');

       let userCommentobj = {
         comment_text:userCommentStr,
         topicId:topicId,
         userId:userdetails.userid,
         superuser:superuser
      }

      const response = await addUserComment(userCommentobj);

       if(response.STATUSCODE == 0) {

          let notify = {
              notifyto:superuser,
              commentTxt:userCommentStr,
              notifyfrom:userdetails.userid,
              notify_topicid:topicId,
              notify_type:"commented",
              notify_comment_id:response.PAYLOAD,
              notify_reply_id:""
          }

          if(superuser != userdetails.userid) {
            socket?.emit("replyNoti",notify);
          }


          let payload = {
                  comment_id:response.PAYLOAD,
                  comment_text:userCommentStr,
                  topicId:topicId,
                  superuser:superuser,
                  username:userdetails.username,
                  profile_image:userdetails.profile_image,
                  userid:userdetails.userid,
                  commentlikecount:0,
                  likeFlag:false,
                  loginUser:userdetails.userid,
                  replycount:0,
                  currentTime:"0 minutes ago",
                  commentisedited:null
                }

          let tempostcomment = [];
          tempostcomment.push(payload);

           dispatch(actionCreators.addPostComment(tempostcomment));
           dispatch(actionCreators.resetReply());

       }


   }

   const cancelPost = () => {
     setuserComment("");
     document.getElementById("postid").innerHTML = "";
   }


  const fileDrop = (e) => {
      e.preventDefault();
  }

  const handlePaste = (e) => {
     var clipboardData, pastedData;

      clipboardData = e.clipboardData || window.clipboardData;
      pastedData = clipboardData.getData('Text');

     if(pastedData.length < 1) {
        e.preventDefault();
     }
  }

   useEffect(() => {

      let commentText = document.getElementById("postid").textContent;

      if(commentText.length < 1) {
          return setpostBoxdisable(true);
       } else {
          return setpostBoxdisable(false);
       }

    }, [userComment]);


  return (
    <div className="Poolpostbox-container" onDrop={fileDrop} >

       <div className="Poolpostbox-profile-wrapper">
          <div className="Pooldetails-profile-img"

          style={{
             backgroundImage:userdetails.profile_image ? `url("${userdetails.profile_image}")` : `url("${defaultprofile}")`,
             backgroundPosition: 'center',
             backgroundSize: 'cover',
             backgroundRepeat: 'no-repeat',
             maxWidth:'100%',
             maxHeight:'100%'
           }}>

          </div>
       </div>

       <div className="Poolpostbox-emptyspace"></div>

        <div className="Poolpostbox-txt-wrapper">
          <div id="postid" className="Poolpostbox-formcontrol" data-placeholder="Drop a comment"
           onInput={(event) => handleTextarea(event)} contentEditable="true"
           onClick={handleTextareaClick} suppressContentEditableWarning={true}
           onPaste={(e) => handlePaste(e)} >

           </div>
        </div>

        <div className="Poolpostbox-emptyspace-post"> </div>

        <div className="Poolpostbox-post-wrapper">

          {postBoxdisable ?
            <>
              <div className="Poolpostbox-postbtn-icn-disable" >
                <TiArrowRightThick />
              </div>
              <div className="Poolpostbox-emptyspace"> </div>
             <div className="Poolpostbox-cancelbtn-icn-disable" >
                <ImCross />
              </div>
            </>
            :
            <>
              <div className="Poolpostbox-postbtn-icn" onClick={() => handlePost(props.topicId,props.superuser)} >
                <TiArrowRightThick />
              </div>
              <div className="Poolpostbox-emptyspace"> </div>
              <div className="Poolpostbox-cancelbtn-icn" onClick={cancelPost} >
                 <ImCross />
               </div>
            </>
          }

       </div>

    </div>
  );
}

export default Poolpostbox;
