import * as actionTypes from "../actions/actionTypes";

const initialState = {
  devicetype:""
};

const deviceReducer = (state = initialState, action) => {

  switch (action.type) {

    case actionTypes.DEVICE_TYPE:

      return {
        ...state,
        devicetype:action.payload
      };

    default:
      return state;
  }
};

export default deviceReducer;
