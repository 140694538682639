import React from 'react';
import { useEffect,useState,useCallback,useRef } from 'react';
import { useSelector } from "react-redux";
import { useParams,useNavigate } from "react-router-dom";
import './Followers.css';

import { getFollower,getFollower1 } from '../../../../redux/thunk/follower/fetchdata';
import { getUserAccountDetails,addFollower,removeFollower } from '../../../../redux/thunk/follower/fetchdata';

import defaultProfile from '../../../../assets/default/user.png';
import Spinner from '../../../../spinner/classic-spiner/Classicspiner';
import Buttonspiner from '../../../../spinner/button-spiner-rqs/Buttonspiner';

function Followers(props) {

  const params = useParams();
  const navigate = useNavigate();
  const observer = useRef();

  const userdetails = useSelector((state) => state.userReducer.userdetails);

  const [follower,setFollower] = useState("");
  const [waiting,setWaiting] = useState("");
  const [loading,setLoading] = useState(false);

   {/* pagination */}

  const [page,setPage] = useState(1);
  const [hasMore,setHasMore] = useState(true);
  const [nextpage,setNextpage] = useState("");

  const lastfollowerElementRef = useCallback(node => {

      if (observer.current)  {
         observer.current.disconnect()
      }

      observer.current = new IntersectionObserver(entries => {
          if (entries[0].isIntersecting && hasMore) {
              setPage(nextpage);
          }
      })

      if(node) {
          observer.current.observe(node)
       }
 })

    {/* navigate to user profile */}

  const handleProfile = (userId) => {
      // props.clearAccountdetails();
      navigate(`/homepage/account/${userId}`);
  }

    {/* unfollow user */}

  const handleunFollow = async (event,userid,followerId,index) => {

       event.stopPropagation();
       setWaiting(index);

       let followerObj = {
           userid:followerId,
           followerId:userid
        }

        const response = await removeFollower(followerObj);

        if(response.STATUSCODE === 0) {
           const newArray = [...follower];
           newArray[index].isFollowing = false;
           setFollower(newArray);
           setWaiting("");
        }

  }

    {/* follow user */}

  const handleFollow = async (event,userid,followerId,index) => {

     event.stopPropagation();
     setWaiting(index);

     let followerObj = {
         userid:followerId,
         followerId:userid
      }

      const response = await addFollower(followerObj);

      if(response.STATUSCODE == 0) {
         const newArray = [...follower];
         newArray[index].isFollowing = true;
         setFollower(newArray);
         setWaiting("");
      }

  }

    {/* Fetch the follower  */}

  useEffect(async () => {

     if(String(params.accId).length > 0 && Object.keys(userdetails).length > 0) {

       let limit = 10;

       let followerObj = {
           userid:params.accId,
           loginUserid:userdetails.userid
        }

      setLoading(true);

       if(page) {

          const response = await getFollower(page,limit,followerObj);

            if(response.STATUSCODE == 0) {
               if(response.NEXT.next == undefined) {
                  setFollower([...follower,...response.PAYLOAD]);
                  setHasMore(false);
              } else {
                  setFollower([...follower,...response.PAYLOAD]);
                  setNextpage(response.NEXT.next.page)
              }
            }
        }

     }

     setLoading(false);

   },[userdetails,params.accId,page])


return (

  <div className="followerwrapper">

    {follower && follower.map((element,index) => {
      return (
        <div  key={index} ref={lastfollowerElementRef} className="followerouter" onClick={() => handleProfile(element.followerDetails.userid)}>

          <div className="followerscontainer">

            <div className="followersprfwrapper">
             <div className="followers-profileimg"
                style={{
                  backgroundImage:element && element.followerDetails.profile_image ? `url("${element.followerDetails.profile_image}")` : `url("${defaultProfile}")`,
                  backgroundPosition:'center',
                  backgroundSize:'cover',
                  backgroundRepeat:'no-repeat',
                  maxWidth:'100%',
                  maxHeight:'100%'
               }}></div>

             <div>&nbsp;&nbsp;</div>

                <div className="followers-username">
                   {element.followerDetails.username}
                </div>

            </div>

            {element.isFollowing !== "self" ?
           <>
           <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>

           {waiting === index ?
             <div className="account-btnwrapper">
         <button className="account-loading">
            &nbsp;&nbsp;<Buttonspiner />&nbsp;&nbsp;
         </button>
             </div> :
              <>
                {element.isFollowing  ?
           <div className="followers-btn"
             onClick={(event) => handleunFollow(event,userdetails.userid,element.followerDetails.userid,index)}>
              &nbsp;&nbsp;UnFollow&nbsp;&nbsp;
            </div> :
            <div className="followers-btn"
              onClick={(event) => handleFollow(event,userdetails.userid,element.followerDetails.userid,index)}>
              &nbsp;&nbsp;Follow&nbsp;&nbsp;
            </div>
           }
              </>
             }
           </>
         : null }

          </div>

          <div>&nbsp;</div>

        </div>
        )
     })}

    {loading ? <Spinner /> : null }

    <div className="followerhtspace">
    </div>

  </div>

  );
}

export default Followers;
