import * as actionTypes from "../actions/actionTypes";

const initialState = {
    userdetails:{}
};

const userReducer = (state = initialState, action) => {

  switch (action.type) {

    case actionTypes.ADD_USER_DETAILS:

      return {
        ...state,
        userdetails:action.payload
      };

    case actionTypes.UPDATE_EMAIL_ID:

      let temp = {
         firstname:state.userdetails.firstname,
         lastname:state.userdetails.lastname,
         mobile:state.userdetails.mobile,
         profile_image:state.userdetails.profile_image,
         useremail:action.payload.useremail,
         userid:state.userdetails.userid,
         username:state.userdetails.username,
         isEmailVerified:action.payload.isEmailVerified
      }

       return {
         userdetails:temp
        }

    case actionTypes.UPDATE_USERNAME:

      let tempusername = {
         firstname:state.userdetails.firstname,
         lastname:state.userdetails.lastname,
         mobile:state.userdetails.mobile,
         profile_image:state.userdetails.profile_image,
         useremail:state.userdetails.useremail,
         userid:state.userdetails.userid,
         username:action.payload
      }

       return {
         userdetails:tempusername
        }

    case actionTypes.UPDATE_EMAIL_VERFICATION:


       let tempObj = {...state.userdetails}

       tempObj.isEmailVerified = action.payload;

          return {
            userdetails:tempObj
          };

    default:
      return state;
  }
};

export default userReducer;
