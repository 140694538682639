

// Get active pool

export const getactivePool = async(userid) => {

  try {

    let url = '/getactivepool';

    const requestOptions = {
         method: 'POST',
         headers: {
           'Content-Type': 'application/json',
           'Accept': 'application/json',
         }, body:JSON.stringify({
              userid:userid
          })
    };

     const response = await fetch(url,requestOptions);

     if(!response.ok) {
        throw {
          STATUSCODE:1,
          STATUS:"ERROR",
          MESSAGE:"Something broke!" }
      } else {
         const result = await response.json();
         return result;
      }

  } catch(error) {
      return error;
  }

}

export const getTeamDetails = async(topicid) => {

  try {

    let url = '/getteamedetails';

    const requestOptions = {
         method: 'POST',
         headers: {
           'Content-Type': 'application/json',
           'Accept': 'application/json',
         }, body:JSON.stringify({
              topicid:topicid
          })
    };

     const response = await fetch(url,requestOptions);

     if(!response.ok) {
        throw {
          STATUSCODE:1,
          STATUS:"ERROR",
          MESSAGE:"Something broke!" }
      } else {
         const result = await response.json();
         return result;
      }

  } catch(error) {
      return error;
  }

}

export const getTimelineDetails = async(topicid) => {

   try {

     let url = '/timelinedetails';

     const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          }, body:JSON.stringify({
               topicid:topicid
           })
     };

      const response = await fetch(url,requestOptions);

      if(!response.ok) {
         throw {
           STATUSCODE:1,
           STATUS:"ERROR",
           MESSAGE:"Something broke!" }
       } else {
          const result = await response.json();
          return result;
       }

   } catch(error) {
      return error;
   }

}

export const updateTimeline = async(obj) => {

  try {

    let url = '/updatetimeline';

    const requestOptions = {
         method: 'POST',
         headers: {
           'Content-Type': 'application/json',
           'Accept': 'application/json',
         }, body:JSON.stringify({
              timelineobj:obj
          })
    };

     const response = await fetch(url,requestOptions);

     if(!response.ok) {
        throw {
          STATUSCODE:1,
          STATUS:"ERROR",
          MESSAGE:"Something broke!" }
      } else {
         const result = await response.json();
         return result;
      }

  } catch(error) {
     return error;
  }

}

export const topicsuperuser = async(topicid) => {

  try {

    let url = '/topicsuperuser';

    const requestOptions = {
         method: 'POST',
         headers: {
           'Content-Type': 'application/json',
           'Accept': 'application/json',
         }, body:JSON.stringify({
              topicid:topicid
          })
    };

     const response = await fetch(url,requestOptions);

     if(!response.ok) {
        throw {
          STATUSCODE:1,
          STATUS:"ERROR",
          MESSAGE:"Something broke!" }
      } else {
         const result = await response.json();
         return result;
      }

  } catch(error) {
     return error;
  }


}
