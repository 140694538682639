import React from 'react';
import './Opscreen.css';
import { RiCloseFill } from 'react-icons/ri';
import { useState,useEffect,useRef } from 'react';
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { registerPost,getUserInfo,getUsermobInfo,checkEmail } from '../../../../redux/thunk/gateway/fetchdata';
import { addUserPin } from '../../../../redux/thunk/security/fetchdata';
import { MdOutlineCancel } from 'react-icons/md';
import * as actionCreators from "../../../../redux/actions/actionCreators";
import { v4 as uuidV4 } from "uuid";
import Fpasswordchange from '../../../landingpage/fpassword/Fpasswordchange';
import Fpasswordcode from '../../../landingpage/fpassword/Fpasswordcode';
import Classicspiner from '../../../../spinner/classic-spiner/Classicspiner';

import Recoverycode from './Recoverycode';
import Pinscreen from './Pinscreen';
import { checkuserpin } from  '../../../../redux/thunk/security/fetchdata';

function Opscreen(props) {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [pinflag,setpinflag] = useState(false);
  const [revflag,setrevflag] = useState(false);

  const [useremail,setUseremail] = useState(null);
  const [usermobile,setUsermobile] = useState(null);
  const [verifyid,setVerifyid] = useState(null);
  const [loading,setLoading] = useState(false);

  const handleEmail = async () => {

    setLoading(true);

     let passobj = {
        useremail:props.useremail
      }

    const response = await getUserInfo(passobj);

    if(response.STATUSCODE === 0) {

      setLoading(false);

       if(response.PAYLOAD.result != null) {

           if(response.PAYLOAD.useremail == 'NA') {
                setUseremail("");
            } else {
                setUseremail(response.PAYLOAD.result.useremail);
                setVerifyid(response.PAYLOAD.result.verifyid);
                setpinflag(true);
            }


       } else {

       }

    } else  {
        setLoading(false);
    }



  }

  const handleRecoverycode = () => {
      setrevflag(true);
  }

  const handleCl = () => {
     props.handleBack();
  }

  const handlePinclose = () => {
    setpinflag(false);
  }

  const handleRecclose = () => {
    setrevflag(false);
  }

  useEffect(() => {
    setUseremail(props.useremail)
  },[props])

 return (

    <div className="sharewrapper">
      <div className="sharewrapper-wrapper">

        <div className="Opscreen-header">

          <div className="Otpscreen-header-right">
            <RiCloseFill />
          </div>

         <div className="Ops-header">Reset Password</div>
          <div className="Otpscreen-header-left" onClick={() => handleCl()}>
            <RiCloseFill />
          </div>
        </div>

          <div style={{height:10}} />

          <div className="Opscreen-title" onClick={() => handleEmail()}>
            <div className="Opsc">Using Email</div>
          </div>

          <div className="Opscreen-title" onClick={() => handleRecoverycode()}>
            <div className="Opsc">Recovery Code</div>
          </div>

          <div style={{height:20}} />

          {loading ?
           <div className="changepassword-spinner">
             <Classicspiner />
           </div> : null }


      {pinflag ?
        <Fpasswordcode useremail={useremail} usermobile={props.usermobile} verifyid={verifyid} handlePinclose={handlePinclose} />
       : null }

       {revflag ?
          <Recoverycode useremail={useremail} usermobile={props.usermobile} verifyid={verifyid} handleRecclose={handleRecclose} />
        : null }

      </div>
    </div>

   )

}

export default Opscreen;
