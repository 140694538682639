import React from 'react';
import { useState,useEffect,useCallback,useRef } from 'react';
import { useSelector,useDispatch } from "react-redux";
import { useNavigate,useLocation,useParams } from "react-router-dom";

import { BsHandThumbsUp } from 'react-icons/bs';
import { BsHandThumbsUpFill } from 'react-icons/bs';
import { HiOutlineDotsHorizontal } from 'react-icons/hi';

import * as actionCreators from "../../../../redux/actions/actionCreators";
import { getUserReply,addLikeReply,getUserComment,undoLikeReply } from '../../../../redux/thunk/comment/fetchdata';

import Poolcommentbox from './Poolcommentbox';
import PoolEditDelete from './PoolEditDelete';
import Pooleditcomment from './Pooleditcomment';
import Pooldelete from './Pooldelete';
import Poolreport from './Poolreport';
import Successnotify from '../../../../notify/Successnotify';
import Classicspiner from '../../../../spinner/classic-spiner/Classicspiner';

import img4 from '../../../../assets/default/user.png';

import './Poolreplylist.css';

function Poolreplylist(props) {

  const authUser = useSelector((state) => state.authReducer.authflag);
  const notify = useSelector((state) => state.notifyReducer.notify_id);
  const userdetails = useSelector((state) => state.userReducer.userdetails);
  const replylist = useSelector((state) => state.replyReducer.reply);
  const rplycomment_id = useSelector((state) => state.replyReducer.comment_id);
  const observer = useRef();

  const [subreplyIndex,setsubreplyIndex] = useState("");
  const [likebtn,setLikebtn] = useState(true);
  const [editId,seteditId] = useState("");
  const [editcmtId,seteditcmtId] = useState("");
  const [editdelId,seteditdelId] = useState(false);
  const [replylist1,setReplylist] = useState("");
  const [commentid,setCommentid] = useState("");
  const [reportId,setreportId] = useState(false);
  const [notifyMsg,setNotifyMsg] = useState("");
  const [displayflashmsg,setDisplayflashmsg] = useState(false);
  const [loading,setLoading] = useState(true);

  const[reply_id,setReply_id] = useState("");
  const[loginUser,setLoginUser] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentLocation = useLocation();
  const params = useParams();

  const [rppage,setPage] = useState(1);
  const [hasMore,setHasMore] = useState(true);
  const [temp,setTemp] = useState();

  const lastRplyElementRef = useCallback(node => {

    if (observer.current) observer.current.disconnect()
       observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasMore) {
          setPage(temp);
       }
    })

      if(node) {
          observer.current.observe(node)
       }
  })

  const handleReply = (id) => {

     if(authUser) {

     } else {
        navigate("/signin", { state: { path: currentLocation.pathname}});
        return;
     }

    setsubreplyIndex(id);
  }

  const closeReply = () => {
    setsubreplyIndex("")
  }

  const handleCancelReply = () => {
    setsubreplyIndex("");
  }

  const cancelEdit = () => {
     seteditcmtId("");
  }

  const cancelEditPanel = () => {
    seteditId("");
  }

  const handleEdit = (index) => {
     seteditId(index);
  }

  const handleEditCmt = (id) => {
     seteditcmtId(id)
     seteditId("");
  }

  const handleDelCmt = (reply_id,cmtid,loginUser) => {
    seteditId("");
    seteditdelId(true);
    setReply_id(cmtid);
    setLoginUser(loginUser);
  }

  const handlecancelDelete = () => {
      seteditdelId(false);
  }

  const refReplylist = (PAYLOAD) => {
     setReplylist(PAYLOAD);
  }

  const refReplylista = async(commentreply_id) => {
    const response = await getUserReply(commentreply_id);
    setReplylist(response.PAYLOAD);
  }

  const handleFocus = () => {
    let cmtId = document.getElementById("replycmtid")
    cmtId.focus();
  }

  const handleReport = (comment_id,loginUser) => {
         setLoginUser(loginUser);
         setCommentid(comment_id);
         setreportId(true);
         seteditId("");
  }

  const handlecancelReport = () => {
    setreportId(false);
  }

  const handleNotifymsg = (msg) => {
    setNotifyMsg(msg);
    setDisplayflashmsg(true);
  }

  const closeFlashMsg = () => {
    setDisplayflashmsg(false);
  }

  const handleAccount = (userid) => {
    if(props.loginUser) {
        navigate(`/homepage/account/${userid}`)
     } else {
        navigate(`/account/${userid}`)
     }
  }

  const handLike = async(reply_id,flag) => {

     if(authUser) {

     } else {
        navigate("/signin", { state: { path: currentLocation.pathname}});
        return;
     }

     if(flag == true) {

        const response = await undoLikeReply(reply_id);

        if(response.STATUSCODE == 0) {
           dispatch(actionCreators.rpyunlikeComment(reply_id));
       }

     }

     if(flag == false) {

       const response = await addLikeReply(reply_id);

       if(response.STATUSCODE == 0) {
           dispatch(actionCreators.rpylikeComment(reply_id));
      }

     }

  }

  useEffect(async() => {

   if(props.commentreply_id) {

     let lim = 5;

     let obj = {
      userid:userdetails.userid,
      commentreply_id:props.commentreply_id,
      page:rppage,
      limit:lim
    }

     if(obj.page) {

    const result = await getUserReply(obj);

    if(result) {

      let ft = result[0];

      if(ft.next == undefined) {
         dispatch(actionCreators.addReplyComment(result.slice(1)));
         setHasMore(false);
       } else {
         dispatch(actionCreators.addReplyComment(result.slice(1)));
         setTemp(ft.next.page)
      }

   }

 }

   }

 },[props.commentreply_id,rppage]);


return (
  <div className="Poolreplylist-wrapper" >

    {replylist && replylist.map((element,index) => {

      return (

       <div key={element.reply_id}>
        {element.commentreply_id == props.commentreply_id ?
         <div  id="replycmtid" ref={lastRplyElementRef} onFocus={(event) => handleFocus(event)}>
           <div className="Poolreplylist-details">

              {/* Profile Image of User for Reply */}
             <div className="Poolreplylist-profile-wrapper">
               <div className="Poolreplylist-profile-img" onClick={() => handleAccount(element.replyuserid)}>

                  <div className="Poolcommentlist-profile-img" style={{
                     backgroundImage:`url("${element.replyuserdetail.profile_image}")`,
                     backgroundPosition: 'center',
                     backgroundSize: 'cover',
                     backgroundRepeat: 'no-repeat',
                     maxWidth:'100%',
                     maxHeight:'100%'
                   }}/>

                </div>
              </div>

             <div className="Poolreplylist-emptyspace"> </div>

                {/* Reply username and Comment */}
               <div className="Poolreplylist-cmt">
                  <div className="Poolreplylist-cmt-name">
                    <strong className="Poolreplylist-cmt-txt" onClick={() => handleAccount(element.replyuserid)}>{element.replyuserdetail.username}</strong>
                    <p className="Poolreplylist-cmt-time">
                     &nbsp;&nbsp;<small>{element.currentTime}</small>
                     &nbsp;{element.replyisedited ? "(edited)" : null}
                    </p>
                  </div>
                  <div className="Poolreplylist-emptycml"> </div>
                  <div className="Poolreplylist-cmt-comment">

                  { editcmtId === index ?
                   <Pooleditcomment
                     userComment={element.reply_text}
                     cancelEdit={cancelEdit}
                     reply_id={element.reply_id}
                     commentreply_id={rplycomment_id}
                     refReplylist={refReplylist}
                     typeCmt="replycomment"  />
                   : null }

                  {editcmtId === index ?  null :
                     <div dangerouslySetInnerHTML={{__html: `<span class=Poolreplylist-cmt-replyto>${  `${element.replyto ?  "@" + element.replyto : "" }` } </span>` + `<span class=Poolreplylist-cmt-reply>${element.reply_text}</span>` }} />
                   }
                </div>



             </div>
           </div>

            { editcmtId === index ? null :
              <div className="Poolreplylist-panel">
                 <div className="Poolreplylist-profile-ept"></div>

                 <div className="Poolreplylist-panel-btn">

                 {/* Reply Like Button  */}
               <div className="Poolreplylist-up" onClick={() => handLike(element.reply_id,element.likeFlag)}>
                    {element.likeFlag ?
                   <span className="Pooldetails-tflag">
                     <BsHandThumbsUpFill />
                   </span> :
                   <span className="Pooldetails-fflag">
                     <BsHandThumbsUp />
                   </span>
                 }
               </div>


                 {/* Reply Like Count */}
              <div className="Poolreplylist-liekcount">
                {element.replylikecount === 0 ?
                    <span> </span> :
                    <span>
                    {element.replylikecount}
                   </span>
                 }
               </div>


              <div className="Poolreplylist-emptyspace"> </div>
              <div className="Poolreplylist-emptyspace"> </div>
              <div className="Poolreplylist-edit" onClick={() => handleReply(index)}>Reply</div>
              <div className="Poolreplylist-profile-ept"></div>

            {props.loginUser ?
              <div className="Poolreplylist-edit"
                onClick={() =>  handleEdit(index)}>
                <HiOutlineDotsHorizontal />

                {editId === index ?
                  <div>
                     <PoolEditDelete cancelEditPanel={cancelEditPanel}
                        handleEditCmtlst={handleEditCmt}
                        handleDelCmtlst={handleDelCmt}
                        handleReport={handleReport}
                        cmtListid={index}
                        comment_id={element.reply_id}
                        loginUser={props.loginUser}
                        superuser={props.superuser}
                        userid={element.replyuserdetail.userid}
                       />
                   </div>
                    : null
                  }
               </div> : null }

                 </div>
              </div>
            }

             {/* Comment box for Reply */}
           {subreplyIndex === index ?
              <div className="Poolreplylist-panel-cmt">
               <Poolcommentbox
                   cancelReply={handleCancelReply}
                   Listname={"Replylist"}
                   replyto={element.replyuserdetail.username}
                   userid={element.replyuserdetail.userid}
                   superuser={props.superuser}
                   commentreply_id={props.commentreply_id}
                   loginUser={props.loginUser}
                   rplyIndex={props.rplyIndex}

                   handleShowReplycom={props.handleShowReplycom}
                   handleHideReply={props.handleHideReply}

                   notifyto={element.replyuserdetail.userid}
                   topicId={props.topicId}
                 />
               </div> : null
            }

        </div> : null }
      </div>

     )}
   )}

     {hasMore ? <div className="Poolreplylist-spinner">
                <Classicspiner />
             </div>
     : null }

   {editdelId === true ?
     <div className="Poolreplylist-del">
       <Pooldelete handlecancelDelete={handlecancelDelete}
         rplyIndex={reply_id}
         loginUser={loginUser}
         refReplylist={refReplylista}
         commentreply_id={rplycomment_id}
         listName={"Replylist"} />
     </div>
      : null
   }

   {reportId === true ?
      <div className="Poolcommentlist-del">
        <Poolreport
          handlecancelReport={handlecancelReport}
          handleNotifymsg={handleNotifymsg}
          commentid={commentid}
          loginUser={loginUser}
          listName={"Replylist"} />
      </div>
     : null  }

   {displayflashmsg ?
     <div className="Poolcommentlist-notify">
        <Successnotify notifyMsg={notifyMsg} closeFlashMsg={closeFlashMsg}  />
     </div> : null }

   </div>
  );
}

export default Poolreplylist;
