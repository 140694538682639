import * as actionTypes from "../actions/actionTypes";

const initialState = {
   callFlag:false
};

const callReducer = (state = initialState, action) => {

  switch (action.type) {

    case actionTypes.CALL_START:

      return {
         callFlag:action.payload
      };

    case actionTypes.CALL_CANCEL:

      return {
        callFlag:action.payload
      };

    default:
      return state;
  }
};

export default callReducer;
