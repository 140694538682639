import './Poolreport.css';
import { BiCircle } from 'react-icons/bi';
import { BiCheckCircle } from 'react-icons/bi';
import { MdOutlineClose } from 'react-icons/md';
import { reptUserComment,reptUserReply } from '../../../../redux/thunk/comment/fetchdata';
import React, { useRef,useState,useEffect } from 'react';

function Poolreport(props) {

   const [flagindex,setFlagindex] = useState(null);
   const [reportcomment,setReportcomment] = useState("");

   const myRef = useRef(null);

   const array = ['Harassment','Spam','Hate Speech','Violence',
                  'Suicide or Self-Harm','Misinformation',
                  'Terrorism','Sexually Explicit Content']

   const handleCheck = (index,element) => {
        setFlagindex(index)
        setReportcomment(element);
   }

   const handleCancel = () => {
      props.handlecancelReport();
   }

   const handleSubmit = async(commentid,loginUser) =>  {

     if(props.listName == "Commentlist") {
       const response = await reptUserComment(commentid,loginUser,reportcomment);
        props.handlecancelReport();
         if(response.STATUSCODE === 0) {
           props.handleNotifymsg(response.MESSAGE);
         }
      }

      if(props.listName == "Replylist") {
        const response = await reptUserReply(commentid,loginUser,reportcomment);
         props.handlecancelReport();
          if(response.STATUSCODE === 0) {
            props.handleNotifymsg(response.MESSAGE);
          }
       }

   }

   const handleClick = (e) => {

     var source = document.getElementById('rptid');

      if(source === null) {
        return;
      }

     if (myRef.current.contains(e.target)) {
       // inside click
       return;
     }

     if (myRef.current.contains(e.target) === false) {
       props.handlecancelReport();
     }
     // outside click
   };

   useEffect(() => {
      document.addEventListener("mousedown", handleClick);
    return () => {
       document.removeEventListener("mousedown", handleClick, false);
     };
   }, []);


  return (
    <div className="report-modal">
      <div ref={myRef} id="rptid" className="report-modal_content">

        <div className="report-title">
          <div>Report</div>
          <div className="report-close" onClick={handleCancel}>
            <MdOutlineClose/>
          </div>
        </div>

        <div className="report-line"> </div>

      {array.map((element,index) => {
          let tempIndex = index + 1;
        return (
        <>
         <div className="report-wrapper">
             <div className="report-icon" onClick={() => handleCheck(tempIndex,element)}>
               {tempIndex == flagindex ? <BiCheckCircle/> : <BiCircle />  }
                <div className="report-text"><small>&nbsp;{element}</small></div>
             </div>
           </div>
          <br/>
        </>
        )
      })}

        <div className="report-btn-wrapper">
         {flagindex ?
           <button className="report-btn" onClick={() => handleSubmit(props.commentid,props.loginUser)}>
              Submit
           </button>
           :
           <button  disabled className="report-btn-disable" >
              Submit
           </button>
          }
        </div>

       </div>
    </div>
  )
}

export default Poolreport;
