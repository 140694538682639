import React, { useState,useEffect,useRef,useContext } from 'react';
import * as actionCreators from "../../../../redux/actions/actionCreators";
import { addUserReply } from '../../../../redux/thunk/comment/fetchdata';
import { useDispatch,useSelector } from "react-redux";
import { useNavigate, useLocation,useParams } from "react-router-dom";
import {  SocketContext  } from "../../../../context/SocketContext";
import { TiArrowRightThick } from 'react-icons/ti';
import { ImCross } from 'react-icons/im';
import { HiArrowSmRight } from 'react-icons/hi';

import './Poolcommentbox.css';
import img4 from '../../../../assets/default/user.png';

function Poolcommentbox(props) {

   const [userComment,setuserComment] = useState('');
   const [postBoxdisable,setpostBoxdisable] = useState(true);

   const userdetails = useSelector((state) => state.userReducer.userdetails);
   const authUser = useSelector((state) => state.authReducer.authflag);
   const { socket } = useContext(SocketContext);

   const dispatch = useDispatch();
   const myRefin = useRef(null);
   const navigate = useNavigate();
   const currentLocation = useLocation();
   const params = useParams();

   const handleTextarea = (event) => {
      setuserComment(event.target.innerHTML);
   }

   const handlePost = async(listName) => {

     if(authUser) {

     } else {
        navigate("/signin", { state: { path: currentLocation.pathname}});
        return;
     }

     var userCommentStr = userComment.replace(/^(\ ?<div><br><(\/)?div>\ ?)+|(\ ?<div><br><(\/)?div>\ ?)+$/, '');

     if(listName === "Commentlist") {

        let replySub2 = {
             commentreply_id:props.comment_id,
             reply_text:userCommentStr,
             replyuserid:props.loginUser,
             superuser:props.superuser,
             replylikecount:0,
             replyisdeleted:false
        }

        const response = await addUserReply(replySub2);

        if(response.STATUSCODE === 0) {

           let notify = {
             notifyto:props.notifyto,
             commentTxt:userCommentStr,
             notifyfrom:props.loginUser,
             notify_topicid:props.topicId,
             notify_type:"replied",
             notify_comment_id:props.comment_id,
             notify_reply_id:response.PAYLOAD
          }

           let replyobj = {
             commentreply_id:props.comment_id,
             currentTime:"0 minute ago",
             likeFlag:false,
             reply_id:response.PAYLOAD,
             reply_text:userCommentStr,
             replyisedited:null,
             replylikecount:0,
             replyto:null,
             replyuserdetail:userdetails
          }

           let temp_replyobj = [];
           temp_replyobj.push(replyobj);

           if(props.replycount) {

               if(props.rplyIndex) {
                 dispatch(actionCreators.addReplyComment(temp_replyobj));
               } else {

                }

            } else {
                 dispatch(actionCreators.addCountComment(props.comment_id));
            }


           setuserComment("");
           setpostBoxdisable(true);

            props.cancelReply();


           if(props.notifyto != userdetails.userid) {
              socket?.emit("replyNoti",notify);
            }

        }


    }

     if(listName === "Replylist") {


       let replySub = {
         commentreply_id:props.commentreply_id,
         reply_text:userCommentStr,
         replyuserid:props.loginUser,
         superuser:props.superuser,
         replylikecount:0,
         replyisdeleted:false,
         replyto:props.replyto
       }


     const response = await addUserReply(replySub);

       if(response.STATUSCODE === 0) {

          let notify = {
             notifyto:props.notifyto,
             commentTxt:userCommentStr,
             notifyfrom:props.loginUser,
             notify_topicid:props.topicId,
             notify_type:"replied",
             notify_comment_id:props.commentreply_id,
             notify_reply_id:response.PAYLOAD
          }

           setuserComment("");
           setpostBoxdisable(true);
           props.cancelReply();


         if(props.notifyto != userdetails.userid) {
            socket?.emit("replyNoti",notify);
          }

           let subrplyobj = {
              commentreply_id:props.commentreply_id,
              currentTime:"0 minutes ago",
              likeFlag: false,
              reply_id:response.PAYLOAD,
              reply_text:userCommentStr,
              replyisedited: null,
              replylikecount:0,
              replyto:props.replyto,
              replyuserdetail:userdetails,
              replyuserid:props.loginUser,
              superuser:props.superuser
             }

          dispatch(actionCreators.addSubReply(subrplyobj));

      }


    }

   }

   const cancelPost = () => {
     setuserComment("");
   }

   const handleCancel = (name) => {
     props.cancelReply(name);
   }

   const handlePaste = (e) => {
      var clipboardData, pastedData;

       clipboardData = e.clipboardData || window.clipboardData;
       pastedData = clipboardData.getData('Text');

      if(pastedData.length < 1) {
         e.preventDefault();
      }
   }

   const fileDrop = (e) => {
       e.preventDefault();
   }

   useEffect(() => {

      myRefin.current.focus();

     let comment = userComment.trim();

      if(comment.length >= 1) {
         setpostBoxdisable(false);
      } else {
        setpostBoxdisable(true);
      }

    }, [userComment]);

  return (
    <div className="Poolcommentbox-container">

       <div className="Poolcommentbox-profile-wrapper">
          <div className="Poolcommentbox-profile-img"

           style={{
              backgroundImage:userdetails.profile_image ? `url("${userdetails.profile_image}")` : `url("${img4}")`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              maxWidth:'100%',
              maxHeight:'100%'
            }}>

          </div>
       </div>

       <div className="Poolcommentbox-emptyspace"> </div>

        <div className="Poolcommentbox-txt-wrapper">
          <div id="postid" className="Poolcommentbox-formcontrol"
           data-placeholder={`reply@${props.replyto}`} onInput={(event) => handleTextarea(event)}
           ref={myRefin} contentEditable="true" onPaste={(e) => handlePaste(e)} onDrop={fileDrop} >
           </div>
        </div>


      <div className="Poolpostbox-emptyspace-post"> </div>

        <div className="Poolcommentbox-post-wrapper">

          {postBoxdisable ?
            <>
              <div className="Poolcommentbox-postbtn-icn-disable" >
                <TiArrowRightThick />
              </div>
              <div className="Poolcommentbox-emptyspace"> </div>
             <div className="Poolcommentbox-cancelbtn-icn" onClick={handleCancel} >
                <ImCross />
              </div>
            </>
            :
            <>
              <div className="Poolcommentbox-postbtn-icn" onClick={() => handlePost(props.Listname)} >
                <TiArrowRightThick />
              </div>
              <div className="Poolcommentbox-emptyspace"> </div>
              <div className="Poolcommentbox-cancelbtn-icn" onClick={() => handleCancel("deepak")} >
                 <ImCross />
               </div>
            </>
          }

       </div>

    </div>
  );
}

export default Poolcommentbox;
