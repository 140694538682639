import React from 'react';
import './Nomatch.css';
import img from './404.png';

function Nomatch() {
  return (
    <div className="nm-wrapper">
     <img src={img} />
    </div>
  );
}

export default Nomatch;
