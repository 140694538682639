import * as actionTypes from "../actions/actionTypes";

const initialState = {
   redirectpath:null
  };

const redirectReducer = (state = initialState, action) => {

  switch (action.type) {

    case actionTypes.SETREDIRECTPATH:

      return {
        redirectpath:action.payload
      };

    default:
      return state;
  }
};

export default redirectReducer;
