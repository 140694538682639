import React, { useEffect,useState,useContext } from 'react';
import { useSelector,useDispatch } from "react-redux";
import { useParams,useNavigate,Route } from 'react-router-dom';
import {  SocketContext  } from "../../../../context/SocketContext";
import { getTeamDetails } from '../../../../redux/thunk/active/fetchdata';
import { getmeetingStatus } from '../../../../redux/thunk/meeting/fetchdata';
import { topicsuperuser } from '../../../../redux/thunk/active/fetchdata';

import * as actionCreators from "../../../../redux/actions/actionCreators";

import './Startcall.css';
import defaultprofile from '../../../../assets/default/user.png';
import Videowindow from '../Videocall/Videowindow';

function Startcall() {

  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const { socket } = useContext(SocketContext);
  const [teamm,setTeamm] = useState("");
  const [callFlag,setcallFlag] = useState(false);

  const team = useSelector((state) => state.teamReducer.team);
  const userdetails = useSelector((state) => state.userReducer.userdetails);
  const topicobj = useSelector((state) => state.activeReducer.topicid);
  const callstart =  useSelector((state) => state.videoReducer.callstart);

  const topicid = useSelector((state) => state.tstReducer.flag);
  const roomstatus = useSelector((state) => state.roomReducer.roomflag);

  const handleCallstart = () => {

     let usersid = team && team.map((item) =>
      {
        let objroom = [
           item.enrolleduserId
         ]
        return objroom
      });

      let userid = userdetails.userid;
      let username = userdetails.username;
      let topic = topicobj?.topic;
      let topicid = topicobj?.topicid;
      let teammember = [].concat(...usersid);

      const data = {
        userid,
        username,
        topicid,
        topic,
        teammember
      }

      socket.emit('request-grp-call',data);

      dispatch(actionCreators.callStart(true))
      dispatch(actionCreators.startCall(!callstart));
      dispatch(actionCreators.tstTopic(topicid));
      dispatch(actionCreators.reqgrpCall(true));

  }

  const handleCalljoin = () => {
    let value = false;

    dispatch(actionCreators.tstTopic(topicobj.topicid));
    dispatch(actionCreators.reqgrpCall(true));
    dispatch(actionCreators.startCall(value));

  }

  const handleCallend = async () => {
    dispatch(actionCreators.startCall(!callstart));
    window.location.reload();
  }

  useEffect(async () => {

     if(topicobj?.topicid) {

       const result = await getmeetingStatus(topicobj?.topicid);

       if(result.STATUSCODE == 0) {

         if(result.PAYLOAD === 1) {
             dispatch(actionCreators.changeRoomFlag(true));
         } else {
            dispatch(actionCreators.changeRoomFlag(false));
         }

     }

     }

     if(topicobj == null) {

       if(params.topicid) {

         const response = await topicsuperuser(params.topicid);

          if(response.STATUSCODE == 0) {

             let obj = {
                topicid:params.topicid,
                topic:response.PAYLOAD.topic,
                superuser:response.PAYLOAD.userid
             }

              dispatch(actionCreators.addTopicId(obj));

          }

       }


     }


  },[topicobj])


  return (
    <>
     {topicobj != null ?
      <div className="ac-wrapper">
        <h4 className="call-list-title">Start Discussion</h4>
        <div className="call-header">

          <div>
            <div className="call-participants">
            <div className="call-online-user">
               {team && team.map((element,index) => {

             return (
                <div key={index} className="call-account-outer">
                 <div className="call-account-profile"
                        style={{
                           backgroundImage:element && element.profileImage ? `url("${element.profileImage}")` : `url("${defaultprofile}")`,
                           backgroundPosition: 'center',
                           backgroundSize: 'cover',
                           backgroundRepeat: 'no-repeat',
                           maxWidth:'100%',
                           maxHeight:'100%',
                        }}
                       />

                       <div className={element.onlineusr ? "call-status-online"
                          : "call-status-offline"} />

                </div>
                   )
                 })}
            </div>
            </div>
          </div>

          <div className="call-empty-height"> </div>

          <div>

           {callstart ?
              <Videowindow /> :
              null
            }

            <div className="call-dock">
              {roomstatus ?
                <button className="call-btn" onClick={() => handleCalljoin()}>Join Call</button>
               : <button className="call-btn" onClick={() => handleCallstart()}>Start Call</button>
               }
            </div>

          </div>

        </div>

      </div> : null }
    </>
  )

}

export default Startcall;
