// Profile details

export const profileDetail = async () => {

 try {

   let url = '/profile';

   const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
    };

   const response = await fetch(url,requestOptions);

    if(!response.ok) {
      throw {
        STATUSCODE:1,
        STATUS:"ERROR",
        MESSAGE:"Something broke!" }
    }  else {
        const result = await response.json();
        return result;
     }

 } catch (error) {
     return error;
  }

}

// Update Profile details

export const profileDetailupdate = async (profileObj) => {

 try {

   let url = '/profiledetailupdate';

   const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body:JSON.stringify({
          profileObj:profileObj
        })
    };

   const response = await fetch(url,requestOptions);

    if(!response.ok) {
       throw {
         STATUSCODE:1,
         STATUS:"ERROR",
         MESSAGE:"Something broke!" }
     } else {
        const result = await response.json();
        return result;
     }

 } catch (error) {
     return error;
  }

}

// Profile photo update

export const profilePhoto = async (formData) => {

 try {

   let url = '/updateprofilephoto';

   const requestOptions = {
        method: 'POST',
        body:formData
    };

   const response = await fetch(url,requestOptions);

   if(!response.ok) {
      throw {
        STATUSCODE:1,
        STATUS:"ERROR",
        MESSAGE:"Something broke!" }
   }  else {
       const result = await response.json();
       return result;
    }

  } catch (error) {
      return error;
   }

}

// Get User Details

export const getUserDetails = async () => {

  try {

     let url = '/userdetails';

     const requestOptions = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          }
     };

     const response = await fetch(url,requestOptions);

      if(!response.ok) {
         throw {
           STATUSCODE:1,
           STATUS:"ERROR",
           MESSAGE:"Something broke!" }
       } else {
          const result = await response.json();
          return result;
       }


    } catch (error) {
        return error;
     }

}

// Get User Notifications

export const getUserNotifications = async(notifyobj) => {

  try {

     let url = '/getusernotification';

     const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          }, body:JSON.stringify({
              notifyto:notifyobj
           })
     };

     const response = await fetch(url,requestOptions);

      if(!response.ok) {
         throw {
           STATUSCODE:1,
           STATUS:"ERROR",
           MESSAGE:"Something broke!" }
       } else {
          const result = await response.json();
          return result;
       }

    } catch (error) {
        return error;
     }

}

// Update User Notifications

export const updateUserNotifications = async(notify_id) => {

  try {

     let url = '/updateusernotification';

     const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          }, body:JSON.stringify({
              notify_id:notify_id
           })
     };

     const response = await fetch(url,requestOptions);

      if(!response.ok) {
         throw {
           STATUSCODE:1,
           STATUS:"ERROR",
           MESSAGE:"Something broke!" }
       } else {
          const result = await response.json();
          return result;
       }

    } catch (error) {
        return error;
     }

}

// Get User Account Details

export const getUserAccountDetails = async(userid,loginUser) => {

  try {

     let url = '/getuseraccountdetails';

     const requestOptions = {
           method: 'POST',
           headers: {
             'Content-Type': 'application/json',
             'Accept': 'application/json'
           }, body:JSON.stringify({
               userid:userid,
               loginUser:loginUser
            })
     };

     const response = await fetch(url,requestOptions);

      if(!response.ok) {
         throw {
           STATUSCODE:1,
           STATUS:"ERROR",
           MESSAGE:"Something broke!" }
       } else {
          const result = await response.json();
          return result;
       }

    } catch (error) {
        return error;
     }

}

 // Get User Friend List

export const getuserfreindlist = async(friendobj) => {

  try {

    let url = '/userfreindlist';

    const requestOptions = {
         method: 'POST',
         headers: {
           'Content-Type': 'application/json',
           'Accept': 'application/json',
         }, body:JSON.stringify({
              friendobj:friendobj
          })
    };

     const response = await fetch(url,requestOptions);

     if(!response.ok) {
        throw {
          STATUSCODE:1,
          STATUS:"ERROR",
          MESSAGE:"Something broke!" }
      } else {
         const result = await response.json();
         return result;
      }

  } catch(error) {
      return error;
  }


}

// Post User Info Data

export const usersinfodata = async(obj) => {

 try {

   let url = '/usersinfodata';

   const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        }, body:JSON.stringify({
             userobj:obj
         })
   };

    const response = await fetch(url,requestOptions);

    if(!response.ok) {
       throw {
         STATUSCODE:1,
         STATUS:"ERROR",
         MESSAGE:"Something broke!" }
     } else {
        const result = await response.json();
        return result;
     }

 } catch(error) {
     return error;
 }



}

// User App Download

export const userappdownload = async(obj) => {

 try {

   let url = '/appdownload';

   const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        }, body:JSON.stringify({
             userobj:obj
         })
   };

    const response = await fetch(url,requestOptions);

    if(!response.ok) {
       throw {
         STATUSCODE:1,
         STATUS:"ERROR",
         MESSAGE:"Something broke!" }
     } else {
        const result = await response.json();
        return result;
     }

 } catch(error) {
     return error;
 }



}
