import React, { useState,useRef,useEffect,useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Routes,Route,NavLink } from "react-router-dom";
import Homescreen from './Homescreen/Homescreen';
import Searchbar from './SearchBar/SearchBar';
import NavBar from '../homepage-topnavbar/Homepage-topnavbar';
import Browserpool from '../pool/Browserpool';
import Useractivepool from '../pool/Useractivepool';
import Timeline from './Timeline/Timeline';
import Friend from './Friend/Friend';
import Publish from './Poolrequest/Publish';
import Homepagemaincontainer from './Homepagemaincontainer';
import NoMatch from '../../../pages/errpage/Nomatch';
import Profile from '../profile&settings/profile/Profile';
import Request from '../profile&settings/request/Request';
import Account from '../profile&settings/account/Account';
import Setting from '../profile&settings/settings/Settings';
import Searchpage from '../searchresult/Searchresult';
import Messages from '../profile&settings/messages/Messages';

import './Homepagewrapper.css';

import home from '../../../assets/Activelist/home.png';
import publish from '../../../assets/Activelist/publish.png';
import friend from '../../../assets/Activelist/friend.png';
import explore from '../../../assets/Activelist/explore.png';


function Homepagewrapper() {

  const navigate = useNavigate();
  const [searchFlag,setsearchFlag] = useState(false);
  const searchMenuflag = useRef(null);

  const showSearch = () => {
     setsearchFlag(true);
  }

  const closeSearch = () => {
     setsearchFlag(false);
  }

  const navPool = () => {
    navigate('/homepage/pool')
  }

  const showProfile = () => {
     navigate('/homepage/profile')
  }

  const showRequest = () => {
     navigate('/homepage/request')
  }

  const showAccount = (accId) => {
     navigate(`/homepage/account/${accId}`)
  }

  const handleSearchMenu = (e) => {

   if (searchMenuflag.current.contains(e.target)) {
     // inside click
     return;
   }

   // outside click
   setsearchFlag(false);

 }

  useEffect(() => {

    document.addEventListener("mousedown", handleSearchMenu);

     return () => {
        document.removeEventListener("mousedown", handleSearchMenu, false);
     };

   }, []);


  return (

     <div className="homapagewrapper">

       <NavBar showSearch={showSearch} navPool={navPool}
               showProfile={showProfile} showRequest={showRequest}
               showAccount={showAccount} />

       <div className="homapagelinkwrapper">

         <div ref={searchMenuflag}>
            {searchFlag &&  <Searchbar closeSearch={closeSearch} /> }
         </div>


         <NavLink className={(navData) => (navData.isActive ? 'homapagelinkbtnactive':'homapagelinkbtn')} to="" end>
            <div className="homapage-btn">
               <img src={home} className="homapage-homeimg" alt="home" />
               <div className="homapage-btntxt">Home</div>
            </div>
         </NavLink>

          <div className="homapagebtn-space"></div>

          <NavLink className={(navData) => (navData.isActive ? 'homapagelinkbtnactive':'homapagelinkbtn')} to="publish">
            <div className="homapage-btn">
              <img src={publish} className="homapage-publishimg"  alt="publish" />
               <div className="homapage-btntxt">Publish</div>
             </div>
           </NavLink>

          <div className="homapagebtn-space"></div>

           <NavLink className={(navData) => (navData.isActive ? 'homapagelinkbtnactive':'homapagelinkbtn' )} to="friend">
             <div className="homapage-btn">
              <img src={friend} className="homapage-freindsimg" alt="friend" />
              <div className="homapage-btntxt">Friends</div>
             </div>
           </NavLink>

           <div className="homapagebtn-space"></div>

           <NavLink className={(navData) => (navData.isActive ? 'homapagelinkbtnactive':'homapagelinkbtn' )} to="useractivepool">
             <div className="homapage-btn">
              <img src={explore} className="homapage-exploreimg" alt="explore" />
              <div className="homapage-btntxt">Pool</div>
             </div>
           </NavLink>

       </div>

        <div className="homapagecontainer">
          <Routes>
               <Route path="/" element={<Homescreen />}></Route>
               <Route path="publish/*" element={<Publish />}></Route>
               <Route path="friend/" element={<Friend />}></Route>
               <Route path="Active/*" element={<Homepagemaincontainer />}></Route>
               <Route path="pool/*" element={<Browserpool />}></Route>
               <Route path="useractivepool/*" element={<Useractivepool />}></Route>
               <Route path="searchresult/*" element={<Searchpage />}></Route>
               <Route path="timeline/" element={<Timeline />}></Route>
               <Route path="setting/*" element={<Setting />}></Route>
               <Route path="profile/*" element={<Profile />}></Route>
               <Route path="request/*" element={<Request />}></Route>
               <Route path="message" element={<Messages />}></Route>
               <Route path="account/:accId/*" element={<Account />}></Route>
               <Route path="/*" element={<NoMatch />}></Route>
          </Routes>
        </div>

     </div>
  )

}

export default Homepagewrapper;
