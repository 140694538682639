import React from 'react';
import { MdOutlineClose } from 'react-icons/md';
import { useEffect,useState,useRef,useContext } from 'react';
import { useSelector,useDispatch } from "react-redux";
import { addUserTopicRequest,cancelUserTopicRequest } from '../../../../redux/thunk/topic/fetchdata';
import {  SocketContext  } from "../../../../context/SocketContext";
import cancel from '../../../../assets/videocall/can.png';
import './Poolreqcancel.css';

function Poolreqcancel(props) {

  const myref = useRef(null);
  const [name,setName] = useState("");
  const { socket } = useContext(SocketContext);

  const userdetails = useSelector((state) => state.userReducer.userdetails);
  const topicDetails = useSelector((state) => state.poolDetailsReducer.topicDetails);

  const closeRequest = () => {
    props.hideCancel();
  }

  const handleSubmit = async() => {

      props.handleLoading();

      let topicrequest = {
        topicid:topicDetails?.topicId,
        fromuserid:userdetails.userid,
        touserid:topicDetails?.userid,
        status:"cancel",
        isdeleted:true,
        requestid:topicDetails?.status.requestid
      }

      const response = await cancelUserTopicRequest(topicrequest);

       if(response.STATUSCODE == 0) {
           props.reloadRequest();
           if(socket) {
             socket?.emit("delNoti",topicDetails?.status.requestid);
           }
       }

       if(response.STATUSCODE == 3) {
           alert(response.MESSAGE);
           props.reloadRequest();
       };

       props.hideCancel();

  }

  const handleClick = (event) => {
    if (myref.current && !myref.current.contains(event.target)) {
        props.hideCancel();
      }
   };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
       document.removeEventListener("mousedown", handleClick, false);
     };

  }, []);

  return (
     <div className="poolreqcancel-modal">
       <div ref={myref} className="poolreqcancel-wrapper">

         <div className="poolreqcancel-upperpanel">

            <div>&nbsp;</div>

               {/* Image and close button */}
           <div className="poolreqcancel-imgwrapper">
             <img className="poolreqcancel-img" src={cancel} />
              <div className="poolreqcancel-icon" onClick={() => closeRequest()}>
                 <MdOutlineClose  />
              </div>
           </div>

               {/* topic heading */}


                {/* topic title */}
            <div className="poolreqcancel-topic">
                 <div className="poolreqcancel-topicname">
                    Cancel Request For
                 </div>
               <div>{topicDetails?.topic}</div>
                 <div>&nbsp;</div>
            </div>

         </div>

                {/* submit button */}
         <div className="poolreqcancel-lowerpanel">
           <div className="poolreqcancel-btnconfirm-wrapper">
              <div className="poolreqcancel-btnconfirm" onClick={() => handleSubmit()}>
                 Cancel
              </div>
            </div>
         </div>

       </div>
     </div>
  );
}

export default Poolreqcancel;
