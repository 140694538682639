import React, { useState,useEffect } from 'react';
import { useSelector,useDispatch } from "react-redux";
import { useLocation } from 'react-router-dom';

import { Routes,Link,Route,useParams,useNavigate,useSearchParams  } from "react-router-dom";
import { MdOutlineShareLocation } from 'react-icons/md';
import * as actionCreators from "../../../../redux/actions/actionCreators";

import './Accountprofile.css';

import { addFollower,removeFollower } from '../../../../redux/thunk/follower/fetchdata';
import { getUserAccountDetails } from '../../../../redux/thunk/user/fetchdata';

import { isNumeric } from '../../../../utils/Validation';

import defaultprofile from '../../../../assets/default/user.png';
import Nomatch from '../../../../pages/errpage/Nomatch';
import Spinner from '../../../../spinner/classic-spiner/Classicspiner';
import Buttonspiner from '../../../../spinner/button-spiner-rqs/Buttonspiner';
import Details from './Details';

import one from  '../../../../assets/sterotype/1.png';
import two from  '../../../../assets/sterotype/2.png';
import three from  '../../../../assets/sterotype/3.png';
import four from  '../../../../assets/sterotype/4.png';
import five from  '../../../../assets/sterotype/5.png';
import six from  '../../../../assets/sterotype/6.png';

function Accountprofile() {

  const userdetails = useSelector((state) => state.userReducer.userdetails);
  const authUser = useSelector((state) => state.authReducer.authflag);

  const [accountdetail,setAccountdetail] = useState(null);
  const [loading,setLoading] = useState(true);
  const [waiting,setWaiting] = useState(true);
  const [page404,setPage404] = useState(false);

  const location = useLocation();
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(async () => {

    window.scrollTo(0, 0);

    let result = isNumeric(params.accId);


    if(result == true) {

      let loginUser = 0;

        if(Object.entries(userdetails).length > 0) {
           loginUser = userdetails.userid;
        }

       const response = await getUserAccountDetails(params.accId,loginUser);

        if(response.STATUSCODE == 0) {
           setAccountdetail(response.PAYLOAD);
           setLoading(false);
        } else {
           setPage404(true);
           setLoading(false);
        }
    } else {
      setPage404(true);
      setLoading(false);
    }

  },[params.accId,userdetails]);

  const handleFollow = async (userid,followerId) => {

     if(authUser) {

        setWaiting(false)

        let followerObj = {
           userid:followerId,
           followerId:userid
        }

        const response = await addFollower(followerObj);

        if(response.STATUSCODE === 0) {
           const response = await getUserAccountDetails(params.accId,userdetails.userid);
           setAccountdetail(response.PAYLOAD);
        }

        setWaiting(true)

      } else {
          navigate("/signin", { state: { path: location.pathname}})
     }

  }

  const handleUnFollow = async (userid,followerId) => {

     setWaiting(false)

    let followerObj = {
       userid:followerId,
       followerId:userid
    }

    const response = await removeFollower(followerObj);

    if(response.STATUSCODE === 0) {
       const response = await getUserAccountDetails(params.accId,userdetails.userid);
       setAccountdetail(response.PAYLOAD);
    }

    setWaiting(true)

  }

  const showFollowers = () => {
    if(authUser) {
      navigate("details/follower");
    } else {
       navigate("/signin", { state: { path: location.pathname}})
    }
  }

  const showFollowing = () => {
    if(authUser) {
     navigate("details/following")
    } else  {
       navigate("/signin", { state: { path: location.pathname}})
    }
  }

  const clearAccountdetails = () => {
    setAccountdetail(null);
  }

  const handleMessage = (accountdetail) => {

    if(authUser) {
      dispatch(actionCreators.addUserAccountDetails(accountdetail));
      navigate('/homepage/message');
     } else {
      navigate("/signin", { state: { path: location.pathname}})
    }

  }

  return (
    <div className="Profilecontainer">

      {loading ?
        <div className="account-spinner">
           <Spinner />
        </div> : null }

     {accountdetail && accountdetail ?

       <div className="Subprofilecontainer">
         <div className="accountwrapper">

           <div className="accountheader"> </div>

            <div className="account-profile" style={{
               backgroundImage:accountdetail && accountdetail.profile_image ? `url("${accountdetail.profile_image}")` : `url("${defaultprofile}")`,
               backgroundPosition: 'center',
               backgroundSize: 'cover',
               backgroundRepeat: 'no-repeat',
               maxWidth:'100%',
               maxHeight:'100%',
             }} > </div>

                <div className="account-defaultname">
                   {accountdetail.username}
                </div>

               {accountdetail.location ?
                 <div className="account-location">
                  <MdOutlineShareLocation /> &nbsp;{accountdetail.location}
                 </div> : null }

                <div style={{height:10}}> </div>

                    {accountdetail.sterotype == "Genius" ?
                <div className="account-sterotype">
                  <div className="account-sterotype-imgwrapper">
                      <img className="account-sterotype-img" src={three} />
                  </div>
                  <div style={{height:10}}>  </div>
                  <div className="account-sterotype-name">
                    {accountdetail.sterotype}
                  </div>
                  <div style={{height:10}}>  </div>
                </div> : null }

                    {accountdetail.sterotype == "Lazy" ?
                  <div className="account-sterotype">
                    <div className="account-sterotype-imgwrapper">
                        <img className="account-sterotype-img" src={five} />
                    </div>
                    <div style={{height:10}}>  </div>
                    <div className="account-sterotype-name">
                      {accountdetail.sterotype}
                    </div>
                    <div style={{height:10}}>  </div>
                  </div> : null }

                    {accountdetail.sterotype == "Nerd" ?
                    <div className="account-sterotype">
                      <div className="account-sterotype-imgwrapper">
                          <img className="account-sterotype-img" src={six} />
                      </div>
                      <div style={{height:10}}>  </div>
                      <div className="account-sterotype-name">
                        {accountdetail.sterotype}
                      </div>
                      <div style={{height:10}}>  </div>
                    </div> : null }

                    {accountdetail.sterotype == "Smart" ?
                      <div className="account-sterotype">
                        <div className="account-sterotype-imgwrapper">
                            <img className="account-sterotype-img" src={four} />
                        </div>
                        <div style={{height:10}}>  </div>
                        <div className="account-sterotype-name">
                          {accountdetail.sterotype}
                        </div>
                        <div style={{height:10}}>  </div>
                      </div> : null }

                    {accountdetail.sterotype == "Casual" ?
                        <div className="account-sterotype">
                          <div className="account-sterotype-imgwrapper">
                              <img className="account-sterotype-img" src={one} />
                          </div>
                          <div style={{height:10}}>  </div>
                          <div className="account-sterotype-name">
                            {accountdetail.sterotype}
                          </div>
                          <div style={{height:10}}>  </div>
                        </div> : null }

                    {accountdetail.sterotype == "Geek" ?
                            <div className="account-sterotype">
                              <div className="account-sterotype-imgwrapper">
                                  <img className="account-sterotype-img" src={two} />
                              </div>
                              <div style={{height:10}}>  </div>
                              <div className="account-sterotype-name">
                                {accountdetail.sterotype}
                              </div>
                              <div style={{height:10}}>  </div>
                            </div> : null }


                <div>
                   <b><span className="account-showfollow"  onClick={() => showFollowers()}>
                      {accountdetail.followercount}&nbsp;followers</span></b>
                    <span>&nbsp;&nbsp;&nbsp;</span>
                   <b><span className="account-showfollow" onClick={showFollowing}>
                      {accountdetail.followingcount}&nbsp;following
                    </span></b>
                </div>

               <div className="account-btn">

             {userdetails.userid !== accountdetail.userid ?
               <>
                 <div className="account-btnwrapper" onClick={() => handleMessage(accountdetail)}>
                   <button className="account-msg">
                      &nbsp;&nbsp;Message&nbsp;&nbsp;
                   </button>
                 </div>

                 <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>


          {waiting ?
             <>
              {accountdetail.isFollowing ?
                 <div className="account-btnwrapper"
                   onClick={() => handleUnFollow(userdetails.userid,accountdetail.userid)}>
                   <button className="account-follow">
                      &nbsp;&nbsp;Unfollow&nbsp;&nbsp;
                   </button>
                 </div> :
                 <div className="account-btnwrapper"
                   onClick={() => handleFollow(userdetails.userid,accountdetail.userid)}>
                   <button className="account-follow">
                      &nbsp;&nbsp;Follow&nbsp;&nbsp;
                   </button>
                 </div>
               } </> :

               <div className="account-btnwrapper">
                 <button className="account-loading">
                    &nbsp;&nbsp;<Buttonspiner />&nbsp;&nbsp;
                 </button>
               </div>

                }

                </>
                 : null }
               </div>

               <div className="account-bio" dangerouslySetInnerHTML={{__html: accountdetail.bio }}>

               </div>

         </div>
       </div>

    : null
   }

     {page404 ? <Nomatch /> : null }

     <Routes>
        <Route path="details/*"
         element={<Details username={accountdetail && accountdetail.username ? accountdetail.username : ""}
                           userId={params.accId}
                           clearAccountdetails={clearAccountdetails} />}>
         </Route>
    </Routes>


    </div>
  )

}

export default Accountprofile;
