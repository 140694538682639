import * as actionTypes from "../actions/actionTypes";

const initialState = {
   postlist:[],
   addsmarttag:{smartflag:false},
   delsmarttag:{smartflag:false},
   delpost:{delflag:false},
   imageUrl:null,
   imageFlag:false,
   page:1,
   temp:null,
   hasMore:true,
   previewpostid:null,
   previewpost:[],
   postcount:null,
   postscrollPosition:null,
   postsearch:[],
   postsearchterm:"",
   searchpage:1,
   searchtemp:null,
   searchhasMore:false
};

const postlistReducer = (state = initialState, action) => {

  switch (action.type) {

    case actionTypes.REST_USER_POST:

     let restemp = [];

     return {
       ...state,
        previewpost:restemp
     };

    case actionTypes.ADD_USER_POST:

      let temp = [];
      temp.push(action.payload);

      let temp_post_count = state.postcount + 1;

       return {
         ...state,
          postlist:[...temp,...state.postlist],
          postcount:temp_post_count
       };

    case actionTypes.ADD_USER_POST_DB:

          return {
            ...state,
             postlist:[...state.postlist,...action.payload]
          };

    case actionTypes.ADD_SMART_TAG:

       return {
         ...state,
          addsmarttag:action.payload
       };

    case actionTypes.UPDATE_SMART_TAG:

      let tempsmart = [...state.postlist];

      let indexsmart = tempsmart.findIndex(item => item.postid == action.payload.postid);

      let arraysmarttag = tempsmart[indexsmart].smarttag ? [...tempsmart[indexsmart].smarttag] : [];
      arraysmarttag.push(action.payload.smartobj);

      tempsmart[indexsmart].smarttag = arraysmarttag;

      return {
          ...state,
        postlist:tempsmart
      };

    case actionTypes.DEL_SMART_TAG:

        return {
          ...state,
          delsmarttag:action.payload
        };

    case actionTypes.DEL_USER_POST:

        return {
          ...state,
          delpost:action.payload
        }

    case actionTypes.DEL_USER_POST_LIST:

      let templist = [...state.postlist];

      let index = templist.findIndex(item => item.postid == action.payload);

        if (index !== -1) {
          templist.splice(index, 1);
       }

       let temp_rmv_count = templist.length;

        return {
            ...state,
          postlist:templist,
          postcount:temp_rmv_count
        }

    case actionTypes.ADD_IMAGE_URL:

      return {
          ...state,
          imageUrl:action.payload
      };

    case actionTypes.ADD_IMAGE_FLAG:

        return {
            ...state,
            imageFlag:action.payload
        };

    case actionTypes.ADD_POST_PAGE:

    return {
        ...state,
        page:action.payload
    };

    case actionTypes.ADD_POST_TEMP:

    return {
        ...state,
        temp:action.payload
    };

    case actionTypes.ADD_POST_HASMORE:

    return {
        ...state,
        hasMore:action.payload
    };

    case actionTypes.ADD_SEARCH_PAGE:

    return {
        ...state,
        searchpage:action.payload
    };

    case actionTypes.ADD_SEARCH_TEMP:

    return {
        ...state,
        searchtemp:action.payload
    };

    case actionTypes.ADD_SEARCH_HASMORE:

    return {
        ...state,
        searchhasMore:action.payload
    };

    case actionTypes.UPDATE_SMART_TAG_USR:

      let tempupdsmart = [...state.postlist];

      let indexupdsmart = tempupdsmart.findIndex(item => item.postid == action.payload.postId);

      let arrayupdsmarttag = tempupdsmart[indexupdsmart].smarttag ? [...tempupdsmart[indexupdsmart].smarttag] : [];

      let indexupdatesmart = arrayupdsmarttag.findIndex(item => item.userid == action.payload.userId);

      arrayupdsmarttag.splice(indexupdatesmart, 1);

      tempupdsmart[indexupdsmart].smarttag = arrayupdsmarttag;

    return {
        ...state,
        postlist:tempupdsmart
    };

    case actionTypes.ADD_PREVIEWID:

      return {
        ...state,
        previewpostid:action.payload
      }

    case actionTypes.ADD_PREVIEWPOST:

      return {
        ...state,
        previewpost:[...state.previewpost,...action.payload]
      }

    case actionTypes.RMV_USERPOST:

        let temppost = [...state.previewpost];
        temppost.splice(action.payload, 1);

       return {
         ...state,
         previewpost:temppost
       }

    case actionTypes.ADD_POST_COUNT:

       let temp_post_count1 = state.postcount + 1;

      return {
        ...state,
        postcount:temp_post_count1
      }

    case actionTypes.SET_POST_COUNT:

      return {
        ...state,
        postcount:action.payload
      }

    case actionTypes.SET_SCROLL_POSITION:

     return {
       ...state,
       postscrollPosition:action.payload
     }

    case actionTypes.SET_POST_SEARCH:

     return {
       ...state,
       postsearch:[...state.postsearch,...action.payload]
     }

    case actionTypes.SET_POST_SEARCH_TERM:

      return {
        ...state,
        searchhasMore:true,
        postsearchterm:action.payload
      }

    case actionTypes.CLEAR_POST_SEARCH:

     return {
        ...state,
        searchpage:1,
        searchtemp:null,
        searchhasMore:false,
        postsearch:action.payload
     }

    case actionTypes.POST_RESET_INITIAL_STATE:

       return {
         ...initialState,
       };

    default:
      return state;
  }
};

export default postlistReducer;
