import * as actionTypes from "../actions/actionTypes";

const initialState = {
    usersinfodata:{}
};

const usersinfodataReducer = (state = initialState, action) => {

  switch (action.type) {

    case actionTypes.ADD_USER_INFODATA:

    let tempObj = {...action.payload}

      return {
        ...state,
        usersinfodata:tempObj
      };

    default:
      return state;
  }
};

export default usersinfodataReducer;
