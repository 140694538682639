import React, { useState,useEffect,useRef,useContext } from 'react';
import { useSelector,useDispatch } from "react-redux";
import defaultProfile from '../../../../assets/default/user.png';
import * as actionCreators from '../../../../redux/actions/actionCreators';
import { addUserMessage,getUserChat,addNewUserMessage,updateUsernoti } from '../../../../redux/thunk/message/fetchdata';
import { SocketContext } from "../../../../context/SocketContext";
import { RxDotsVertical } from 'react-icons/rx';
import { RxTrash } from 'react-icons/rx';
import { BsArrowLeftShort } from 'react-icons/bs';

import './Messagelist.css';

function Messagelist(props) {

   const dispatch = useDispatch();
   const { socket } = useContext(SocketContext);
   const myref = useRef(null);

   const userdetails = useSelector((state) => state.userReducer.userdetails);
   const useraccountdetails = useSelector((state) => state.accountDetailReducer.useraccountdetails);
   const currentchatuser = useSelector((state) => state.messageReducer.currentchatuser);
   const userchat = useSelector((state) => state.messageReducer.userchat);
   const chatuserlist = useSelector((state) => state.messageReducer.chatuserlist);

   const [textArea,setTextarea] = useState("");
   const [chatuser,setChatuser] = useState("");
   const [flag,setFlag] = useState(false);


   const handleKeyPress = async (e) => {

    if (e.charCode === 13 ) {

       let value = textArea.trim();

       if(value.length > 0) {

        if(currentchatuser.messageid) {

          let messageObj = {
            messageid:currentchatuser.messageid,
            userid:userdetails.userid,
            touserid:currentchatuser.touserid,
            text:textArea
         }

          socket?.emit("rplymsg",messageObj, function (data) {
             if (data.error) {}
             if (data.ok) {
                dispatch(actionCreators.addNewUserChat(data.payload));
             }
           })

        } else  {

          let messageObj = {
              fromuserid:userdetails.userid,
              touserid:currentchatuser.userdetailsto.PAYLOAD.userid,
              text:textArea
           }

          socket?.emit("newmsg",messageObj, function (data) {
                if (data.error) {}

                if (data.ok) {

                  let msgObj = {
                    messageid:data.response.messageid,
                    msgtxtid:data.response.msgtxtid,
                    userid:userdetails.userid,
                    text:textArea
                  }

                  let obj =  [...chatuserlist];
                  obj[0].messageid = data.response.messageid;
                  obj[0].msgtxtid = data.response.msgtxtid;

                  dispatch(actionCreators.addChatUserList(obj));
                  dispatch(actionCreators.addNewUserChat(msgObj));

                }
          })
       }

         setTextarea("");
       }

     }

   }

   const handleTextarea = (event) => {
       let value = event.target.value;
       setTextarea(value);
   }

   const deleteMsg = (msgid) => {

     let obj = {
       delflag:true,
       delid:msgid
     }

     dispatch(actionCreators.delUserChatmsg(obj));
   }

   const handleBack = () => {
     props.showUser();
   }

   useEffect(async () => {


    if(currentchatuser && currentchatuser.messageid) {

      let msgchatobj = {
        userid:userdetails.userid,
        messageid:currentchatuser.messageid
      }

      const response = await getUserChat(msgchatobj);

       if(response.STATUSCODE == 0) {

          dispatch(actionCreators.addUserChat(response.PAYLOAD));

          let notiobj = {
            fromuser:currentchatuser.touserid,
            touser:userdetails.userid
          }

          const updateres = await updateUsernoti(notiobj);

        }

    } else {
      dispatch(actionCreators.addUserChat(""));
    }

    if(currentchatuser) {
      myref.current.focus();
    }

   },[currentchatuser])

   return (

    <div className="messagelist-wrapper-outer">

      {currentchatuser != null ?
      <>
        <div className="messagewrapper-header">

       <div className="msg-pooldetails-titlearrow" onClick={handleBack}>
         <BsArrowLeftShort/>
       </div>

       <div style={{width:10}}> </div>

      <div className="message-prfwrapper">

       <div className="message-profileimg"
          style={{
            backgroundImage:currentchatuser && currentchatuser.userdetailsto.PAYLOAD.profile_image ? `url("${currentchatuser.userdetailsto.PAYLOAD.profile_image}")` : `url("${defaultProfile}")`,
            backgroundPosition:'center',
            backgroundSize:'cover',
            backgroundRepeat:'no-repeat',
            maxWidth:'100%',
            maxHeight:'100%'
         }}></div>

        <div style={{width:10}}></div>

        <div className="message-username">
           {currentchatuser && currentchatuser.userdetailsto.PAYLOAD.username}
        </div>

      </div>
        </div>

        <div className="messagewrapper-body">
         <div className="messagewrapper-body-text">
            <div className="messagelist-wrapper">

             {userchat && userchat.map((element,index) => {

               return (
                 <>
                 {currentchatuser.messageid == element.messageid ?
                 <>
                  {userdetails.userid == element.userid ?
                   <div className="messagelist-item" key={index} >

                      <div className="messagelist-menu" onClick={() => deleteMsg(element.msgtxtid)}>
                        <RxTrash />
                      </div>

                      <p className="messagelist-item-text">
                        {element.text}
                      </p>

                      <div className="message-profileimg"
                         style={{
                           backgroundImage:userdetails && userdetails.profile_image ? `url("${userdetails.profile_image}")` : `url("${defaultProfile}")`,
                           backgroundPosition:'center',
                           backgroundSize:'cover',
                           backgroundRepeat:'no-repeat',
                           maxWidth:'100%',
                           maxHeight:'100%'
                        }}></div>

                   </div>  :
                   <div className="messagelist-item-str" key={index}>


                   <div className="message-profileimg"
                      style={{
                        backgroundImage:currentchatuser && currentchatuser.userdetailsto.PAYLOAD.profile_image ? `url("${currentchatuser.userdetailsto.PAYLOAD.profile_image}")` : `url("${defaultProfile}")`,
                        backgroundPosition:'center',
                        backgroundSize:'cover',
                        backgroundRepeat:'no-repeat',
                        maxWidth:'100%',
                        maxHeight:'100%'
                     }}></div>

                      <p className="messagelist-item-text-from">
                        {element.text}
                      </p>

                      <div className="messagelist-menu" onClick={() => deleteMsg(element.msgtxtid)}>
                        <RxTrash color={'red'} />
                      </div>

                   </div>
                  }
                 </> : null }
                 </>
                )

              })}

            </div>
         </div>
        </div>

        <div className="messagelist-bottom">
           <textarea className="messagewrapper-input" ref={myref} value={textArea} onKeyPress={(e) => handleKeyPress(e)} onChange={(event) => handleTextarea(event)} rows="2" >
           </textarea>
        </div>

      </> : null }

    </div>
   )
 }

export default Messagelist;
