import * as actionTypes from "../actions/actionTypes";

const initialState = {
   notifyAlert:null,
   msgAlert:null
};

const notifyAlertReducer = (state = initialState, action) => {

  

  switch (action.type) {

    case actionTypes.ADD_NOTIFY_ALERT:

      return {
        ...state,
        notifyAlert:action.payload
      };

    case actionTypes.ADD_MSG_ALERT:

        return {
          ...state,
          msgAlert:action.payload
        };

    default:
      return state;
  }
};

export default notifyAlertReducer;
