import './Friend.css';
import React, { useState,useEffect,useRef,useCallback } from 'react';
import { useSelector } from "react-redux";
import { Routes, Link, Route } from "react-router-dom";
import { BsArrowLeftShort } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import Doublebounce from '../../../../spinner/double-bounce/Doublebounce';
import { getuserfreindlist } from '../../../../redux/thunk/user/fetchdata';
import defaultprofile from '../../../../assets/default/user.png';

function Friend() {

  const [page,setPage] = useState(1);
  const [hasMore,setHasMore] = useState(true);
  const [aa,setAa] = useState([]);
  const [temp,setTemp] = useState();

  const [frd,setFrd] = useState(false);

  const userdetails = useSelector((state) => state.userReducer.userdetails);

  const navigate = useNavigate();
  const observer = useRef();

  const lastFriendElementRef = useCallback(node => {

    if (observer.current) observer.current.disconnect()
       observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasMore) {
          setPage(temp);
       }
    })

      if(node) {
          observer.current.observe(node)
       }
  })

  const handleAccount = (userid) => {
    navigate(`/homepage/account/${userid}`)
  }

  const handlePool = () => {
     navigate(`/homepage/pool`)
  }

   useEffect(async () => {

    let lim = 5;

    if(userdetails.userid) {

    let obj = {
      userid:userdetails.userid,
      page:page,
      limit:lim
    }

    const result = await getuserfreindlist(obj);


    if(result) {

      let ft = result[0];

     if(ft.next == undefined) {
          setAa([...aa,...result.slice(1)]);
          setHasMore(false);
      } else {
          setAa([...aa,...result.slice(1)]);
          setTemp(ft.next.page)
     }

     } else {
         setHasMore(false)
   }

  }

  },[userdetails,page])



  return (
     <div className="main-container">

      {aa && aa.map((element,index) => {

       return (
         <>
         {element.fdetails != null ?
            <div className="friend-container">

             <div className="friend-container-topic">{element.topic}</div>

             <div className="friend-wrapper">

              {element.fdetails?.map((item,inx) => {

              return (
                <>
                {item.frienddetails.userid != userdetails.userid ?
                  <div className="friend-proflie-wrapper" ref={lastFriendElementRef}  onClick={() => handleAccount(item.frienddetails.userid)}>

                  <div className="Team-profile-outline">

                  <div className="Team-profile-circle">
                    <div className="account-profile"
                     style={{
                       backgroundImage:item && item.frienddetails.profile_image ? `url("${item.frienddetails.profile_image}")` : `url("${defaultprofile}")`,
                       backgroundPosition: 'center',
                       backgroundSize: 'cover',
                       backgroundRepeat: 'no-repeat',
                       maxWidth:'100%',
                       maxHeight:'100%',
                     }}
                    />
                  </div>

                <div className="Team-empspace"></div>

                <div className="Team-profile-name">
                  {item.frienddetails.username}
                </div>

               </div>

                  </div> : null }
                </>
                )

              })}

            </div>

          </div> : null }
         </>
       )

       })}


       <div className="freindlst">
        <div className="friend-start" onClick={() => handlePool()}>
          <div className="friend-btn">Connect & Start learning with Friends</div>
        </div>
      </div>

       {hasMore ?
         <div className="browselist-spinner">
           <Doublebounce />
         </div> : null }

     </div>
  )
}

export default Friend;
