import './Request.css';
import Requestbox from './Requestbox';
import Requestnotify from './Requestnotify';
import Pooldetails from '../../pool/Pooldetails';
import React, { useState,useEffect } from 'react';
import { Routes,Route,useNavigate,NavLink } from "react-router-dom";
import { BsArrowLeft } from 'react-icons/bs';
import { BsArrowLeftShort } from 'react-icons/bs';

function Request() {

  const navigate = useNavigate();

  const handleBackarrow = () => {
     navigate('/homepage')
  }

return (
   <div className="Profilecontainer">

   <div className="Subprofilecontainer">

     <div className="Profiletitle">
       <div className="Profiletitlearrow" onClick={handleBackarrow} ><BsArrowLeftShort/></div>
       <div className="Profiletitletext">&nbsp;Profile & settings</div>
     </div>

      <div className="Profiletitlesubmenu">

        <div>
         <NavLink className={(navData) => (navData.isActive ? 'Profilenavitema' : 'Profilenavitem')} to="" end>Request</NavLink>
        </div>

         <div className="Profilemptyspace"></div>
         <div className="Profilemptyspace"></div>

        <div>
         <NavLink className={(navData) => (navData.isActive ? 'Profilenavitema' : 'Profilenavitem')} to="requestsent">Request Sent</NavLink>
        </div>

        <div className="Profilemptyspace"></div>

      </div>

       <div>
         <Routes>
            <Route path="/"element={<Requestbox />}> </Route>
            <Route path="requestsent" element={<Requestnotify />}></Route>
            <Route path="/pooldetails/:picId/" element={<Pooldetails />}></Route>
            <Route path="requestsent/pooldetails/:picId/" element={<Pooldetails />}></Route>
          </Routes>
       </div>

      </div>

   </div>
  )
}

export default Request;
