import React from 'react';
import { useState,useEffect,useRef } from 'react';
import DatePicker from "react-datepicker";
import './Datepickercomp.css';


function Datepickercomp(props) {

  const datemyref = useRef(null);
  const [ndate,setNdate] = useState("");

  const handleClick = (event) => {
    if (datemyref.current && !datemyref.current.contains(event.target)) {
        props.hideDatepicker();
      }
   };

  const handleDate = (newDate) => {
     props.mountDate(newDate);
  }

  useEffect(() => {

     var date = new Date();
     let temp = date.setDate(date.getDate() + 1);
     setNdate(temp);

    document.addEventListener("mousedown", handleClick);

    return () => {
       document.removeEventListener("mousedown", handleClick, false);
     };
  },[]);

  return (
    <div ref={datemyref} className="datepicker-wrapper">
     <div className="datepicker-custompicker">
        <DatePicker open={true}
          onChange={handleDate}
          minDate={ndate} />
     </div>
    </div>
  )

}

export default Datepickercomp;
