import React, { useState,useEffect } from 'react';
import { useSelector,useDispatch } from "react-redux";
import { useNavigate,useLocation } from 'react-router-dom';

import * as actionCreators from "../../../redux/actions/actionCreators";
import { loginPost,userLoginInfo } from '../../../redux/thunk/gateway/fetchdata';
import { emailValidation } from '../../../services/Validation';
import { passwordValidation } from '../../../services/Validation';

import { BiMessageRoundedError } from 'react-icons/bi';
import { RiErrorWarningLine }from 'react-icons/ri';

import { getdeviceType } from '../../../utils/Devicetype';
import l2 from '../../../assets/logo/l2.png';

import "./Login.css";

function Login() {

  const [userEmail, setuserEmail] = useState('');
  const [userPassword, setuserPassword] = useState('');

  const [passwordValue,setpasswordValue] = useState(false);
  const [passwordText,setpasswordText] = useState('show');

  const [emailFlag, setemailFlag] = useState(false);
  const [passFlag, setpassFlag] = useState(false);

  const [errFlag, seterrFlag] = useState(false);
  const [urlLocaltion, setUrlLocaltion] = useState("");
  const [serverError, setserverError] = useState(true);
  const [serverMsg, setserverMsg] = useState("");

   const usersinfodata = useSelector((state) => state.usersinfodataReducer.usersinfodata);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  let location = useLocation()

  const handleLogin = async (event) => {

    if(event.type == 'click' || event.keyCode == 13) {

      let resultEmail = userEmail.replace(/^\s+|\s+$/gm,'');

      const emailvalidFlag = emailValidation(resultEmail);
      const passvalidFlag = passwordValidation(userPassword);

    if(emailvalidFlag == "valid") {

      setemailFlag(false);
      setpassFlag(false);
      const responseData = await loginPost(resultEmail,userPassword);

       if(responseData.STATUSCODE == 0) {

            if(responseData.PAYLOAD.userdetails.status == 2) {
                dispatch(actionCreators.decAccFlag(true));
            }


             let tempObj = {...usersinfodata}

             tempObj.userid = responseData.PAYLOAD.userdetails.userid;

             dispatch(actionCreators.adduserInfoData(tempObj));

             const responselogin = await userLoginInfo(tempObj);

             dispatch(actionCreators.addAuthUser(true));
             setemailFlag(false);

             let url = '/homepage' + urlLocaltion;

             setTimeout(function(){
               navigate(url);
             }, 100);


        }

          if(responseData.STATUSCODE == 1) {
             setuserPassword("");
             setserverError(false);
             setserverMsg(responseData.MESSAGE.replace(/"/g, ''));
        }

          if(responseData.STATUSCODE == 3) {
             setuserPassword("");
             setserverError(false);
             setserverMsg(responseData.MESSAGE.replace(/"/g, ''));
        }

       } else {

           if(emailvalidFlag !== "valid") {
              setemailFlag(true);
              setserverError(true);
              setuserPassword("");
              setserverMsg("");
           }

           if(emailvalidFlag == "valid") {
             setemailFlag(false);
           }

       }

   }

  }

  const handleLoginemail = (event) => {
    setuserEmail(event.target.value);
  }

  const handleLoginpassword = (event) => {
    setuserPassword(event.target.value);
  }

  const handleClickShowPassword = () => {
    setpasswordValue(!passwordValue);

    if(passwordText === 'hide') {
        setpasswordText('show');
      } else {
        setpasswordText('hide');
    }

  }

  const handleForgetpassword = () => {
     navigate('/forgetpassword');
  }

  useEffect (() => {

    let abortController = new AbortController();

    const deviceType = getdeviceType();
    dispatch(actionCreators.deviceOrinent(deviceType));

     if(location.state !== null)  {
         setUrlLocaltion(location.state.path);
     }

     return () => {
       abortController.abort();
      }

   }, [])

  return (
    <div className="Login-container-wrapper">

      <div className="Login-container">

           {/* Login Header */}
         <a href="/" className="Howitwork-navbar-logo">
            <div className="font-face-gm Howitwork-logo-text">prior</div>
            <span className="Howitwork-logoline"><img src={l2} /></span>
            <div className="font-face-gm Howitwork-logo-text">you</div>
         </a>

            {/* Separator */}
          <div className="Login-separator">
             <div className="Login-separator-line">&nbsp;</div>
          </div>

            {/* Main body */}
          <div className="Login-main-wrapper">
            <div className="Login-content">

                {/* Login */}
             {serverError ?
               <div className="Login-main">
                   Log In
               </div>
               :
               <div className="Login-title-err">
               <div className="Login-err-icon"><RiErrorWarningLine/></div>
                  <div className="Login-title-word">{serverMsg}</div>
               </div>
             }

                {/* Email */}
               <div className="Login-email">
                   <input type="email"  onChange={(event) => handleLoginemail(event)} onKeyDown={(event) => handleLogin(event)} className="Login-formcontrol" placeholder="Email" name="" id="" />
                   <small style={{color:"red"}} >{emailFlag ? "Please enter valid email address." : " "}</small>
              </div>  {/* Email End */}

                {/* Password */}
               <div className="Login-password-wrapper">

                  <input hidden  type={passwordValue ? "text" : "password"}  autoComplete="new-password"  onChange={(event) => handleLoginpassword(event)} onKeyDown={(event) => handleLogin(event)} className="Login-formcontrol" placeholder="Password"  value={userPassword} />

                  <button  className="Login-password-show" onClick={() => handleClickShowPassword()}>{passwordText}</button>
              </div>   {/* Password End */}

                <small style={{color:"red"}} >{passFlag ? "Please provide password." : " "}</small>

                {/* Forget password */}
               <div className="Login-forget-password">
                 <button className="Login-forget-button" onClick={() => handleForgetpassword()}>
                   Forgot Password ?
                  </button>
                 <small style={{color:"red"}} className="LoginemailFlag">{errFlag ?
                   "Your Username or Password is Incorrect" : " "}</small>
               </div> {/* Forget password End */}

                {/* Login button */ }
                <div className="Login-footer">
                  <button className="Login-button" onClick={(event) => handleLogin(event)} >Login</button>
               </div>    {/* Login button End */ }

               <div className="Register-note">
                 <small>New to prior//you ? SignUp &nbsp;<a  style={{color:"red"}} href="/register">HERE</a></small>
               </div>


            </div> { /* Login-content */ }
          </div>  { /* Login-main */ }

      </div>  { /* Login-container */ }

    </div>

  )
}

export default Login;
