import * as actionTypes from "../actions/actionTypes";

const initialState = {
    topicid:null
};

const activeReducer = (state = initialState, action) => {

  switch (action.type) {

    case actionTypes.ADD_TOPIC_ID:

      return {
        ...state,
        topicid:action.payload
      };

    default:
      return state;
  }
};

export default activeReducer;
