import './Pooleditcomment.css';
import * as actionCreators from "../../../../redux/actions/actionCreators";
import { getUserComment,editSaveComment,editUserReply,getUserReply } from '../../../../redux/thunk/comment/fetchdata';
import { useDispatch } from "react-redux";
import { useState, useEffect, useRef  } from 'react';


function Pooleditcomment(props) {

  const [editcmtTxt,seteditcmtTxt] = useState("");
  const [editflag,seteditflag] = useState(true);
  const [userComment,setuserComment] = useState(props.userComment);

  const myRef = useRef(null);

  const dispatch = useDispatch();

  const handleFocus = (event) => {

    let cmtId = document.getElementById(`edicmt-${props.editcmtId}`)

    const selection = window.getSelection();
    const range = document.createRange();
    selection.removeAllRanges();
    range.selectNodeContents(cmtId);
    range.collapse(false);
    selection.addRange(range);
    cmtId.focus();
}

  const noPaste = (event) => {

    var ediv = document.getElementById(`edicmt-${props.editcmtId}`);

     setTimeout(function() {

        var str1 = ediv.innerText.replace(/\n/g,"");
        var str2 = ediv.innerHTML.replace(/<br>/g,"");

         if (str1 != str2) {
             ediv.innerText = ediv.innerText;
          }

     }, 1);



    if(event.dataTransfer !== undefined ) {

      if(event.dataTransfer.dropEffect === "copy") {
          event.preventDefault();
       }
    }

 }

  const canceleditCmt = () => {
    props.cancelEdit();
  }

  const handlesaveCmt = async () => {

    // Edit Reply Comment
    if(props.typeCmt === "replycomment") {

      var userReplyStr = editcmtTxt.replace(/^(\ ?<div><br><(\/)?div>\ ?)+|(\ ?<div><br><(\/)?div>\ ?)+$/, '');

       let replyEditcmt = {
           reply_id:props.reply_id,
           reply_text:userReplyStr,
           replyprevious:props.userComment
      }

      const response = await editUserReply(replyEditcmt);

      if(response.STATUSCODE == 0) {

           let rplyobj = {
             reply_id:props.reply_id,
             reply_text:userReplyStr
           }

         dispatch(actionCreators.editsaveReply(rplyobj));
      }

  }

    // Edit Main Comment
    if(props.typeCmt === "maincomment") {

      var userCommentStr = editcmtTxt.replace(/^(\ ?<div><br><(\/)?div>\ ?)+|(\ ?<div><br><(\/)?div>\ ?)+$/, '');

      const response = await editSaveComment(props.comment_id,userCommentStr);

      if(response.STATUSCODE == 0) {

        let editobj = {
          comment_id:props.comment_id,
          userComment:userCommentStr
        }

         dispatch(actionCreators.editsaveComment(editobj));
       }

    }

    props.cancelEdit();

  }

  const handleTextarea = (event) => {
     seteditcmtTxt(event.target.innerHTML);
     seteditflag(false)
  }

  const fileDrop = (e) => {
      e.preventDefault();
  }

  const handlePaste = (e) => {
     var clipboardData, pastedData;

      clipboardData = e.clipboardData || window.clipboardData;
      pastedData = clipboardData.getData('Text');

     if(pastedData.length < 1) {
        e.preventDefault();
     }
  }

  useEffect(() => {

    myRef.current.focus();

     document.addEventListener("paste", noPaste);
     document.addEventListener("drop", noPaste);

    return () => {
       document.removeEventListener("paste",noPaste);
       document.removeEventListener("drop",noPaste);
     };

   }, []);

  return (
     <div className="Pooleditcomment-wrapper">

     <div>
       <div ref={myRef} id={`edicmt-${props.editcmtId}`} onFocus={(event) => handleFocus(event)} className="Pooleditcomment-container" contentEditable="true" onInput={(event) => handleTextarea(event)}
       suppressContentEditableWarning={true}
       dangerouslySetInnerHTML={{__html: userComment }}
       onPaste={(e) => handlePaste(e)}
       onDrop={fileDrop} />

    </div>

        <div className="Pooleditcomment-btn">
          <div className="edit-btn-cancel" onClick={() => canceleditCmt()}>Cancel</div>
          <div className="edit-empty-btn"> </div>

          {editflag ?
            <div className="edit-btn-save-dis">
               Save
            </div>
            : <div onClick={() => handlesaveCmt()} className="edit-btn-save-enb">
                Save
             </div>
          }

        </div>

     </div>
  )
}

export default Pooleditcomment;
