import React, { useState,useEffect,useRef } from 'react';
import { RiCloseFill } from 'react-icons/ri';
import { BiCheckCircle } from 'react-icons/bi';
import { BiCircle } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { resetPassword } from '../../../redux/thunk/setting/fetchdata';
import Classicspiner from '../../../spinner/classic-spiner/Classicspiner';
import Fpasswordnext from './Fpasswordnext';
import './Fpasswordchange.css';

function Fpasswordchange(props) {

  const navigate = useNavigate();

  const [showflag,setShowflag] = useState(false);
  const [showconflag,setShowconflag] = useState(false);

  const [errflag,setErrflag] = useState(false);
  const [errMsg,setMsg] = useState("");

  const [password,setPassword] = useState(null);
  const [confpassword,setConfpassword] = useState(null);

  const [conflag,setConflag] = useState(false);

  const handleCancel = () => {
    navigate(-1);
  }

  const handleConfirm = async () => {

    if(password != null) {

      if(password.length > 7) {

        if(password != confpassword) {
          setErrflag(true);
          setMsg("* Password does not match.")
          return;
        } else {
          setErrflag(false);
          setMsg("");
        }

        let passobj = {
          useremail:props.useremail,
          password:password,
          confpassword:confpassword
        }

       const response  = await resetPassword(passobj);

       if(response.STATUSCODE == 0) {
           setConflag(true);
       }


      }  else {
          setErrflag(true);
          setMsg("* Password must be at least 8 characters.")
        }

    }

  }

  const handleprev = () => {
     props.handleBack();
  }

  const handlePassword = (event) => {
      setPassword(event.target.value);
  }

  const handleconfPassword = (event) => {
     setConfpassword(event.target.value);
  }

  const showpassword = () => {
    setShowflag(!showflag);
  }

  const showconfirmpassword = () => {
     setShowconflag(!showconflag);
  }


  return (
    <div className="Fpasswordcode-backdrop">
      <div className="Otpscreen-wrapper">
       {conflag ?
         <Fpasswordnext password={password} useremail={props.useremail} /> :
         <div className="Otpscreen-container">
            <div className="Otpscreen-header">
               <div className="Otpscreen-header-right">
                <RiCloseFill />
               </div>
               <div className="Otpscreen-header-center">
                  Choose a new password
               </div>
               <div className="Otpscreen-header-left" onClick={() => handleCancel()}>
                <RiCloseFill />
               </div>
            </div>

            <div className="Otpscreen-body">

              <div className="Otpscreen-body-up">
                <div className="Otpscreen-body-text">
                  Password must be at least 8 characters.
                 </div>
                <div>&nbsp;</div>

                   <div className="Fpasswordchange-input">
                        <div>
                          <input className="Fpasswordchange-input-field" type={showflag ? "text" : "password"}
                           placeholder="Enter a new password" onChange={(event) => handlePassword(event)}
                           value={password} />
                        </div>
                     <div className="Fpasswordchange-btn-show" onClick={() => showpassword()}>
                       {showflag ? "hide" : "show" }
                      </div>
                   </div>

                  <div>&nbsp;</div>

                  <div className="Fpasswordchange-input">
                     <div>
                       <input className="Fpasswordchange-input-field" type={showconflag ? "text" : "password"}
                         onChange={(event) => handleconfPassword(event)} placeholder="Confirm your password"
                         value={confpassword} />
                     </div>
                    <div className="Fpasswordchange-btn-show" onClick={() => showconfirmpassword()}>
                      {showconflag ? "hide" : "show" }
                    </div>
                 </div>

              </div>

               <div className="Otpscreen-body-down">

                 <div className="Otpscreen-error">
                   {errflag ?
                      <div>{errMsg}</div> :
                      <div>&nbsp;</div>
                    }
                 </div>

                 <div>&nbsp;</div>
                 <div className="Otp-confirm-btn" onClick={() => handleConfirm()}>
                    Confirm
                  </div>

               </div>

            </div>
            <div className="Otpscreen-footer">
               <div></div>
            </div>
         </div>
       }

       {/*
          <div className="Otpscreen-spinner">
             <Classicspiner />
          </div>
       */}

      </div>
    </div>
  );
}

export default Fpasswordchange;
