import * as actionTypes from "../actions/actionTypes";

const initialState = {
  flag:false,
  deaccflag:false
};

const deactReducer = (state = initialState, action) => {

  switch (action.type) {

    case actionTypes.DEACT_FLAG:

      return {
        ...state,
        flag:action.payload
      };

      case actionTypes.DEACT_ACCN_FLAG:

        return {
          ...state,
          deaccflag:action.payload
        };

    default:
      return state;
  }
};

export default deactReducer;
