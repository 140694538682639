// Publish Topice

export const publishTopic = async (formData) => {

 try {

   let url = '/publish';

   const requestOptions = {
        method: 'POST',
        body:formData
    };

   const response = await fetch(url,requestOptions);

   if(!response.ok) {
     throw {
       STATUSCODE:1,
       STATUS:"ERROR",
       MESSAGE:"Something broke!" }
     }  else {
       const result = await response.json();
       return result;
     }


 } catch (error) {
     return error.message;
  }


}
