import React from 'react';
import { useRef,useEffect } from 'react';
import { useSelector,useDispatch } from "react-redux";
import { RiCloseFill } from 'react-icons/ri';
import { rmvUserSmartTag } from '../../../../redux/thunk/post/fetchdata';
import * as actionCreators from '../../../../redux/actions/actionCreators';
import './Rmvtaj.css';

function Rmvtaj(props) {

   const myref = useRef(null);
   const dispatch = useDispatch();
   const delsmarttag = useSelector((state) => state.postlistReducer.delsmarttag);

   const handleClick = (event) => {

     let obj = {
       smartflag:false
     }

     if (myref.current && !myref.current.contains(event.target)) {
          dispatch(actionCreators.rmvSmarttag(obj));
      }
   };

   const handleClose = () => {

     let obj = {
       smartflag:false
     }

     dispatch(actionCreators.rmvSmarttag(obj));
   }

   const handleDel = async() => {

      let sendobj = {
         postId:delsmarttag.postId,
         userId:delsmarttag.userId
      }

      let obj = {
        smartflag:false,
      }

       dispatch(actionCreators.rmvSmarttag(obj));

       const response = await rmvUserSmartTag(sendobj);

       if(response.STATUSCODE === 0) {
         dispatch(actionCreators.updateSmartTagUser(sendobj));
       }

   }

   useEffect(() => {

     document.addEventListener("mousedown", handleClick);

     return () => {
       document.removeEventListener("mousedown", handleClick, false);
     };

  }, []);

  return (
     <div className="Rmvtaj-wrapper" >
        <div ref={myref} className="Rmvtaj-container">
           <div className="Rmvtaj-title">
              <div className="Rmvtaj-close-text"><strong>Remove Smart Tag ?</strong></div>
              <div className="Rmvtaj-close" onClick={handleClose}>
                <RiCloseFill/>
              </div>
            </div>
           <div className="Rmvtaj-name">
              <p className="Rmvtaj-name-text">Remove tag {delsmarttag.tagname} ?</p>
            </div>
            <div className="Rmvtaj-btn-height"> </div>
           <div className="Rmvtaj-btn-wrapper">
              <div className="Rmvtaj-btn" onClick={handleDel}>Remove</div>
           </div>
        </div>
     </div>
  );
}

export default Rmvtaj;
