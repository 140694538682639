import './Poolpage.css';
import Topnavigationpool from '../../components/landingpage/gateway/Topnavigationpool';
import Browserpool from '../../components/homepage/pool/Browserpool';

function Poolpage() {

  return (
    <div className="Poolpage-container">
      <Topnavigationpool />

       <div className="Poolpage-poolcontainer">
         <Browserpool />
       </div>
    </div>
  );
}

export default Poolpage;
