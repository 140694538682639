import * as actionTypes from "../actions/actionTypes";

const initialState = {
   currentemail:null,
   otpflag:false,
   emailid:null,
   verifyid:null,
   updateflag:false
};

const otpReducer = (state = initialState, action) => {

  switch (action.type) {

    case actionTypes.SET_OTP_FLAG:

      return {
        currentemail:action.payload.currentemail,
        otpflag:action.payload.otpFlag,
        emailid:action.payload.emailId,
        verifyid:action.payload.verifyid,
        updateflag:action.payload.updateflag
      };


    default:
      return state;
  }
};

export default otpReducer;
