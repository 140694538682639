import './Howitwork.css';

import React from 'react';
import { useNavigate } from "react-router-dom";
import { BsArrowLeftShort } from 'react-icons/bs';

import oneboy from  '../../assets/howitworks/oneboy.png';
import onegirl from  '../../assets/howitworks/onegirl.png';
import two from '../../assets/howitworks/twogril.jpg';
import three from  '../../assets/howitworks/three.jpg';
import four from  '../../assets/howitworks/four.png';
import five from  '../../assets/howitworks/five.png';
import crowd from  '../../assets/howitworks/crowd.png';

import l1 from '../../assets/logo/l1.png';
import arrowdown from '../../assets/icons/arrowdown.svg';

import binary from '../../assets/howitworks/squad/binary.png';
import quint from  '../../assets/howitworks/squad/qunit.png';
import quad from  '../../assets/howitworks/squad/square.png';
import tri from  '../../assets/howitworks/squad/tri.png';

import prioryou from '../../assets/logo/prioryou.jpg';

function Howitwork() {

  const navigate = useNavigate();

  const handleBack = () => {
      navigate(-1);
  }

  return (
    <div className="Howitwork-wrapper">

       <div className="Howitwork-navbar-wrapper">

         <div className="Detailstitlearrow" onClick={() => handleBack()} >
           <BsArrowLeftShort/>
         </div>

          <a href="/" className="Howitwork-navbar-logo">
            <div className="font-face-gm Howitwork-logo-text">prior</div>
            <span className="Howitwork-logoline"><img src={l1} /></span>
            <div className="font-face-gm Howitwork-logo-text">you</div>
          </a>


        <div> </div>

      </div>

        <div className="Howitwork-title">
            Learning Made Simple,Interactive & Enjoyable
         </div>

         <div style={{height:20}}>  </div>

          <div className="Howitwork-wrapper-one">
            <img className="Howitwork-wrapper-imgone" src={oneboy} />
            <img className="Howitwork-wrapper-imgone" src={onegirl} />
         </div>

         <div className="Howitwork-wrapper-text">
           <div className="Howitwork-wrapper-text-title">Distracted by </div>
             <img className="arrow" src={arrowdown} />
             <div style={{height:10}}> </div>
           <div className="Howitwork-wrapper-subtext">
             <div className="Howitwork-wrapper-b1">Random Thoughts</div>
             <div className="Howitwork-wrapper-b2">Soical Media</div>
             <div className="Howitwork-wrapper-b3">Daydreaming</div>
             <div className="Howitwork-wrapper-b4">Multitasking</div>
           </div>
        </div>

        <div className="Howitwork-wrapper-text">
          <div className="Howitwork-wrapper-text-title">As Result</div>
          <img className="arrow" src={arrowdown} />
          <div style={{height:10}}> </div>
          <div className="Howitwork-wrapper-subtext">
            <div className="Howitwork-wrapper-bb1">Lose Focus</div>
            <div className="Howitwork-wrapper-bb2">Wasting Time</div>
            <div className="Howitwork-wrapper-bb3">Slow Down Learning</div>
            <div className="Howitwork-wrapper-bb4">Reduced Capacity</div>
          </div>
       </div>


           <div className="Howitwork-wrapper-text">
             <div className="Howitwork-wrapper-text-title">Create Squad to</div>
             <img className="arrow" src={arrowdown} />
             <div style={{height:10}}> </div>
             <div className="Howitwork-wrapper-subtext">
               <div className="Howitwork-wrapper-bbb1">Increases Productivity</div>
               <div className="Howitwork-wrapper-bbb2">Healthy Competition</div>
               <div className="Howitwork-wrapper-bbb3">Save Time & Cost</div>
               <div className="Howitwork-wrapper-bbb4">Share Knowledge & Resources</div>
             </div>
          </div>



     <div style={{height:20}}>  </div>

    <div className="Howitwork-wrapper-squad">

         <div className="Howitwork-wrapper-center">

           <div className="Howitwork-wrapper-title">Binary Squad of 2</div>

           <div className="Howitwork-wrapper-squadtwo">
             <div className="Howitwork-wrapper-imgdesc"><img className="Howitwork-wrapper-imgone" src={two} /></div>
             <div className="Howitwork-wrapper-desc"><img className="imgsquad" src={binary} /></div>
           </div>

            <div className="Howitwork-wrapper-note">2 People, 1 Communication Path</div>

         </div>

         <div className="Howitwork-wrapper-center">

         <div className="Howitwork-wrapper-title">Tri Squad of 3</div>

           <div className="Howitwork-wrapper-squadtwo">
               <div className="Howitwork-wrapper-imgdesc"><img className="Howitwork-wrapper-imgone" src={three} /></div>
              <div className="Howitwork-wrapper-desc"><img className="imgsquad" className="imgsquad" src={tri} /></div>
           </div>

           <div className="Howitwork-wrapper-note">3 People, 3 Communication Paths</div>

         </div>

         <div className="Howitwork-wrapper-center">

         <div className="Howitwork-wrapper-title">Quad Squad of 4</div>

           <div className="Howitwork-wrapper-squadtwo">
               <div className="Howitwork-wrapper-imgdesc"><img className="Howitwork-wrapper-imgone" src={four} /></div>
              <div className="Howitwork-wrapper-desc"><img className="imgsquad" src={quad} /></div>
           </div>

          <div className="Howitwork-wrapper-note">4 People, 6 Communication Paths</div>

         </div>


        <div className="Howitwork-wrapper-center">

        <div className="Howitwork-wrapper-title">Quint Squad of 5</div>

          <div className="Howitwork-wrapper-squadtwo">
              <div className="Howitwork-wrapper-imgdesc"><img className="Howitwork-wrapper-imgone" src={five} /></div>
             <div className="Howitwork-wrapper-desc"><img className="imgsquad" src={quint} /></div>
          </div>

        <div className="Howitwork-wrapper-note">5 People, 10 Communication Paths</div>

        </div>



    </div>


     <div style={{height:20}}> </div>
        <div className="Howitwork-wrapper-sq-note">
          <div>With Communication&nbsp;</div>
          <div>Complexity Increases&nbsp;</div>
        </div>
      <div style={{height:20}}> </div>


      <div className="Howitwork-wrapper-text">
        <div className="Howitwork-wrapper-text-title">Big Team Create</div>
        <img className="arrow" src={arrowdown} />
        <div style={{height:10}}> </div>
        <div className="Howitwork-wrapper-subtext">
          <div className="Howitwork-wrapper-t1">Conflict</div>
          <div className="Howitwork-wrapper-t2">Chaos</div>
          <div className="Howitwork-wrapper-t3">Confusion</div>
       </div>
    </div>


         <div className="Howitwork-wrapper-bottom">
          <img className="Howitwork-wrapper-img" src={crowd} />
        </div>

        <a href="/pool" className="Mainbody-pool-button">Dive in pool</a>

        <div style={{height:30}}> </div>
    </div>
  );
}

export default Howitwork;
