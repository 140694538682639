import * as actionTypes from "../actions/actionTypes";

const initialState = {
    yourname:"",
    topic:"",
    description:"",
    filepath:"",
    noofpartner:"",
    noofdays:"",
    startdate:"",
    timezone:"",
    starttime:"",
    gmtoffset:"",
    timeline:[{
        date:"",
        title:"",
        description:""
   }]
};

const publishReducer = (state = initialState, action) => {

  switch (action.type) {

   case actionTypes.CREATE_TIMELINE:

      return {
        ...state,
        ...action.payload
      }

      break;

   case actionTypes.EDIT_TIMELINE:

     let editDesc = [...state.timeline];
     editDesc[action.payload.id].title =  action.payload.title;
     editDesc[action.payload.id].description =  action.payload.description;

      return {
        ...state,
        timeline:editDesc
      }

      break;

   case actionTypes.REMOVE_FILEPATH:

        let object = {...action.payload}

        return {
          ...state,
          ...object
        }

        break;


   case actionTypes.SAVE_TIMELINE:

       return {

       }

      break;

   case  actionTypes.DATE_RANGE:

      return {

      }

      break;

    case  actionTypes.RESET_PUBLISH:

      return {
        ...initialState
      };

      break;

    default:
      return state;

   }
}

export default publishReducer;
