// Pool Explore

export const poolExplore = async (page,limit) => {

  try {

    let url = `/poolController?page=${page}&limit=${limit}`;

    const requestOptions = {
         method: 'GET',
         headers: {
           'Content-Type': 'application/json',
           'Accept': 'application/json'
         }
    };

    const response = await fetch(url,requestOptions);
    const result = await response.json();

    return result;

  } catch (error) {
      return error.message;
   }

}

// Get User Active pool

export const getUserActivePool = async (obj) => {

    try {

       let url = `/getuseractivepool`;

       const requestOptions = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
            }, body:JSON.stringify({
                activepool:obj
             })
       };

       const response = await fetch(url,requestOptions);

        if(!response.ok) {
           throw {
             STATUSCODE:1,
             STATUS:"ERROR",
             MESSAGE:"Something broke!" }
         } else {
            const result = await response.json();
            return result;
         }

      } catch (error) {
          return error;
       }

}

// Get User Explore pool

export const getUserExplorePool = async (obj) => {

    try {

       let url = `/getuserexplorepool`;

       const requestOptions = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
            }, body:JSON.stringify({
                activepool:obj
             })
       };

       const response = await fetch(url,requestOptions);

        if(!response.ok) {
           throw {
             STATUSCODE:1,
             STATUS:"ERROR",
             MESSAGE:"Something broke!" }
         } else {
            const result = await response.json();
            return result;
         }

      } catch (error) {
          return error;
       }

}

// Delete user poolList

export const delUserpool = async (usertopicId) => {

  try {

     let url = '/deluserpool';

     const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
          body:JSON.stringify({
             topicId:usertopicId
          })
     };

     const response = await fetch(url, requestOptions);

     if(!response.ok) {
        throw {
          STATUSCODE:1,
          STATUS:"ERROR",
          MESSAGE:"Something broke!" }
      } else {
         const result = await response.json();
         return result;
      }

    } catch (error) {
        let err = {error:error.message}
        return err;
     }


}

// Your pool list

export const yourPoollist = async (userObj) => {

  try {

     let url = '/getuserpool';

     const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
          body:JSON.stringify({
             userObj:userObj
          })
     };

     const response = await fetch(url, requestOptions);

     if(!response.ok) {
        throw {
          STATUSCODE:1,
          STATUS:"ERROR",
          MESSAGE:"Something broke!" }
      } else {
         const result = await response.json();
         return result;
      }

    } catch (error) {
        let err = {error:error.message}
        return err;
     }


}

// Get active pool

export const getUserActiveSearchPool = async (obj) => {

  try {

     let url = '/getuseractivesearchpool';

     const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          }, body:JSON.stringify({
              activesearchterm:obj
           })
     };

     const response = await fetch(url, requestOptions);

     if(!response.ok) {
        throw {
          STATUSCODE:1,
          STATUS:"ERROR",
          MESSAGE:"Something broke!" }
      } else {
         const result = await response.json();
         return result;
      }

    } catch (error) {
        let err = {error:error.message}
        return err;
     }


}
