import './Addresources.css';
import * as actionCreators from "../../../../redux/actions/actionCreators";
import React, { useState, useRef, useEffect } from 'react';
import { useSelector,useDispatch } from "react-redux";
import { adduserResources,getuserResources } from '../../../../redux/thunk/resources/fetchdata';

function Addasset(props) {

  const myref = useRef(null);
  const dispatch = useDispatch();

  const userdetails = useSelector((state) => state.userReducer.userdetails);
  const topicobj = useSelector((state) => state.activeReducer.topicid);

  const [linkAddress,setlinkAddress] = useState('');
  const [linkName,setlinkName] = useState('');
  const [confirmflag,setConfirmflag] = useState(false);

   const handleClick = (event) => {
    if (myref.current && !myref.current.contains(event.target)) {
       dispatch(actionCreators.addResources(false));
      }
   };

   const handleClose = () => {
     dispatch(actionCreators.addResources(false));
   }

   const handleConfirm = async () => {

    let  str = linkAddress
    let  result = str.replace(/(^\w+:|^)\/\//, '');
    let  prorstr = "//";

    let finalurl = prorstr.concat(result);

      let link = {
        userid:userdetails.userid,
        topicid:topicobj.topicid,
        asseturl:finalurl,
        assetname:linkName
      }

       console.log('link',link);

      const response = await adduserResources(link);

        console.log("response",response);

        if(response.STATUSCODE == 0) {

          let linkobj = {
            assetid:response.PAYLOAD,
            userid:userdetails.userid,
            topicid:topicobj.topicid,
            asseturl:finalurl,
            assetname:linkName
          }

           dispatch(actionCreators.updateAddResources(linkobj));
        }

        dispatch(actionCreators.addResources(false));

   }

   const handleLinkAddress = (event) => {
     event.preventDefault();
     event.stopPropagation();
     setlinkAddress(event.target.value);
   }

   const handleLinkName = (event) => {
     event.preventDefault();
     event.stopPropagation();
     setlinkName(event.target.value);
   }

   useEffect(() => {
     document.addEventListener("mousedown", handleClick);

     return () => {
        document.removeEventListener("mousedown", handleClick, false);
      };

   }, []);

   useEffect(() => {

     if(linkAddress.length > 0 && linkName.length > 0) {
        setConfirmflag(true);
     } else {
        setConfirmflag(false);
     }

   },[linkAddress,linkName])


  return (
    <div  className="Addresources-modal">

      <div className="Addresources-topspace"> </div>

       <div ref={myref} className="Addresources-modal_content">

            <div className="Addresources-header">
               <div className="Addresources-header-title">Add Resources</div>
               <div className="Addresources-close" onClick={(event) => handleClose(event)}>
                 &times;
               </div>
            </div>

             <div style={{height:20}}> </div>

       <div>

           <div className="Addresources-container-link">
             <label className="Addresources-link">Link:</label>
             <input className="Addresources-formcontrol" name="url" id="url" placeholder="eg: https://prioryou.com" name="link" onChange={(event) => handleLinkAddress(event)}  />
           </div>

           <div style={{height:20}}> </div>

           <div className="Addresources-container-name">
             <label className="Addresources-link">Name:</label>
             <input className="Addresources-formcontrol" type="text" name="name" onChange={(event) => handleLinkName(event)} placeholder="prioryou" />
           </div>

            <div style={{height:20}}> </div>

            <div className="Addresources-container-btn">
             {confirmflag ?
              <button className="Addresources-btn"
                  onClick={() => handleConfirm()}>Confirm
              </button> :
              <button className="Addresources-btn-dis">Confirm</button> }
           </div>

        </div>

       </div>
    </div>
  )
}

export default Addasset;
