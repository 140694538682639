import * as actionTypes from "../actions/actionTypes";

const initialState = {
  chatuserlist:"",
  currentchatuser:null,
  userchat:"",
  delmsgchat:{
    delflag:false,
    delid:""
  },
  delAllChat:{
    delflag:false,
    delmsgid:"",
    userid:"",
    deluserid:""
  }
};

const messageReducer = (state = initialState, action) => {

  switch (action.type) {

    case actionTypes.ADD_CHAT_USER_LIST:

      return {
        ...state,
        chatuserlist:action.payload
      };

    case actionTypes.UPDATE_CHAT_USER_LIST:

       let tempchatuserlist = [...state.chatuserlist];

       let finaltempuserlist = tempchatuserlist.filter(function(item) {
          return item.touserid !== action.payload;
        });

      return {
        ...state,
        chatuserlist:finaltempuserlist
      };

    case actionTypes.ADD_CURRENT_CHAT_USER:

      return {
        ...state,
        currentchatuser:action.payload
      }

    case actionTypes.ADD_USER_CHAT:

      return {
        ...state,
        userchat:action.payload
      }

    case actionTypes.ADD_NEW_USER_CHAT:

      let temp = [];

        temp.push(action.payload);
        temp = [...temp,...state.userchat];

      return {
        ...state,
        userchat:temp
      }

    case actionTypes.DEL_CHAT_MSG:

     return {
       ...state,
       delmsgchat:action.payload
     }

    case actionTypes.UPDATE_CHAT_MSG:

     let tempchat = [...state.userchat];
     let index = tempchat.findIndex(item => item.msgtxtid == action.payload);

      if (index !== -1) {
         tempchat.splice(index, 1);
       }

      return {
        ...state,
        userchat:tempchat
     }

    case actionTypes.DEL_ALL_CHAT_MSG:

       return {
         ...state,
         delAllChat:action.payload
       }

    case actionTypes.UPDATE_ALL_CHAT_MSG:

       let userchattemp = [...state.userchat];

       let userchatrst = userchattemp.filter(function(item) {
          return item.messageid !== action.payload;
        });

        return {
           ...state,
           userchat:userchatrst
        }

    default:
      return state;
  }
};

export default messageReducer;
