import React, { useEffect,useState,useRef,useCallback } from 'react';
import { useParams,useNavigate } from "react-router-dom";
import { useSelector,useDispatch } from "react-redux";

import { RxDotsHorizontal } from 'react-icons/rx';
import { TbSquareRotated } from 'react-icons/tb';
import { MdOutlineReply } from 'react-icons/md';

import * as actionCreators from '../../../../redux/actions/actionCreators';
import defaultprofile from '../../../../assets/default/user.png';
import Doublebounce from '../../../../spinner/double-bounce/Doublebounce';

import { getUserpostLimit } from '../../../../redux/thunk/post/fetchdata';


import './Postlist.css';

function Postlist(props) {

  const myref = useRef(null);
  const observer = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const userdetails = useSelector((state) => state.userReducer.userdetails);
  const page = useSelector((state) => state.postlistReducer.page);
  const temp = useSelector((state) => state.postlistReducer.temp);
  const hasMore = useSelector((state) => state.postlistReducer.hasMore);
  const postscrollPosition = useSelector((state) => state.postlistReducer.postscrollPosition);
  const postlist = useSelector((state) => state.postlistReducer.postlist);
  const topicobj = useSelector((state) => state.activeReducer.topicid);
  const team = useSelector((state) => state.teamReducer.team);

  const [menuFlag,setMenuflag] = useState(null);
  const [imageUrl,setImageurl] = useState(null);
  const [imageFlag,setImageflag] = useState(null);
  const [loading,setLoading] = useState(false);

  const handleReply = (username,postid,userid,post,imgArray) => {
    props.handleReply(username,postid,userid,post,imgArray);
  }

  const hanleMenuopt = (index) => {
      setMenuflag(index)
  }

  const handleDelete = (postid,userid,currentuser,topicid) => {

    let usertype;

    if(userid == currentuser) {
       usertype = "normaluser";
    } else if (topicobj.superuser == userid) {
       usertype = "superuser";
    }

    let obj = {
      delflag:true,
      postId:postid,
      userId:userid,
      userType:usertype,
      topicid:topicid
    }

    dispatch(actionCreators.delUserpost(obj));
    setMenuflag(null);

  }

  const handleSmarttag = (postid,userid) => {

    let obj = {
      smartflag:true,
      postId:postid,
      userId:userid
    }

    dispatch(actionCreators.addSmartTag(obj));
    setMenuflag(null);
  }

  const handleClick = (event) => {
    if (myref.current && !myref.current.contains(event.target)) {
        setMenuflag(null);
      }
   };

  const lastpostElementRef = useCallback(node => {

    if(observer.current) observer.current.disconnect()

    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
         dispatch(actionCreators.addPostpage(temp));
      }
    })

    if(node) {
       observer.current.observe(node)
     }

  })

  function enlargeImage(imgElement){
     dispatch(actionCreators.addImageUrl(imgElement.src));
     dispatch(actionCreators.addImageFlag(true));
 }

  const handelFocusReply = (item) => {

     dispatch(actionCreators.setscrollPosition(window.pageYOffset));
     dispatch(actionCreators.restUserPost());
     dispatch(actionCreators.addUserpreviewid(item.postid));

     navigate(`/homepage/active/${topicobj.topicid}/preview/${item.postid}`);

   }

  const handleRmvtag = (postid,userid,tag) => {

    let tname = tag.smarttag.filter(item => item.userid == userid);

     let obj = {
       smartflag:true,
       postId:postid,
       userId:userid,
       tagname:tname[0].tagname
     }

     dispatch(actionCreators.rmvSmarttag(obj));
     setMenuflag(null);

  }

  useEffect(() => {

    var imageNodes = document.getElementsByClassName('postlistimg');

      for (var i=0; i<imageNodes.length; i++)
      {
        imageNodes[i].addEventListener('click', function() {
          enlargeImage(this);
        });
      }

    document.addEventListener("mousedown", handleClick);

    return () => {
       document.removeEventListener("mousedown", handleClick, false);
     };

  },[postlist])

  useEffect(async () => {

    if(topicobj?.topicid)  {

      let lim = 12;
      let limit;

      limit = page * lim;

      if(limit > postlist.length && hasMore == true) {

      setLoading(true);

     let result = await getUserpostLimit(page,lim,topicobj.topicid);

      if(result.STATUSCODE == 0) {

        if(result.PAYLOAD) {

        let ft = result.PAYLOAD[0];

       if(ft.next == undefined) {
          dispatch(actionCreators.addUserPostdb(result.PAYLOAD.slice(1)));
          dispatch(actionCreators.addPosthasmore(false));
          setLoading(false);
       } else {
          dispatch(actionCreators.addUserPostdb(result.PAYLOAD.slice(1)));
          dispatch(actionCreators.addPosttemp(ft.next.page));
       }
     }
      } else {
        dispatch(actionCreators.addPosthasmore(false));
        setLoading(false);
     }

   }

}

  },[page,temp,topicobj])



  return (
    <div className="postlist-container-wrapper">

     {(postlist && team) && postlist.map((element,index) => {

       let usrdetails = team.filter(function (el) {
          return el.enrolleduserId == element.userid;
       });

      return (
          <div key={element.postid} tabIndex={index} ref={lastpostElementRef} id={element.postid} className="postlist-container">

             <div  className="postlist-wrapper">

                <div className="postlist-outwrapper">

                     <div className="postlist-outprofile">

                      <div className="postlist-profile"
                        style={{
                           backgroundImage:usrdetails[0] && usrdetails[0].profileImage ? `url("${usrdetails[0].profileImage}")` : `url("${defaultprofile}")`,
                           backgroundPosition: 'center',
                           backgroundSize: 'cover',
                           backgroundRepeat: 'no-repeat',
                           maxWidth:'100%',
                           maxHeight:'100%',
                        }}
                     />

                      <span className="postlist-space"></span>
                      <span className="postlist-name">{usrdetails[0] && usrdetails[0].username}
                        <span style={{width:10}} />
                        <span style={{color:"#909090"}}>{element.currentTime}</span>
                      </span>
                     </div>


                     <button className="postlist-tag" onClick={() => hanleMenuopt(index)}>
                        <RxDotsHorizontal />
                      </button>


                   <div>

                  </div>
                </div>

                 <div className="postlist-empty">
                  {menuFlag == index ?
                    <div className="postlist-menu" ref={myref}>

                       {(userdetails.userid == element.userid) || (topicobj.superuser == userdetails.userid) ?
                          <div className="postlist-menu-btn" onClick={() => handleDelete(element.postid,userdetails.userid,element.userid,element.topicid)}>Delete</div>
                        : null }

                        <div className="postlist-menu-ht"></div>

                    {element.smarttag && element.smarttag.some(item => item.userid == userdetails.userid) ?

                         <div className="postlist-menu-btn" onClick={() => handleRmvtag(element.postid,userdetails.userid,element)}>
                           Remove Smart Tag
                         </div> :
                         <div className="postlist-menu-btn" onClick={() => handleSmarttag(element.postid,userdetails.userid)}>
                            Add Smart Tag
                         </div>
                       }

                    </div> : null }
                 </div>

                 {element.topicpost && element.topicpost ?
                   <div className="postlist-text" dangerouslySetInnerHTML={{__html: element.topicpost }} >
                  </div> : null }

                  <div className="postlist-img">
                    {element.imgArray && element.imgArray.map((item,index) => {

                      let imgurl

                      if(item.postimage) {
                        imgurl = item.postimage;
                      } else {
                         const b64 = new Buffer(item).toString('base64')
                          imgurl  = "data:image/png;base64,"+b64;
                       }

                     return (
                        <img className="postlistimg" key={index} src={imgurl} />
                     )
                  })}
                  </div>

                  {element.replydetails && element.replydetails.map((item,index) => {

                      let replyusrdetails = team.filter(function (el) {
                        return el.enrolleduserId == item.userid;
                      });

                       return (
                       <div key={index} className="postlist-reply-details" onClick={() => handelFocusReply(item)}>

                         <div className="postlist-outprofile">
                           <div className="postlist-reply-profile"
                          style={{
                             backgroundImage:replyusrdetails[0] && replyusrdetails[0].profileImage ? `url("${replyusrdetails[0].profileImage}")` : `url("${defaultprofile}")`,
                             backgroundPosition: 'center',
                             backgroundSize: 'cover',
                             backgroundRepeat: 'no-repeat',
                             maxWidth:'100%',
                             maxHeight:'100%',
                           }}
                         />

                            <span className="postlist-space"></span>
                            <span className="postlist-name">{replyusrdetails[0] && replyusrdetails[0].username }
                           <span style={{width:10}} />
                             <span style={{color:"#909090"}}>{item.currentTime}</span>
                            </span>
                          </div>

                         {item.topicpost && item.topicpost ?
                           <div className="postlist-text" dangerouslySetInnerHTML={{__html: item.topicpost }} >
                          </div> : null }


                         <div style={{height:10}}> </div>

                         <div className="postlist-item-wrapper-img">
                           {item.imgArray	&& item.imgArray.map((imgitem,index) => {
                             let imgurl;

                             if(imgitem.postimage) {
                               imgurl = imgitem.postimage;
                             } else {
                                const b64 = new Buffer(imgitem).toString('base64')
                                 imgurl  = "data:image/png;base64,"+b64;
                              }
                              return (
                                  <div key={index} className="postlist-item-img">
                                    <img src={imgurl} />
                                  </div>
                              )

                          })}
                          </div>
                       </div>
                     )
                    })}

                  <div className="postlist-reply">
                   <div className="postlist-reply-btn-wrapper" onClick={() => handleReply(usrdetails[0].username,element.postid,element.userid,element.topicpost,element.imgArray)}>
                     <div className="postlist-reply-text">Reply</div>
                     <div className="postlist-reply-text" style={{width:10}}></div>
                     <div className="postlist-reply-icon"><MdOutlineReply /></div>
                  </div>
                  </div>

              </div>
          </div>
        )
      })}

      {loading ?
        <div className="postlist-spinner">
          <Doublebounce />
        </div> : null }

    </div>
  );
}

export default Postlist;
