import './Alert.css';
import { IoAlert } from 'react-icons/io5';
import { IoMdCloseCircleOutline } from 'react-icons/io';

function Alert(props) {

  const handleClose = () => {
      props.closeAlert();
  }

  return (
     <div className="alert-wrapper">
        <div className="alert-container">
         <div className="alert-contains-icons"><IoAlert /></div>
          <div className="alert-contains">
            <div className="alert-contains-text">{props.topic} @ Start in 15 min</div>
          </div>
          <div className="alert-contains-icons-close" onClick={() => handleClose()}><IoMdCloseCircleOutline/></div>
        </div>
     </div>
    )

  }

export default Alert;
