import React from 'react';
import { useState,useEffect } from 'react';
import { useSelector } from "react-redux";
import './timepicker.css';

function Timepicker(props) {

  const[hour,setHour] =  useState('');
  const[minute,setMinute] =  useState('');
  const[meridiem,setMeridiem] = useState('');
  const[meridiemval,setMeridiemval] = useState('');

  const publish = useSelector((state) => state.publish);

  let hourcnt = ['00','01','02','03','04','05','06','07','08','09','10','11','12'];

  let minutecnt = ['00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16',
                   '17','18','19','20','21','22','23','24','25','26','27','28','29','30','31','32',
                   '33','34','35','36','37','38','39','40','41','42','43','44','45','46','47','48',
                   '49','50','51','52','53','54','55','56','57','58','59','60'];

  let meridiemvalue = [
    {
      id:1,
      value:'AM'
    },{
      id:2,
      value:'PM'
    }
  ];

  const handleHour = (event) => {
    setHour(event.target.value);
  }

  const handleMin = (event) => {
    setMinute(event.target.value);
  }

  const handleMeridiem = (event) => {

    let result = meridiemvalue.filter(function (item) {
      return item.id == event.target.value;
    });

    setMeridiemval(result[0].id);
    setMeridiem(result[0].value);
  }

  useEffect(() => {
       props.handleStarttime(hourcnt[hour],minutecnt[minute],meridiem);
  },[hour,minute,meridiem])

  useEffect(() => {

    let date = new Date();
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let meridiem = hours >= 12 ? 'pm' : 'am';

    let nethours = hours > 12 ? `${hours - 12}` : hours;

    setHour(nethours);
    setMinute(minutes);

     if(meridiem == 'am') {
       setMeridiemval(1);
       setMeridiem("AM");
     } else {
       setMeridiemval(2);
       setMeridiem("PM");
     }

  },[]);

  return (

  <div className="timepickerwrapper">

        {/* hour */}
     <div className="timepickercontainer">

         <div className="timepickerhour">
            Hour
        </div>

       <select value={hour} className="timeselect" onChange={handleHour}>
        {hourcnt.map((element,index) => {
          let keyvalue = parseInt(element);
          return (
             <option key={index} value={keyvalue} >{element}</option>
        )})}

      </select>
     </div>

      <div className="temptspace"></div>
      <div className="temptspace"></div>

        {/* Min */}
      <div className="timepickercontainer">
      <div className="timepickerhour">Min</div>
        <select value={minute} className="timeselect" onChange={handleMin} >
          {minutecnt.map((element,index) => {
                let keyvalue = parseInt(element);
             return (
                <option key={index} value={keyvalue}>{element}</option>
            )})}
       </select>
      </div>

      <div className="temptspace"></div>
      <div className="temptspace"></div>

         {/* AM/PM */}
      <div className="timepickercontainer">

         <div className="timepickerhour">
            AM/PM
         </div>

         <select value={meridiemval} className="timeselect" onChange={handleMeridiem}>
            {meridiemvalue.map((element,index) => {
               let keyvalue = parseInt(element.id);
              return (
               <option key={index} value={keyvalue} >{element.value}</option>
             )
           })}
         </select>

      </div>

 </div>

  )

}

export default Timepicker;
