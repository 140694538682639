import * as actionTypes from "../actions/actionTypes";

const initialState = {
   authflag:false
};

const authReducer = (state = initialState, action) => {

  switch (action.type) {

    case actionTypes.ADD_AUTH_USER:

      return {
        authflag:action.payload
      };

    default:
      return state;
  }
};

export default authReducer;
