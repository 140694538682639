import './Accountpage.css';
import Topnavigationpool from '../../components/landingpage/gateway/Topnavigationpool';
import Account from '../../components/homepage/profile&settings/account/Account';

function Accountpage() {

  return (
    <div className="Accountpage-container">
      <Topnavigationpool />
      <div className="Accountpage-poolcontainer">
        <Account />
      </div>
    </div>
  )

}

export default Accountpage;
