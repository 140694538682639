import './takeaway.css';

import React from 'react';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector,useDispatch } from "react-redux";
import { MdOutlineCancel } from 'react-icons/md';
import * as actionCreators from "../../../../redux/actions/actionCreators";
import defaultprofile from '../../../../assets/default/user.png';
import { usertakeaway } from '../../../../redux/thunk/takeaway/fetchdata';


function Takeaway() {

 const [takeaway,setTakeaway] = useState("");
 const dispatch = useDispatch();

 const userdetails = useSelector((state) => state.userReducer.userdetails);
 const topicobj = useSelector((state) => state.activeReducer.topicid);

  const handleShare = async () => {

    let shareobj = {
        topicid:topicobj.topicid,
        userid:userdetails.userid,
        takeaway:takeaway
    }

     if(takeaway.length > 0) {

       const response = await usertakeaway(shareobj);

       if(response.STATUSCODE == 0) {
          dispatch(actionCreators.showTakeAway(false));
       }

     }

  }

  const handleClose = () => {
    dispatch(actionCreators.showTakeAway(false));
  }

  const onKeyDwn = (event) => {

     if (event.keyCode === 13) {
         event.preventDefault();
      }

     const currentTextLength = event.target.outerText.length;

      if (currentTextLength > 249 && event.keyCode != 8 && event.keyCode !== 46) {
           event.preventDefault();
       }
  }

  const handleKeypress = (event) => {

    if(event.currentTarget.textContent.length  <= 250) {

      let value = event.currentTarget.textContent;
      setTakeaway(value);

    } else {
        event.preventDefault();
     }

  }

 return (
  <div className="takeawaywrapper">
    <div className="takeaway-container">

         <div className="takeaway-canel">
            <div className="takeaway-canel-ico" onClick={() => handleClose()}>
              <MdOutlineCancel/>
            </div>
         </div>

     <div className="takeaway-title">
       <h3>Share Your Takeaway</h3>
     </div>

     <div className="takeaway-edit"
      placeholder="Describe here...."
      suppressContentEditableWarning={true}
      contentEditable="true"
      onKeyDown={onKeyDwn}
      onInput={(event) => handleKeypress(event)}
      onPaste={(e) =>  e.preventDefault()}
      onDrop={(e) =>  e.preventDefault()}
      >

      </div>

      <div style={{height:10}}> </div>

    <div className="takeaway-profile">

      <div className="takeaway-account-profile"
       style={{
         backgroundImage:userdetails && userdetails.profile_image ? `url("${userdetails.profile_image}")` : `url("${defaultprofile}")`,
         backgroundPosition: 'center',
         backgroundSize: 'cover',
         backgroundRepeat: 'no-repeat',
         maxWidth:'100%',
         maxHeight:'100%',
       }}
      />

      <div className="takeaway-footer">
         <cite> ~ {userdetails.username}</cite>
      </div>

    </div>

    <div className="takeaway-btn" onClick={() => handleShare()}>
      Share
    </div>

    </div>
  </div>
 )

}

export default Takeaway;
