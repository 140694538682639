import React from 'react';
import { MdOutlineClose } from 'react-icons/md';
import { useEffect,useState,useRef,useContext } from 'react';
import { useSelector,useDispatch } from "react-redux";
import { addUserTopicRequest } from '../../../../redux/thunk/topic/fetchdata';
import {  SocketContext  } from "../../../../context/SocketContext";
import request from '../../../../assets/dashboard/nohos.png';
import * as actionCreators from "../../../../redux/actions/actionCreators";

import './Poolrequest.css';

function Poolrequestsender(props) {

  const myref = useRef(null);
  const [name,setName] = useState("");
  const dispatch = useDispatch();

  const userdetails = useSelector((state) => state.userReducer.userdetails);
  const topicDetails = useSelector((state) => state.poolDetailsReducer.topicDetails);

  const { socket } = useContext(SocketContext);

  const closeRequest = () => {
    props.hideRequest();
  }

  const handleSubmit = async() => {

     const topicrequestid =  topicDetails?.topicId + userdetails.userid;

     let tousrid;

       if(props.touserid == null) {
          tousrid = topicDetails?.userid;
       } else {
          tousrid = props.touserid;
        }

      let topicrequest = {
         topicid:topicDetails?.topicId,
         topic:topicDetails?.topic,
         fromuserid:userdetails.userid,
         touserid:tousrid,
         status:"pending",
         isdeleted:false,
         topicrequestid:topicrequestid
      }

     const response = await addUserTopicRequest(topicrequest);

      let notify = {
        notifyto:tousrid,
        notifyfrom:userdetails.userid,
        commentTxt:topicDetails?.topic,
        notify_topicid:topicDetails?.topicId,
        notify_type:"request",
        notify_comment_id:"12",
        notify_reply_id:"",
        topicrequestid:topicrequestid,
        requestid:response.PAYLOAD
     }

      if(response.STATUSCODE == 0)  {

          if(socket) {
            socket?.emit("replyNoti",notify);
          }

            let obj = {
              userid:props.touserid,
              requestid:response.PAYLOAD
             }

            dispatch(actionCreators.updateStatus(obj));

      }

     props.hideRequest();

  }

  const handleClick = (event) => {
    if (myref.current && !myref.current.contains(event.target)) {
        props.hideRequest();
      }
   };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
       document.removeEventListener("mousedown", handleClick, false);
     };

  }, []);


  return (
     <div className="poolrequest-modal">
       <div ref={myref} className="poolrequest-wrapper">

         <div className="poolrequest-upperpanel">

               {/* Image and close button */}
           <div className="poolrequest-imgwrapper">
             <img className="poolrequest-img" src={request} />
              <div className="poolrequest-icon" onClick={closeRequest}>
                 <MdOutlineClose  />
              </div>
           </div>

               {/* topic heading */}
            <div className="poolrequest-title">
               <div>
                  <s>&nbsp;&nbsp;&nbsp;</s>
                  <span>&nbsp;Topic&nbsp;</span>
                  <s>&nbsp;&nbsp;&nbsp;</s>
               </div>
             </div>

                {/* topic title */}
            <div className="poolrequest-topic">
               <div>{topicDetails?.topic}</div>
            </div>

         </div>

                {/* submit button */}
         <div className="poolrequest-lowerpanel">
           <div className="poolrequest-btnconfirm-wrapper">
              <div className="poolrequest-btnconfirm" onClick={handleSubmit}>
                 Submit
              </div>
            </div>
         </div>

       </div>
     </div>
  );
}

export default Poolrequestsender;
