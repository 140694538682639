import * as actionTypes from "../actions/actionTypes";

const initialState = {
   topicId:""
};

const reqnavReducer = (state = initialState, action) => {

  switch (action.type) {

    case actionTypes.REQNAV:

      return {
        topicId:action.payload
      };

    default:
      return state;
  }
};

export default reqnavReducer;
