import * as actionTypes from "../actions/actionTypes";

const initialState = {
  userid:"",
  username:"",
  flag:false,
  alldone:false
};

const welReducer = (state = initialState, action) => {

  switch (action.type) {

    case actionTypes.WELCOME_FLAG:

      return {
        userid:action.payload.userid,
        username:action.payload.username,
        flag:action.payload.flag,
        alldone:action.payload.alldone
      };


    default:
      return state;
  }
};

export default welReducer;
