import * as actionTypes from "../actions/actionTypes";

const initialState = {
   sterotypeflag:false,
   sterotype:null
};

const profileReducer = (state = initialState, action) => {

  switch (action.type) {

    case actionTypes.STEROTYPE_FLAG:

      return {
        ...state,
        sterotypeflag:action.payload
      };

      case actionTypes.SET_STEROTYPE:

        return {
          sterotypeflag:action.payload.sterotypeflag,
          sterotype:action.payload.sterotype
        };

    default:
      return state;
  }
};

export default profileReducer;
