import './Topicsearch.css';
import React, { useState,useEffect,useRef } from 'react';
import { useSelector,useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getSearchResult } from '../../../../redux/thunk/post/fetchdata';
import * as actionCreators from '../../../../redux/actions/actionCreators';
import { BiSearchAlt } from 'react-icons/bi';
import { MdOutlineClear } from 'react-icons/md';
import { BsArrowLeftShort } from 'react-icons/bs';


function Topicsearch(props) {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchTerm,setSearchTerm] = useState(null);
  const [searchResult,setSearchReult] = useState(null);
  const [searchclearflag,Setsearchclearflag] = useState(false);

  const topicobj = useSelector((state) => state.activeReducer.topicid);
  const postsearch = useSelector((state) => state.postlistReducer.postsearch);

  const searchwindowRef = useRef(null);
  const searchbarRef = useRef(null);
  const inputref = useRef(null);

  const handleBack = () => {
    props.handleSearch();
  }

  const handlePostSearch = (item) => {

    searchbarRef.current.value = "";
    setSearchReult(null);

    let tempempty = [];
    dispatch(actionCreators.clearPostSearchTerm(tempempty));

    let tempitem = [];
    tempitem.push(item);
    dispatch(actionCreators.setPostSearchResult(tempitem));
    navigate(`/homepage/active/${topicobj.topicid}/postsearch`);

  }

  const handleAllPostSearch = (allresult) => {

     searchbarRef.current.value = "";
     setSearchReult(null);

     let tempempty = [];
     dispatch(actionCreators.clearPostSearchTerm(tempempty));

     let tempitem = [...allresult];
     dispatch(actionCreators.setPostSearchResult(tempitem));
     navigate(`/homepage/active/${topicobj.topicid}/postsearch`);

  }

  const handleSearchTerm = (event) => {

    let searchQuery = event.target.value.toLowerCase();

     setSearchTerm(searchQuery);
     Setsearchclearflag(true);

  }

  const handleClickOutside = (event) => {
    if (searchwindowRef.current && !searchwindowRef.current.contains(event.target)) {
        setSearchReult(null);
      }
  }

  const handleClear = () => {
     searchbarRef.current.value = "";
     Setsearchclearflag(false);
     searchbarRef.current.focus();
  }

  const handleSearchResult = () => {

    let searchQuery = searchbarRef.current.value.trim().toLowerCase();

    let tempempty = [];
    dispatch(actionCreators.clearPostSearchTerm(tempempty));

    if(searchQuery.length > 1) {
      dispatch(actionCreators.setPostSearchTerm(searchQuery));
      navigate(`/homepage/active/${topicobj.topicid}/postsearch`);
    }

  }

  useEffect(async () => {

    if(searchTerm != null) {

       if(searchTerm.length  >  1) {

         let searchObj = {
           topicid:topicobj.topicid,
           searchterm:searchTerm
         }

         const searchResponse = await getSearchResult(searchObj);

         if(searchResponse.STATUSCODE == 0) {
            setSearchReult(searchResponse.PAYLOAD);
          }

        } else {
          setSearchReult(null);
       }

    }

  },[searchTerm])

  useEffect(() => {

    searchbarRef.current.focus();

     document.addEventListener("mousedown", handleClickOutside);

     return () => {
        document.removeEventListener("mousedown", handleClickOutside, false);
     };

  },[]);

  return (
     <div className="topicsearch-container">

     <div className="topicsearch-closebtn" onClick={handleBack}>
       <BsArrowLeftShort/>
      </div>

      <input onChange={(event) => handleSearchTerm(event)} className="topicsearch-inp"  ref={searchbarRef} type="text" placeholder="Search..." />

      <div className={searchclearflag ? "topicsearch-clearicon" : null } onClick={handleClear} >
       {searchclearflag ? <MdOutlineClear /> : null }
      </div>

      <div style={{width:8}}> </div>

      <div className="ac-header-search" onClick={handleSearchResult} >
          <BiSearchAlt />
      </div>

       {searchResult ?
         <div ref={searchwindowRef} className="topicsearc-result">
          {searchResult?.map((element,index) => {
            return  (
                <div className="topicsearch-line" onClick={() => handlePostSearch(element)}>
                   {element.topictxt}
                  </div>
               )})}
           <div style={{height:4}}> </div>
            <div className="topicsearch-line-all" onClick={() => handleAllPostSearch(searchResult)}>
              Show All Search
            </div>

          </div> :
         null }

     </div>
  )
}

export default Topicsearch;
