import './Register.css';
import React, { useState,useEffect,useRef } from 'react';
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { v4 as uuidV4 } from "uuid";

import * as actionCreators from "../../../redux/actions/actionCreators";

import { usernamevalidation,emailvalidation,passwordvalidation,mobilevalidation } from '../../../utils/Validation';
import { checkUserName,sendUserOtp,checkEmail,checkMobile } from '../../../redux/thunk/gateway/fetchdata';
import { registerPost } from '../../../redux/thunk/gateway/fetchdata';

import { BiMessageRoundedError } from 'react-icons/bi';
import { IoIosArrowDown } from 'react-icons/io';

import { getdeviceType } from '../../../utils/Devicetype';
import { byCountryCode } from '../../../object/callcode';
import { byCountrytimezone } from '../../../object/timezone';


import Loader from '../../../spinner/loading/Spinner';

import l2 from '../../../assets/logo/l2.png';


function Register() {

 const [passwordValue,setpasswordValue] = useState(false);
 const [passwordText,setpasswordText] = useState('show');

 const [userName, setuserName] = useState('');
 const [userpassword, setuserPassword] = useState('');
 const [userEmail, setuserEmail] = useState('');

 const [userNameFlag, setuserNameFlag] = useState(true);
 const [passwordFlag, setpasswordFlag ] = useState(true);
 const [emailFlag, setemailFlag] = useState(true);
 const [userMobile,setuserMobile] = useState("");
 const [submitFlag1, setsubmitFlag1] = useState(false);
 const [submitFlag2, setsubmitFlag2] = useState(false);
 const [submitFlag3, setsubmitFlag3] = useState(false);
 const [serverError, setserverError] = useState(true);
 const [serverMsg, setserverMsg] = useState("");
 const [checkflag, setcheckflag] = useState(true);
 const [dropFlag, setdropFlag] = useState(false);
 const [searchResult, setsearchResult] = useState([]);
 const [countryName, setcountryName] = useState("");
 const [countryCode,setCountrycode] = useState("");
 const [userNameErrmsg,setuserNameErrmsg] = useState("");
 const [userEmailErrmsg,setuserEmailErrmsg] = useState("");
 const [showotp,setShowopt] = useState(false);
 const [verfiedObj,setVerfiedObj] = useState("");
 const [loading,setLoading] = useState(false);

 const navigate = useNavigate();
 const dispatch = useDispatch();
 const myRef = useRef(null);
 const myRefmobile = useRef(null);

 const closeOtp = () => {
   setShowopt(false);
   setuserName("");
   setuserPassword("");
   setuserEmail("");
   setuserMobile("");
   setVerfiedObj("");
   setsubmitFlag1(false);
   setsubmitFlag2(false);
  setsubmitFlag3(false);
 }

 const closePin = () => {
   setShowopt(false);
 }

 const handleMobileFocus = () => {
    myRefmobile.current.focus();
 }

const handleMobile = (e) => {
  const rstStr = (e.target.validity.valid) ? e.target.value : userMobile;
  setuserMobile(rstStr);
}

const handleSignup = (event) => {

  if(event.type == 'click' || event.keyCode == 13) {

    if(userNameFlag == true) {
      if(usernamevalidation(userName) == true) {
          setuserNameFlag(true);
          setuserNameErrmsg("");
          setsubmitFlag1(true)
      } else {
          setuserNameFlag(false);
          setsubmitFlag1(false);
          setuserNameErrmsg("Username must be least 2 characters.");
          setuserPassword("");
      }
   }

    if(emailFlag == false) {
     return;
  }


   if(passwordvalidation(userpassword)) {
        setpasswordFlag(true)
        setsubmitFlag2(true);
   } else {
        setpasswordFlag(false)
        setsubmitFlag2(false);
        setuserPassword("");
     }

   if(checkflag == true) {

     if(emailvalidation(userEmail)) {
        setemailFlag(true);
        setsubmitFlag3(true);
     } else {
        setemailFlag(false);
        setsubmitFlag3(false);
        setuserPassword("");
     }

   } else {

     let value = `${countryCode}${userMobile}`;

     if(mobilevalidation(value)) {
        setemailFlag(true);
        setsubmitFlag3(true);
     } else {
        setemailFlag(false);
        setsubmitFlag3(false);
        setuserPassword("");
     }

  }


  }

}

  // handleRegisterUsername
const handleRegisterUsername = (event) => {
  let username = event.target.value;
  setuserName(username);
}

  // handlePassword
const handlePassword = (event) => {
   let usrpassword = event.target.value;
   setuserPassword(usrpassword);
}

  // handleEmail
const handleEmail = (event) => {
  let usremail = event.target.value.trim();
  setuserEmail(usremail);
}

  // handleClickShowPassword
const handleClickShowPassword = () => {

  setpasswordValue(!passwordValue);

  if(passwordText === 'hide') {
      setpasswordText('show');
    } else {
      setpasswordText('hide');
  }
}

const handleEmailbox = () => {
  setcheckflag(true);
  setuserMobile("");
  setuserPassword("");
  setemailFlag(true);
  setuserEmailErrmsg("");
}

const handleMobilebox = () => {
  setcheckflag(false);
  setuserEmail("");
  setuserPassword("");
  setemailFlag(true);
  setuserEmailErrmsg("");
}

const handleDropdown = () => {
    setdropFlag(!dropFlag)
    setsearchResult("");
}

const filterFunction = (e) => {
  let searchString = e.target.value;
  let result = byCountryCode.filter(x => x.name.toLowerCase().includes(searchString.toLowerCase()))
  setsearchResult(result);
}

const handleCode = (dialcode,code) => {
    setcountryName(dialcode);
    setCountrycode(code);
    setdropFlag(!dropFlag);
}

const handleClick = (e) => {

   if(myRef.current == null) {
     return
   }

    if (!myRef.current.contains(e.target)) {
       setdropFlag(false);
    }

}

const chkUsername = async () => {

  if(userName.length >= 2) {
     let username = userName.trim();
     const response =  await checkUserName(username);
     if(response.STATUSCODE == 0) {
         if(response.PAYLOAD == 1) {
             setuserNameFlag(false);
             let str = "User name already exist";
             setuserNameErrmsg(str);
         } else {
             setuserNameFlag(true);
             setuserNameErrmsg("")
         }
     }
  } else {
    if(userName.length < 2 && userName.trim().length != 0) {
       let str = "Username must be at least 2 characters.";
        setuserNameFlag(false);
        setuserNameErrmsg(str);
      }
  }

}

const chkUseremail = async () => {

 if(userEmail.length > 0) {

    if(emailvalidation(userEmail)) {

       const response = await checkEmail(userEmail);

       if(response.STATUSCODE == 0) {
           if(response.PAYLOAD == 1) {
               setemailFlag(false);
               let str = "Email already exist";
               setuserEmailErrmsg(str);
           } else {
               setemailFlag(true);
               setuserEmailErrmsg("");
           }
       }

     } else {
        setemailFlag(false);
        setuserEmailErrmsg("Please enter valid email address.");
    }

  }

}

const chkUsermobile = async () => {

   let value = `${countryCode}${userMobile}`;

   if(userMobile.length > 0) {

    if(mobilevalidation(value)) {

     const response = await checkMobile(value);

     if(response.STATUSCODE == 0) {
         if(response.PAYLOAD == 1) {
             setemailFlag(false);
             let str = "Mobile already exist";
             setuserEmailErrmsg(str);
         } else {
             setemailFlag(true);
             setuserEmailErrmsg("");
         }
     }

    } else {
       setemailFlag(false);
       setemailFlag(false);
       let str = "Invalid mobile number";
       setuserEmailErrmsg(str);
    }

  }

}

useEffect( async () => {

  if(submitFlag1 === true && submitFlag2 === true && submitFlag3 === true ) {

      setLoading(true);

      const code1 = uuidV4();
      const code2 = uuidV4();
      const scode =  code1+"-"+code2;

      let userObjvf = {
           userName:userName,
           userpassword:userpassword,
           email:userEmail,
           mobile:userMobile,
           pin:"",
           scode:scode
        }

    const responseData = await registerPost(userName,userEmail,userpassword,scode);


      if(responseData.STATUSCODE == 0) {

          let obj = {
            userid:responseData.PAYLOAD.userid,
            username:userName,
            flag:true
          }

          let timestamp = Date.now();

          let verifyId = uuidV4() + "-" + timestamp;

          let userObj = {
              verifyid:verifyId,
              userName:userName,
              email:userEmail,
              mobile:userMobile
           }

           setLoading(false);

           dispatch(actionCreators.addAuthUser(true));
           dispatch(actionCreators.setWelcomeFlag(obj));

           const responseOtpCode = await sendUserOtp(userObj);

           let submitobj = {
                currentemail:userEmail,
                emailId:userEmail,
                otpFlag:false,
                verifyid:verifyId,
                updateflag:false
             }


            dispatch(actionCreators.setOtpFlag(submitobj));

           if(responseOtpCode.STATUSCODE == 0) {
             navigate('/homepage');
           } else {
             navigate('/homepage');
           }


      }

  }

}, [submitFlag1,submitFlag2,submitFlag3]);

useEffect(() => {

   const deviceType = getdeviceType();

   function getTimeZone() {
     var offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
     return (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) + ":" + ("00" + (o % 60)).slice(-2);
  }

    let timezonename = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let timez =  getTimeZone();
    let result = byCountrytimezone.filter(x => x.TimeZone == timezonename);

    if(result.length > 0) {

      let res = byCountryCode.filter(x => x.code == result[0].CountryCode);

       if(res.length > 0) {
         setcountryName(res[0].code);
         setCountrycode(res[0].dial_code);
       }
    } else {
       setcountryName("US");
       setCountrycode("+1");
    }

   document.addEventListener("mousedown", handleClick);

   return () => {
      document.removeEventListener("mousedown", handleClick, false);
    };

  dispatch(actionCreators.deviceOrinent(deviceType));
},[])

useEffect(() => {
  chkUsermobile();
},[countryCode])

return (

  <div className="Register-container-wrapper">

    <div className="Register-container">

     { /* header */ }
     <div className="Register-header">

     { /* logo */ }
     <a href="/" className="Register-logo font-face-gm">
       prior<span className="Register-logo-style"><img src={l2} /></span>you
     </a>

      <div>&nbsp;</div>

     { /* title */ }
     {serverError ?
      <div className="Register-title">
         <div>Sign up and start Learning</div>
         <div>with your friends</div>
     </div> :
     <div className="Register-title-err">
         {serverMsg}&nbsp;&nbsp;
       <div className="Err-icon"><BiMessageRoundedError /></div>
     </div> }


   </div>

   { /* separator */ }
  <div className="Register-title-separator">
    <hr />
  </div>

    { /* main body */ }
  <div className="Register-main">
    <div className="main-content">

    <div className="Register-toggle">

    {/*
      <div className="btn-container">
         {checkflag ?
         <div className="btn-container-email" >
            Email
          </div> :
          <div className="btn-container-emailmv" onClick={() => handleEmailbox()}>
             Email
           </div>  }
         <div>&nbsp;</div>
         <div>&nbsp;</div>
         {checkflag ?
         <div className="btn-container-mobile"  onClick={() => handleMobilebox()}>
           Mobile
          </div> :
           <div className="btn-container-mobilemv">
           Mobile
          </div>
        }
      </div>
    */}

     </div>

     <div className="Register-ht">
     </div>

      { /* user */ }
      <div className="Register-user">
        <input type="text" onChange={(event) => handleRegisterUsername(event)} className="Register-formcontrol" placeholder="Username"
         onBlur={chkUsername}  value={userName} />
        <p className={userNameFlag ? "msg":"msgerr"}>{userNameFlag ? "This will be your new Prioryou ID" : userNameErrmsg }</p>
      </div>

       { /* email */ }

    {checkflag ?
      <div className="Register-email">
        <input type="email" className="Register-formcontrol"
          placeholder="Email" onBlur={chkUseremail} onChange={(event) => handleEmail(event)} value={userEmail} />
        <p className={emailFlag ? "msg":"msgerr"}>{emailFlag ? "" : userEmailErrmsg}</p>
      </div> :

       <div>
         <div className="Register-formcontrol Regmobile" onClick={() => handleMobileFocus()}>

           <div className="Register-mobile-code" onClick={() => handleDropdown()} >
            <div className="Register-country-code">{countryName}&nbsp;{countryCode}</div>
            <div className="Register-mobile-arrow">
             <IoIosArrowDown/>
            </div>
           </div>

          <div className="Register-mobile-wrapper" >
           <input className="Register-mobile-input" type="text"
             onBlur={chkUsermobile} pattern="[0-9]*"
             placeholder="Mobile" onKeyDown={(event) => handleSignup(event)} onChange={(event) => handleMobile(event)} ref={myRefmobile}
             value={userMobile}  / >
          </div>

        </div>

         { dropFlag ?
          <div className="Register-dropdown" ref={myRef}>
              <div className="Register-dropdown-body">

                <div className="Register-list">
                  <input className="mobilenuminp"  type="text"
                    placeholder="Search" id="myInput"
                    onChange={(event) => filterFunction(event)} />
                </div>

                <div className="alist">

                 <div>&nbsp;</div>

          {searchResult.length > 0 ?
             <>
              {searchResult.map((element,index) => {
              return (
             <div className="itemlist" key={index}>
               <span className="item" href="#about" onClick={() => handleCode(element.code,element.dial_code)}>{element.emoji} &nbsp;{element.name}&nbsp;({element.dial_code})</span>
             </div>
           )
          })}
             </> :
             <>
              {byCountryCode.map((element,index) => {
                return (
                <div className="itemlist" key={index}>
                  <span className="item" href="#about"
                    onClick={() => handleCode(element.code,element.dial_code)}>
                    {element.emoji} &nbsp;{element.name}
                    &nbsp;({element.dial_code})</span>
                </div>
                )
              })}
             </>
           }

            </div>
          </div>
          </div> : null }

           <p className={emailFlag ? "msgm":"msgmerr"}>{emailFlag ? "" : userEmailErrmsg}</p>
       </div>
     }

        { /* password  */ }
      <div className="Register-password-wrapper">

        <input type={passwordValue ? "text" : "password"} className="Register-formcontrol" placeholder="Password"
         onKeyDown={(event) => handleSignup(event)} onChange={(event) => handlePassword(event)}
         value={userpassword} />

         <span className="Register-password-show" onClick={() => handleClickShowPassword()} >{passwordText}</span>

         <p className={passwordFlag ? "msg":"msgerr"}>{passwordFlag ? "Use 6 or more characters" : "Password must be at least 6 characters."}</p>
      </div>

      <div>&nbsp;</div>

       { /* signup button  */ }
      <div className="Register-footer">
        <button className="Register-signup-button" onClick={(event) => handleSignup(event)}>SignUp</button>
      </div>

      <div className="Register-note">
        <small>Already have an account? Sign in&nbsp;<a  style={{color:"red"}} href="/signin">HERE</a></small>
      </div>

    </div>
  </div>

    </div>

  {loading ? <Loader /> : null }


 </div>

  )
}

export default Register;
