import * as actionTypes from "../actions/actionTypes";

const initialState = {
    addResources:false,
    delResources:{deleteflag:false},
 updateResources:{ updateflag:false },
    allResources:null
};

const resourcesReducer = (state = initialState, action) => {

  switch (action.type) {

    case actionTypes.ADD_RESOURCES:

      return {
        ...state,
        addResources:action.payload
      };

    case actionTypes.ADD_ALL_RESOURCES:

      return {
        ...state,
        allResources:action.payload
      };

    case actionTypes.DEL_RESOURCES:

        return {
          ...state,
          delResources:action.payload
       };

    case actionTypes.UPDATE_RESOURCES:

        return {
           ...state,
            updateResources:action.payload
        };

    case actionTypes.UPDATE_ADD_RESOURCES:

           let finalupdate = [];
           let tempupdate = [];
           let tempallresources = [];

            tempupdate.push(action.payload);

            if(state.allResources != null) {
               tempallresources = [...state.allResources];
               finalupdate = [...tempupdate,...tempallresources];
            } else {
                finalupdate = [...tempupdate];
            }

      return {
        ...state,
          allResources:finalupdate
        };

    case  actionTypes.UPDATE_RESOURCES_RECORD:

          let tempresupdate = [...state.allResources];

          let updindex = tempresupdate.findIndex(item => item.assetid == action.payload.assetid);

          tempresupdate[updindex] = action.payload;

          return {
            ...state,
              allResources:tempresupdate
            };

    case actionTypes.UPDATE_DEL_RECORD:

        let tempdelresupdate = [...state.allResources];

        let upddelindex = tempdelresupdate.findIndex(item => item.assetid == action.payload);


        if (upddelindex !== -1) {
           tempdelresupdate.splice(upddelindex, 1);
         }


        return {
          ...state,
          allResources:tempdelresupdate
        };

    default:
      return state;
  }
};

export default resourcesReducer;
