import * as actionTypes from "../actions/actionTypes";

const initialState = {
   flag:""
};

const tstReducer = (state = initialState, action) => {

  switch (action.type) {

    case actionTypes.ADD_TOPIC_ID2:

      return {
        flag:action.payload
      };

    default:
      return state;
  }
};

export default tstReducer;
