import './Homepage-topnavbar.css';
import React, { useState,useRef,useEffect,useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import l1 from '../../../assets/logo/l1.png';

import { HiOutlineSearch } from 'react-icons/hi';
import { BiMessageRounded }  from 'react-icons/bi';
import { IoMdNotificationsOutline } from 'react-icons/io';
import { TbZodiacAquarius } from 'react-icons/tb';
import { BiWater } from 'react-icons/bi';

import { useSelector,useDispatch } from "react-redux";
import {  SocketContext  } from "../../../context/SocketContext";
import { getActiveMsgNotifications } from '../../../redux/thunk/message/fetchdata';
import * as actionCreators from "../../../redux/actions/actionCreators";

import Navbarmenu from './homepage-topnavbar-menu/Homepage-topnavbar-menu';
import Notification from  './homepage-topnavbar-notification/Homepage-topnavbar-notification';
import Msgnotification from './homepage-topnavbar-msgnotification/Homepage-topnavbar-msgnotification';
import Searchbar from '../homepage-maincontainer/SearchBar/SearchBar';
import Requestcall from '../homepage-maincontainer/Videocall/Requestcall';
import Videoroom from '../homepage-maincontainer/Videocall/Videoroom';
import Addsmarttag from '../homepage-maincontainer/Smarttag/Addsmarttag';
import Delpost from '../homepage-maincontainer/Post/Delpost';
import Imgpreview from '../homepage-maincontainer/Post/Imgpreview';
import Takeaway from '../homepage-maincontainer/Takeaway/takeaway';
import Rmvtaj from '../homepage-maincontainer/Smarttag/Rmvtaj';
import Otp from '../profile&settings/otp/Otpscreen';
import Messagedel from '../profile&settings/messages/Messagedel';
import Allmsgdel from '../profile&settings/messages/Allmsgdel';

import Addresources from '../homepage-maincontainer/Resources/Addresources';
import Deleteresources from '../homepage-maincontainer/Resources/Deleteresources';
import Updateresources from '../homepage-maincontainer/Resources/Updateresources';
import Leavesqaud from '../homepage-maincontainer/Team/Leavesqaud';
import Removesquad from '../homepage-maincontainer/Team/Removesqaud';
import Squadreport from '../homepage-maincontainer/Team/Squadreport';
import Welcome from '../../homepage/welcome/Welcome';
import Sterotypesel from '../profile&settings/profile/Sterotypesel';
import Alldone from '../../homepage/welcome/Alldone';
import Welcomeback from '../../homepage/welcome/Welcomeback';
import Alert from '../../../notify/Alert';
import Requestdisplay from '../profile&settings/request/Requestdisplay';
import Recoverycode from '../../landingpage/security/Recoverycode';


import Share from '../../landingpage/share/sharelink';

import defaultprofile from '../../../assets/default/user.png';

import OtpVerfication from '../welcome/OtpVerfication';

function Homepagetopnavbar(props) {

const navigate = useNavigate();
const dispatch = useDispatch();


const userdetails = useSelector((state) => state.userReducer.userdetails);

const notifyAlert = useSelector((state) => state.notifyAlertReducer.notifyAlert);
const msgAlert = useSelector((state) => state.notifyAlertReducer.msgAlert);

const grpcall = useSelector((state) => state.reqgrpcallReducer.startgrpcall);
const delmsgchat = useSelector((state) => state.messageReducer.delmsgchat);
const delAllChat = useSelector((state) => state.messageReducer.delAllChat);

const topicid = useSelector((state) => state.tstReducer.flag);
const topicRequest = useSelector((state) => state.reqnavReducer.topicId);

const addsmarttag = useSelector((state) => state.postlistReducer.addsmarttag);
const delsmarttag = useSelector((state) => state.postlistReducer.delsmarttag);
const imageFlag = useSelector((state) => state.postlistReducer.imageFlag);
const delFlag = useSelector((state) => state.postlistReducer.delpost);
const otpflag = useSelector((state) => state.otpReducer.otpflag);
const updateflag = useSelector((state) => state.otpReducer.updateflag);

const addResources = useSelector((state) => state.resourcesReducer.addResources);
const delResources = useSelector((state) => state.resourcesReducer.delResources);
const updateres = useSelector((state) => state.resourcesReducer.updateResources);
const leavesquad = useSelector((state) => state.squadReducer.leavesquad);
const removesquad = useSelector((state) => state.squadReducer.removesquad);
const reportsquad = useSelector((state) => state.squadReducer.reportsquad);
const welFlag = useSelector((state) => state.welReducer.flag);
const sterotypeflag = useSelector((state) => state.profileReducer.sterotypeflag);
const alldone = useSelector((state) => state.welReducer.alldone);
const takeaway = useSelector((state) => state.takeawayReducer.flag);
const shareflag = useSelector((state) => state.shareReducer.flag);
const deactFlag = useSelector((state) => state.deactReducer.flag);

const recoverycodeFlag = useSelector((state) => state.recoverycodeReducer.flag)

const team = useSelector((state) => state.teamReducer.team);

const { socket } = useContext(SocketContext);

const [displayflag,setDisplayflag] = useState(false);
const [notidisplay,setNotidisplay] = useState(false);
const [searchMenu,setSearchMenu] = useState(false);

// const [count,setCount] = useState(null);

const [recuserid,setRecuserid] = useState("");
const [incall,setIncall] = useState(false);
const [ongoingcall,setOngoingcall] = useState(true);
const [calldetails,setCalldetails] = useState(null);
const [setting,setSetting] = useState(false);
const [showmsg,setShowmsg] = useState(false);


const [topicAlertFlag,setTopicAlertFlag] = useState({
  flag:false,
  topic:"",
  startTime:""
});


const searchMenuflag = useRef(null);

const handleSetting = () => {
   setSetting(true);
}

const handlePool = () => {
  props.navPool();
}

const handleongoingcall = () => {
  setOngoingcall(false);
}

const displaymenu = (event) => {
   setDisplayflag(!displayflag);
}

const displayNotification = () => {
  console.log("Inside display notification");
  setNotidisplay(!notidisplay);
}

const handleSearch = () => {
  setSearchMenu(true);
}

const handleSearchMenu = (e) => {

  if (searchMenuflag.current.contains(e.target)) {
    // inside click
    return;
  }

  // outside click
  setSearchMenu(false);

}

const handleSearchClose = () => {
   setSearchMenu(false);
}

const handleRequestCall = (userid) => {
  socket?.emit("rmv-busyuser",userid);
  setIncall(false);
}

const handlePlay = () => {
  setIncall(true);
}

const closeMessage = () => {
  setShowmsg(false);
}

const checkMessage = () => {
  dispatch(actionCreators.addmsgAlert(false));
  setShowmsg(true);
}

const closeAlert = () => {

  let alertobj = {
   flag:false,
   topic:"",
   startTime:""
 }

 setTopicAlertFlag(alertobj);

}

const checkNotify = () => {

  setNotidisplay(!notidisplay);

  if(notifyAlert !== null) {
     socket?.emit("checkNoti",notifyAlert,userdetails.userid);
      dispatch(actionCreators.addnotifyAlert(null));
   }

}

useEffect(() => {

 if(socket) {

    socket.on("getNotification", (data) => {

      dispatch(actionCreators.addnotifyAlert(data.obj.countnt));
    //  setCount(data.obj.countnt);
    //  setRecuserid(data.obj.userid);
    });

    socket.on("callend", (data) => {
        handleRequestCall(userdetails.userid)
    });

    socket.on("room-status", (data) => {
      if(data.data == 0) {
        dispatch(actionCreators.changeRoomFlag(false));
      }
    });

    socket.on("grpcall", (data) => {

        if(data) {
           setCalldetails(data.obj);
           setIncall(true);
        }
    });

    socket.on("chatmsg", async (data)  => {

      let str = window.location.href;
      let urlstr = "http://192.168.0.112:3000/homepage/message";

      if(str != urlstr) {
         dispatch(actionCreators.addmsgAlert(true));
      } else {
         dispatch(actionCreators.addNewUserChat(data));
      }

    })

    socket.on("newmsgnoti", async (data) => {

      let str = window.location.href;
      let urlstr = "https://192.168.0.113:3000/homepage/message";

            if(str != urlstr) {
              dispatch(actionCreators.addmsgAlert(true));
            } else {
              dispatch(actionCreators.addNewUserChat(data));
            }


    })

    socket.on("getOnlineUser", (data) => {

       if(data.userid) {
         dispatch(actionCreators.addMember(data.userid));
       }
    });

    socket.on("rmvOnlineUser", (data) => {

       if(data.userid) {
         dispatch(actionCreators.rmvMember(data.userid));
       }
    });

    socket.on("topicAlert", ({obj}) => {

        let alertobj = {
         flag:true,
         topic:obj.topic,
         startTime:obj.startTime
       }

       setTopicAlertFlag(alertobj);

    });

 }
}, [socket]);


useEffect(() => {

  document.addEventListener("mousedown", handleSearchMenu);
  return () => {
     document.removeEventListener("mousedown", handleSearchMenu, false);
   };
}, [userdetails]);


useEffect(async () => {

   if(userdetails) {

     let obj = {
       userid:userdetails.userid,
     }

    {/*
     const response = await getActiveMsgNotifications(userdetails.userid);

     if(response.STATUSCODE == 0) {
        setMsgflag(true);
      }
    */}

      socket?.emit("meonlineuser",obj)
   }

},[userdetails])


useEffect(() => {
  console.log("updateflag",updateflag);
},[updateflag])

   return (
      <div className="Homepage-topnavbar-navigation-bar">

           { /* logo */ }

          <a href="/homepage" className="Homepage-topnavbar-navbar-logo">
                 <div className="Topnavigation-logo font-face-gm">prior</div>
               <span className="Topnavigation-logoline">
                  <img className="Topnavigation-logo-img" src={l1} />
               </span>
               <div className="Topnavigation-logo font-face-gm">you</div>
          </a>

           { /* Search bar */ }
          <div className="Homepage-topnavbar-navbar-searchbar">

           <div className="Homepage-topnavbar-wrapper-searchbar" onClick={handleSearch}>
               <div className="Homepage-topnavbar-searchtext">Search . . . .</div>
               <div className="Homepage-topnavbar-searchline">|</div>
               <div className="Homepage-topnavbar-searchicon"><HiOutlineSearch/></div>
           </div>

          </div>

           { /* navigation bar panel */ }
          <div className="Homepage-topnavbar-navbar-navpanel">

                { /* Search Icon for mobile view */ }
              <div className="Homepage-topnavbar-search-btn" onClick={handleSearch}>
                <HiOutlineSearch/>
              </div>

              <div className="Homepage-topnavbar-space"></div>

                { /* pool button */ }
               <div className='Homepage-topnavbar-navbar-pool' onClick={() => handlePool()}>
                 DiveIn
               </div>

              <div className="Homepage-topnavbar-pool-btn" onClick={() => handlePool()}>
                <BiWater />
              </div>


              <div className="Homepage-topnavbar-navbar-navpanel-space"> </div>

                <div className="Homepage-topnavbar-navbar-notification" onClick={() => checkMessage()}>
                  {msgAlert ?
                   <div className="Homepage-topnavbar-navbar-num">!</div>
                   : null }
                    <span> <BiMessageRounded /> </span>
                   {showmsg ?
                     <Msgnotification closeMessage={() => closeMessage()}  />
                     : null }
                </div>


                { /* notification button */ }
              <div className="Homepage-topnavbar-navbar-notification" onClick={() => checkNotify()}>
                  <span>
                  {notifyAlert ?
                     <div className="Homepage-topnavbar-navbar-num">{notifyAlert}</div>
                     : null }
                    <IoMdNotificationsOutline />
                  </span>
                    { notidisplay && <Notification
                      displayNotification={displayNotification}
                      userid={userdetails.userid} /> }
               </div>

               <div className="Homepage-topnavbar-space"> </div>

                 { /* profile dropdown */ }
               <div className="Homepage-topnavbar-navbar-menu">

                <div className="Homepage-topnavbar-navbar-profile-outer">
                    <div className="Homepage-topnavbar-navbar-profile" onClick={displaymenu}

                     style={{
                        backgroundImage:userdetails.profile_image ? `url("${userdetails.profile_image}")` : `url("${defaultprofile}")`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        maxWidth:'100%',
                        maxHeight:'100%',
                     }}>
                </div>
              </div>
                 {displayflag &&  <Navbarmenu
                      displaymenu={displaymenu}
                      handleSetting={handleSetting}
                      showProfile={props.showProfile}
                      showRequest={props.showRequest}
                      showAccount={props.showAccount}
                      /> }
               </div>

          </div>


          { /* Popup searchbar */ }
          <div ref={searchMenuflag} className="Homepage-topnavbar-searchbar">
            { searchMenu && <Searchbar handleSearchClose={handleSearchClose} /> }
          </div>


          { /* Popup for call */ }

        {incall ?
          <Requestcall calldetails={calldetails}
            handleRequestCall={handleRequestCall}
            userid={userdetails.userid}
            handleongoingcall={handleongoingcall}  />
          : null }

        {grpcall ?
           <Videoroom  />
        : null }

        {addsmarttag.smartflag ?
          <Addsmarttag />
        :null }

        {delFlag.delflag ?
          <Delpost />
        : null }

        {imageFlag ?
          <Imgpreview />
         : null }

        {delsmarttag.smartflag ?
          <Rmvtaj />
          : null }

        {delmsgchat.delflag ?
          <Messagedel />
         : null  }

        {delAllChat.delflag ?
          <Allmsgdel />
          : null }

       { /* Popup for Resources */ }

        {addResources ?
          <Addresources />
        : null }

        {delResources.deleteflag ?
          <Deleteresources />
         : null }

        {updateres.updateflag ?
         <Updateresources />
         : null }

        {leavesquad.leavesquadflag ?
          <Leavesqaud />
         : null }

         {removesquad.removesquadflag ?
           <Removesquad />
         : null }

        {reportsquad.reportsquadflag ?
          <Squadreport />
        : null }

        {welFlag ?
         <Welcome />
        : null }

         {sterotypeflag ?
         <Sterotypesel />
         : null }


        {alldone ?
         <Alldone />
         : null }

        {takeaway ?
         <Takeaway />
         : null }

         {shareflag ?
          <Share /> : null }

        {topicAlertFlag.flag ?
          <Alert closeAlert={closeAlert} topic={topicAlertFlag.topic} startTime={topicAlertFlag.startTime} />
          : null }

         {deactFlag ?
           <Welcomeback />
         : null }

        {topicRequest.requestflag ?
          <Requestdisplay />
        : null }

        {otpflag ?
          <OtpVerfication />
         : null }

       {recoverycodeFlag ?
        <Recoverycode />
       : null }


       {updateflag ?
        <Otp />
       : null }

     </div>
   )

}

export default Homepagetopnavbar;
