import './Searchpage.css';
import Topnavigationpool from '../../components/landingpage/gateway/Topnavigationpool';
import Searchresult from '../../components/homepage/searchresult/Searchresult';

function Searchpage() {

  return (
    <div className="Searchpage-container">
      <Topnavigationpool />
      <Searchresult />
    </div>
  );
}

export default Searchpage;
