import './sharelink.css';

import React from 'react';
import { useState,useEffect,useRef } from 'react';
import { useDispatch } from "react-redux";
import { MdOutlineCancel } from 'react-icons/md';
import * as actionCreators from "../../../redux/actions/actionCreators";


function Share() {

  const [link,setLink] = useState("");
  const [linkflag,setLinkflag] = useState(false);
  const shareflag = useRef(null);

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(actionCreators.showShareBox(false));
  }

  const handlecopy = () => {

    var copyText = document.getElementById("myInput");

    copyText.select();
    copyText.setSelectionRange(0, 99999);

    navigator.clipboard.writeText(copyText.value);

    setLinkflag(true);

  }

  const handleClickoutside = (e) => {

    if (shareflag.current.contains(e.target)) {
      // inside click
      return;
    }

    // outside click
    dispatch(actionCreators.showShareBox(false));

  }

  useEffect(() => {

    let sharlink = window.location.href;

    let urladdress = sharlink.replace("/homepage","");

    setLink(urladdress);


    document.addEventListener("mousedown", handleClickoutside);

    return () => {
       document.removeEventListener("mousedown", handleClickoutside, false);
     };


  },[])


 return (
  <div  className="sharewrapper">

     <div ref={shareflag} className="share-container">

        <div className="share-canel">
            <div className="share-canel-ico" onClick={() => handleClose()}>
              <MdOutlineCancel/>
            </div>
        </div>

        <div className="share-title">
            <h3>Share Link</h3>
        </div>

        <div style={{height:30}}> </div>

        <div className="share-input-wrapper">
          <input id="myInput" className="share-formcontrol" type="text" value={link} />
        </div>

        <div style={{height:20}}> </div>

        <div className="share-btn" onClick={() => handlecopy()}>
         Copy
        </div>

        <div style={{height:10}}> </div>

        <div style={{height:10}}>{linkflag ? "Link Copied !!" : null}</div>

     </div>
  </div>
 )

}

export default Share;
