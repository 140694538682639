import React, { useEffect,useState,useContext,useRef } from 'react';
import { useParams,useNavigate,useLocation } from "react-router-dom";
import { useSelector,useDispatch } from "react-redux";
import { v4 as uuidV4 } from "uuid";

import { BiSmile } from 'react-icons/bi';
import { BiImage } from 'react-icons/bi';
import { BiRightArrowAlt } from 'react-icons/bi';
import { BiSearchAlt } from 'react-icons/bi';
import { HiHashtag } from 'react-icons/hi';
import { MdOutlineCancel } from 'react-icons/md';

import Topicsearch from './Topicsearch';
import Postlist from './Postlist';

import * as actionCreators from '../../../../redux/actions/actionCreators';
import { returnPhotoURL,fileSize,fileName } from '../../../../services/FileType';
import { handleCompress } from '../../../../utils/CompressImage';
import { SocketContext } from "../../../../context/SocketContext";
import { getPostCount } from '../../../../redux/thunk/post/fetchdata';
import { topicsuperuser } from '../../../../redux/thunk/active/fetchdata';
import { insertUserpost } from '../../../../redux/thunk/post/fetchdata';

import defaultprofile from '../../../../assets/default/user.png';

import './Ac.css';

function Ac(props) {

   const dispatch = useDispatch();
   const navigate = useNavigate();
   const myText = useRef(null);
   const params = useParams();
   const location = useLocation()

   const postcount = useSelector((state) => state.postlistReducer.postcount);

   const postscrollPosition = useSelector((state) => state.postlistReducer.postscrollPosition);
   const team = useSelector((state) => state.teamReducer.team);
   const topicobj = useSelector((state) => state.activeReducer.topicid);
   const userdetails = useSelector((state) => state.userReducer.userdetails);
   const { socket } = useContext(SocketContext);

   const [myDivheight,setmyDivheight] = useState(120);
   const [file, setFile] = useState();

   const [imgFlag,setImgflag] = useState(false);
   const [dragFlag,setDragflag] =  useState(false);
   const [searchflag,setSearchflag] = useState(false);
   const [imgArray,setImgArray] = useState([]);
   const [teamusers,setTeamusers] = useState(null);

   const [replyUser,setReplyuser] = useState(null);
   const [replypostid,setReplypostid] = useState(null);
   const [replyuserid,setReplyuserid] = useState(null);
   const [replyImgarray,setReplyimgarray] = useState(null);
   const [postreply,setPostreply] = useState(null);

   function replaceURLWithHTMLLinks(text) {

    var re = /(\(.*?)?\b((?:https?|ftp|file):\/\/[-a-z0-9+&@#\/%?=~_()|!:,.;]*[-a-z0-9+&@#\/%=~_()|])/ig;
    return text.replace(re, function(match, lParens, url) {
        var rParens = '';
        lParens = lParens || '';

        var lParenCounter = /\(/g;
        while (lParenCounter.exec(lParens)) {
            var m;

            if (m = /(.*)(\.\).*)/.exec(url) ||
                    /(.*)(\).*)/.exec(url)) {
                url = m[1];
                rParens = m[2] + rParens;
            }
        }
        return lParens + "<a target=_blank href='" + url + "'>" + url + "</a>" + rParens;
     });
  }

   const handlePostcount = (value) => {
      dispatch(actionCreators.setPostCount(value));
  }

   const handleAccount = (userid) => {
     navigate(`/homepage/account/${userid}`)
  }

   const handleDragOver = async (e) => {
     setDragflag(true);
   }

   const handleDragLeave = (e) => {
     setDragflag(false);
   }

   const handleDrop = async (e) => {

     e.preventDefault();
     e.stopPropagation();

     let size = await fileSize(e.dataTransfer.files[0].size);

     if(size === true) {
       alert("File too Big,Select a file less than 4mb");
       return;
     }

     if(size === false) {

       let file =  e.dataTransfer.files[0];
       let result = await returnPhotoURL(URL.createObjectURL(e.dataTransfer.files[0]));

       if(result === true) {
            alert("Image is not valid format");
            return;
          }

       if(result === false) {
           let newFile = await fileName(file);
           setFile(newFile);
       }

  }

     setDragflag(false);

};

   const onImageUpload = async (event) => {

      console.log("imgArray",imgArray.length);

    if(imgArray.length < 8) {

     if(event.target.files.length <= 0) {
       return;
     }

     let size = await fileSize(event.target.files[0].size);

      // Check file size
      if(size === true) {
          alert("File too Big,Select a file less than 4mb");
          //setImageError(true);
          return;
      }

      if(size === false) {

        let result = await returnPhotoURL(URL.createObjectURL(event.target.files[0]));

         // Check image is valid or not
         if(result === true) {
             alert("Image is not valid format");
             return;
         }

         if(result === false) {
           let newFile = await fileName(event.target.files[0]);

            var offsetHeight = document.getElementById('Homepage-myDiv').offsetHeight;
            setmyDivheight(offsetHeight + 210);
            setFile(newFile);
          }
       }

     } else {
         alert("Maximum 8 Images")
     }

   }
   
   
   
    const handlePost = async () => {

      if(topicobj != null && document.getElementById("Homepage-myDiv").innerHTML.length > 0) {

       let data = document.getElementById("Homepage-myDiv").innerHTML;
       let userPostline = data.replace(/^(\ ?<div><br><(\/)?div>\ ?)+|(\ ?<div><br><(\/)?div>\ ?)+$/, '');

       if(userPostline.length > 64000) {
       return alert("maxium char is allowed 64000")
     }

       let datatext = document.getElementById("Homepage-myDiv").textContent;
       let pdata = datatext.trim();

       let finalpost = replaceURLWithHTMLLinks(userPostline);

       if(imgArray.length > 0 || pdata.length > 0) {

        const poId = uuidV4();
        const timestamp = Date.now();
        const postId = userdetails.userid+timestamp+poId;

        let userpostobj = {
         postid:postId,
         userid:userdetails.userid,
         topicid:topicobj.topicid,
         topictxt:pdata,
         userPostline:finalpost,
         teamusers:teamusers,
         imgArray:imgArray,
         replyto:replyuserid,
         replypostid:replypostid,
         replyPost:postreply,
         replyImgarray:replyImgarray
       }

         socket.emit("userpost1", {
            userpostobj
        })
 
 {/*

        const response = await insertUserpost(userpostobj);

      if(response.STATUSCODE == 0) {

         let userpostobjsoc = {
           sendobj:response.PAYLOAD,
           roomid:response.ROOMID,
         }

         socket.emit("userpost", {
             userpostobjsoc
         })

      }
 
 */}


        document.getElementById("Homepage-myDiv").innerHTML = "";

        let tempArray = [];

        setImgArray(tempArray);
        setImgflag(false);
        setmyDivheight(130);

        setReplyuser(null);
        setReplypostid(null);
        setReplyuserid(null);
        setPostreply(null);
        setReplyimgarray(null);

     }

    }

   }
   

   const handlePost1 = async () => {


     if(topicobj != null && document.getElementById("Homepage-myDiv").innerHTML.length > 0) {

      let data = document.getElementById("Homepage-myDiv").innerHTML;
      let userPostline = data.replace(/^(\ ?<div><br><(\/)?div>\ ?)+|(\ ?<div><br><(\/)?div>\ ?)+$/, '');

      if(userPostline.length > 64000) {
       return alert("maxium char is allowed 64000")
     }

      let datatext = document.getElementById("Homepage-myDiv").textContent;
      let pdata = datatext.trim();

      let finalpost = replaceURLWithHTMLLinks(userPostline);

      if(imgArray.length > 0 || pdata.length > 0) {

       const poId = uuidV4();
       const timestamp = Date.now();
       const postId = userdetails.userid+timestamp+poId;

       let userpostobj = {
         postid:postId,
         userid:userdetails.userid,
         topicid:topicobj.topicid,
         topictxt:pdata,
         userPostline:finalpost,
         teamusers:teamusers,
         imgArray:imgArray,
         replyto:replyuserid,
         replypostid:replypostid,
         replyPost:postreply,
         replyImgarray:replyImgarray
       }

       socket.emit("userpost", {
            userpostobj
        })


        document.getElementById("Homepage-myDiv").innerHTML = "";

        let tempArray = [];

        setImgArray(tempArray);
        setImgflag(false);
        setmyDivheight(130);

        setReplyuser(null);
        setReplypostid(null);
        setReplyuserid(null);
        setPostreply(null);
        setReplyimgarray(null);

     }

    }

   }

   const handleSearch = () =>  {
      setSearchflag(!searchflag);
   }

   const handleTopic = (topicid) => {
      navigate(`/homepage/pool/pooldetails/${topicid}`)
   }

   const handlePostChange = (event) => {

     var offsetHeight = document.getElementById('Homepage-myDiv').offsetHeight;

          /* Setting myDivheight height */

      if (offsetHeight <= 19) {
         setmyDivheight(offsetHeight + 71);
      } else {
        if(!imgFlag) {
          setmyDivheight(offsetHeight + 90);
        } else {
           setmyDivheight(offsetHeight + 210);
        }
      }

   }

   const handlePostbox = () => {
      myText.current.focus();
   }

   const handlePaste = async (e) => {

      var clipboardData, pastedData;

      clipboardData = e.clipboardData || window.clipboardData;
      pastedData = clipboardData.getData('Text');

      var item = e.clipboardData.items[0];

      if(pastedData.length < 1) {
        e.preventDefault();

        if(item.type.indexOf("image") === 0) {

          var blob = item.getAsFile();

          let result = await returnPhotoURL(URL.createObjectURL(blob));

          // Check image is valid or not
          if(result === true) {
             alert("Image is not valid format");
             return;
         }

          if(result === false) {
           let newFile = await fileName(blob);
             setFile(newFile);
          }
        }
      }

  }

   const clearImageSel = () => {

     var offsetHeight = document.getElementById('Homepage-myDiv').offsetHeight;
     setmyDivheight(130);

     let tempArray = [];
     setImgArray(tempArray);
     setImgflag(false);
   }

   const removeDiv = (index) => {

     let tempArray =  [...imgArray]

     tempArray.splice(index,1);
     setImgArray(tempArray);

     if(tempArray.length == 0) {
        setImgflag(false);
        setmyDivheight(130);
      }

   }

   const handleReply = (username,rpypostid,rpyuserid,post,imgArray) => {

      let name = "@"+username;
      setReplyuser(name);
      setReplypostid(rpypostid);
      setReplyuserid(rpyuserid);
      setPostreply(post);
      setReplyimgarray(imgArray);

      myText.current.focus();
   }

   const cancelReply = () => {

      setReplyuser(null);
      setReplypostid(null);
      setReplyuserid(null);
      setPostreply(null);
      setReplyimgarray(null);
      document.getElementById("Homepage-myDiv").innerHTML = "";

      let tempArray = [];
      setImgArray(tempArray);
      setImgflag(false);
      setmyDivheight(130);


  }

   useEffect(async() =>  {

   if(postscrollPosition) {
      window.scrollTo(0, parseInt(postscrollPosition));
   } else {
     window.scrollTo(0, 0);
   }

     if(team) {
         const finalArray = team.map(function (obj) {
            return parseInt(obj.enrolleduserId);
           });
         setTeamusers(finalArray);
       }
   },[team])

   useEffect(async() => {
      if(file) {
        let compressFile = await handleCompress(file,900,700,40);

        setImgArray([...imgArray,compressFile]);
        setFile(null);
        setImgflag(true)
      }
   },[file])

   useEffect(async () => {


      if(topicobj?.topicid && userdetails.userid) {

         const response = await getPostCount(params.topicid);

         if(response.STATUSCODE == 0) {
            dispatch(actionCreators.setPostCount(response.PAYLOAD));
         }

      }

   },[topicobj,userdetails])


  {/*

   useEffect(async () => {

      dispatch(actionCreators.postResetInitialState());
      dispatch(actionCreators.addTopicId(null));

     if(params.topicid) {

       const response = await topicsuperuser(params.topicid);

        if(response.STATUSCODE == 0) {

           let obj = {
              topicid:params.topicid,
              topic:response.PAYLOAD.topic,
              superuser:response.PAYLOAD.userid
           }

            dispatch(actionCreators.addTopicId(obj));

        }

     }

   },[params.topicid])

  */}

  return (

    <div className="ac-wrapper">

        {/* postbox */}
         <div className="ac-postbox-wrapper">
            <div className="ac-wrapper-content">
              {searchflag ?
                <div className="ac-header-topic">

                  <div className="ac-header-title">
                    <Topicsearch handleSearch={handleSearch} />
                  </div>

                </div>
                 :

            <div className="ac-header-topic">

             <div className="smart-tag-header-wrapper">
              <div className="smart-tag-hash"><HiHashtag /></div>

              <div className="smart-tag-header smart-tag-text" onClick={() => handleTopic(topicobj?.topicid)}>
                {topicobj?.topic}
              </div>

              <div style={{width:10}}> </div>

              <div className="ac-header-content">
                {postcount}
              </div>

             </div>

            <div className="ac-header-search" onClick={() => handleSearch()}>
                <BiSearchAlt />
            </div>

               </div> }
            </div>


           <div style={{height:10}}> </div>


            <div className="ac-team">
               {team && team.map((element,index) => {

                 return (
                   <div key={index} className="circlewrapper">
                     <div className="Homepagemaincontainer-circle" onClick={() => handleAccount(element.enrolleduserId)}>
                     <div className="Homepagemaincontainer-accprofile"
                       style={{
                          backgroundImage:element && element.profileImage ? `url("${element.profileImage}")` : `url("${defaultprofile}")`,
                          backgroundPosition: 'center',
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          maxWidth:'100%',
                          maxHeight:'100%',
                        }}
                      />
                      </div>
                      <div>
                      <div className={element.onlineusr ? "Homepagemaincontainer-status-online"
                         : "Homepagemaincontainer-status-offline"} />
                        &nbsp;&nbsp;
                      </div>
                   </div>
                  )}
                )}
            </div>


            <div className="ac-postbox-outer">
              <div className="ac-postbox" onClick={() => handlePostbox()}>

              <div className="ac-message-input-wrapper">

              {replyUser ?
                <div className="ac-replyuser">

                  <div className="ac-replyuser-name">
                     {replyUser}
                     <span className="ac-replyuser-cancel" onClick={() => cancelReply()}>
                    <MdOutlineCancel/>
                     </span>
                  </div>

                  <div className="ac-reply-details">
                    <div className="ac-reply-text" dangerouslySetInnerHTML={{__html: postreply }} >
                    </div>
                    <div className="ac-item-wrapper-img">

                      {replyImgarray	&& replyImgarray.map((imgitem,index) => {

                        let imgurl;

                        if(imgitem.postimage) {
                          imgurl = imgitem.postimage;
                        } else {
                           const b64 = new Buffer(imgitem).toString('base64')
                            imgurl  = "data:image/png;base64,"+b64;
                         }
                         return (
                             <div key={index} className="ac-item-img">
                               <img src={imgurl} />
                             </div>
                         )

                     })}
                     </div>
                  </div>

               </div> : null }

            <div id="Homepage-myDiv" contentEditable="true"
             className={dragFlag ? "ac-postform-drag" : "ac-postform" }

             data-placeholder="Text Now . . . "
               onInput={(event) => handlePostChange(event)} suppressContentEditableWarning={true} ref={myText}
               onPaste={(event) => handlePaste(event)}
               onDrop={e => handleDrop(e)}
               onDragOver={e => handleDragOver(e)}
               onDragLeave={e => handleDragLeave(e)}
                ></div>

              {imgFlag ? <div className="ac-himg-clear" >
                  <span className="ac-himg-clear-btn" onClick={() => clearImageSel()}>
                    <MdOutlineCancel />
                  </span>
                </div> : null }

            <div onDrop={e => handleDrop(e)} contentEditable={imgFlag ? "true" : "false"}
               suppressContentEditableWarning={true}  className={imgFlag ? "ac-himg-container" : ""}>
                  {imgArray && imgArray.map((element,index) => {
                     let imageurl = URL.createObjectURL(element);
                     return (
                         <div key={index} style={{ backgroundImage: `url(${imageurl})`}} className="ac-imagecontainer">
                            <span className="ac-remove-image" onClick={() => removeDiv(index)}><MdOutlineCancel/></span>
                         </div>
                     )
                  })}
            </div>

           <div className="ac-button-panel">

             <div className="ac-post-panel-left">

            {/*
              <div className="ac-emoji-button">
                 <BiSmile />
              </div>
            */}

                <div style={{width:10}}></div>

                <label className="ac-emoji-button">
                  <BiImage />
                  <input type="file" className="ac-upbtn" onClick={(event) => (event.target.value = null)}
                     onChange={(event) => onImageUpload(event)} accept="image/png,image/jpeg,image/jpg,image/bmp" multiple />
                </label>
             </div>

              <div className="ac-post-panel-right">
               <div className="ac-post-button">
                  <button className="ac-btncancel" onClick={() => cancelReply()}>cancel</button>
                  <div style={{width:20}}> </div>
                  <button className="ac-btnpost" onClick={() => handlePost()}>
                    <BiRightArrowAlt />
                 </button>
               </div>
              </div>

           </div>

              </div>
            </div>

            </div>



         </div>

         {/* postlist */}

        {topicobj != null ?
         <div className="ac-active-list">
            <Postlist postlistkey={location.state} handleReply={handleReply} />
         </div> : null }

    </div>

   )
}

export default Ac;
