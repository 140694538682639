import './Team.css';
import React, { useEffect,useState,useContext,useRef } from 'react';
import { useParams,useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from "react-redux";
import { HiHashtag } from 'react-icons/hi';
import { BiDotsHorizontalRounded } from 'react-icons/bi';
import * as actionCreators from '../../../../redux/actions/actionCreators';
import { getTeamDetails } from '../../../../redux/thunk/active/fetchdata';

import defaultprofile from '../../../../assets/default/user.png';
import tumbup from '../../../../assets/dashboard/squad.png';

function Team() {

  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const myref = useRef(null);

  const topicobj = useSelector((state) => state.activeReducer.topicid);
  const userdetails = useSelector((state) => state.userReducer.userdetails);
  const team = useSelector((state) => state.teamReducer.team);
  const [menuflag,setMenuflag] = useState([]);

  const handleLeaveSqaud = (userid,topicid) => {

    let leaveSquadObj = {
       userid:userid,
       removed_by:userid,
       topicid:topicid,
       leavesquadflag:true
    }

    dispatch(actionCreators.leavefromSquad(leaveSquadObj));
    let tempflash = [];
    setMenuflag(tempflash);

  }

  const handleReport = (byuserid,foruserid,topicid) => {

      let reportobj = {
        byuserid:byuserid,
        foruserid:foruserid,
        topicid:topicid,
        reportsquadflag:true
      }

      dispatch(actionCreators.reportSquadfor(reportobj));
      let tempflash = [];
      setMenuflag(tempflash);

  }

  const handleClickout = (event) => {
    if (myref.current && !myref.current.contains(event.target)) {
       let tempflash = [];
       setMenuflag(tempflash);
     }
  }

  const handleAccount = (userid) => {
    navigate(`/homepage/account/${userid}`)
  }

  const handleMenu = (event,index) => {
    event.preventDefault();
    event.stopPropagation();
    let temp = [];
    temp.push(index);
    setMenuflag(temp);
  }

  const showMenu = (index) => {
     let temp = [];
     temp.push(index);
     setMenuflag(temp);
  }

  const hideMenu = (index) => {
     let temprmv = [...menuflag];

      let indexrmv = temprmv.findIndex(function(element) {
        return element == index;
     });

     temprmv.splice(indexrmv, 1);

     setMenuflag(temprmv);
  }

  const handleLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
  }

  const handleRemoveUser = (userid,topicid,username) => {

    let rmvSquadObj = {
       userid:userid,
       removed_by:topicobj.superuser,
       topicid:topicid,
       username:username,
       removesquadflag:true
    }

    dispatch(actionCreators.removefromSquad(rmvSquadObj));
    let tempflash = [];
    setMenuflag(tempflash);

  }

  const handleTopic = (topicid) => {
     navigate(`/homepage/pool/pooldetails/${topicid}`)
  }

  useEffect(async() =>  {
     window.scrollTo(0, 0);

     document.addEventListener("mousedown", handleClickout);
     return () => {
        document.removeEventListener("mousedown", handleClickout, false);
      };

   },[])

  return (
    <>
   {topicobj != null ?
    <div className="ac-wrapper">

     {team &&
       <>


         <div className="smart-tag-header-wrapper">
           <div className="smart-tag-hash"><HiHashtag /></div>
           <div className="smart-tag-header smart-tag-text" onClick={() => handleTopic(topicobj.topicid)}>
             {topicobj.topic}
           </div>
         </div>

        <h4 className="Team-list-title">Squad</h4>
          <div className="Team-list-title">
             <img className="Team-title-img" src={tumbup} />
          </div>
        </>
       }

      <div className="Team-profile-wrapper" >

      {team && team.map((element,index) => {

        let supuser;

         if((userdetails.userid == topicobj.superuser) && (element.enrolleduserId == topicobj.superuser)) {
            supuser = true;
         } else {
            supuser = false;
         }


         return (
           <div className="Team-profile" onClick={() => handleAccount(element.enrolleduserId)}>
             <div className="Team-profile-outline">

              {element.enrolleduserId == topicobj.superuser  ?
                 <div className="Team-super-user">SuperUser</div> :
                 <div className="Team-super-user-dis">Super User</div>
              }

            {supuser ?

              <div className="Team-profile-menu-dis">
                <div className="Team-menu-dot" onClick={(event) => handleMenu(event,index)}>
                   <BiDotsHorizontalRounded />
                 </div>
              </div> :

              <div className="Team-profile-menu">
                <div className="Team-menu-dot" onClick={(event) => handleMenu(event,index)}>
                   <BiDotsHorizontalRounded />
                 </div>
              </div>
            }

            {menuflag.includes(index) ?
              <div ref={myref} className="Team-profile-menu" onClick={(event) => handleLeave(event)}>

              {topicobj.superuser == userdetails.userid ?
                <div className="Team-profile-submenu">
                    <div className="Team-profile-submenu-item" onClick={() => handleRemoveUser(element.enrolleduserId,topicobj.topicid,element.username)}>
                       Remove User
                     </div>
                     <div className="Team-profile-submenu-item-dis"> </div>
                     <div className="Team-profile-submenu-item" onClick={() => handleReport(userdetails.userid,element.enrolleduserId,topicobj.topicid)}>
                       Report Squad
                     </div>
                </div> :

               <>
                {element.enrolleduserId == userdetails.userid ?
                 <div className="Team-profile-submenu" onClick={() => handleLeaveSqaud(userdetails.userid,topicobj.topicid)}>
                    <div>Leave Sqaud</div>
                 </div>
                 :  <div className="Team-profile-submenu" onClick={() => handleReport(userdetails.userid,element.enrolleduserId,topicobj.topicid)}>
                    <div>Report Sqaud</div>
                 </div>}
                </>

              }

              </div> :null }

               <div className="Team-profile-circle">
                  <div className="account-profile"
                    style={{
                       backgroundImage:element && element.profileImage ? `url("${element.profileImage}")` : `url("${defaultprofile}")`,
                       backgroundPosition: 'center',
                       backgroundSize: 'cover',
                       backgroundRepeat: 'no-repeat',
                       maxWidth:'100%',
                       maxHeight:'100%',
                    }}
                   />

               </div>

               <div className="Team-empspace"></div>

               <div className="Team-profile-name">
                 {element.username}
               </div>

             </div>


           </div>
       )}
     )}

      </div>

    </div> : null }
    </>
   )
}

export default Team;
