import React, { useState,useEffect,useRef,useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from "react-redux";
import { BiSearchAlt } from 'react-icons/bi';
import { BsArrowLeftShort } from 'react-icons/bs';
import { MdOutlineClear } from 'react-icons/md';

import { getUserActivePool,getUserActiveSearchPool } from '../../../../redux/thunk/pool/fetchdata';
import { topicsuperuser } from '../../../../redux/thunk/active/fetchdata';
import * as actionCreators from "../../../../redux/actions/actionCreators";

import Doublebounce from '../../../../spinner/double-bounce/Doublebounce';
import epool from '../../../../assets/dashboard/epool.png';


import './Homescreen.css';

function Homescreen() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const observer = useRef();

  const inputref = useRef();

  const userdetails = useSelector((state) => state.userReducer.userdetails);

  const lastActiveElementRef = useCallback(node => {

     if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {
         if (entries[0].isIntersecting && hasMore) {
           setPage(temp);
        }
     })

       if(node) {
           observer.current.observe(node)
        }
   })

  const [count,setCount] = useState(100);
  const [limit,setlimit] = useState(500);
  const [page,setPage] = useState(1);
  const [hasMore,setHasMore] = useState(true);

  const [activepool,setActivepool] = useState([]);
  const [activesearch,setActivesearch] = useState([]);

  const [temp,setTemp] = useState();
  const [searchflag,setSearchflag] = useState(false);
  const [searchvalue,setSearchvalue] = useState("");

  const [flag,setFlag] = useState(false);
  const [activeflag,setActiveflag] = useState(true);

  const handleActive = async(topicid) => {

      dispatch(actionCreators.postResetInitialState());
      dispatch(actionCreators.addTopicId(null));

      const response = await topicsuperuser(topicid);

      if(response.STATUSCODE == 0) {

        let obj = {
           topicid:topicid,
           topic:response.PAYLOAD.topic,
           superuser:response.PAYLOAD.userid
        }

         dispatch(actionCreators.addTopicId(obj));

     }

      let userroom = {
        roomIddis:topicid,
        userid:userdetails.userid
      }

      dispatch(actionCreators.addRoomDetails(userroom));

      navigate(`/homepage/active/${topicid}`);

  }

  const handlePublish = () => {
      navigate(`/homepage/publish/`);
    }

  const handleTopicSearch = () => {

    if(searchflag == true) {
        console.log("activepool",activepool);
      } else {
        setSearchflag(true);
    }

  }

  const closeTopicSearch = () => {
    setActiveflag(true);
    setSearchflag(false);
    window.scrollTo(0, 0);
  }

  const handleSearch = (event) => {
    setSearchvalue(event.target.value);
  }

  const handleClear = () => {
     setSearchvalue("");
  }

  useEffect(async() => {
      window.scrollTo(0, 0);
    }, []);

  useEffect(() => {
       if(count == limit) {
            setHasMore(false)
       }
  }, [count]);

  useEffect(async () => {

    if(activeflag == true) {

      let lim = 5;

      let obj = {
        userid:userdetails.userid,
        page:page,
        limit:lim
      }

      const result = await getUserActivePool(obj)

       if(result == false) {
          setFlag(true);
          setHasMore(false);
          return;
       }

       if(result) {
         let ft = result[0];

        if(ft.next == undefined) {
          setActivepool([...activepool,...result.slice(1)]);
          setHasMore(false);
        } else {
          setActivepool([...activepool,...result.slice(1)]);
          setTemp(ft.next.page)
        }

       }

  }

  }, [page]);

  useEffect(() => {

    if(searchflag == true) {
      inputref.current.focus();
    }

  },[searchflag])

 useEffect(async () => {

   if(searchvalue != null) {

      if(searchvalue.length  >  1) {

        setActiveflag(false);

        let obj = {
          userid:userdetails.userid,
          searchterm:searchvalue
        }

        const searchResponse = await getUserActiveSearchPool(obj)

        if(searchResponse.STATUSCODE == 0) {
           setActivesearch(searchResponse.PAYLOAD);
         }

       } else {
         setSearchvalue(null);
      }

   }

 },[searchvalue])


  return (
      <div className="Homescreenwrapper">

      <div className="Homescreenwrapper-titlewrap">

       {searchflag ?
         <div className="Homescreenwrapper-header">
            <div className="Homescreenwrapper-arrow" onClick={() => closeTopicSearch()}><BsArrowLeftShort /></div>
            <div className="Homescreenwrapper-inptarrow"><input value={searchvalue} ref={inputref} onChange={(event) => handleSearch(event)} className="hometopicsearch-inp" placeholder="Search..." /></div>
            <div onClick={() => handleClear()} className="topicsearch-clearicon">
               <MdOutlineClear />
            </div>
            <div>&nbsp;</div>
         </div> :
         <div className="Homescreenwrapper-header">
            <h2 className="Homescreenwrapper-title">
              Active Pool
           </h2>
         </div>
      }

        {flag == true ?
          null :
         <div className="Homescreenwrapper-search" onClick={() => handleTopicSearch()}>
            <BiSearchAlt />
         </div>
       }

      </div>

        {activeflag ?

          <>
           {activepool && activepool.map((element,index) =>  {

           return (
            <div key={index} ref={lastActiveElementRef}>
              <div className="Homescreenactivelist" onClick={() => handleActive(element.topicId)}>
                  <div className="Homescreenactivelist-title">


                  <div className="Homescreenactivelist-text">
                    {element.topic}
                    </div>
                   <div className="Homescreenactivelist-text1">
                     @ {element.startTime}
                  </div>

                   </div>
                  <div>&nbsp;</div>

                  <div className="Homescreenactivelist-time">
                    <div>from&nbsp;</div>
                    <div>{element.startDate} to {element.endDate}</div>
                 </div>

            
               </div>
               <div>&nbsp;</div>
             </div>
           )
         })}
          </>

          :

          <>
           {activesearch && activesearch.map((element,index) =>  {

           return (
            <div key={index}>
              <div className="Homescreenactivelist" onClick={() => handleActive(element.topicId)}>
                  <div className="Homescreenactivelist-title">
                    <div className="Homescreenactivelist-text">
                      {element.topic}
                    </div>
                    <div>@ {element.startTime}</div>
                   </div>
                  <div>&nbsp;</div>
                  <div className="Homescreenactivelist-time">
                    from {element.startDate} to {element.endDate}
                  </div>
               </div>
               <div>&nbsp;</div>
             </div>
           )
         })}
          </>

        }


    {activeflag ?
       <>

         {hasMore ?
           <div className="browselist-spinner">
             <Doublebounce />
           </div> : null }
      </> : null }

          {flag == true ?
           <div className="Homescreenactivelist-epool">
             <div> <img  className="Homescreenactivelist-epool-img" src={epool} /> </div>
             <div>
              <div className="Mainbody-pool-button"  onClick={() => handlePublish()}>publish now</div>
             </div>
           </div>
           : null }

      </div>
  )
}


export default Homescreen;
