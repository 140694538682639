import './Profilepicture.css';
import userprofile from '../../../../assets/default/user.png';

import { useState,useEffect } from 'react';
import { fileType,returnPhotoURL,fileSize,fileName } from '../../../../services/FileType';
import { handleCompress } from '../../../../services/CompressImage';
import Classicspiner from '../../../../spinner/classic-spiner/Classicspiner';
import { profilePhoto } from '../../../../redux/thunk/user/fetchdata';

function Profilepicture(props) {

  const [imageErrormsg,setImageErrormsg] = useState(false);
  const [imagetypeErr,setImagetypeErr] = useState(false);
  const [imagefileUrl,setImagefileUrl] = useState("");
  const [imageFile,setImageFile] = useState("");
  const [imageFlag,setImageflag] = useState(false);
  const [loader,setLoader] = useState(false);
  const [uploadflag,setUploadflag] = useState(false);

  const onImageUpload = async (event) => {

   let size = await fileSize(event.target.files[0].size);

    if(size) {
        setImageErrormsg(true);
        setImagetypeErr(false);
        return;
      } else {
        setImageErrormsg(false);
      }

   let result = await returnPhotoURL(URL.createObjectURL(event.target.files[0]));

     if(result) {
        setImagetypeErr(true);
        return;
     } else {
        setImagetypeErr(false);
        setImageflag(true);

        let newFile = await fileName(event.target.files[0]);
        setImagefileUrl(URL.createObjectURL(newFile));
        setImageFile(newFile);
        setUploadflag(true);
     }

  }

  const handleSubmit = async () => {
     setLoader(true);

     let compressFile = await handleCompress(imageFile,280,280);

     console.log("compressFile",compressFile)

     let formData = new FormData()
     formData.append('file',compressFile);

     const responseData = await profilePhoto(formData);

     switch (responseData.STATUSCODE) {

       case 0:
           props.refershProfile();
           setLoader(false);
           break;
       case 1:
           alert(responseData.MESSAGE);
           setLoader(false);
           break;

       default:
         setLoader(false);

     }
  }

  const profileStyle = {
    backgroundImage: `url("${imagefileUrl}")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    maxWidth:'100%',
    maxHeight:'100%'
  };

  const profileStyleaa = {
    backgroundImage:  props.profileimage ? `url("${props.profileimage}")`  : `url("${userprofile}")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    maxWidth:'100%',
    maxHeight:'100%'
  };

  return (
     <div className="Profilepicturecontainer">

         <div className="Profilepicture-emptyspace"/>
         <div className="Profilepicture-emptyspace"/>

          <div className="Profilepicture-imgpreview">
             Set Profile Picture
          </div>

          <div className="Profilepicture-emptyspace" />

          <div className="Profilepicture-userprofileicon"
            style={ imageFlag ? profileStyle : profileStyleaa } >
             <label className="Profilepicture-icobtn">
               <input type="file" className="Profilepicture-inptuploadButton"
                 onChange={(event) => onImageUpload(event)} accept=".jpg, .jpeg, .png .bmp" />

              </label>
           </div>

           <div className="Profilepicture-emptyspace" />

           <div className="Profilepicture-btn" >
              <label className="Profilepicture-uploadbtn">
                <input type="file" className="Profilepicture-inptuploadButton"
                 onChange={(event) => onImageUpload(event)} accept=".jpg, .jpeg, .png .bmp" />
                Upload Image
               </label>
           </div>

          <div className="Profilepicture-emptyspace" />
           <p className="Profilepicture-err">
             {imageErrormsg ? "File too Big,Select a file less than 4mb !" : ""}
           </p>
           <p className="Profilepicture-err">
             {imagetypeErr ? "Image is not valid format !" : ""}
           </p>

           <div className="Profilepicture-emptyspace" />
           <div className="Profilepicture-emptyspace" />

           <div className="Profilepicture-btn">
            {uploadflag ?
             <button className="Profilepicture-savebtn" onClick={() => handleSubmit()}>
                Save
             </button> :
             <button className="Profilepicture-savebtn-dis">
                Save
             </button> }
           </div>

            <div className="Profilepicture-emptyspace" />
            <div className="Profilepicture-emptyspace" />

          {loader ? <Classicspiner /> : null }

     </div>
  )
}

export default Profilepicture;
