import React from 'react';
import { useState, useEffect } from 'react';
import { Routes,Route,useNavigate,NavLink } from "react-router-dom";
import { BsArrowLeftShort } from 'react-icons/bs';
import Searchpool from './Searchpool';
import Searchpeople from './Searchpeople';
import './Searchresult.css';

function Searchresult(props) {

  const navigate = useNavigate();

  const handleBack = () => {
       navigate(-1);
  }

  return (
    <div className="Searchresult-container">
       <div className="Searchresult-Subprofilecontainer">

         <div className="Searchresult-profiletitle">
           <div className="Searchresult-profiletitlearrow" onClick={handleBack} ><BsArrowLeftShort/></div>
           <div className="Searchresult-profiletitletext">back</div>
         </div>

         <div className="Searchresult-profiletitlesubmenu">

           <div>
            <NavLink className={(navData) => (navData.isActive ? 'Searchresult-profilenavitema' : 'Searchresult-profilenavitem')} to="" end>Pool</NavLink>
           </div>

           <div className="Searchresult-profilemptyspace"></div>

           <div>
             <NavLink className={(navData) => (navData.isActive ? 'Searchresult-profilenavitema' : 'Searchresult-profilenavitem')} to="people">People</NavLink>
           </div>

         </div>

          <div className="Searchresult-wrapper-route">
            <Routes>
               <Route path="/" element={<Searchpool />}></Route>
               <Route path="people" element={<Searchpeople  />}></Route>
            </Routes>
          </div>

       </div>
     </div>
   )

}

export default Searchresult;
