import React, { useState,useEffect,useRef } from 'react';
import { useSelector,useDispatch } from "react-redux";
import Sterotype from './Sterotype';
import Timezone from '../homepage-maincontainer/Poolrequest/Timezone';
import { completeprofile,sendUserOtp } from '../../../redux/thunk/gateway/fetchdata';
import { BsArrowLeftShort } from 'react-icons/bs';
import { BiCheckCircle } from 'react-icons/bi';
import { BiCircle } from 'react-icons/bi';
import { BiWorld } from 'react-icons/bi';
import * as actionCreators from "../../../redux/actions/actionCreators";
import './Details.css';
import timezonelist from '../../../json/timezone.json';
import prioryou from  '../../../assets/logo/prioryou.jpg';
import OtpVerfication from './OtpVerfication';

function Details(props) {

  const dispatch = useDispatch();
  const userId = useSelector((state) => state.welReducer.userid);
  const otpValue = useSelector((state) => state.otpReducer);

  const [startFlag,Setstartflag] = useState(false);
  const [timezoneFlag,setTimezoneflag] = useState(false);
  const [timezone,setTimezone] = useState(null);
  const [gender,setGender] = useState("");
  const [location,setLocation] = useState("");

  const handleGetStarted = () => {
     Setstartflag(true);
  }

  const handleLocaltion = (event) => {
     setLocation(event.target.value);
  }

  const handleGender = (value) => {
     setGender(value);
  }

  const showTimezone = () =>  {
    setTimezoneflag(true);
  }

  const hideTimezone = ()  => {
     setTimezoneflag(false);
  }

  const mountTimezone = (value) => {
    setTimezone(value);
    setTimezoneflag(false);
  }

  const handleallDone = async () => {

    let obj= {
     userid:userId,
     sterotype:props.steroType,
     timezone:timezone,
     gender:gender,
     location:location
 }

    const response = await completeprofile(obj);

    if(response.STATUSCODE == 0) {

      let obj = {
        userid:"",
        username:"",
        flag:false,
        alldone:false
      }

      dispatch(actionCreators.setWelcomeFlag(obj));

     let submitobj = {
        currentemail:otpValue.currentemail,
        emailId:otpValue.currentemail,
        otpFlag:true,
        verifyid:otpValue.verifyid,
        updateflag:false
     }

    dispatch(actionCreators.setOtpFlag(submitobj));

  }


  }

  const handleBackarrow = () => {
     props.handleBack();
  }

  useEffect(() => {

    if(timezonelist.length > 0) {

      let value = Intl.DateTimeFormat().resolvedOptions().timeZone;

      if(value === "Asia/Calcutta" || value === "Asia/Kolkata") {

       let finalresult = "India Asia/Mumbai - UTC +05:30";
       setTimezone(finalresult);

      } else {

      let filtervalue = timezonelist.filter((item) =>  {
         return (
            item.TimeZone.toLowerCase().includes(value.toLowerCase())
         );
      });

      if(filtervalue.length > 0) {

         let finalresult = "";

         if(filtervalue[0].TimeZone) {
            finalresult = `${filtervalue[0].CountryName} ${filtervalue[0].TimeZone} - ${filtervalue[0].GMTOffset}`;
         }

         setTimezone(finalresult);
      }

    }

   }

  },[])

  return (
      <div className="Details-wrapper">


         <div className="Details-container">

           <div className="Detailstitle">
             <div className="Detailstitlearrow" onClick={() => handleBackarrow()}><BsArrowLeftShort/></div>
             <div className="Detailstitletext">&nbsp;back</div>
           </div>

           <div  className="Details-gender-container">

              <div className="Details-gender">Gender</div>

              <div className="Details-gender-wrapper" onClick={() => handleGender("M")}>
                 <div>Male</div>
                 {gender == "M" ?
                 <div className="Details-icon-gender"><BiCheckCircle /></div> :
                 <div className="Details-icon-gender"><BiCircle /></div> }
              </div>

              <div className="Details-gender-wrapper" onClick={() => handleGender("F")}>
                <div>Female</div>
                 {gender == "F" ?
                   <div className="Details-icon-gender"><BiCheckCircle /></div> :
                   <div className="Details-icon-gender"><BiCircle /></div> }
              </div>

              <div className="Details-gender-wrapper" onClick={() => handleGender("O")}>
                <div>Other</div>
                {gender == "O" ?
                  <div className="Details-icon-gender"><BiCheckCircle /></div> :
                  <div className="Details-icon-gender"><BiCircle /></div> }
              </div>

           </div>

            <div style={{height:10}}> </div>

            {/* Location */}
           <div className="Details-container-location">
             <div className="Details-location-title">Location</div>
             <div className="Details-location-name">
               <input className="Details-location" type="text"
                 onChange={(event) => handleLocaltion(event)} placeholder="City,Country" />
             </div>
              <div style={{height:20}}> </div>
           </div>

           <div style={{height:10}}> </div>

           <div className="Details-container-footer">
             <div className="Details-gender">Timezone</div>

             <div className="Details-time">
              <div className="Details-timetitle-zone" onClick={showTimezone}>
                 <div className="Details-zone"><BiWorld /></div>
                  <div className="Details-zone">{timezone ? timezone :"Timezone"}</div>
              </div>
             {timezoneFlag ? <Timezone mountTimezone={mountTimezone} hideTimezone={hideTimezone} /> : null }
             </div>

           </div>

           <div style={{height:10}}> </div>

            <div className="Details-container-footer">
               <div className="Details-container-getstarted" onClick={() => handleallDone()}>
                   Next
                </div>
            </div>

         </div>

         <div style={{height:10}}> </div>


      </div>
  );
}

export default Details;
