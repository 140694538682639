import './Duration.css';
import React, { useEffect,useState,useContext,useRef } from 'react';
import { useParams,useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from "react-redux";
import { MdOutlineCancel } from 'react-icons/md';

function Duration(props) {

  const myref = useRef(null);

  const days = Array(99).fill(0);

  const handleCancel = () => {
     props.hideDuration();
  }

  const handleDuration = (value) => {
    props.handleDaycnt(value)
    props.hideDuration();
  }

  const cancelDuration = () => {
    props.hideDuration();
  }

  const handleClick = (event) => {
    if (myref.current && !myref.current.contains(event.target)) {
       props.hideDuration();
      }
   };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
       document.removeEventListener("mousedown", handleClick, false);
     };
  },[])

  return (
    <div className="Duration-modal">
      <div className="Duration-wrapper" ref={myref}>

        <div className="Duration-header">
           <div> </div>
           <div>Number of days</div>
           <div className="Duration-cancel" onClick={() => handleCancel()}>
              <MdOutlineCancel />
            </div>
         </div>

         <div style={{height:10}}> </div>

         <div className="Duration-container">

           {days.map((element,index) => {
                let value = index + 1;
             return (
                <div key={index} className="Duration-box-wrapper" onClick={() => handleDuration(value)}>
                    {value}
                </div>
              )
            })}


        </div>
      </div>
    </div>
   )
}

export default Duration;
