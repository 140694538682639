import './Username.css';
import React, { useState,useEffect } from 'react';
import { Routes,Route,useNavigate,NavLink } from "react-router-dom";
import { BsArrowLeft } from 'react-icons/bs';
import { BsArrowLeftShort } from 'react-icons/bs';
import { useSelector,useDispatch } from "react-redux";
import { checkUserName } from '../../../../redux/thunk/gateway/fetchdata';
import { updateUserName } from '../../../../redux/thunk/setting/fetchdata';
import Successnotify from '../../../../notify/Successnotify';
import * as actionCreators from "../../../../redux/actions/actionCreators";

function Username() {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [submitflag,setSubmitflag] = useState(false);
  const [userName, setuserName] = useState('');
  const [userNameFlag, setuserNameFlag] = useState(true);
  const [userNameErrmsg,setuserNameErrmsg] = useState("");

  const [defaultname,setDefaultname] = useState("");

  const userdetails = useSelector((state) => state.userReducer.userdetails);
  const [displayflashmsg,setDisplayflashmsg] = useState(false);

  const handleBackarrow = () => {
     navigate(-1)
  }

  const handlechange = (event) => {
    let username = event.target.value;
    setuserNameErrmsg("");
    setuserName(username);
  }

  const closeFlashMsg = () => {
    setDisplayflashmsg(false);
  }

  const chkUsername = async () => {

    if(userName.length >= 2) {
       let username = userName.trim();

       const response =  await checkUserName(username);

       if(response.STATUSCODE == 0) {
           if(response.PAYLOAD == 1) {
               setuserNameFlag(false);
               let str = "User name already exist";
               setuserNameErrmsg(str);
           } else {
               setuserNameFlag(true);
               setSubmitflag(true);
               setuserNameErrmsg("")
           }
       }
     } else {
       if(userName.length < 2 && userName.trim().length != 0) {
          let str = "Username must be at least 2 characters.";
           setuserNameFlag(false);
           setuserNameErrmsg(str);
        }
    }

  }

  const handleSubmit = async () => {

    let userobj = {
       userid:userdetails.userid,
       username:userName
    }

    const response = await updateUserName(userobj);

      if(response.STATUSCODE == 0) {
         dispatch(actionCreators.updateUserName(userName));
         setDisplayflashmsg(true);
         setSubmitflag(false);
         setuserName("");
      }

  }


  return (
     <div className="deactivateaccount">

      <div className="setting-empspace"> </div>

       <div className="username-container">

          <div className="Profiletitle">
             <div className="Profiletitlearrow" onClick={handleBackarrow} ><BsArrowLeftShort/></div>
             <div style={{width:10}}> </div>
             <div className="Profiletitletext">back</div>
         </div>

         <div className="changepassword-title">Username</div>

         <div style={{height:20}}> </div>

         <div className="username-body">

           <div className="email-currentemail">{userdetails.username}</div>

           <div style={{height:20}}> </div>
           <input className="changepassword-inp" value={userName} hidefocus="true" onBlur={() => chkUsername()} type="text" onChange={(event) => handlechange(event)} placeholder="Username" />

           <div className="changepassword-error">{userNameErrmsg}</div>

           {submitflag ?
             <button className="changepassword-savebtn" onClick={() => handleSubmit()}>
                Submit
             </button> :
             <button className="changepassword-savebtn">
                Submit
            </button> }

        </div>

        {displayflashmsg ?
          <div className="changepassword-msg">
            <Successnotify notifyMsg="Username updated" closeFlashMsg={closeFlashMsg} />
          </div> : null }


       </div>
     </div>
  );

}

export default Username;
