import './Buttonspiner.css';

function Buttonspiner() {

  return (
     <div>
        <span className="loaderbl"></span>
    </div>
  )
}

export default Buttonspiner;
