export  function emailvalidation(userEmail) {

  let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

  if(pattern.test(userEmail)) {
     return true;
  } else {
     return false;
  }

}

export  function passwordvalidation(userPassword) {

  let password = userPassword.trim();

  if(password.length < 6) {
     return false;
  } else {
     return true;
  }

}

export function usernamevalidation(userName) {

  if(userName.length < 2) {
      return false;
  } else {
      return true;
  }

}

export function isNumeric(value) {
    return /^\d+$/.test(value);
}

// International phone number validation

export function mobilevalidation(phone) {

  var regex = /^\+(?:[0-9] ?){6,14}[0-9]$/;

  if (regex.test(phone)) {
     return true;
    } else {
     return false;
  }

}
