import * as actionTypes from "../actions/actionTypes";

const initialState = {
    reply:[],
    comment_id:null
};

const replyReducer = (state = initialState, action) => {

  switch (action.type) {

    case actionTypes.RMV_REPLY_COMMENT_ID:

       const rmvArray = [...state.reply];

       const newrmvArray = rmvArray.filter(item => item.commentreply_id != action.payload);

      return {
        ...state,
        reply:newrmvArray
      }

    case actionTypes.ADD_REPLY_COMMENT:

      return {
         ...state,
      reply: [...state.reply,...action.payload]
    };

    case actionTypes.ADD_SUB_REPLY:

      const subreplyArray = [];
      subreplyArray.push(action.payload);

       return {
         ...state,
           reply: [...state.reply,...subreplyArray]
       }

    case actionTypes.DELETE_REPLY:

      const delnewArray = [...state.reply];
      let delindex = delnewArray.findIndex(item => item.reply_id == action.payload);

      delnewArray.splice(delindex, 1);

      return {
          ...state,
          reply:delnewArray,
      }

    case actionTypes.LIKE_REPLY:

        const newlikeArray = [...state.reply];

        let likeindex = newlikeArray.findIndex(item => item.reply_id == action.payload);

        let rplylikecount = newlikeArray[likeindex].replylikecount + 1;

        newlikeArray[likeindex].likeFlag = true;
        newlikeArray[likeindex].replylikecount = rplylikecount;

        return {
          ...state,
          reply:newlikeArray,
        };

    case actionTypes.UNLIKE_REPLY:

          const newunlikeArray = [...state.reply];

          let unlikeindex = newunlikeArray.findIndex(item => item.reply_id  == action.payload);

          let replyunlikecount = newunlikeArray[unlikeindex].replylikecount - 1;

          newunlikeArray[unlikeindex].likeFlag = false;
          newunlikeArray[unlikeindex].replylikecount = replyunlikecount;

          return {
            ...state,
            reply:newunlikeArray,
          };

    case actionTypes.EDIT_SAVE_REPLY:

      const editsaveArray = [...state.reply]; //making a new array

      let editindex = editsaveArray.findIndex(item => item.reply_id == action.payload.reply_id);

      editsaveArray[editindex].reply_text = action.payload.reply_text;

      editsaveArray[editindex].replyisedited = "1";

         return {
             ...state,
             reply:editsaveArray
          }

    case actionTypes.RESET_REPLY:

       return {
          reply:[]
        }

    default:
      return state;
  }
};

export default replyReducer;
