import * as actionTypes from "../actions/actionTypes";

const initialState = {
   flag:false
};

const recoverycodeReducer = (state = initialState, action) => {

  switch (action.type) {

    case actionTypes.UPDATE_RECOVERYCODE_FLAG:

      return {
        flag:action.payload
      };

    default:
      return state;
  }
};

export default recoverycodeReducer;
