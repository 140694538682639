export function unVald() {

}


export  function emailValidation(email) {

  let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

   if(pattern.test(email)) {
        return('valid');
   } else {
        return('Invalid');
    }

}

export function passwordValidation(usrpassword) {

   if(usrpassword.length >= 6 ) {
      return('valid');
   } else {
      return('Invalid');
   }

}
