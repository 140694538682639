import { createContext,useEffect,useState} from "react";
import socketIO from 'socket.io-client';
import { useDispatch,useSelector } from "react-redux";
import * as actionCreators from "../redux/actions/actionCreators";

const socketURL = process.env.REACT_APP_SOCKET_URL;
const socket = socketIO(socketURL);

export const SocketContext = createContext();

export const SocketProvider = ({ children }) => {

const [socketid,setSocketid] = useState(false);
const userroom = useSelector((state) => state.socketReducer.userroom);

const dispatch = useDispatch();

  useEffect(() => {

     socket.on('connect', () => {
       setSocketid(socket.id);
     })

  },[socket])

  useEffect(() =>  {
     if(socketid) {

      {/* User Post */}

       socket.on('user-newpost', (data) => {
         dispatch(actionCreators.addUserPost(data));
       });

       socket.on("getNotification", (data) => {
         console.log("data",data);
         // setCount(data.obj.countnt);
         // setRecuserid(data.obj.userid);
       });

     }
  },[socketid])

  useEffect(() => {

      {/* Room Creation */}
    if(userroom != null) {
      socket.emit("join-room-dis", {
         roomIddis:userroom.roomIddis,
         userid:userroom.userid
       })
    }

  },[userroom])

  return (
   <SocketContext.Provider value={{ socket,socketid }}>
      {children}
   </SocketContext.Provider>
 )

}
