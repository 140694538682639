import './Deleteresources.css';
import * as actionCreators from "../../../../redux/actions/actionCreators";
import React, { useState, useRef, useEffect } from 'react';
import { useSelector,useDispatch } from "react-redux";
import { delUserResources } from '../../../../redux/thunk/resources/fetchdata';

function Popupdelete(props) {

   const myref = useRef(null);
   const dispatch = useDispatch();
   const [linkName,setlinkName] = useState('');

   const delResources = useSelector((state) => state.resourcesReducer.delResources);

   const handleClickout = (event) => {
    if (myref.current && !myref.current.contains(event.target)) {
      let deleteobj = {
         deleteflag:false
      }
      dispatch(actionCreators.delResources(deleteobj));
      }
   };

   const handleConfirmdel = async () => {

     if(delResources.assetid) {

       const response = await delUserResources(delResources.assetid);

       if(response.STATUSCODE == 0) {
          dispatch(actionCreators.updateDelResources(delResources.assetid));
       }

        let deleteobj = {
           deleteflag:false
        }

       dispatch(actionCreators.delResources(deleteobj));

     }

   }

   const handleClose = () => {
     let deleteobj = {
        deleteflag:false
     }
     dispatch(actionCreators.delResources(deleteobj));
   }

   useEffect(() => {

     document.addEventListener("mousedown", handleClickout);
     return () => {
        document.removeEventListener("mousedown", handleClickout, false);
      };

   }, []);

   useEffect(() => {
     if(delResources.deleteflag == true) {
       setlinkName(delResources.assetname)
     }
   },[delResources])


  return (
      <div className="Deleteresources-delete">
         <div ref={myref} className="Deleteresources_content-delete">

           <div className="Deleteresources-delete-header">
             <div>Delete resources</div>
             <div className="Deleteresources-delete-cancel" onClick={() => handleClose()}>&times;</div>
           </div>

            <div>&nbsp;</div>

            <div className="Deleteresources-delete-body">
               <div>This resources will be deleted:</div>
               <div><strong>{linkName}</strong></div>
            </div>

            <div>&nbsp;</div>
            <div>&nbsp;</div>

            <div className="Deleteresources-delete-footer">
                <button className="Deleteresources-btn-delete" onClick={() => handleConfirmdel()} >&nbsp;Confirm&nbsp;</button>
            </div>

         </div>
      </div>
   )
}

export default Popupdelete;
