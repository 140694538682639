import React, { useState,useEffect,useRef } from 'react';
import { useSelector,useDispatch } from "react-redux";
import { completeprofile } from '../../../redux/thunk/gateway/fetchdata';
import { BsArrowLeftShort } from 'react-icons/bs';
import { BiCheckCircle } from 'react-icons/bi';
import { BiCircle } from 'react-icons/bi';
import { BiWorld } from 'react-icons/bi';
import { RiCloseFill } from 'react-icons/ri';
import { verifyemailotp,sendEmailOtp, } from '../../../redux/thunk/setting/fetchdata';
import { resendOtp,verifyuserotp } from '../../../redux/thunk/gateway/fetchdata';
import { updateUserEmailAccount,sendUserOtp } from '../../../redux/thunk/gateway/fetchdata';
import Classicspiner from '../../../spinner/classic-spiner/Classicspiner';
import { emailvalidation } from '../../../utils/Validation';
import * as actionCreators from "../../../redux/actions/actionCreators";
import './OtpVerfication.css';
import timezonelist from '../../../json/timezone.json';
import prioryou from  '../../../assets/logo/prioryou.jpg';
import { v4 as uuidV4 } from "uuid";
import Recoverycode from '../../landingpage/security/Recoverycode';



function OtpVerfication(props) {

  const dispatch = useDispatch();
  const userId = useSelector((state) => state.welReducer.userid);
  const emailid = useSelector((state) => state.otpReducer.emailid);
  const verifyid = useSelector((state) => state.otpReducer.verifyid);
  const currentemail = useSelector((state) => state.otpReducer.currentemail);
  const userdetails = useSelector((state) => state.userReducer.userdetails);
  const otpflag = useSelector((state) => state.otpReducer);

  const [sendFlag,setSendflag] = useState(false);
  const [otpValue,setOtpValue] = useState("");
  const [otperror,setOtperror] = useState(false);
  const [otpspinner,setOtpspinner] = useState(false);
  const [userEmail, setuserEmail] = useState('');
  const [accEmail,setaccEmail] = useState('');
  const [emailFlag, setemailFlag] = useState(true);
  const [emailerrFlag, setemailerrFlag] = useState(true);
  const [userEmailErrmsg,setuserEmailErrmsg] = useState("  ");
  const [userEmailsuccmsg,setuserEmailsuccmsg] = useState(" ");

  const [loadingFlag,SetloadingFlag] = useState(false);
  const [timflag,setTimflag] = useState(false);
  const [verifyOtpId,setVerifyOtpId] = useState("");



  const otpRef = useRef();
  const [timer, setTimer] = useState(30);

  function countdown(flag) {

   let timeid = setTimeout(() => {
      let   time = timer - 1;
      if(time < 10) {
       time = "0"+time;
      }
      setTimer(time);
    }, 1000);

   if(flag == false) {
     clearTimeout(timeid);
  }

  }

  const handleConfirm = async () => {

    if(otpValue.length > 0) {

      let verifyobj = {
          email:accEmail,
          userid:userdetails.userid,
          verifyid:verifyOtpId,
          otp:otpValue,
          currentemail:accEmail
      }

      const response = await verifyemailotp(verifyobj);

        if(response.STATUSCODE == 0) {
            setuserEmailErrmsg("");
            setuserEmailsuccmsg(response.MESSAGE);
            setemailerrFlag(false);

            dispatch(actionCreators.setrecoveryCode(true));

          let submitobj = {
              currentemail:otpValue.userEmail,
              emailId:otpValue.userEmail,
              otpFlag:false,
              verifyid:"",
              updateflag:false
          }

          dispatch(actionCreators.setOtpFlag(submitobj));


         } else {
            setemailerrFlag(true);
            setuserEmailErrmsg(response.MESSAGE);
            setuserEmailsuccmsg("");
        }


    }
  }

  const handleOtpChange = (e) => {
    const otp = (e.target.validity.valid) ? e.target.value : otpValue;
    setOtpValue(otp);
  }

  const handleEmailCancel = () => {
    setemailFlag(true);
    setuserEmailErrmsg("");
    setuserEmailsuccmsg("");
    setTimflag(false);
    setTimer(30);
  }

  const handleCancel = () => {

    let submitobj = {
      currentemail:"",
      emailId:"",
      otpFlag:false,
      verifyid:"",
      updateflag:false
    }

    dispatch(actionCreators.setOtpFlag(submitobj));
  }

  const startTimer = async() => {

    let timestamp = Date.now();

    let verifyId = uuidV4() + "-" + timestamp;

     let userObj = {
       userid:userdetails.userid,
       verifyid:verifyId,
       email:accEmail,
       userName:userdetails.username
     }

      setOtpspinner(true);
      setTimer(30);
      setSendflag(false);

      const responseData = await sendUserOtp(userObj);

      if(responseData.STATUSCODE == 0) {
          setTimer(30);
          setSendflag(false);
          setVerifyOtpId(verifyId);
      }

      setOtpspinner(false);

  }

  const editEmail = () => {
   setemailFlag(!emailFlag);
   setuserEmailsuccmsg("");
   setuserEmailErrmsg("");
   setTimflag(true);
  }

  const saveEmail = async () => {

    let resultEmail = userEmail.replace(/^\s+|\s+$/gm,'');
    const emailvalidFlag = emailvalidation(resultEmail);

   if(emailvalidFlag == false) {
        setemailerrFlag(true);
        setuserEmailErrmsg('Please enter valid email address');
    }  else {

      SetloadingFlag(true);

      let timestamp = Date.now();
      let temp_verifyId = uuidV4() + "-" + timestamp;

      const response = await updateUserEmailAccount(resultEmail,userdetails.userid,userdetails.username,temp_verifyId);

      if(response.STATUSCODE == 0) {
          if(response.PAYLOAD == 1) {
              let str = "Email already exist";
              setuserEmailErrmsg(str);
              setemailerrFlag(true);
              SetloadingFlag(false);
          } else {
              setVerifyOtpId(temp_verifyId);
              setemailFlag(true);
              setuserEmailErrmsg("");
              setemailerrFlag(false);
              setaccEmail(resultEmail);
              SetloadingFlag(false);
              setuserEmailsuccmsg("Email Updated OTP send to new email id")
              setTimflag(false);
              setTimer(30);
          }
      }

    }


  }

  const handleLoginemail = (event) => {
    setuserEmail(event.target.value);
  }

  const handleLogin = async (event) => {


  }

  const handleSkip = () => {

    let submitobj = {
       currentemail:otpValue.userEmail,
       emailId:otpValue.userEmail,
       otpFlag:false,
       verifyid:"",
       updateflag:false
    }

   dispatch(actionCreators.setOtpFlag(submitobj));

   dispatch(actionCreators.setrecoveryCode(true));

  }

  useEffect(() => {

    if(timer != "00") {

      if(timflag == false) {
        countdown(true);
       } else {
         countdown(false);
       }

    } else {
      setSendflag(true);
    }

  },[timer])

  useEffect(() => {
   setaccEmail(userdetails.useremail)
  },[userdetails])

  useEffect(() => {
   setVerifyOtpId(otpflag.verifyid);
  },[otpflag])

  return (
      <div className="Otp-wrapper">
         <div className="Otp-container">


            <div className="Otp-header">Verify Email Id</div>

             <div style={{height:20}} />


            <div className="Otp-spam-msg">
              <div style={{color:'#404040',fontSize:'18px'}}>Enter confirmation code</div>
              <div style={{color:'#404040',fontSize:'18px'}}>&nbsp;sent to your email</div>
            </div>

            <div style={{height:2}} />

             <div className="Otp-err">
              <small>{emailerrFlag ?<div style={{color:"red"}}>{userEmailErrmsg}</div> : <div style={{color:"cornflowerblue"}}>{userEmailsuccmsg}</div>}</small>
             </div>

        <div className="Otp-email-box">
            {emailFlag ?
              <div className="Otp-email-text1">
                {accEmail}
              </div> :
              <div className="Otp-email">
                 <input type="email"  onChange={(event) => handleLoginemail(event)} onKeyDown={(event) => handleLogin(event)} autoFocus={true} className="Otp-email-idtext" placeholder="Email" name="" id="" />
                 <div className="Otp-email-cancel" onClick={() => handleEmailCancel()}>
                   <RiCloseFill size={20} />
                 </div>
              </div>
            }
        </div>

         {loadingFlag ?
           <div className="Opt-backdrop1">
           <Classicspiner />
          </div> : null }


            {emailFlag ?
              <div className="Otp-email-text">
                  <div className="Otp-edit-btn" onClick={() => editEmail()}>Edit</div>
              </div> :
             <div className="Otp-email-text">
                <div className="Otp-edit-btn" onClick={() => saveEmail()}>Save</div>
             </div>
           }

             <div style={{height:2}} />

            <div><small> w r o n g - e m a i l </small></div>

              <div style={{height:20}} />

            <div>
              <input className="Otpscreen-input" type="text" placeholder="OTP"
              value={otpValue} pattern="[0-9]*" onChange={(event) => handleOtpChange(event)} />
           </div>

           <div style={{height:20}} />

           <div className="Otp-spam-msg">
             <div style={{color:'red'}}><b>OTP</b> may sometimes end up&nbsp;</div>
             <div style={{color:'red'}}>in your <b>SPAM FOLDER</b></div>
           </div>

            <div style={{height:20}} />

           <div className="Otp-con-btn " onClick={() => handleConfirm()}>
              Confirm
            </div>

              <div style={{height:10}} />

              <div className="Otp-screen-timer">
                00:{timer}
               </div>

               <div>&nbsp;</div>

               <div>Didn't receive OTP ?
                 {sendFlag ?
                <span className="Otp-resend" onClick={() => startTimer()}>
                   &nbsp;Resend code
                 </span> :
                 <span className="Otp-resend-dis">
                    &nbsp;Resend code
                 </span>
               }
               </div>

               <div style={{height:10}} />

               {sendFlag ?
               <div className="Otp-container-footer">
                 <div className="Otp-container-getstarted" onClick={() => handleSkip()}>
                     Skip Now
                 </div>
               </div> :
               <div className="Otp-container-footer1">
                 <div className="Otp-container-getstarted1">
                   &nbsp;
                 </div>
               </div>
             }


         </div>
      </div>
  );
}

export default OtpVerfication;
