import React from 'react';
import { Routes,Route,useNavigate,NavLink,Link  } from "react-router-dom";
import Requestpeople from './Requestpeople';
import Requestfriend from './Requestfriend';
import './Request.css';

function Request(props) {

  return (
     <div className="Request-container">

      <div>
        <Routes>
          <Route path="/" element={<Requestpeople />}></Route>
       </Routes>
    </div>


    </div>
  );
}

export default Request;
