import './Timeline.css';
import '../Poolrequest/Tm.css';

import React from 'react';
import { useState, useEffect } from 'react';
import { useParams,useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from "react-redux";
import { HiHashtag } from 'react-icons/hi';

import * as actionCreators from "../../../../redux/actions/actionCreators";
import { getTimelineDetails,updateTimeline } from '../../../../redux/thunk/active/fetchdata';


function Timeline() {

 const params = useParams();
 const dispatch = useDispatch();
 const navigate = useNavigate();

 const userdetails = useSelector((state) => state.userReducer.userdetails);
 const topicobj = useSelector((state) => state.activeReducer.topicid);

 const [timeline,setTimeline] = useState("");
 const [templine,setTempline] = useState("");
 const [editarray,setEditarray] = useState("");
 const [show,setShow] = useState([]);
 const [editflag,setEditflag] = useState(false);

const noPaste = (event) => {


  var ediv = document.getElementById(event.target.id);

  if(ediv != null)  {

   setTimeout(function() {

      var str1 = ediv.innerText.replace(/\n/g,"");
      var str2 = ediv.innerHTML.replace(/<br>/g,"");

       if (str1 != str2) {
           ediv.innerText = ediv.innerText;
        }

   }, 1);

  if(event.dataTransfer !== undefined ) {

    if(event.dataTransfer.dropEffect === "copy") {
        event.preventDefault();
     }
  }

 }

}

const handleTitleChange = (event,timelineId,index) => {

  var newData ={
     date:templine[index].date,
     description:templine[index].description,
     timelineId:templine[index].timelineId,
     title:event.target.innerHTML,
     topic:templine[index].topic,
     topicId:templine[index].topicId,
     userid:templine[index].userid
 };

   let tmp = templine;
   tmp[index] = newData;

   setTempline([...tmp]);

}

const handleDescChange = (event,timelineId,index) => {
  templine[index].description = event.target.innerHTML;
}

const handleEdit = (event,id) => {
  event.stopPropagation();
  setEditflag(true);

  let temp = [];
  temp.push(id);
  setShow([...temp,...show]);

  setEditarray([...editarray,id])
}

const handleSave = async(id,timelineId) => {

      let obj = {
          title:templine[id].title,
          description:templine[id].description,
          timelineId:templine[id].timelineId
       }

       const response = await updateTimeline(obj);

        if(response.STATUSCODE === 0) {
           const newArray = [...templine];
           setTimeline([...templine]);


           if(show.includes(id)) {

             const tempindex = show.indexOf(id);

             let temparray = [...show];

              if (tempindex > -1) {
                temparray.splice(tempindex, 1);
              }

              setShow([...temparray]);

           }




        }

        const ind = editarray.indexOf(id);
        const neweditArray = [...editarray];
        neweditArray.splice(ind, 1);
        setEditarray([...neweditArray]);
        setEditflag(false);

   }

const handleExpand = (index) => {

   if(show.includes(index)) {

     const tempindex = show.indexOf(index);

     let temparray = [...show];

      if (tempindex > -1) {
        temparray.splice(tempindex, 1);
      }

      setShow([...temparray]);

   } else {
       let temp = [];
       temp.push(index);
       setShow([...temp,...show]);
   }

}

const handleTopic = (topicid) => {
   navigate(`/homepage/pool/pooldetails/${topicid}`)
}


const handleTakeaway = () => {
  dispatch(actionCreators.showTakeAway(true));
}

 useEffect (async() => {

     if(topicobj.topicid) {
       const response = await getTimelineDetails(topicobj.topicid);

       setTimeline(response.PAYLOAD);
       setTempline(response.PAYLOAD);
     }

     window.scrollTo(0, 0);
     document.addEventListener("paste", noPaste);
     document.addEventListener("drop", noPaste);
   return () => {
       document.removeEventListener("paste",noPaste);
       document.removeEventListener("drop",noPaste);
     };
  }, [topicobj])


 return (
  <>
  {topicobj != null ?
   <div className="ac-wrapper">

    {timeline &&
       <>


         <div className="smart-tag-header-wrapper">
           <div className="smart-tag-hash"><HiHashtag /></div>
           <div className="smart-tag-header smart-tag-text" onClick={() => handleTopic(timeline[0].topicId)}>
             {timeline[0].topic}
           </div>
         </div>


         <h4 className="tm-list-title">Timeline</h4>
       </>
      }

    <div className="tm-outerwrapper">

      <div className="tm-outercontainer">
       {timeline && timeline.map((element,index) => {

         let tmstyle;

          tmstyle = "tm-collapsible";

          let value = timeline.length - 1;

          if(value == index && !show.includes(index)) {
             tmstyle = "tm-collapsible-dis";
          }

        return (

        <div className="tm-listitem" key={index} onClick={editflag ? null : () => handleExpand(index)}>

           <div className={tmstyle} >

              <div className="tm-topicday">{index}</div>

              <div className="tm-topicdate">
                <div className="tm-topictext">{element.date}</div>

                {userdetails.userid == element.userid ?
                     <>
                         {editarray.includes(index)
                           ?<><button className="tm-edit-btn" onClick={() => handleSave(index,element.timelineId)}>save</button></>:
                           <><button className="tm-edit-btn" onClick={(event) => handleEdit(event,index)}>edit</button></>
                         }
                     </>
               : null }

              </div>


              <div id={`titleId-${index}`} className={
                editarray.includes(index) ? "tmp-title" : "tmp-title-edit"}
                  contentEditable={editarray.includes(index) ?
                  true : false} data-placeholder="Title"
                  onInput={(event) => handleTitleChange(event,element.timelineId,index)}
                  dangerouslySetInnerHTML={{__html: element.title }} >
               </div>

           </div>

           <div className={show.includes(index) ? "tm-content-max":"tm-content"}>

             <div id={`descId-${index}`} className={
               editarray.includes(index) ? "tmp-desc" : "tmp-desc-edit"}
               contentEditable={editarray.includes(index) ?
                true : false} data-placeholder ="Description" onInput={(event) => handleDescChange(event,element.timelineId,index)}
               dangerouslySetInnerHTML={{__html: element.description }} >
              </div>

              <div style={{height:20}}> </div>

           </div>

        </div>

      );

       })}
      </div>

    </div>

      {timeline &&
        <div className="tm-takeaway">
         <div className="Mainbody-pool-button" onClick={() => handleTakeaway()}>
           Takeaway
          </div>
        </div>
      }

   </div> : null }
  </>
  )

}

export default Timeline;
