import React, { useState,useEffect,useRef } from 'react';
import { Routes,Route,useNavigate,NavLink } from "react-router-dom";
import { useSelector,useDispatch } from "react-redux";
import { BsArrowLeftShort } from 'react-icons/bs';
import { verifyuserpin,updatepin } from '../../../../redux/thunk/security/fetchdata';
import Classicspiner from '../../../../spinner/classic-spiner/Classicspiner';
import Successnotify from '../../../../notify/Successnotify';
import * as actionCreators from "../../../../redux/actions/actionCreators";
import './Changepassword.css';


function Changepin(props) {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showostflag,setShowostflag] = useState(true);
  const [showflag,setShowflag] = useState(true);

  const [pinerrorflag,setPinerrorflag] = useState(false);

  const [verifyflag,setVerifyflag] = useState(false);

  const userdetails = useSelector((state) => state.userReducer.userdetails);

  const [currentpass,setCurrentpass] = useState("");
  const [verifypass,setVerifypass] = useState(false);
  const [newpass,setNewpass] = useState("");
  const [conpass,setConpass] = useState("");
  const [errormsg,setErrormsg] = useState(false);
  const [errorcmpmsg,setErrorcmpmsg] = useState(false);
  const [errorcon,setErrorcon] = useState(false);
  const [submitflag,setSubmitflag] = useState(false);
  const [displayflashmsg,setDisplayflashmsg] = useState(false);
  const [notifyMsg,setNotifyMsg] = useState("");

  const [changepass,setChangepass] = useState(false);

  const [pinFlag,setpingFlag] = useState(false);



  const handleBackarrow = () => {
     navigate(-1)
  }

  const [pinValue1,setpinValue1] = useState("");
  const [pinValue2,setpinValue2] = useState("");
  const [pinValue3,setpinValue3] = useState("");
  const [pinValue4,setpinValue4] = useState("");

  const [pinValue5,setpinValue5] = useState("");
  const [pinValue6,setpinValue6] = useState("");
  const [pinValue7,setpinValue7] = useState("");
  const [pinValue8,setpinValue8] = useState("");

  const [pinValue9,setpinValue9] = useState("");
  const [pinValue10,setpinValue10] = useState("");
  const [pinValue11,setpinValue11] = useState("");
  const [pinValue12,setpinValue12] = useState("");

  const p1 = useRef(null);
  const p2 = useRef(null);
  const p3 = useRef(null);
  const p4 = useRef(null);

  const pn1 = useRef(null);
  const pn2 = useRef(null);
  const pn3 = useRef(null);
  const pn4 = useRef(null);

  const pc1 = useRef(null);
  const pc2 = useRef(null);
  const pc3 = useRef(null);
  const pc4 = useRef(null);

  const handleShow = () => {
   setShowflag(false);
  }

  const handleHide = () => {
   setShowflag(true);
  }

  const handlepinChange1 = (e) => {
    const pvalue = (e.target.validity.valid) ? e.target.value : pinValue1;
    setpinValue1(pvalue);

      if(pvalue) {
        p2.current.focus();
      }
  }

  const handlepinChange2 = (e) => {
    const pvalue2 = (e.target.validity.valid) ? e.target.value : pinValue2;
    setpinValue2(pvalue2);

    if(pvalue2) {
      p3.current.focus();
    }
  }

  const handlepinChange3 = (e) => {
    const pvalue3 = (e.target.validity.valid) ? e.target.value : pinValue3;
    setpinValue3(pvalue3);

    if(pvalue3) {
      p4.current.focus();
    }
  }

  const handlepinChange4 = (e) => {
    const pvalue4 = (e.target.validity.valid) ? e.target.value : pinValue4;
    setpinValue4(pvalue4);

    if(pvalue4) {
      p4.current.focus();
    }
  }

  const handlepinChange5 = (e) => {
    const pinValue5 = (e.target.validity.valid) ? e.target.value : pinValue5;
    setpinValue5(pinValue5);

    if(pinValue5) {
      pn2.current.focus();
    }
  }

  const handlepinChange6 = (e) => {

    const pinValue6 = (e.target.validity.valid) ? e.target.value : pinValue6;
    setpinValue6(pinValue6);

    if(pinValue6) {
      pn3.current.focus();
    }

  }

  const handlepinChange7 = (e) => {

    const pinValue7 = (e.target.validity.valid) ? e.target.value : pinValue7;
    setpinValue7(pinValue7);

    if(pinValue7) {
      pn4.current.focus();
    }

  }

  const handlepinChange8 = (e) => {

    const pinValue8 = (e.target.validity.valid) ? e.target.value : pinValue8;
    setpinValue8(pinValue8);

    if(pinValue8) {

    }

  }

  const handlepinChange9 = (e) => {

    const pinValue9_temp = (e.target.validity.valid) ? e.target.value : pinValue9;
    setpinValue9(pinValue9_temp);

    if(pinValue9_temp) {
      pc2.current.focus();
    }

  }

  const handlepinChange10 = (e) => {

    const pinValue10_temp = (e.target.validity.valid) ? e.target.value : pinValue10;
    setpinValue10(pinValue10_temp);

    if(pinValue10_temp) {
      pc3.current.focus();
    }

  }

  const handlepinChange11 = (e) => {

    const pinValue11_temp = (e.target.validity.valid) ? e.target.value : pinValue11;
    setpinValue11(pinValue11_temp);

    if(pinValue11_temp) {
      pc4.current.focus();
    }

  }

  const handlepinChange12 = (e) => {

    const pinValue12_temp = (e.target.validity.valid) ? e.target.value : pinValue12;
    setpinValue12(pinValue12_temp);

    if(pinValue12) {

    }

  }

  const handleConfirm = async() => {

    const pin = pinValue1 + pinValue2 + pinValue3 + pinValue4;
    const pin1 = pinValue5 + pinValue6 + pinValue7 + pinValue8;

       if(pin == pin1) {

            let userName = props.verfiedObj.userName;
            let userEmail = props.verfiedObj.email;
            let userpassword = props.verfiedObj.userpassword;

          //  const responseData = await registerPost(userName,userEmail,userpassword,pin);

         {/*
            if(responseData.STATUSCODE == 0) {

             let obj = {
               userid:responseData.PAYLOAD.userid,
               username:userName,
               flag:true
             }

             dispatch(actionCreators.addAuthUser(true));
             dispatch(actionCreators.setWelcomeFlag(obj));
             navigate('/homepage');

           }
         */}

         } else {
           setPinerrorflag(true);
         }

  }

  const onKeyDownp1 = (e) => {

    if(e.keyCode == 8) {
      if(pinValue2 == "") {

       }
     }


  }

  const onKeyDownp2 = (e) => {

    if(e.keyCode == 8) {
      if(pinValue2 == "") {
        p1.current.focus();
       }
     }

  }

  const onKeyDownp3 = (e) => {

    if(e.keyCode == 8) {
      if(pinValue3 == "") {
        p2.current.focus();
       }
     }

  }

  const onKeyDownp4 = (e) => {

    if(e.keyCode == 8) {
      if(pinValue4 == "") {
        p3.current.focus();
       }
     }

  }

  const onKeyDownp5 = (e) => {

    if(e.keyCode == 8) {
      if(pinValue6 == "") {
        pn1.current.focus();
       }
     }

  }

  const onKeyDownp6 = (e) => {

    if(e.keyCode == 8) {
      if(pinValue6 == "") {
         pn1.current.focus();
       }
     }

  }

  const onKeyDownp7 = (e) => {

    if(e.keyCode == 8) {
      if(pinValue7 == "") {
        pn2.current.focus();
       }
     }

  }

  const onKeyDownp8 = (e) => {

    if(e.keyCode == 8) {
      if(pinValue8 == "") {
        pn3.current.focus();
       }
     }

  }

  const onKeyDownp9 = (e) => {

    if(e.keyCode == 8) {

      if(pinValue9 == "") {

       }

     }

  }

  const onKeyDownp10 = (e) => {

    if(e.keyCode == 8) {

      if(pinValue10 == "") {
        pc1.current.focus();
       }

     }

  }

  const onKeyDownp11 = (e) => {

    if(e.keyCode == 8) {

      if(pinValue11 == "") {
        pc2.current.focus();
       }

     }

  }

  const onKeyDownp12 = (e) => {

    if(e.keyCode == 8) {

      if(pinValue12 == "") {
        pc3.current.focus();
       }

     }

  }

  const handleVerifypin = async () => {

      let userpin = pinValue1+pinValue2+pinValue3+pinValue4;
      const response = await verifyuserpin(userpin,userdetails.userid);

       if(response.STATUSCODE == 0) {
           setVerifyflag(true);
       } else {
           setVerifyflag(false);
       }

  }

  const handleSave = async () => {

    let pin    = pinValue5 + pinValue6 + pinValue7 + pinValue8;
    let conpin = pinValue9 + pinValue10 + pinValue11 + pinValue12;

    if(pin.length > 3 && conpin.length > 3) {

      if(pin == conpin) {

        console.log("userdetails.userid",userdetails.userid);

         const response = await updatepin(pin,userdetails.userid);

          if(response.STATUSCODE == 0) {
            setpinValue1("");
            setpinValue2("");
            setpinValue3("");
            setpinValue4("");
            setpinValue5("");
            setpinValue6("");
            setpinValue7("");
            setpinValue8("");
            setpinValue9("");
            setpinValue10("");
            setpinValue11("");
            setpinValue12("");

            setVerifyflag(false);
            setNotifyMsg("User pin is updated")
            setDisplayflashmsg(true)
            setpingFlag(false);


          }

       } else {

      }

    } else {
       setpingFlag(true);
    }

  }

  const closeFlashMsg = () => {
    setDisplayflashmsg(false);
  }

  useEffect(() => {
    if(currentpass.length > 0 && newpass.length > 0 && conpass.length > 0) {
        setSubmitflag(true);
    } else {
        setSubmitflag(false);
    }
  },[currentpass,newpass,conpass])

  return (
     <div className="deactivateaccount">

        <div className="setting-empspace"> </div>

        <div className="changepassword-container">

          <div className="Profiletitle">
             <div className="Profiletitlearrow" onClick={() => handleBackarrow()} ><BsArrowLeftShort/></div>
             <div style={{width:10}}> </div>
             <div className="Profiletitletext">back</div>
         </div>

         <div className="changepassword-title">Change Pin</div>
         <div style={{height:30}}> </div>

         <div className="sharewrapper-title">Current Pin</div>

           <div className="sharewrapper-input">
               <input type={showostflag ? "password" : "text"} ref={p1} onKeyDown={(event) => onKeyDownp1(event)}  className="sharewrapper-input-box" value={pinValue1} pattern="[0-9]*" onChange={(event) => handlepinChange1(event)}  maxlength="1" />
                 <div style={{width:10}}></div>
               <input type={showostflag ? "password" : "text"} ref={p2} onKeyDown={(event) => onKeyDownp2(event)} className="sharewrapper-input-box" value={pinValue2} pattern="[0-9]*" onChange={(event) => handlepinChange2(event)}  maxlength="1" />
                 <div style={{width:10}}></div>
               <input type={showostflag ? "password" : "text"} ref={p3} onKeyDown={(event) => onKeyDownp3(event)} className="sharewrapper-input-box" value={pinValue3} pattern="[0-9]*" onChange={(event) => handlepinChange3(event)}  maxlength="1" />
                <div style={{width:10}}></div>
              <input type={showostflag ? "password" : "text"}  ref={p4} onKeyDown={(event) => onKeyDownp4(event)} className="sharewrapper-input-box" value={pinValue4} pattern="[0-9]*" onChange={(event) => handlepinChange4(event)}  maxlength="1" />
           </div>

       <div style={{height:"5px"}} />

        <div className="verifypin" onClick={() => handleVerifypin()}>
          <div className="verifypinbtn">Verify Pin</div>
        </div>

       <div style={{height:"10px"}} />


         <div className= {verifyflag ? "sharewrapper-title" : "sharewrapper-title-disable"}>New Pin</div>

           <div className="sharewrapper-input">
               <input type={showflag ? "password" : "text"} ref={pn1} onKeyDown={(event) => onKeyDownp5(event)} className={verifyflag ? "sharewrapper-input-enablebox" : "sharewrapper-input-disablebox" } value={pinValue5} pattern="[0-9]*" onChange={(event) => handlepinChange5(event)} disabled={verifyflag ? false:true} maxLength="1" />
                 <div style={{width:10}}></div>
               <input type={showflag ? "password" : "text"} ref={pn2} onKeyDown={(event) => onKeyDownp6(event)} className={verifyflag ? "sharewrapper-input-enablebox" : "sharewrapper-input-disablebox" } value={pinValue6} pattern="[0-9]*" onChange={(event) => handlepinChange6(event)} disabled={verifyflag ? false:true}  maxLength="1" />
                 <div style={{width:10}}></div>
               <input type={showflag ? "password" : "text"} ref={pn3} onKeyDown={(event) => onKeyDownp7(event)} className={verifyflag ? "sharewrapper-input-enablebox" : "sharewrapper-input-disablebox" } value={pinValue7} pattern="[0-9]*" onChange={(event) => handlepinChange7(event)} disabled={verifyflag ? false:true}  maxLength="1" />
                <div style={{width:10}}></div>
              <input type={showflag ? "password" : "text"}  ref={pn4} onKeyDown={(event) => onKeyDownp8(event)} className={verifyflag ? "sharewrapper-input-enablebox" : "sharewrapper-input-disablebox" } value={pinValue8} pattern="[0-9]*" onChange={(event) => handlepinChange8(event)}  disabled={verifyflag ? false:true}  maxLength="1" />
           </div>

           <div className={verifyflag ? "sharewrapper-title" : "sharewrapper-title-disable"}>Confirm Pin</div>

          <div className="sharewrapper-input">
            <input type={showflag ? "password" : "text"} ref={pc1} onKeyDown={(event) => onKeyDownp9(event)} onChange={(event) => handlepinChange9(event)} value={pinValue9} pattern="[0-9]*" className={verifyflag ? "sharewrapper-input-enablebox" : "sharewrapper-input-disablebox" } disabled={verifyflag ? false :true}  maxLength="1" />
              <div style={{width:10}}></div>
            <input type={showflag ? "password" : "text"} ref={pc2} onKeyDown={(event) => onKeyDownp10(event)} onChange={(event) => handlepinChange10(event)} value={pinValue10} pattern="[0-9]*" className={verifyflag ? "sharewrapper-input-enablebox" : "sharewrapper-input-disablebox" } disabled={verifyflag ? false :true}  maxLength="1" />
              <div style={{width:10}}></div>
            <input type={showflag ? "password" : "text"} ref={pc3} onKeyDown={(event) => onKeyDownp11(event)} onChange={(event) => handlepinChange11(event)} value={pinValue11} pattern="[0-9]*" className={verifyflag ? "sharewrapper-input-enablebox" : "sharewrapper-input-disablebox" } disabled={verifyflag ? false :true} maxLength="1" />
              <div style={{width:10}}></div>
            <input type={showflag ? "password" : "text"} ref={pc4} onKeyDown={(event) => onKeyDownp12(event)} onChange={(event) => handlepinChange12(event)} value={pinValue12} pattern="[0-9]*" className={verifyflag ? "sharewrapper-input-enablebox" : "sharewrapper-input-disablebox" } disabled={verifyflag ? false :true} maxLength="1" />
          </div>

        <div style={{height:"5px"}} />

          <div className={verifyflag ? "showbtn" : "sharewrapper-title-disable"}>
           {showflag ?
             <div onClick={() => handleShow()}>Show</div> :
             <div onClick={() => handleHide()}>Hide</div>
           }
          </div>

        <div style={{height:"5px"}} />

      {verifyflag ?
          <div onClick={() => handleSave()} className="sharewrapper-title">
            <div className="verifypinbtn">Save Pin</div>
          </div> :
          <div  className="sharewrapper-title-disable">
            <div className="verifypinbtn1">Save Pin</div>
          </div>
        }

     {pinFlag ?
        <div className="pinerrormsg" >
          Please Enter Pin
        </div> : null }


        </div>

       {displayflashmsg ?
        <Successnotify notifyMsg={notifyMsg} closeFlashMsg={closeFlashMsg}  />
      :  null }

     </div>
  );

}

export default Changepin;
