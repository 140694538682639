import React, { useState,useRef,useEffect } from 'react';
import { useSelector,useDispatch } from "react-redux";
import { useNavigate, useLocation, generatePath } from "react-router-dom";

import { HiOutlineSearch } from 'react-icons/hi';

import NavBar from '../../homepage/homepage-topnavbar/Homepage-topnavbar';
import Searchbar from '../../homepage/homepage-maincontainer/Searchbarouter/SearchBarOut';
import Share from '../share/sharelink';

import prioryou from  '../../../assets/logo/prioryou.jpg';
import l1 from '../../../assets/logo/l1.png';

import './Topnavigationpool.css';


function Topnavigationpool() {

  const currentLocation = useLocation();
  const navigate = useNavigate();

  const shareflag = useSelector((state) => state.shareReducer.flag);

  const [searchMenu,setSearchMenu] = useState(false);

  const searchMenuflag = useRef(null);

   const handleSearch = () => {
      setSearchMenu(true);
   }

   const handleSearchMenu = (e) => {

     if (searchMenuflag.current.contains(e.target)) {
       // inside click
       return;
     }

     // outside click
     setSearchMenu(false);

   }

   const handleSearchClose = () => {
      setSearchMenu(false);
   }

   const handleLogin = () => {
     navigate("/signin", { state: { path: currentLocation.pathname } })
   }

   useEffect(() => {

     document.addEventListener("mousedown", handleSearchMenu);

     return () => {
        document.removeEventListener("mousedown", handleSearchMenu, false);
      };

   }, []);

    return (
      <div className="Topnavigationpool-header">
       <nav className="Topnavigation-navbar">

              {/* logo */}
              <a href="/" className="Homepage-topnavbar-navbar-logo font-face-gm">
                     prior
                   <span className="Topnavigation-logoline">
                      <img className="Topnavigation-logo-pool-img" src={l1} />
                   </span>you
              </a>

              {/*Topnavigation menu */ }
          <div className="Topnavigation-cmenu">

             <div> </div>

             { /* Search bar */ }
              <div className="Homepage-topnavbar-navbar-searchbar">

               <div className="Homepage-topnavbar-wrapper-searchbar" onClick={handleSearch}>
                   <div className="Homepage-topnavbar-searchtext">Search . . . .</div>
                   <div className="Homepage-topnavbar-searchline">|</div>
                   <div className="Homepage-topnavbar-searchicon"><HiOutlineSearch/></div>
               </div>

              </div>

              { /* Search Icon for mobile view */ }
            <div className="Homepage-topnavbar-search-btn" onClick={handleSearch}>
              <HiOutlineSearch/>
            </div>

             <div> </div>

                 {/* Right Menu */}
             <div className="Topnavigation-gateway">
                <div></div>
                <div></div>
                <div></div>
                <div className="Topnavigation-joinnow">
                   <a onClick={() => handleLogin()} className="Topnavigation-btn">Login</a>
                </div>

                <div className="Topnavigation-signin">
                  <a href="/register" className="Topnavigation-sign">Get Started</a>
               </div>

             </div>

          </div>

       </nav>

       { /* Popup searchbar */ }
       <div ref={searchMenuflag} className="Homepage-topnavbar-searchbar">
         { searchMenu && <Searchbar handleSearchClose={handleSearchClose} /> }
       </div>

      {shareflag ?
       <Share /> : null }

    </div>

    )
}

export default Topnavigationpool;
