var CryptoJS = require("crypto-js");

export  function setCookie(name,value,maxage,maxvalue) {
  let data = value;
  let ciphertext = CryptoJS.AES.encrypt(data,'hU0tF5nE9sN6rL7lM5l').toString();
  
  let cookvl = `${name}` + "=" + `${ciphertext}` + `${maxage}` + "=" + `${maxvalue}` + "secure";
  document.cookie = cookvl;
}

export  function getCookie() {
    let uaid = document.cookie.replace(/(?:(?:^|.*;\s*)uaid\s*\=\s*([^;]*).*$)|^.*$/, "$1");
    let bytes = CryptoJS.AES.decrypt(uaid.toString(),'hU0tF5nE9sN6rL7lM5l');
    let decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return(decryptedData);
}

export  function delCookie(name) {
     document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}
