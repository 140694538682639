import * as actionTypes from "../actions/actionTypes";

const initialState = {
  leavesquad:{
    leavesquadflag:false
  },
  removesquad:{
    removesquadflag:false
  },
  reportsquad:{
    reportsquadflag:false
  }
};

const squadReducer = (state = initialState, action) => {

  switch (action.type) {

    case actionTypes.LEAVE_SQUAD:

      return {
        ...state,
        leavesquad:action.payload
      };

      case actionTypes.REMOVE_SQUAD:

        return {
          ...state,
          removesquad:action.payload
        };

      case actionTypes.REPORT_SQUAD:

        return {
          ...state,
          reportsquad:action.payload
        };

    default:
      return state;
  }
};

export default squadReducer;
