import React, { useState,useEffect,useContext,useCallback,useRef } from "react";
import { useSelector,useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Spinner from '../../../../spinner/classic-spiner/Classicspiner';

import './Requestbox.css';

import defaultProfile from '../../../../assets/default/user.png';

import { reqDel,refreshReq,confirmReq } from '../../../../redux/actions/actionCreators';
import { getUserRequest,addEnrolledUser } from '../../../../redux/thunk/topic/fetchdata';
import {  SocketContext  } from "../../../../context/SocketContext";

import * as actionCreators from '../../../../redux/actions/actionCreators';
import Successnotify from '../../../../notify/Successnotify';
import Errornotify from '../../../../notify/Errornotify';


function Requestbox() {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const observer = useRef();

  const [request,setRequest] = useState([]);
  const [notifyFlag,setNotifyflag] = useState(false);
  const [notifymsg,setNotifymsg] = useState("");
  const [errorflag,setErrorflag] = useState("");

  const [page,setPage] = useState(1);
  const [hasMore,setHasMore] = useState(true);
  const [nextpage,setNextpage] = useState("");
  const [loading,setLoading] = useState(false);

  const { socket } = useContext(SocketContext);
  const userdetails = useSelector((state) => state.userReducer.userdetails);
  const topicId = useSelector((state) => state.reqnavReducer.topicId);
  const refreshFlag = useSelector((state) => state.refreqReducer.refreshFlag);
  const errorInc = useSelector((state) => state.refreqReducer.error);
  const reqmessage = useSelector((state) => state.refreqReducer.reqmessage);
  const requestDelete = useSelector((state) => state.popupReducer.requestDelete);
  const requestId = useSelector((state) => state.requestdeleteReduce.requestid);

  const lastfollowerElementRef = useCallback(node => {

     if (observer.current)  {
        observer.current.disconnect()
     }

     observer.current = new IntersectionObserver(entries => {
         if (entries[0].isIntersecting && hasMore) {
             setPage(nextpage);
         }
     })

     if(node) {
         observer.current.observe(node)
      }
})



   useEffect(async () => {


     if(page) {

     let lim = 5;

     let userObj = {
         userid:userdetails.userid,
         page:page,
         limit:lim
      }

      console.log("Inside requestbox----");

      console.log("userObj",userObj);

       setLoading(true);

     const response = await getUserRequest(userObj);

     console.log("response",response);

    if(response.STATUSCODE == 0) {

       if(response.NEXT.next == undefined) {
           setRequest([...request,...response.PAYLOAD]);
           setHasMore(false);
           setLoading(false);
       } else {
           setRequest([...request,...response.PAYLOAD]);
           setNextpage(response.NEXT.next.page)
       }

     } else {
         setLoading(false);
     }


   }

   },[page])

  useEffect(() => {
      if(requestId != null && request.length > 0) {
          let temparray = [...request]
          let index = temparray.findIndex(item => item.requestid == requestId);
          temparray.splice(index, 1);
          setRequest(temparray);
          dispatch(actionCreators.addRequestdeleteId(null));
      }
  },[requestId,request])

  const closeFlashMsg = () => {
    setNotifyflag(false);
  }

  const handleTopic = (topicid) => {
    navigate(`pooldetails/${topicid}`);
  }

  const handleProfile = (userid) => {
     navigate(`/homepage/account/${userid}`);
   }

  const handleConfirm = async (requestid,topicid,userid,index,fromuserid,commentTxt,topicrequestid) => {

     let notify = {
        notifyto:userid,
        notifyfrom:fromuserid,
        commentTxt:commentTxt,
        notify_topicid:topicid,
        notify_type:"Accpeted",
        notify_comment_id:"12",
        notify_reply_id:"",
        topicrequestid:topicrequestid,
        requestid:requestid,
        reqstatus:true
     }

        dispatch(confirmReq(notify));

  }

  const handleDelete = async (requestid,topicid,userid,index,fromuserid,commentTxt,topicrequestid) => {

     let notify = {
       notifyto:userid,
       notifyfrom:fromuserid,
       commentTxt:commentTxt,
       notify_topicid:topicid,
       notify_type:"Deleted",
       notify_comment_id:"12",
       notify_reply_id:"",
       topicrequestid:topicrequestid,
       requestid:requestid,
       reqstatus:true
    }

      dispatch(reqDel(notify));

  }

   useEffect(() => {
     console.log("Inside requestbox");
   },[])

 return (

 <div>

  {request && request?.map((element,index) =>  {

    return (
     <div key={element.topicrequestid} ref={lastfollowerElementRef} className="requestbox-wrapper">

      <div tabIndex={index} id={element.topicrequestid} className="requestbox-container">

       <div className="requestbox-profilepanel">

          <div className="requestbox-profileimg"
             onClick={() => handleProfile(element.userDetails.PAYLOAD.userid)}
             style={{
                backgroundImage:element.userDetails.PAYLOAD.profile_image ? `url("${element.userDetails.PAYLOAD.profile_image}")` : `url("${defaultProfile}")`,
                backgroundPosition:'center',
                backgroundSize:'cover',
                backgroundRepeat:'no-repeat',
                maxWidth:'100%',
                maxHeight:'100%'
             }}></div>

             <div className="requestbox-name"
               onClick={() => handleProfile(element.userDetails.PAYLOAD.userid)}>
                {element.userDetails.PAYLOAD.username}
             </div>

             <div className="requestbox-topictitle" onClick={() => handleTopic(element.topicid)}>
               {element.topic}
             </div>

       </div>


      <div className="requestbox-buttonpanel">
             <div>
                <button className="requestboxdelbtn"
                  onClick={() => handleDelete(element.requestid,element.topicid,element.userDetails.PAYLOAD.userid,index,userdetails.userid,element.topic,element.topicrequestid)}>
                   Delete
                 </button>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                 <button className="requestboxconbtn"
                   onClick={() => handleConfirm(element.requestid,element.topicid,element.userDetails.PAYLOAD.userid,index,userdetails.userid,element.topic,element.topicrequestid)}>
                   Confirm
                 </button>
             </div>
       </div>

    </div>
      </div>
    )

   })}

    {loading ? <Spinner /> : null }

     <div className="followerhtspace">
     </div>

    {notifyFlag ?
      <div className="requestnotifymsg">
        {errorflag ?
          <Errornotify notifyMsg={notifymsg} closeFlashMsg={closeFlashMsg} /> :
          <Successnotify notifyMsg={notifymsg} closeFlashMsg={closeFlashMsg} />
          }
       </div>
     : null }

 </div>

  )
}

export default Requestbox;
