import React from 'react';
import { useNavigate } from "react-router-dom";
import { useEffect,useState } from 'react';
import { BsArrowLeftShort } from 'react-icons/bs';
import { useSelector } from "react-redux";

import l1 from '../../assets/logo/l1.png';
import lff from '../../assets/dashboard/lff.png';
import prioryou from '../../assets/logo/prioryou.jpg';

import './Deactivatedpage.css';

function Deactivatedpage() {

  const navigate = useNavigate();
  const flag = useSelector((state) => state.deactReducer.deaccflag);

  const handleBack = () => {
      navigate("/");
  }

  useEffect(() => {
    if(flag == false) {
      navigate("/");
    }
  },[flag])

  return (
    <div className="Howitwork-wrapper">

       <div className="Deactivatedpage-header">

        {/*
         <div className="Detailstitlearrow" onClick={() => handleBack()} >
           <BsArrowLeftShort/>
         </div>
         */}

          <a href="/" className="Howitwork-navbar-logo">
            <div className="font-face-gm Howitwork-logo-text">prior</div>
            <span className="Howitwork-logoline"><img src={l1} /></span>
            <div className="font-face-gm Howitwork-logo-text">you</div>
          </a>


        <div> </div>

      </div>



         <div style={{height:20}}>  </div>

          <div className="Howitwork-wrapper-one">
            <img className="Deactivatedpage-wrapper-imgone" src={lff} />
         </div>


    </div>
  );
}

export default Deactivatedpage;
