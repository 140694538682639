import React from 'react';
import * as actionCreators from "../../../redux/actions/actionCreators";
import { useRef,useEffect,useState,useCallback,useContext } from 'react';
import { useDispatch,useSelector } from "react-redux";
import { Routes,Route,useNavigate,NavLink,Link  } from "react-router-dom";
import { getPeopleSearch,getPeopleSearchTopic,updateUserRequest } from '../../../redux/thunk/search/fetchdata';
import Requestpeople from './Requestpeople';
import Requestfriend from './Requestfriend';
import Poolrequestsender from './Pooldetail/Poolrequestsender';
import {  SocketContext  } from "../../../context/SocketContext";

import './Request.css';
import defaultprofile from '../../../assets/default/user.png';
import Doublebounce from '../../../spinner/double-bounce/Doublebounce';

function Requestpeoplelist(props) {

    const observer = useRef();
    const { socket } = useContext(SocketContext);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [page,setPage] = useState(1);
    const [temp,setTemp] = useState();

    const [touserid,setTouserid] = useState(null);
    const [loading,setLoading] = useState(false);

    const [hasMore,setHasMore] = useState(false);
    const [showrequest,setShowrequest] = useState(false);
    const userdetails = useSelector((state) => state.userReducer.userdetails);

    const topicDetails = useSelector((state) => state.poolDetailsReducer.topicDetails);
    const peoplesearchTerm = useSelector((state) => state.searchpeopleReducer.peoplesearchTerm);
    const peoplePage = useSelector((state) => state.searchpeopleReducer.peoplePage);
    const peoplehasMore = useSelector((state) => state.searchpeopleReducer.peoplehasMore);
    const peopleTemp = useSelector((state) => state.searchpeopleReducer.peopleTemp);
    const peoplePool = useSelector((state) => state.searchpeopleReducer.peoplePool);

    
    const handleAccount = (userid) => {
      navigate(`/homepage/account/${userid}`)
    }


    const handleCancelRequst = async(requestId,userid) => {

       const result = await updateUserRequest(requestId);

       if(result.STATUSCODE == 0) {
      
           dispatch(actionCreators.updateCancelStatus(userid));
    
          if(socket) {
            socket?.emit("delNoti",topicDetails?.status.requestid);
          }

       }


    }


  const handleSendRequst = (touserid) => {
      setTouserid(touserid);
      setShowrequest(true);
   }

  const hideRequest = () => {
     setShowrequest(false)
   }

  const lastFriendElementRef = useCallback(node => {

    if (observer.current) observer.current.disconnect()
       observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasMore) {
          setPage(temp);
       }
    })

      if(node) {
          observer.current.observe(node)
       }
  })



  useEffect(async () => {

    let lim = 5;
    let limit;

    limit = page * lim;

    if(peoplesearchTerm.length > 0) {


    if(limit > peoplePool.length && peoplehasMore == true) {

     const result = await getPeopleSearchTopic(page,lim,peoplesearchTerm,topicDetails.topicId);

    if(result) {
      let ft = result.PAYLOAD[0];

      if(ft.next == undefined) {
         let payload = result.PAYLOAD.slice(1);
         dispatch(actionCreators.addPeoplePool(payload));
        // dispatch(actionCreators.addPeoplePoolHasmore(false));
         setHasMore(false);
      } else {

        let payload = result.PAYLOAD.slice(1);

         if(payload.length > 0) {
            dispatch(actionCreators.addPeoplePool(payload));
        //    dispatch(actionCreators.addPeoplePoolTemp(ft.next.page));
          //  dispatch(actionCreators.addPeoplePoolHasmore(true));

          setTemp(ft.next.page);
            setHasMore(true);
         } else {
          //  dispatch(actionCreators.addPeoplePoolHasmore(false));
          setHasMore(false);
         }

      }
     }
  }
    }


  },[peoplesearchTerm,page,temp]);





      useEffect(() => {
         setPage(1);
         setTemp()
      },[peoplesearchTerm])

  return (
     <div className="Request-container">
       <div>
         {peoplePool && peoplePool.map((element,index) => {

         return (
            <div key={index}>
              {element.userid != userdetails.userid ?
                <div className="friend-proflie-wrapper" ref={lastFriendElementRef} >

             <div className="Request-detail">

              <div className="Request-detail-wrapper" onClick={() => handleAccount(element.userid)}>
                 <div className="Team-profile-circle" >
                <div className="account-profile"
                style={{
                   backgroundImage:element && element.profile_image ? `url("${element.profile_image}")` : `url("${defaultprofile}")`,
                   backgroundPosition: 'center',
                   backgroundSize: 'cover',
                   backgroundRepeat: 'no-repeat',
                   maxWidth:'100%',
                   maxHeight:'100%',
                 }}
               />
                 </div>
                  <div className="Request-text-name-wrapper">
                   <div className="Request-text-name">{element.username}</div>
                 </div>
              </div>

               <div style={{width:30}}> </div>

                {element.status.status ?
                 <div onClick={() => handleCancelRequst(element.status.requestId,element.userid)} className="Request-request-btn">
                   Cancel Request
                 </div> :
                 <div onClick={() => handleSendRequst(element.userid)} className="Request-request-btn">
                   Send Request
                 </div>
                }

              </div>

                </div>
                 :
               null
             }


           </div>
         )
        })}

         {hasMore ?
          <div className="browselist-spinner">
             <Doublebounce />
          </div> : null }

      </div>

      {showrequest ?
        <Poolrequestsender touserid={touserid}  hideRequest={hideRequest} />
      : null }

    </div>
  );
}

export default Requestpeoplelist;
