import './Username.css';
import React, { useState,useEffect } from 'react';
import { Routes,Route,useNavigate,NavLink } from "react-router-dom";
import { BsArrowLeft } from 'react-icons/bs';
import { BsArrowLeftShort } from 'react-icons/bs';
import { useSelector,useDispatch } from "react-redux";
import { getSecruityCode } from '../../../../redux/thunk/security/fetchdata';
import { deactivateAccount } from '../../../../redux/thunk/setting/fetchdata';
import { checkUserPassword } from '../../../../redux/thunk/setting/fetchdata';


function Secuirtycode() {

  const [secruitycode,setSecruitycode] = useState(null);

  const [currentpass,setCurrentpass] = useState("");
  const [errMsg,setErrmsg] = useState("");
  const [passFlag,setPassflag] = useState(false);

  const userdetails = useSelector((state) => state.userReducer.userdetails);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleBackarrow = () => {
     navigate(-1)
  }

  const handlePassword = (event) => {
     setCurrentpass(event.target.value);
  }

  const deactivateusraccount = async () => {

    function isBlank(str) {
     return (!str || /^\s*$/.test(str));
   }

   const result = isBlank(currentpass);

  if(!result) {

    let obj = {
      userid:userdetails.userid,
      password:currentpass
    }

    const response = await checkUserPassword(obj);

      if(response.STATUSCODE == 0) {

        if(userdetails.userid) {
          const response = await getSecruityCode(userdetails.userid);
          setCurrentpass("");
          setSecruitycode(response.PAYLOAD);
          setPassflag(true);
        }


      } else {
        setErrmsg("Invalid Password");
        setCurrentpass("");
      }

  }

  }



  return (
     <div className="deactivateaccount">
          <h1>Secruity Code</h1>


          <div className="setting-empspace"> </div>

           <div className="username-container">

              <div className="Profiletitle">
                 <div className="Profiletitlearrow" onClick={handleBackarrow} ><BsArrowLeftShort/></div>
                 <div style={{width:10}}> </div>
                 <div className="Profiletitletext">back</div>
             </div>

             <div className="changepassword-title">Secuirty Code</div>

             <div style={{height:20}}> </div>

             <div className="deactivateaccount-text">
              <input type="password" className="depassword-btn" placeholder="Password" onChange={(event) => handlePassword(event)} value={currentpass} />
             </div>

             <div className="deactivateaccount-text">
               <button className="deactivate-btn" onClick={() => deactivateusraccount()}>
                 Enter Password
               </button>
             </div>

                <div style={{height:20}}> </div>

          {passFlag ?
             <div>
               <div className="secruitycode">{secruitycode}</div>
            </div> :
              null
           }




           </div>


     </div>
  );

}

export default Secuirtycode;
