import * as actionTypes from "../actions/actionTypes";

const initialState = {
   peoplePage:1,
   peoplehasMore:true,
   peoplesearchTerm:"",
   peopleTemp:null,
   peoplePool:[]
};

const searchpeopleReducer = (state = initialState, action) => {

  switch (action.type) {

    case actionTypes.ADD_PEOPLE_TERM:

      return {
        ...state,
        peoplePage:1,
        peoplehasMore:true,
        peoplesearchTerm:action.payload,
        peopleTemp:null,
        peoplePool:[]
      }

    case actionTypes.ADD_PEOPLE_POOL:

       return {
         ...state,
         peoplePool:[...state.peoplePool,...action.payload]
       }

    case actionTypes.REST_PEOPLE_POOL:

          return {
            ...state,
            peoplePool:[]
          }

    case actionTypes.ADD_SEARCH_PEOPLE_TERM:

      return {
        ...state,
        peoplesearchTerm:action.payload
      };

    case actionTypes.ADD_SEARCH_PEOPLE_HASMORE:

      return {
        ...state,
        peoplehasMore:action.payload
      };

    case actionTypes.ADD_SEARCH_PEOPLE_TEMP:

      return {
        ...state,
        peopleTemp:action.payload
      };

    case actionTypes.ADD_SEARCH_PEOPLE_PAGE:

        return {
          ...state,
          peoplePage:action.payload
        };

    case actionTypes.UPDATE_USER_STATUS:

         let temppeople = [...state.peoplePool];

         let index = temppeople.findIndex(item => item.userid == action.payload.userid);

           let status1 = {
             requestId:action.payload.requestid,
             status:true
            } 

        temppeople[index].status = status1;

            return {
              ...state,
              peoplePool:temppeople
           }



     case actionTypes.UPDATE_USER_CANCEL_STATUS:

          let temppeople1 = [...state.peoplePool];

          let index1 = temppeople1.findIndex(item => item.userid == action.payload);

           temppeople1[index1].status.status = false;

            return {
              ...state,
              peoplePool:temppeople1
            }


    default:
      return state;
  }
};

export default searchpeopleReducer;
