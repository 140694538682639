import * as actionTypes from "../actions/actionTypes";

const initialState = {
   flag:false
};

const takeawayReducer = (state = initialState, action) => {

  switch (action.type) {

    case actionTypes.SHOW_TAKEAWAY:

      return {
        flag:action.payload
      };

    default:
      return state;
  }
};

export default takeawayReducer;
