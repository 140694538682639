import './Email.css';
import React, { useState,useEffect } from 'react';
import { useSelector,useDispatch } from "react-redux";
import { Routes,Route,useNavigate,NavLink } from "react-router-dom";
import { BsArrowLeft } from 'react-icons/bs';
import { GoVerified } from "react-icons/go";
import { MdOutlineVerified } from "react-icons/md";
import { BsArrowLeftShort } from 'react-icons/bs';
import * as actionCreators from "../../../../redux/actions/actionCreators";
import { checkEmail,sendUserOtp,sendUserEmailOtp } from '../../../../redux/thunk/gateway/fetchdata';
import { sendEmailOtp } from '../../../../redux/thunk/setting/fetchdata';

import { emailvalidation } from '../../../../utils/Validation';
import { v4 as uuidV4 } from "uuid";
import Classicspiner from '../../../../spinner/classic-spiner/Classicspiner';
import Successnotify from '../../../../notify/Successnotify';

function Email() {

  const userdetails = useSelector((state) => state.userReducer.userdetails);
  const updateflag =  useSelector((state) => state.otpReducer.updateflag);
  const updatedemail = useSelector((state) => state.otpReducer.updatedemail);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [emailid,setEmailid] = useState("");
  const [submitflag,setSubmitflag] = useState(false);
  const [loading,setLoading] = useState(false);
  const [userEmailErrmsg,setuserEmailErrmsg] = useState("");
  const [displayflashmsg,setDisplayflashmsg] = useState(false);

  const handleBackarrow = () => {
     navigate(-1)
  }

  const chkUseremail = async () => {

    if(emailid.length > 0) {

       if(emailvalidation(emailid)) {

          const response = await checkEmail(emailid);

          if(response.STATUSCODE == 0) {
              if(response.PAYLOAD == 1) {
                  let str = "* Email already exist";
                  setuserEmailErrmsg(str);
                  setSubmitflag(false);
              } else {
                  setuserEmailErrmsg("");
                  setSubmitflag(true);
              }
          }

        } else {
           setuserEmailErrmsg("* Please enter valid email address.");
           setSubmitflag(false);
       }

     } else {
         setSubmitflag(false);
     }

  }

  const handlechange = (event) => {
     setuserEmailErrmsg("");
     setSubmitflag(false);
     let value = event.target.value.trim();
     setEmailid(value);
  }

  const handleVerifyEmail = async (event) => {

    setLoading(true);

    let timestamp = Date.now();

    let verifyId = uuidV4() + "-" + timestamp;

    let userObj = {
        verifyid:verifyId,
        userName:userdetails.username,
        email:userdetails.useremail,
        mobile:""
     }

     const responseOtpCode = await sendUserOtp(userObj);

      setLoading(false);

     let submitobj = {
          currentemail:userdetails.useremail,
          emailId:userdetails.useremail,
          otpFlag:false,
          verifyid:verifyId,
          updateflag:true
       }


     dispatch(actionCreators.setOtpFlag(submitobj));

}

  const handleSubmit = async (event) => {

     if(submitflag == true) {

       let timestamp = Date.now();

       let verifyId = uuidV4() + "-" + timestamp;

       let userObj = {
           verifyid:verifyId,
           userName:userdetails.username,
           email:emailid,
           mobile:""
        }

        setLoading(true);

        const responseData = await sendUserEmailOtp(userObj);

        if(responseData.STATUSCODE == 0) {

          let submitobj = {
               currentemail:userdetails.useremail,
               emailId:emailid,
               otpFlag:false,
               verifyid:verifyId,
               updateflag:true
            }

           dispatch(actionCreators.setOtpFlag(submitobj));

            setEmailid("");
            setSubmitflag(false);
            setLoading(false);
        }

     }

  }

  const closeFlashMsg = () => {
    setDisplayflashmsg(false);
  }

{/*
  useEffect(() => {
    if(updateflag === true) {

       setDisplayflashmsg(true);

       let submitobj = {
            currentemail:userdetails.useremail,
            emailId:"",
            otpFlag:false,
            verifyid:"",
            updateflag:false
         }

       dispatch(actionCreators.setOtpFlag(submitobj));

       const updateStatus = () => {
         setDisplayflashmsg(false);
       };

       setTimeout(function(){
         updateStatus();
       }, 5000);

    }
  },[updateflag])
*/}


  return (
     <div className="deactivateaccount">

        <div className="setting-empspace"> </div>

       <div className="changepassword-container">

          <div className="Profiletitle">
             <div className="Profiletitlearrow" onClick={handleBackarrow} ><BsArrowLeftShort/></div>
             <div style={{width:10}}> </div>
             <div className="Profiletitletext">back</div>
         </div>

         <div className="changepassword-title">Email Verfication</div>

         <div className="changepassword-body">

           <div className="changepassword-body-inner">

           <div style={{height:30}}> </div>

            {userdetails && userdetails.useremail ?
              <div className="email-currentemail-wrapper">
               <div className="email-currentemail">{userdetails.useremail}&nbsp;&nbsp;&nbsp;&nbsp; {userdetails.isEmailVerified  ? <GoVerified size={24} color={'cornflowerblue'}/> : null }</div>
               <div className="email-currentemail"><small style={{color:'red'}}>* Note : to get notification you need to verify email address</small></div>
              </div>
              : <div className="email-currentemail">Add Email Id</div> }

              <div style={{height:20}}> </div>

            {userdetails.isEmailVerified ?
               null :
               <button className="changepassword-savebtn"  onClick={() => handleVerifyEmail()}>
                  Verify Now
               </button>
             }


           <div style={{height:40}}> </div>
           <div className="changepassword-title">Update Email Id</div>

           <div style={{height:20}}> </div>
           <input className="changepassword-inp" hidefocus="true" onBlur={chkUseremail} type="text" value={emailid} onChange={(event) => handlechange(event)} placeholder="Email Id" />

           <div className="changepassword-error">{userEmailErrmsg}</div>

           {submitflag ?
             <button className="changepassword-savebtn"  onClick={() => handleSubmit()}>
               Submit
            </button> :
            <button className="changepassword-savebtn">
              Submit
            </button> }

           {loading ?
            <div className="changepassword-spinner">
              <Classicspiner />
            </div> : null }

            {displayflashmsg ?
              <Successnotify notifyMsg="User email Id updated" closeFlashMsg={closeFlashMsg} />
              : null }

           </div>
         </div>


       </div>
     </div>
  );

}

export default Email;
