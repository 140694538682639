import Compress from "react-image-file-resizer";

export function handleCompress(file,width,height,quality) {

  return new Promise((resolve, reject) => {

    Compress.imageFileResizer(
       file,      // the file from input
       width,     // width
       height,    // height
       "JPEG",    // compress format WEBP, JPEG, PNG
       quality,   // quality
       0,         // rotation
       (uri) => {
         resolve(uri);
       },
        "blob"   // blob or base64 default base64
     );

  })

}



export function handleCompress1(file,width,height,quality) {

  return new Promise((resolve, reject) => {

    Compress.imageFileResizer(
       file,      // the file from input
       width,     // width
       height,    // height
       "JPEG",    // compress format WEBP, JPEG, PNG
       quality,   // quality
       0,         // rotation
       (uri) => {
         resolve(uri);
       },
        "base64"   // blob or base64 default base64
     );

  })

}
