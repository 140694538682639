import React, { useRef,useEffect,useContext,useState } from 'react';
import { useSelector,useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {  SocketContext  } from "../../../../context/SocketContext";
import { topicsuperuser } from '../../../../redux/thunk/active/fetchdata';
import * as actionCreators from "../../../../redux/actions/actionCreators";
import call from '../../../../assets/ringtone/call.mp3';
import callend from '../../../../assets/videocall/callend.png';
import callactive from '../../../../assets/videocall/callactive.png';

import './Requestcall.css';

function Requestcall(props) {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [me,setMe] = useState();
  const { socket } = useContext(SocketContext);

  const requestflag = useRef(null);
  const audioRef = useRef();

  const handleRequest = (e) => {

    if (requestflag.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click

  }

  const cancelCall = () => {
     props.handleRequestCall(props.userid);
  }

  const joinRoom = async(userid,topicid) => {

    socket?.emit("rmv-busyuser",props.userid);

    let data = {
      userid:userid,
      topicid:topicid
    }

    {/* socket?.emit("join-room",data); */}
    props.handleRequestCall(props.userid);

    let value = false;

    dispatch(actionCreators.tstTopic(topicid));
    dispatch(actionCreators.reqgrpCall(true));
    dispatch(actionCreators.startCall(value));


  //  navigate(`active/${topicid}/Startcall`);
  }

  useEffect(() => {

    const timer = setTimeout(() => {
     props.handleRequestCall(props.userid);
   }, 90000);
   return () => clearTimeout(timer);

    document.addEventListener("mousedown", handleRequest);
    return () => {
       document.removeEventListener("mousedown", handleRequest, false);
     };

  }, []);

  useEffect(() => {
    if(socket) {
      let obj = {
        fromid:props.calldetails.fromid,
        userid:props.userid,
        socketid:props.calldetails.socketid
      }
    //  socket?.emit("add-busyuser",obj);
    }
  },[socket])

  return (
     <div className="Requestcallwrapper">

      <div className="Requestcallouter">
        <div ref={requestflag} className="Requestcontainer">

          <div className="Requestcallheader">
           <div className="Requestcalltext">Group Call : {props.calldetails.topic}</div>
           <div className="Requestcalltext">From : {props.calldetails.from}</div>
          </div>

          <div>&nbsp;</div>

          <div className="Requestcalldialer">

            <div className="Requestcallbtn" onClick={cancelCall}>
              <img className="Requestcallimg" src={callend} />
            </div>
            <div className="Reqemptyspace">  </div>
            <div className="Requestcallbtn" onClick={() => joinRoom(props.userid,props.calldetails.topicid)}>
               <img className="Requestcallimg"  src={callactive} />
            </div>

          </div>

           <audio ref={audioRef}  src={call} loop autoPlay />

        </div>
      </div>

     </div>
  )
}

export default Requestcall;
