import './Accountdetails.css';
import { Routes,Route,useNavigate,NavLink } from "react-router-dom";
import { BsArrowLeft } from 'react-icons/bs';
import { BsArrowLeftShort } from 'react-icons/bs';

function Accountdetails() {

  const navigate = useNavigate();

  const handleBackarrow = () => {
     navigate(-1)
  }

  return (
     <div className="accountdetailswrapper">
       <div>

         <div className="Profiletitle">
           <div className="Profiletitlearrow" onClick={handleBackarrow} ><BsArrowLeftShort/></div>
           <div style={{width:10}}> </div>
           <div className="Profiletitletext">back</div>
         </div>


       </div>
     </div>
  );

}

export default Accountdetails;
