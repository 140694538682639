import React from 'react';
import { useEffect,useState } from 'react';
import { useDispatch,useSelector } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { BsArrowLeftShort } from 'react-icons/bs';

import { getTopicDetails } from '../../../../redux/thunk/topic/fetchdata';
import * as actionCreators from "../../../../redux/actions/actionCreators";

import Pbimg from '../../homepage-maincontainer/Poolrequest/Pbimg';
import defaultProfile from '../../../../assets/default/user.png';

import Pooldetaildesc from './Poolnotifydetaildesc';
import Poolrequest from '../Pooldetail/Poolrequest';
import Poolreqcancel from '../Pooldetail/Poolreqcancel';
import Buttonspiner from '../../../../spinner/button-spiner/Buttonspiner';
import Classicspiner from '../../../../spinner/classic-spiner/Classicspiner';

import './Poolnotify.css';

function Poolnotify(props) {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currentLocation = useLocation();
  const params = useParams();

  const [showrequest,setShowrequest] = useState(false);
  const [cancelrequest,setCancelrequest] = useState(false);
  const [startbtn,setStartbtn] = useState(false);
  const [loading,setLoading] = useState(false);
  const [loadingpage,setLoadingpage] = useState(true);

  const authUser = useSelector((state) => state.authReducer.authflag);
  const userdetails = useSelector((state) => state.userReducer.userdetails);
  const topicDetails = useSelector((state) => state.poolDetailsReducer.topicDetails);
  const notify = useSelector((state) => state.notifyReducer.notify_id);

  const handleBack = () => {
    navigate(-1);
  }

  const reloadRequest = async () => {

    const response = await getTopicDetails(params.picId);

     if(response.STATUSCODE == 0) {
        dispatch(actionCreators.setTopicDetails(response.PAYLOAD));
        setLoading(false);
     }
  }

  const handleLoading = () => {
    setLoading(true)
  }

  const handleStartbtn = () => {
    setStartbtn(true);
  }

  const hideRequest = () => {
    setShowrequest(false)
  }

  const hideCancel = () => {
    setCancelrequest(false)
  }

  const handleCancel = () => {
     setCancelrequest(true)
  }

  const showTimeline = () => {

    if(authUser) {
        navigate("/homepage/pool/distimeline");
     } else {
       navigate("/signin", { state: { path: currentLocation.pathname}})
    }

  }

  const showProfile = (userid) => {

     if(userdetails.userid) {
         navigate(`/homepage/account/${userid}`)
      } else {
         navigate(`/account/${userid}`)
      }

  }

  const handleStart = () => {

     if(authUser) {
         setShowrequest(true);
       } else {
         navigate("/signin", { state: { path: currentLocation.pathname}})
      }

   }

  useEffect (async () => {

    window.scrollTo(0, 0);

     dispatch(actionCreators.resetComment());
     dispatch(actionCreators.setTopicDetails(null));

    if(notify.notify_comment_id) {
       if(params.picId) {

    const response = await getTopicDetails(params.picId);

     if(response.STATUSCODE == 0) {
        dispatch(actionCreators.setTopicDetails(response.PAYLOAD));
        setLoadingpage(false);
     } else {
        navigate("/homepage/nomatch");
     }

   }
      } else {
       navigate("/")
    }

 }, [params.picId,notify])


  return (
      <div className="poolnotifycontainer">


     {loadingpage ?
        <div className="poolnotify-spinner">
          <Classicspiner/>
         </div>
       :
        <div>

         <div className="poolnotifywrapper">

            <div className="poolnotify-arrow">

               <div className="poolnotify-titlearrow" onClick={() => handleBack()}><BsArrowLeftShort/></div>
            </div>

            <div className="poolnotify-title-panel">
              {topicDetails?.topic_image ?
               <img src={topicDetails.topic_image} className="poolnotify-img" />
               : <Pbimg /> }
            </div>

            <div>&nbsp;</div>

       {topicDetails?.noofpartner ==  topicDetails?.enrolledusers.length ?

          <div>Member is Full</div> :

          <>
             {topicDetails?.userid !== userdetails.userid ?

          <>
           {topicDetails?.status.status == "approved" ?
               <p>Approved</p>
             :
            <>
             {topicDetails?.status.status ?
            <div className="poolnotify-btn" onClick={() => handleCancel()}>
                 {loading ? <Buttonspiner/> :
                  <div>Cancel</div> }
            </div> :

             <div className="poolnotify-btn" onClick={() => handleStart()}>
                 {loading ? <Buttonspiner/> :
                  <div>
                    <div>Start now</div>
                  </div> }
             </div> }

            </>

            }
           </>

          : null  }
          </>

       }


             <div>&nbsp;</div>
             <div className="poolnotify-member-title">
                Joint Member
              </div>


             <div className="poolnotify-member-wrapper">

               {topicDetails?.enrolledusers.map((item,index) => {

                 return (
                  <div key={index}>
                    <div className="poolnotify-member-team" onClick={() => showProfile(item.enrolledusersdetails.PAYLOAD.userid,userdetails.userid)}
                      style={{
                         backgroundImage:item.enrolledusersdetails.PAYLOAD.profile_image ? `url("${item.enrolledusersdetails.PAYLOAD.profile_image}")` : `url("${defaultProfile}")`,
                         backgroundPosition:'center',
                         backgroundSize:'cover',
                         backgroundRepeat:'no-repeat',
                         maxWidth:'100%',
                         maxHeight:'100%'
                      }}
                    >
                      </div>
                   </div>
                   )
                 })}

             </div>

             <div className="poolnotify-membercount">
               {topicDetails?.enrolledusers.length} / {topicDetails?.noofpartner}
             </div>

             <div>&nbsp;</div>

             <div className="prequest-timeline" onClick={() => showTimeline()}>
                TimeLine
              </div>

         </div>

         <div className="poolnotifywrapperbuffer"> </div>

           {/* Pool detail */}


             <div className="poolnotify-desc">
               <Pooldetaildesc />

          </div>

       {showrequest ?

         <Poolrequest hideRequest={hideRequest}
           handleStartbtn={handleStartbtn}
           reloadRequest={reloadRequest}
           handleLoading={handleLoading} />
           : null }

       {cancelrequest ?

         <Poolreqcancel
           hideCancel={hideCancel}
           handleStartbtn={handleStartbtn}
           reloadRequest={reloadRequest}
           handleLoading={handleLoading}
           />

         : null }

        </div> }

      </div>
   )
}

export default Poolnotify;
