import React from 'react';
import { useState,useEffect,useRef } from 'react';
import { useDispatch } from "react-redux";
import { MdOutlineCancel } from 'react-icons/md';
import { v4 as uuidV4 } from "uuid";
import * as actionCreators from "../../../redux/actions/actionCreators";

function Recoverydis(props) {


  const hleDownload = () => {
     props.handleprint();
  }


  useEffect(() => {

  },[])

 return (
   <div>
     {props.recoverycode}
   </div>
 )

}

export default Recoverydis;
