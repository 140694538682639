import './Deactivateaccount.css';
import React, { useState,useEffect,useContext } from 'react';
import { Routes,Route,useNavigate,NavLink } from "react-router-dom";
import { useSelector,useDispatch } from "react-redux";
import { BsArrowLeft } from 'react-icons/bs';
import { BsArrowLeftShort } from 'react-icons/bs';
import { deactivateAccount } from '../../../../redux/thunk/setting/fetchdata';
import { logout } from '../../../../redux/thunk/gateway/fetchdata';
import * as actionCreators from "../../../../redux/actions/actionCreators";
import { delCookie } from '../../../../services/CookieService';
import {  SocketContext  } from "../../../../context/SocketContext";
import Deactiveconfirm from './Deactiveconfirm';

function Deactivateaccount() {


  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [currentpass,setCurrentpass] = useState("");
  const [errMsg,setErrmsg] = useState("");

  const userdetails = useSelector((state) => state.userReducer.userdetails);
  const { socket } = useContext(SocketContext);

  const handleBackarrow = () => {
     navigate(-1)
  }

  const handlePassword = (event) => {
     setCurrentpass(event.target.value);
  }

  const deactivateusraccount = async () => {

    function isBlank(str) {
     return (!str || /^\s*$/.test(str));
   }

   const result = isBlank(currentpass);

  if(!result) {

    let obj = {
      password:currentpass,
      userid:userdetails.userid
    }

    const response = await deactivateAccount(obj);

      if(response.STATUSCODE == 0) {

        const responseData = await logout();

         if(responseData.success == 1) {

              dispatch(actionCreators.addUserDetails(""));
              delCookie("uaid");

            const responseData = await logout();

            if(responseData.success == 1) {

               socket?.emit("end");

                dispatch(actionCreators.addAuthUser(false));
                dispatch(actionCreators.decAccforuser(true));
                dispatch(actionCreators.addUserDetails(""));

                 setTimeout(function(){
                  navigate('/Deactivatedpage');
                 }, 100);

              }   


          } else {
             console.log("error");
         }


      } else {
        setErrmsg("Invalid Password");
        setCurrentpass("");
      }

  }

  }

  return (
     <div className="deactivateaccount">

      <div className="setting-empspace"> </div>

     <div className="changepassword-container">
       <div className="Profiletitle">
          <div className="Profiletitlearrow" onClick={handleBackarrow} ><BsArrowLeftShort/></div>
          <div style={{width:10}}> </div>
          <div className="Profiletitletext">back</div>
      </div>


      <div className="changepassword-title"><h3>Deactivate Account</h3></div>
      <div style={{height:20}}> </div>
      <div className="deactivateaccount-text"><h3>Deactivate your account ?</h3></div>
      <div style={{height:10}}> </div>
      <div className="deactivateaccount-text"><h4>You can restore your account within 30 days after deactivation.</h4></div>
      <div style={{height:20}}> </div>

      <div className="deactivateaccount-text">
       <input type="password" className="depassword-btn" placeholder="Password" onChange={(event) => handlePassword(event)} value={currentpass} />
      </div>

      <div className="deactivateaccount-text">
        <button className="deactivate-btn" onClick={() => deactivateusraccount()}>
          Deactivate
        </button>
      </div>


      <div style={{height:10}} />

      <div className="deactivateaccount-text">
        <div style={{color:'red',padding:'12px',fontSize:'1.2rem'}}>
          {errMsg}
        </div>
      </div>



     </div>

       <div>
         <Routes>
            <Route path="/confirm" element={<Deactiveconfirm />}></Route>
          </Routes>
       </div>

     </div>
  );

}

export default Deactivateaccount;
