import './Videoparticipant.css';
import React, { useEffect,useState,useContext } from 'react';
import { useSelector,useDispatch } from "react-redux";
import defaultProfile from '../../../../assets/default/user.png';
import {  SocketContext  } from "../../../../context/SocketContext";
import * as actionCreators from '../../../../redux/actions/actionCreators';

function Videoparticipant() {

  const dispatch = useDispatch();
  const { socket } = useContext(SocketContext);
  const topicid = useSelector((state) => state.tstReducer.flag);
  const team = useSelector((state) => state.teamReducer.team);
  const participant = useSelector((state) => state.participantReducer.participant);
  var arr = Array(4).fill(0);


  useEffect(() => {
    if(topicid) {
      socket?.emit("getjoineduser",topicid);
    }

},[topicid]);


  return (
     <div className="videopart-body">

       {team.map((element,index) => {

        return (
          <>
           {participant.includes(parseInt(element.enrolleduserId)) ?
            <div>
             <div className="videobdyemptyspace"></div>
             <div className="videochatcontainer">


         <div className="videochatwrap">
           <div className="videochatprofileimg"
             style={{
              backgroundImage:element && element.profileImage ? `url("${element.profileImage}")` : `url("${defaultProfile}")`,
              backgroundPosition:'center',
              backgroundSize:'cover',
              backgroundRepeat:'no-repeat',
              maxWidth:'100%',
              maxHeight:'100%'
           }}>
           </div>

           <div>&nbsp;&nbsp;</div>

           <div className="videochatusername">
               {element.username}
           </div>
         </div>

             </div>
             <div className="videobdyemptyspace"></div>
            </div>
             : null }
          </>
        )})

      }

     </div>
  )
}

export default Videoparticipant;
