import React from 'react';
import { useRef,useEffect,useContext } from 'react';
import { useSelector,useDispatch } from "react-redux";
import { RiCloseFill } from 'react-icons/ri';
import * as actionCreators from '../../../../redux/actions/actionCreators';
import './Allmsgdel.css';
import { delUserAllChat } from '../../../../redux/thunk/message/fetchdata';

function Allmsgdel(props) {

   const myref = useRef(null);
   const dispatch = useDispatch();
   const delAllChat = useSelector((state) => state.messageReducer.delAllChat);

   const handleClick = (event) => {

     let obj = {
       delflag:false,
       delmsgid:"",
       userid:"",
       deluserid:""
     }

     if (myref.current && !myref.current.contains(event.target)) {
          dispatch(actionCreators.delAllChatMsg(obj));
      }

   };

   const handleClose = () => {

      let obj = {
        delflag:false,
        delmsgid:"",
        userid:"",
        deluserid:""
      }

      dispatch(actionCreators.delAllChatMsg(obj));

   }

   const handleDel = async() => {

      if(delAllChat.delmsgid) {

         let msgobj = {
           delmsgid:delAllChat.delmsgid,
           userid:delAllChat.userid
         }

        const response = await delUserAllChat(msgobj);

        if(response.STATUSCODE === 0) {
           dispatch(actionCreators.updateAllchatmsg(delAllChat.delmsgid));
        }

        let obj = {
          delflag:false,
          delmsgid:"",
          userid:"",
          deluserid:""
        }

        dispatch(actionCreators.updateChatUserList(delAllChat.deluserid));
        dispatch(actionCreators.addCurrentChatUser(null));
        dispatch(actionCreators.delAllChatMsg(obj));

      }
   }

   useEffect(() => {
     document.addEventListener("mousedown", handleClick);
     return () => {
       document.removeEventListener("mousedown", handleClick, false);
     };
  }, []);

   return (
     <div className="Delpost-wrapper" >
        <div ref={myref} className="Delpost-container">
           <div className="Delpost-title">
              <div className="Delpost-close-text"><strong>Delete All Chat ?</strong></div>
              <div className="Delpost-close" onClick={handleClose}>
                <RiCloseFill/>
              </div>
            </div>
           <div className="Delpost-name">
              <p>Delete All chat message ?</p>
            </div>
            <div className="Delpost-btn-height"> </div>
           <div className="Delpost-btn-wrapper">
              <div className="Delpost-btn" onClick={() => handleDel()}>Delete</div>
           </div>
        </div>
     </div>
  );
}

export default Allmsgdel;
