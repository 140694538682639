import React, { useState,useEffect,useRef } from 'react';
import { useSelector,useDispatch } from "react-redux";
import { useNavigate,useLocation,useParams } from "react-router-dom";
import { MdDeleteOutline } from 'react-icons/md';
import { ImCancelCircle } from 'react-icons/im';
import defaultProfile from '../../../../assets/default/user.png';
import Messagelist from './Messagelist';
import * as actionCreators from '../../../../redux/actions/actionCreators';
import { getUserMessage,addUserMessage } from '../../../../redux/thunk/message/fetchdata';
import './Messages.css';

function Messages(props) {

   const myref = useRef(null);
   const navigate = useNavigate();
   const location = useLocation();
   const dispatch = useDispatch();

   const routeParams = useParams();

   const [chatbox,setchatbox] = useState(true);
   const [userbox,setUserbox] = useState(true);

   const userdetails = useSelector((state) => state.userReducer.userdetails);
   const useraccountdetails =  useSelector((state) => state.accountDetailReducer.useraccountdetails);
   const chatuserlist = useSelector((state) => state.messageReducer.chatuserlist);

   const deleteAllchat = (event,messageid,deluserid) => {

     event.preventDefault();
     event.stopPropagation();

    if(messageid) {
        let obj = {
            delflag:true,
            delmsgid:messageid,
            userid:userdetails.userid,
            deluserid:deluserid
        }
       dispatch(actionCreators.delAllChatMsg(obj));
     }

   }

   const hashChangeHandler = () => {
       let obj = "";
       dispatch(actionCreators.addUserAccountDetails(obj));
   }

   const handleChat = (element) => {

     let width = window.innerWidth;

     if(width <= 780) {
        setUserbox(false);
         setchatbox(true);
     }

     dispatch(actionCreators.addCurrentChatUser(element));

   }

   const showUser = () => {
       setUserbox(true);
       setchatbox(false);
   }

   const handleResize = () => {

       let width = window.innerWidth;

       if(width <= 780) {
         setUserbox(false);
       } else {
         setchatbox(true);
         setUserbox(true);
       }

   }

    useEffect(() => {

      let width = window.innerWidth;

      if(width <= 780) {
        setchatbox(false);
      }

      window.addEventListener('resize', handleResize);
      window.addEventListener("popstate", hashChangeHandler);

      return () => {
        window.removeEventListener("popstate", hashChangeHandler);
        window.removeEventListener("resize", handleResize);
      };

  }, []);

    useEffect(async () => {

    if(userdetails)  {

     let obj = {
       userid:userdetails.userid
     }

      const response = await getUserMessage(obj);

      if(response.STATUSCODE === 0) {

         if(response.PAYLOAD.length > 0) {

           if(useraccountdetails) {

             let width = window.innerWidth;

             if(width <= 780) {
               setUserbox(false);
               setchatbox(true);
             }

             let indexobj = response.PAYLOAD.findIndex(o => o.touserid == useraccountdetails.userid);

              if(indexobj >= 0) {
                dispatch(actionCreators.addChatUserList(response.PAYLOAD));
                dispatch(actionCreators.addCurrentChatUser(response.PAYLOAD[indexobj]));

              } else {

                 let PAYLOAD = {
                   firstname:useraccountdetails.firstname,
                   lastname:useraccountdetails.lastname,
                   mobile:useraccountdetails.mobile,
                   profile_image:useraccountdetails.profile_image,
                   useremail:useraccountdetails.useremail,
                   userid:useraccountdetails.userid,
                   username:useraccountdetails.username
                }

                 let userdetailsto = {
                   MESSAGE:"User Details",
                   PAYLOAD:PAYLOAD
                 }

                 let obj = {
                   fromuserid:response.PAYLOAD[0].fromuserid,
                   touserid:useraccountdetails.userid,
                   messageid:"",
                   userdetailsto:userdetailsto,
                   userdetailsfrom:response.PAYLOAD[0].userdetailsfrom,
                 }

                 let temp = [obj,...response.PAYLOAD];

                 dispatch(actionCreators.addChatUserList(temp));
                 dispatch(actionCreators.addCurrentChatUser(obj));

              }

          } else {
              dispatch(actionCreators.addChatUserList(response.PAYLOAD));
              dispatch(actionCreators.addCurrentChatUser(response.PAYLOAD[0]));
           }

         } else {

            if(useraccountdetails) {

              let PAYLOAD = {
                 firstname:useraccountdetails.firstname,
                 lastname:useraccountdetails.lastname,
                 mobile:useraccountdetails.mobile,
                 profile_image:useraccountdetails.profile_image,
                 useremail:useraccountdetails.useremail,
                 userid:useraccountdetails.userid,
                 username:useraccountdetails.username
              }

              let userdetailsto = {
                 MESSAGE:"User Details",
                 PAYLOAD:PAYLOAD
               }

              let PAYLOAD1 = {
                  firstname:userdetails.firstname,
                  lastname:userdetails.lastname,
                  mobile:userdetails.mobile,
                  profile_image:userdetails.profile_image,
                  useremail:userdetails.useremail,
                  userid:userdetails.userid,
                  username:userdetails.username
               }

              let userdetailsfrom = {
                  MESSAGE:"User Details",
                  PAYLOAD:PAYLOAD1
                }

              let obj = {
                 fromuserid:userdetails.userid,
                 touserid:useraccountdetails.userid,
                 messageid:"",
                 userdetailsto:userdetailsto,
                 userdetailsfrom:userdetailsfrom
               }

              let temp = [];

              temp.push(obj);

               dispatch(actionCreators.addChatUserList(temp));
               dispatch(actionCreators.addCurrentChatUser(obj));
             }

         }

      }

    }

   },[userdetails,useraccountdetails]);


  return (
    <div className="messagewrapper">

        {userbox ?
         <div className="messagewrapper-people" >
           <div className="messagewrapper-title"><h4>Chat</h4></div>

          {chatuserlist && chatuserlist.map((element,index) => {

             let useraccname =  element && element.userdetailsto.PAYLOAD.username;
             let useraccdetailsimg = element && element.userdetailsto.PAYLOAD.profile_image;

          return (
            <div className="message-container-outer" key={index}>
             <div className="message-container" onClick={() => handleChat(element)}>
             <div className="message-prfwrapper">

              <div className="message-profileimg"
               style={{
                 backgroundImage:useraccdetailsimg && useraccdetailsimg ? `url("${useraccdetailsimg}")` : `url("${defaultProfile}")`,
                 backgroundPosition:'center',
                 backgroundSize:'cover',
                 backgroundRepeat:'no-repeat',
                 maxWidth:'100%',
                 maxHeight:'100%'
              }} />

              <div style={{width:10}}>  </div>

              <div className="message-username">
                 {useraccname}
              </div>

             </div>

            {element && element.messageid ?
             <div className="message-delete" onClick={(event) => deleteAllchat(event,element.messageid,element.touserid)}>
                <ImCancelCircle />
             </div> : null }

             </div>
            </div>
           )

         })}
         </div> : null }

          <div className={chatbox ? "messagewrapper-chat":"messagewrapper-chat-hide"}>
             <Messagelist showUser={showUser}  />
         </div>

    </div>
  );
}

export default Messages;
