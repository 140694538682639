import React, { useState,useRef,useEffect,useCallback } from 'react';
import { Routes, Link, Route, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TiStarFullOutline } from 'react-icons/ti';

import './Homepage-topnavbar-notification.css';

import { getUserNotifications,updateUserNotifications } from '../../../../redux/thunk/user/fetchdata';
import { topicsuperuser } from '../../../../redux/thunk/active/fetchdata';

import defaultProfile from '../../../../assets/default/user.png';
import * as actionCreators from "../../../../redux/actions/actionCreators";
import Classicspiner from '../../../../spinner/classic-spiner/Classicspiner';

function Homepagetopnavbarnotification(props) {

   const userdetails = useSelector((state) => state.userReducer.userdetails);

   const [animFlag,setAnimFlag] = useState(false);
   const [flag,setFlag] = useState(false);
   const [notification,setNotification] = useState([]);
   const [loading,setLoading] = useState(false);

   const [page,setPage] = useState(1);
   const [hasMore,setHasMore] = useState(true);
   const [temp,setTemp] = useState();

   const params = useParams();

   const dispatch = useDispatch();
   const navigate = useNavigate();

   const observer = useRef();
   const notiRef = useRef(null);

   const closeMenu = () => {
     setAnimFlag(false);
     setFlag(true);
   }

   const lastNotifyRef = useCallback(node => {

    if (observer.current) observer.current.disconnect()
       observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasMore) {
          setPage(temp);
       }
    })

      if(node) {
          observer.current.observe(node)
       }
  })

   const handleNoti = (event) => {
    if( notiRef.current.contains(event.target)) {
       return;
    }
    props.displayNotification();
  }

   const handleNavgate = async(notify_type,topicId,notify_id,notify_comment_id,notify_reply_id,topicrequestid,notifyfrom,notifyfromdetails,replytxt,requestid) => {

     let ntext = "@"+" "+notifyfrom+" "+"response :";

     if(notify_type == "Missedcall") {

        const response = await topicsuperuser(topicId);

        if(response.STATUSCODE == 0) {

          let obj = {
             topicid:topicId,
             topic:response.PAYLOAD.topic,
             superuser:response.PAYLOAD.userid
          }

          dispatch(actionCreators.addTopicId(obj));
        }

         let userroom = {
           roomIddis:topicId,
           userid:userdetails.userid
         }

       dispatch(actionCreators.addRoomDetails(userroom));

        const result = await updateUserNotifications(notify_id);

        dispatch(actionCreators.postResetInitialState());
        navigate(`/homepage/active/${topicId}/Startcall`);

     }

     if(notify_type == "commented" || notify_type == "replied") {


        let notifyId = {
           notify_comment_id:notify_comment_id,
           notify_reply_id:notify_reply_id,
           notify_text:ntext
        }


        dispatch(actionCreators.resetComment());
        dispatch(actionCreators.resetReply());
        dispatch(actionCreators.addNotifyId(notifyId));

        setFlag(true);
        setAnimFlag(false);

        const result = await updateUserNotifications(notify_id);
        navigate(`pool/notifydetails/${topicId}`);

     }

     if(notify_type == "request") {

       let requestobj = {
         topicId:topicId,
         topicrequestid:topicrequestid,
         notify_id:notify_id,
         notify_comment_id:notify_comment_id,
         notify_reply_id:notify_reply_id,
         topicrequestid:topicrequestid,
         notifyfromdetails:notifyfromdetails,
         replytxt:replytxt,
         requestflag:true,
         requestid:requestid,
       }


        dispatch(actionCreators.reqNav(requestobj));
        setFlag(true);
        setAnimFlag(false);



    {/*
        navigate(`request/requestdisplay`, { state: { topicId: topicrequestid } });
    */}

     }

     if(notify_type == "Accpeted") {
        const result = await updateUserNotifications(notify_id);
        navigate(`/homepage/pool/pooldetails/${topicId}`);
     }

     if(notify_type == "Deleted") {
       const result = await updateUserNotifications(notify_id);
       navigate(`/homepage/pool/pooldetails/${topicId}`);
     }

     if(notify_type == "Postreply") {

       dispatch(actionCreators.postResetInitialState());
       dispatch(actionCreators.addTopicId(null));

       const response = await topicsuperuser(topicId);

       if(response.STATUSCODE == 0) {

         let obj = {
            topicid:topicId,
            topic:response.PAYLOAD.topic,
            superuser:response.PAYLOAD.userid
         }

          dispatch(actionCreators.addTopicId(obj));

      }

       let userroom = {
         roomIddis:topicId,
         userid:userdetails.userid
       }

        dispatch(actionCreators.addRoomDetails(userroom));
        dispatch(actionCreators.setscrollPosition(window.pageYOffset));
        dispatch(actionCreators.restUserPost());
        dispatch(actionCreators.addUserpreviewid(notify_comment_id));

        navigate(`/homepage/active/${topicId}/preview/${notify_comment_id}`);

        const result = await updateUserNotifications(notify_id);

     }

  }

   useEffect(() => {
    setAnimFlag(true);
    document.addEventListener("mousedown", handleNoti);
    return () => {
       document.removeEventListener("mousedown", handleNoti, false);
     };
  }, []);

   useEffect(() => {
     if(flag) {
       setTimeout(function(){
         props.displayNotification();
        }, 500);
     }
   },[flag])

   useEffect(async() => {
     if(props.userid) {

       let lim = 5;

       let obj = {
         userid:props.userid,
         page:page,
         limit:lim
       }

       setLoading(true);
       const result = await getUserNotifications(obj);

        if(result) {
          let ft = result[0];

         if(ft.next == undefined) {
           setNotification([...notification,...result.slice(1)]);
           setLoading(false);
           setHasMore(false);
         } else {
           setNotification([...notification,...result.slice(1)]);
           setTemp(ft.next.page)
         }

        }

     }
   },[page])

  return (
    <div ref={notiRef}  className={animFlag ? "Homepage-topnavbar-notification-notificationpanel-op" :
    "Homepage-topnavbar-notification-notificationpanel-cl"}>

       <div className="Homepage-topnavbar-notification-notificationHeader">
         <div className="Homepage-topnavbar-notiheader">Notifications</div>
          <div onClick={() => closeMenu()} className="Homepage-topnavbar-noti-close">&times;</div>
       </div>

        <div  className="Homepage-topnavbar-notification-notificationList">

            {notification && notification.map((element,index) => {

               return (
                 <div key={index} ref={lastNotifyRef} className="Homepage-topnavbar-notification-notificationFeed"
                     onClick={() => handleNavgate(element.notify_type,element.notify_topicid,element.notify_id,element.notify_comment_id,element.notify_reply_id,element.topicrequestid,element.notifyfrom.username,element.notifyfrom,element.replytxt,element.requestid)}>

                    <div className="Homepage-topnavbar-notification-notificationFeed-wrapper">

                    <div className="Homepage-topnavbar-profile-wrapperaa">

                      <div className="Homepage-topnavbar-profile-wrapper">

                       <div className="Homepage-topnavbar-profile-img" style={{
                          backgroundImage:element.notifyfrom.profile_image ? `url("${element.notifyfrom.profile_image}")` : `url("${defaultProfile}")`,
                          backgroundPosition:'center',
                          backgroundSize:'cover',
                          backgroundRepeat:'no-repeat',
                          maxWidth:'100%',
                          maxHeight:'100%'
                        }}>
                       </div>

                      </div>

                       <div className="Homepage-topnavbar-wrapper-text">

                        {element.notify_type !== "request" ?
                          <div className="Homepage-topnavbar-notification-text"
                            dangerouslySetInnerHTML={{__html: `${element.notifyfrom.username}
                            ${element.notify_type} :  ${element.replytxt}`}} />
                          :
                           <div className="Homepage-topnavbar-notification-text">
                                <p className="Homepage-topnavbar-notification-icon">
                                  <TiStarFullOutline/>
                                 </p>
                                <b>{element.notifyfrom.username} Request For : </b>
                                <div className="Homepage-topnavbar-notification-txt">
                                  <p>&nbsp;{element.replytxt}</p>
                                </div>
                            </div>
                         }

                          <div className="Homepage-topnavbar-notification-time">
                             &nbsp;{element.notification_at}
                          </div>

                       </div>
                    </div>

                    </div>
                    <div>&nbsp;&nbsp;</div>
                    {element.notifiyread == 0 ?
                     <div className="Homepage-topnavbar-notification-notificationFeed-check">
                     </div> : <div className="Homepage-topnavbar-notification-notificationFeed-checked">
                     </div> }
                </div>
               )
            })}


            {loading ?
               <div className="Homepage-topnavbar-notification-spinner">
                  <Classicspiner />
               </div> : null
             }

        </div>

 </div>

  )
}

export default Homepagetopnavbarnotification;
