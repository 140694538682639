import './Videochat.css';
import React, { useEffect,useState,useContext } from 'react';
import { useSelector,useDispatch } from "react-redux";
import { BiRightArrowAlt } from 'react-icons/bi';
import { TiArrowRightThick } from 'react-icons/ti';
import { IoCloseOutline } from 'react-icons/io5';
import {  SocketContext  } from "../../../../context/SocketContext";
import defaultProfile from '../../../../assets/default/user.png';
import * as actionCreators from '../../../../redux/actions/actionCreators';
import Videoparticipant from './Videoparticipant';
import { getmeetingChat } from '../../../../redux/thunk/meeting/fetchdata';
import { getTeamDetails } from '../../../../redux/thunk/active/fetchdata';

function Videochat(props) {

   const dispatch = useDispatch();
   const [message,setMessage] = useState("");
   const [msgflag,setMsgflag] = useState(true);
   const [incomingmsg,setIncomingmsg] = useState([]);
   const topicid = useSelector((state) => state.tstReducer.flag);
   const userdetails = useSelector((state) => state.userReducer.userdetails);
   const chatmsg = useSelector((state) => state.chatReducer.chatmsg);
   const team = useSelector((state) => state.teamReducer.team);
   const participant = useSelector((state) => state.participantReducer.participant);

   const { socket } = useContext(SocketContext);

   const closeChat = () => {
    props.hideChat();
  }

   const postMsg = () => {

     document.getElementById("chtmsg").innerHTML = '';

     const messageData = {
        userid:userdetails.userid,
        content: message,
        timestamp: new Date().getTime()
    };


   if(message) {
     socket.emit("send-message", topicid, messageData);
     setMessage("");
   }

}

   const handleMsgChange = (event) => {
     setMessage(event.currentTarget.textContent);
  }

   const handlechatmsg = () => {
     setMsgflag(true);
   }

   const handlechatprt = () => {
     setMsgflag(false);
   }

   useEffect(async () => {

      socket.on("add-message", (data) => {

         dispatch(actionCreators.addChatmsg(data));

          if(data.userid !== userdetails.userid) {
              props.handlechatalert();
          }

      });

      const result = await getmeetingChat(topicid);

      if(result.STATUSCODE == 0) {
         dispatch(actionCreators.addGrpmsg(result.PAYLOAD));
         if(result.PAYLOAD.length > 0) {
             props.handlechatalert();
         }
      }

   },[topicid])

   useEffect(async () => {

     if(team <= 0) {
       if(topicid) {
         const response = await getTeamDetails(topicid);
         dispatch(actionCreators.setTeam(response.PAYLOAD));
      }
    }

  },[]);

  return (
    <div className="videochatwrapper">

          {/* Video chat title */}

      <div className="videochat-title">

         <div className="videogrpchat">
             <div className="videoclosedisable">
               <IoCloseOutline />
              </div>
              <div>Group chat</div>
              <div className="videoclose" onClick={() => closeChat()}>
                <IoCloseOutline />
             </div>
         </div>

         <div className="videomsgwrp">
           <div onClick={handlechatmsg} className={msgflag ? "videomsgtitle" : "videomsgtitledis" }>
              Messages
            </div>
            <div className="videomsgwrp-empspace"> </div>
           <div onClick={handlechatprt} className={msgflag ? "videomsgtitledis" : "videomsgtitle" }>
              Participants
           </div>
         </div>

      </div>

     {msgflag ?
      <div className="videochat-wrapper">

        <div className="videochat-upper">
           <div className="videochatbody">

          {chatmsg.length > 0 && chatmsg.map((element,index) => {


          let obj = team.find(o => o.enrolleduserId == element.userid);
          let  timestamp = new Date().getTime();

          let diff = (timestamp - element.timestamp) / 1000;
          diff /= (60 * 60);
          let newnumber = diff * 60;
          const timelape = newnumber.toString().split('.')[0];

          return (
           <>
            <div className="videoprofilewrapper">

              <div>
               <div className="video-profile-img" style={{
                 backgroundImage:obj && obj.profileImage ? `url("${obj.profileImage}")` : `url("${defaultProfile}")`,
                 backgroundPosition:'center',
                 backgroundSize:'cover',
                 backgroundRepeat:'no-repeat',
                 maxWidth:'100%',
                 maxHeight:'100%'
               }}>
               </div>
              </div>


              <div className="videoprofilename">
                <div className="videoprofilenametxt">
                  &nbsp;{obj && obj.username}
                   &nbsp;&nbsp;&nbsp;
                   <span className="videoprofiletime">{timelape} min ago</span>
                 </div>
                <div className="videoprofilechat">
                  {element.content}
                </div>
              </div>

            </div>

            <div>&nbsp;</div>
           </>
          )
        })}
          </div>

        </div>

        <div className="videochat-bottom">
          <div className="videochatmsg">
          <div className="videochat-postform" contentEditable="true" onInput={handleMsgChange}
             data-placeholder="Drop message . . . " suppressContentEditableWarning={true}
             suppressContentEditableWarning={true} id="chtmsg"
             >
          </div>
          <div className="videochat-btnpost" onClick={() => postMsg()}>
            <TiArrowRightThick />
          </div>
          </div>
        </div>

      </div> :
       <div className="videochat-wrapper">
          <Videoparticipant />
       </div> }

    </div>
  )
}

export default Videochat;
