import React, { useState,useEffect,useCallback,useRef } from 'react';
import { useSelector } from "react-redux";
import { yourPoollist,delUserpool } from '../../../../redux/thunk/pool/fetchdata';
import { Routes, Link, Route } from "react-router-dom";
import Pbimg from '../../homepage-maincontainer/Poolrequest/Pbimg';
import { BsTrash } from 'react-icons/bs';
import './Yourpool.css';
import Pooldetails from '../../pool/Pooldetails';
import Pooldelete from './Pooldelete';
import Successnotify from '../../../../notify/Successnotify';
import Spinner from '../../../../spinner/classic-spiner/Classicspiner';

function Yourpoollist(props) {

  const userdetails = useSelector((state) => state.userReducer.userdetails);

  const observer = useRef();

  const [poolList,setPoolList] = useState([]);
  const [topic,setTopic] = useState("");
  const [usertopicId,setUsertopicId] = useState("");
  const [delFlag,setDelFlag] = useState(false);
  const [displayflashmsg,setDisplayflashmsg] = useState(false);
  const [notifyMsg,setNotifyMsg] = useState("");

  {/* pagination */}

 const [page,setPage] = useState(1);
 const [hasMore,setHasMore] = useState(true);
 const [nextpage,setNextpage] = useState("");
 const [loading,setLoading] = useState(false);

 const lastfollowerElementRef = useCallback(node => {

     if (observer.current)  {
        observer.current.disconnect()
     }

     observer.current = new IntersectionObserver(entries => {
         if (entries[0].isIntersecting && hasMore) {
             setPage(nextpage);
         }
     })

     if(node) {
         observer.current.observe(node)
      }
})

  const handleDelete = (topicdesc,topicId) => {
       setTopic(topicdesc);
       setUsertopicId(topicId);
       setDelFlag(true);
  }

  const closeFlashMsg = () => {
    setDisplayflashmsg(false);
  }

  const submitdelRequest = async (topicid) => {

        const responseData = await delUserpool(topicid);

        if (responseData.STATUSCODE == 0)  {

           setNotifyMsg(responseData.MESSAGE)
           setDelFlag(false);
           setDisplayflashmsg(true);

           let templist = [...poolList];
           let indexlist = templist.findIndex(item => item.topicId == topicid);
           templist.splice(indexlist, 1);

           setPoolList(templist);

      {/*
           const responseList = await yourPoollist();
             if(responseList.STATUSCODE == 0) {
               setPoolList(responseList.PAYLOAD);
            }
        */}

        }
  }

  const toggledel = () => {
     setDelFlag(false);
  }

  useEffect(async() => {

     if(page && Object.keys(userdetails).length > 0) {

       let limit = 5;

       let userObj = {
           userid:userdetails.userid,
           page:page,
           limit:limit
        }


       if(page) {

           setLoading(true);

            const response = await yourPoollist(userObj);

            if(response.STATUSCODE == 0) {
               if(response.NEXT.next == undefined) {
                  setPoolList([...poolList,...response.PAYLOAD]);
                  setHasMore(false);
                  setLoading(false);
              } else {
                  setPoolList([...poolList,...response.PAYLOAD]);
                  setNextpage(response.NEXT.next.page)
              }
            } else {
               setLoading(false);
            }


        }

     }

  },[userdetails,page]);


  return (

     <div className="yourpool-body">

       {poolList.map((element,index) => {

         return (

           <div key={index} className="yourpool-wrapper" ref={lastfollowerElementRef} >
                <div>&nbsp;</div>

                <Link className="yourpool-link" to={`pooldetails/${element.topicId}`} state={{ from: element }} >

                  <div className="yourpool-wrapperimg">

                   {element.imagename ?
                    <img className="yourpool-poolscrollimg" src={element.imagename}  />
                       : <Pbimg /> }
                    </div>

                     <div className="yourpool-desctable">
                     <div className="yourpool-topicdetails">

                      <div className="yourpool-topic">Topic&nbsp;&nbsp;:</div>
                      <div>&nbsp;</div>
                      <div id="yoid" className="yourpool-desc">{element.topic}</div>
                   </div>

                   </div>
                </Link>

            {element.poolactive ?
              <div className="yourpool-delete">
                <div className="yourpool-delline">
                    active
                </div>
             </div>
                :
                <div className="yourpool-delete">
                   <div className="yourpool-delline" onClick={() => handleDelete(element.topic,element.topicId)}>
                        <BsTrash/>
                   </div>
                </div>
            }

             </div>

          )
       })}

        {delFlag ?
           <Pooldelete toggledel={toggledel} itemDel={topic} usertopicId={usertopicId} submitdelRequest={submitdelRequest} />
          : null }

        {displayflashmsg ? <Successnotify notifyMsg={notifyMsg} closeFlashMsg={closeFlashMsg} /> : null }

        {loading ? <Spinner /> : null }

        <div className="followerhtspace">
        </div>

     </div>

  )
}

export default Yourpoollist;
