import Compressor from 'compressorjs';

export function handleCompress(file,width,height) {

  return new Promise((resolve, reject) => {

   const image = file;

  new Compressor(image, {
      quality: 0.4, maxWidth:width, maxHeight:height, // 0.6 can also be used, but its not recommended to go below.
      success: (compressedResult) => {
        return resolve(compressedResult);
      },
    });


 })
}
