
// Get Topic Details

export const getTopicDetails = async (topicId) => {

  try {

     let url = '/topicdetails';

     const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          body:JSON.stringify({
             topicId:topicId
          })
     };

     const response = await fetch(url, requestOptions);

     if(!response.ok) {
        throw {
          STATUSCODE:1,
          STATUS:"ERROR",
          MESSAGE:"Something broke!" }
      } else {
         const result = await response.json();
         return result;
      }

    } catch (error) {
        return error;
     }

}

// add User topicrequest

export const addUserTopicRequest = async(topicrequest) => {

  try {

     let url = '/addtopicrequest';

     const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          }, body:JSON.stringify({
              topicrequest:topicrequest
           })
     };

     const response = await fetch(url,requestOptions);

      if(!response.ok) {
         throw {
           STATUSCODE:1,
           STATUS:"ERROR",
           MESSAGE:"Something broke!" }
       } else {
          const result = await response.json();
          return result;
       }

    } catch (error) {
        return error;
     }

}

// cancel User Topic Request

export const cancelUserTopicRequest = async(topicrequest) => {

  try {

     let url = '/canceltopicrequest';

     const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          }, body:JSON.stringify({
              topicrequest:topicrequest
           })
     };

     const response = await fetch(url,requestOptions);

      if(!response.ok) {
         throw {
           STATUSCODE:1,
           STATUS:"ERROR",
           MESSAGE:"Something broke!" }
       } else {
          const result = await response.json();
          return result;
       }

    } catch (error) {
        return error;
     }

}

// Get User Request

export const getUserRequest = async (userObj) => {

    console.log("userObjuserObj",userObj);

  try {

     let url = '/getuserrequest';

     const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          }, body:JSON.stringify({
              userObj:userObj
           })
      };


     const response = await fetch(url,requestOptions);

      if(!response.ok) {
         throw {
           STATUSCODE:1,
           STATUS:"ERROR",
           MESSAGE:"Something broke!" }
       } else {
          const result = await response.json();
          return result;
       }

    } catch (error) {
        return error;
     }

}

//  Get User display Request


export const getuserrequetdisplayRequest = async (userObj) => {


  try {

     let url = '/getuserrequetdisplayRequest';

     const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          }, body:JSON.stringify({
              userObj:userObj
           })
      };

     const response = await fetch(url,requestOptions);

      if(!response.ok) {
         throw {
           STATUSCODE:1,
           STATUS:"ERROR",
           MESSAGE:"Something broke!" }
       } else {
          const result = await response.json();
          return result;
       }

    } catch (error) {
        return error;
     }


}


// Get User Request Sent

export const getUserRequestSent = async(userObj) => {

    console.log("userObjuserObj",userObj);

  try {

    let url = '/getuserrequestsent';

    const requestOptions = {
         method: 'POST',
         headers: {
           'Content-Type': 'application/json',
           'Accept': 'application/json',
         }, body:JSON.stringify({
             userObj:userObj
          })
     };

     const response = await fetch(url,requestOptions);

      if(!response.ok) {
         throw {
           STATUSCODE:1,
           STATUS:"ERROR",
           MESSAGE:"Something broke!" }
       } else {
          const result = await response.json();
          return result;
       }

    } catch (error) {
        return error;
     }

}

// Add Enrolled User addenrolleduser

export const addEnrolledUser = async(enrolledObj) => {

  try {

     let url = '/addenrolleduser';

     const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          }, body:JSON.stringify({
              enrolledObj:enrolledObj
           })
     };

     const response = await fetch(url,requestOptions);

      if(!response.ok) {
         throw {
           STATUSCODE:1,
           STATUS:"ERROR",
           MESSAGE:"Something broke!" }
       } else {
          const result = await response.json();
          return result;
       }

    } catch (error) {
        return error;
     }

}

// Delete Enrolled User

export const delEnrolledUser = async(requestid) => {

  try {

     let url = '/delenrolleduser';

     const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          }, body:JSON.stringify({
              requestid:requestid
           })
     };

     const response = await fetch(url,requestOptions);

      if(!response.ok) {
         throw {
           STATUSCODE:1,
           STATUS:"ERROR",
           MESSAGE:"Something broke!" }
       } else {
          const result = await response.json();
          return result;
       }

    } catch (error) {
        return error;
     }

}
