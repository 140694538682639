import './Mobile.css';
import { Routes,Route,useNavigate,NavLink } from "react-router-dom";
import { BsArrowLeft } from 'react-icons/bs';
import { BsArrowLeftShort } from 'react-icons/bs';

function Mobile() {

  const navigate = useNavigate();

  const handleBackarrow = () => {
     navigate(-1)
  }

  return (
     <div className="deactivateaccount">

        <div className="setting-empspace"> </div>

       <div className="changepassword-container">

          <div className="Profiletitle">
             <div className="Profiletitlearrow" onClick={handleBackarrow} ><BsArrowLeftShort/></div>
             <div style={{width:10}}> </div>
             <div className="Profiletitletext">back</div>
         </div>

         <div className="changepassword-title">Mobile</div>


       </div>
     </div>
  );

}

export default Mobile;
