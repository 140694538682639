import React from 'react';
import { useRef,useEffect,useContext } from 'react';
import { useSelector,useDispatch } from "react-redux";
import { RiCloseFill } from 'react-icons/ri';
import * as actionCreators from '../../../../redux/actions/actionCreators';
import './Messagedel.css';
import { delUserChat } from '../../../../redux/thunk/message/fetchdata';

function Messagedel(props) {

   const myref = useRef(null);
   const dispatch = useDispatch();
   const delmsgchat = useSelector((state) => state.messageReducer.delmsgchat);

   const handleClick = (event) => {

      let obj = {
        delflag:false,
        delid:""
      }

     if (myref.current && !myref.current.contains(event.target)) {
          dispatch(actionCreators.delUserChatmsg(obj));
      }

   };

   const handleClose = () => {

      let obj = {
        delflag:false,
        delid:""
      }

      dispatch(actionCreators.delUserChatmsg(obj));
   }

   const handleDel = async() => {

      if(delmsgchat.delid) {

        const response = await delUserChat(delmsgchat.delid);

        if(response.STATUSCODE === 0) {
           dispatch(actionCreators.updatChatmsg(delmsgchat.delid));
        }

        let obj = {
          delflag:false,
          delid:""
        }

        dispatch(actionCreators.delUserChatmsg(obj));

      }
   }

    useEffect(() => {

     document.addEventListener("mousedown", handleClick);

     return () => {
       document.removeEventListener("mousedown", handleClick, false);
     };

  }, []);

  return (
     <div className="Delpost-wrapper" >
        <div ref={myref} className="Delpost-container">
           <div className="Delpost-title">
              <div className="Delpost-close-text"><strong>Delete Chat ?</strong></div>
              <div className="Delpost-close" onClick={handleClose}>
                <RiCloseFill/>
              </div>
            </div>
           <div className="Delpost-name">
              <p>Delete chat message ?</p>
            </div>
            <div className="Delpost-btn-height"> </div>
           <div className="Delpost-btn-wrapper">
              <div className="Delpost-btn" onClick={() => handleDel()}>Delete</div>
           </div>
        </div>
     </div>
  );
}

export default Messagedel;
