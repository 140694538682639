import React from 'react';
import { useRef,useEffect } from 'react';
import { useSelector,useDispatch } from "react-redux";
import { RiCloseFill } from 'react-icons/ri';
import { addSmartTag } from '../../../../redux/thunk/post/fetchdata';
import * as actionCreators from '../../../../redux/actions/actionCreators';
import './Addsmarttag.css';

function Addsmarttag(props) {

   const myref = useRef(null);
   const inputref = useRef(null);
   const dispatch = useDispatch();

   const topicobj = useSelector((state) => state.activeReducer.topicid);
   const smarttag = useSelector((state) => state.postlistReducer.addsmarttag);

   const handleClick = (event) => {

      let obj = {
        smartflag:false,
      }

      if (myref.current && !myref.current.contains(event.target)) {
           dispatch(actionCreators.addSmartTag(obj));
       }
   };

   const handleClose = () => {

     let obj = {
       smartflag:false,
     }

     dispatch(actionCreators.addSmartTag(obj));
   }

   const submittag = async (event) => {

    if(inputref.current.value.length > 0) {

      let obj = {
       smartflag:false,
     }

      let sendobj = {
       postId:smarttag.postId,
       topicid:topicobj.topicid,
       userId:smarttag.userId,
       tagname:inputref.current.value,
       isdeleted:false
     }

      dispatch(actionCreators.addSmartTag(obj));
      const response = await addSmartTag(sendobj);

      if(response.STATUSCODE == 0) {

         let smartObj = {
           userid:smarttag.userId,
           tagname:sendobj.tagname
         }

         let smtobj = {
           postid:smarttag.postId,
           smartobj:smartObj
         }

       dispatch(actionCreators.updateUserpost(smtobj));

     }

     }

   }

   const submittagkey = async (event) => {

     if (event.keyCode === 13) {

      if(inputref.current.value.length > 0) {

        let obj = {
          smartflag:false,
        }

        let sendobj = {
          postId:smarttag.postId,
          topicid:topicobj.topicid,
          userId:smarttag.userId,
          tagname:inputref.current.value,
          isdeleted:false
        }

        dispatch(actionCreators.addSmartTag(obj));
        const response = await addSmartTag(sendobj);

        if(response.STATUSCODE == 0) {

          let smartObj = {
            userid:smarttag.userId,
            tagname:sendobj.tagname
          }

         let smtobj = {
           postid:smarttag.postId,
           smartobj:smartObj
         }

       dispatch(actionCreators.updateUserpost(smtobj));

     }

     }

      }

   }

   useEffect(() => {
     inputref.current.focus();

     document.addEventListener("mousedown", handleClick);

   return () => {
      document.removeEventListener("mousedown", handleClick, false);
    };

  }, []);

  return (
     <div className="Addsmarttag-wrapper" >
        <div ref={myref} className="Addsmarttag-container">
           <div className="Addsmarttag-title">
              <div className="Addsmarttag-close-text"><strong>Add Smart Tag</strong></div>
              <div className="Addsmarttag-close" onClick={handleClose}>
                 <RiCloseFill />
              </div>
            </div>
           <div  className="AddSmarttag-name">
              <input ref={inputref} maxLength="250" className="AddSmarttag-inp" placeholder="Tag name"
               type="text" onKeyDown={(event) => submittagkey(event)}  />
            </div>
           <div className="AddSmarttag-btn-wrapper">
              <div className="AddSmarttag-btn"
               onClick={(event) => submittag(event)}>Add Tag</div>
           </div>
        </div>
     </div>
  );
}

export default Addsmarttag;
