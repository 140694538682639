import * as actionTypes from "../actions/actionTypes";

const initialState = {
    useraccountdetails:""
};

const accountDetailReducer = (state = initialState, action) => {

  switch (action.type) {

    case actionTypes.ADD_USER_ACCOUNT_DETAIL:

      return {
        ...state,
        useraccountdetails:action.payload
      };

    default:
      return state;
  }
};

export default accountDetailReducer;
