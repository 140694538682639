import React, { useEffect,useState } from 'react';
import prioryoulogoprint from '../../assets/logo/prioryoulogoprint_dark.png';
import './Aboutprioryou.css';


function Contactprioryou() {


  return (
     <div className="Aboutprioryou-wrapper">

          <div style={{height:"20px"}}> </div>

           <div><img src={prioryoulogoprint} /></div>
           <div style={{height:"20px"}}> </div>


           <div className="Aboutprioryou-content-txt">
              <div className="Aboutprioryou-content-line">Contact</div>
              <div className="Aboutprioryou-content-line">We are located in </div>
              <div className="Aboutprioryou-content-line">Wine Capital of India</div>
              <div className="Aboutprioryou-content-line">Surrounded by nine hills</div>
              <div className="Aboutprioryou-content-line">In Nashik City.</div>
              <div className="Aboutprioryou-content-line"><small>CIN: U85499MH2023PTC407854</small></div>
           </div>


     </div>

  );
}

export default Contactprioryou;
