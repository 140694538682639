import React, { useEffect,useState,useRef } from 'react';
import './Imgpreview.css';
import { useSelector,useDispatch } from "react-redux";
import * as actionCreators from "../../../../redux/actions/actionCreators";
import { IoMdArrowRoundBack } from 'react-icons/io';

function Imgpreview() {

  const myref = useRef(null);
  const dispatch = useDispatch();
  const imageUrl = useSelector((state) => state.postlistReducer.imageUrl);

  const closePreview = () => {
    dispatch(actionCreators.addImageFlag(false));
  }

  const handleClick = (event) => {
    if (myref.current && !myref.current.contains(event.target)) {
         dispatch(actionCreators.addImageFlag(false));
      }
  };

  useEffect(() => {
     document.addEventListener("mousedown", handleClick);
    return () => {
       document.removeEventListener("mousedown", handleClick, false);
     };
   },[])

  return (
     <div className="Imgpreview-exp">

       <div className="Imgpreview-header">
         <div className="Imgpreview-back" onClick={closePreview}>
            <div className="Imgpreview-btn"><IoMdArrowRoundBack/></div>
            <div style={{width:10}}> </div>
            <div className="Imgpreview-btn">back</div>
         </div>
        </div>

       <div className="Imgpreview-container" ref={myref}>
          <img className="Imgpreview-img" src={imageUrl} />
        </div>

        <div className="Imgpreview-footer">  </div>
     </div>
  )
}

export default Imgpreview;
