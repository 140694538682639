export  function  getdeviceType() {

   const deviceType = () => {

     const ua = navigator.userAgent;

     if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
          return "tablet";
       }
      else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
          return "mobile";
       }
         return "desktop";
    };

    let devicename = deviceType();

    let result = {
        device:devicename,
        width:window.screen.width,
        height:window.screen.height
    };

    return result;

}
