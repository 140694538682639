import React, { useState,useEffect,useRef } from 'react';
import { useSelector,useDispatch } from "react-redux";
import { updateaccstatus } from '../../../redux/thunk/gateway/fetchdata';
import * as actionCreators from "../../../redux/actions/actionCreators";
import './Welcomeback.css';
import alldone from "../../../assets/dashboard/welcomeback.png";

function Welcomeback() {

  const myref = useRef(null);
  const dispatch = useDispatch();

  const userdetails = useSelector((state) => state.userReducer.userdetails);

  const handleClick = async(event) => {

     dispatch(actionCreators.decAccFlag(false));

     if(userdetails.userid) {
       const response = await updateaccstatus(userdetails.userid);
     }

  }

  const handleGetStarted = async() => {

    dispatch(actionCreators.decAccFlag(false));

    if(userdetails.userid) {
      const response = await updateaccstatus(userdetails.userid);
    }

  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
       document.removeEventListener("mousedown", handleClick, false);
     };
  },[])

  return (
      <div className="Alldone-wrapper">
         <div ref={myref}  className="Alldone-container">

            <div className="Alldone-container-imgcontainer">
              <img className="Alldone-container-img" src={alldone} />
            </div>

          <div className="Alldone-container-getstarted" onClick={() => handleGetStarted()}>
              JumpBack
           </div>

           <div>&nbsp;</div>

         </div>
      </div>
  );
}

export default Welcomeback;
