import React from 'react';
import { useState,useEffect,useRef } from 'react';
import { useSelector,useDispatch } from "react-redux";
import { getSecruityCode } from '../../../redux/thunk/security/fetchdata';
import { MdOutlineCancel } from 'react-icons/md';
import { v4 as uuidV4 } from "uuid";
import Recoverydis from './Recoverydis.js';
import * as actionCreators from "../../../redux/actions/actionCreators";
import { jsPDF } from "jspdf";
import prioryoulogoprint from '../../../assets/logo/prioryoulogoprint.png';
import './Recoverycode.css';


function Recoverycode(props) {

  const dispatch = useDispatch();

  const userdetails = useSelector((state) => state.userReducer.userdetails);

  const [recoverycode,setRecoverycode] = useState(null);
  const [keyflag,setKeyflag] = useState(false);

  const handleprint = (recoverycode,userName) => {

    var img = new Image()
    img.src = prioryoulogoprint

    let left = 80;
    let top = 5;
    const imgWidth = 50;
    const imgHeight = 15;


      const doc = new jsPDF();
      doc.setFontSize(12);
      doc.addImage(img, 'PNG', left, top, imgWidth, imgHeight)
      doc.text(10, 30,"PriorYou Id :");
      doc.text(34, 30, userName);
      doc.text(10, 40, "Secuirty Code :");
      doc.text(40, 40, recoverycode == null ? "****" : recoverycode);
      doc.text(10, 50, "Note : The recovery code used to reset your password.");
      doc.save("SecuirtyCode.pdf");

      dispatch(actionCreators.setrecoveryCode(false));

      let obj = {
        userid:"",
        username:"",
        flag:false,
        alldone:true
      }

      dispatch(actionCreators.setWelcomeFlag(obj));

    //  props.handleClose();
   }

   useEffect(async () => {

     if(userdetails.userid) {
       const response = await getSecruityCode(userdetails.userid);
       setRecoverycode(response.PAYLOAD);
     }

   },[userdetails])


 return (
   <div  className="sharewrapper">

   <div style={{height:"10px"}}></div>

     <div style={{backgroundColor:'#ffff',padding:"10px"}}>

      <div className="card" id="test">
          <div className="cardcontainer">
                  <div style={{height:"20px"}}></div>
            <div className="cardimg">
             <img className="imglogo" src={prioryoulogoprint} />
            </div>
            <div className="code">PriorYou Id: {userdetails.username}</div>
            <br/>
            <div>Secuirty Code :</div>
            <br/>
            <div className="code">{recoverycode}</div>
            <br/>
          </div>
      </div>

      <div style={{height:"10px"}}></div>

      <div style={{padding:"4px"}}>Note : The recovery code used to reset your password.</div>

       <div style={{height:"10px"}}></div>

     <div className="confirmbtn">
       <div onClick={() => handleprint(recoverycode,userdetails.username)}>Confirm</div>
     </div>

     </div>

   </div>
 )

}

export default Recoverycode;
