import React, { useState,useEffect,useCallback,useRef  } from 'react';
import { yourPoollist } from '../../../../redux/thunk/pool/fetchdata';
import { Routes, Link, Route } from "react-router-dom";
import Pbimg from '../../homepage-maincontainer/Poolrequest/Pbimg';
import { BsTrash } from 'react-icons/bs';
import './Yourpool.css';
import Yourpoollist from './Yourpoollist';
import Pooldetails from '../../pool/Pooldetails';

function Yourpool() {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (

     <div className="yourpool-container">
       <Routes>
           <Route path="/" element={<Yourpoollist />}></Route>
           <Route path="/pooldetails/:picId/" element={<Pooldetails />}></Route>
       </Routes>
     </div>

  )
}

export default Yourpool;
