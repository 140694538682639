import './Profiledetails.css';
import React, { useState,useEffect,useRef  } from 'react';
import { useSelector,useDispatch } from "react-redux";

import { BiWorld } from 'react-icons/bi';
import Classicspiner from '../../../../spinner/classic-spiner/Classicspiner';
import Timezone from '../../homepage-maincontainer/Poolrequest/Timezone';
import * as actionCreators from "../../../../redux/actions/actionCreators";

import one from  '../../../../assets/sterotype/1.png';
import two from  '../../../../assets/sterotype/2.png';
import three from  '../../../../assets/sterotype/3.png';
import four from  '../../../../assets/sterotype/4.png';
import five from  '../../../../assets/sterotype/5.png';
import six from  '../../../../assets/sterotype/6.png';
import seven from  '../../../../assets/sterotype/7.png';
import Sterotypesel from './Sterotypesel';

function Profiledetails(props) {

 const myRef = useRef(null);
 const dispatch = useDispatch();

 const [timezoneFlag,setTimezoneflag] = useState(false);
 const steroseltype = useSelector((state) => state.profileReducer.sterotype);


 const handleSterotype = () => {
    dispatch(actionCreators.setSterotypeFlag(true));
 }

 const handleEdit = () => {
    props.handleEditFlag();
 }

 const handleSave = () => {
   props.saveProfiledetails();
 }

 const handleUsername = (event) => {
    props.handleUsername(event.target.value);
 }

 const handleFirstname = (event) => {
    props.handleFirstname(event.target.value);
 }

 const handleLastname = (event) => {
    props.handleLastname(event.target.value);
 }

 const handleBio = (event) => {
    props.handleBio(event.target.value);
 }

 const handleGender = (event) => {
    props.handleGender(event.target.value);
 }

 const handleLocation = (event) => {
     props.handleLocation(event.target.value)
 }

 const showTimezone = () =>  {
   setTimezoneflag(true);
 }

 const hideTimezone = ()  => {
    setTimezoneflag(false);
 }

 const mountTimezone = (value) => {
   props.handleTimezone(value);
   setTimezoneflag(false);
 }

 const onKeyDwn = (event) => {

    if (event.keyCode === 13) {
        event.preventDefault();
     }

    const currentTextLength = event.target.outerText.length;

     if (currentTextLength > 249 && event.keyCode != 8 && event.keyCode !== 46) {
          event.preventDefault();
      }
 }

 const handleKeypress = (event) => {

   if(event.currentTarget.textContent.length  <= 250) {
       props.handleBio(event.currentTarget.textContent);
   } else {
       event.preventDefault();
    }

 }

 useEffect(() => {
    myRef.current.focus();
 },[props.errflag])


 useEffect(() => {
   if(steroseltype != null) {
    props.handleSterotype(steroseltype);
   }
 },[steroseltype])


 return (

  <div>

     {props.spinnerFlag ?
       <div className="profiledetails-spinnerwrapper">
          <Classicspiner />
       </div> :
      null}

    <div className="profiledetails-wrapper">

    <div className="profile-btnwrapper">
      <div className="profilebtn">
       {props.editFlag ?
        <button className="profilebtn-edit" onClick={() => handleEdit()}>Edit</button> :
        <button className="profilebtn-save" onClick={() => handleSave()}>Save</button>
       }
      </div>
    </div>

       {/* SteroType */}

       <div className={ props.editFlag ? "profiledetails-sterotype" : "profiledetails-sterotype-edit"}
             onClick={props.editFlag ? null : () => handleSterotype()}>

         <label htmlFor="email" className="profiledetails-lbl">SteroType</label>

            <div className="lbl-space"> </div>

            {props.sterotype == "Genius" ?
         <div className="account-sterotype">
           <div className="account-sterotype-imgwrapper">
            <img className="account-sterotype-img" src={three} />
          </div>
          <div style={{height:10}}>  </div>
          <div className="account-sterotype-name">
            {props.sterotype}
          </div>
           <div style={{height:10}}>  </div>
          </div> : null }

             {props.sterotype == "Lazy" ?
           <div className="account-sterotype">
             <div className="account-sterotype-imgwrapper">
              <img className="account-sterotype-img" src={five} />
            </div>
           <div style={{height:10}}>  </div>
           <div className="account-sterotype-name">
            {props.sterotype}
           </div>
          <div style={{height:10}}>  </div>
         </div> : null }

             {props.sterotype == "Nerd" ?
          <div className="account-sterotype">
            <div className="account-sterotype-imgwrapper">
               <img className="account-sterotype-img" src={six} />
            </div>
           <div style={{height:10}}>  </div>
           <div className="account-sterotype-name">
             {props.sterotype}
           </div>
           <div style={{height:10}}>  </div>
          </div> : null }

             {props.sterotype == "Smart" ?
           <div className="account-sterotype">
             <div className="account-sterotype-imgwrapper">
                 <img className="account-sterotype-img" src={four} />
             </div>
             <div style={{height:10}}>  </div>
             <div className="account-sterotype-name">
               {props.sterotype}
             </div>
             <div style={{height:10}}>  </div>
           </div> : null }

             {props.sterotype == "Casual" ?
             <div className="account-sterotype">
               <div className="account-sterotype-imgwrapper">
                   <img className="account-sterotype-img" src={one} />
               </div>
               <div style={{height:10}}>  </div>
               <div className="account-sterotype-name">
                 {props.sterotype}
               </div>
               <div style={{height:10}}>  </div>
             </div> : null }

             {props.sterotype == "Geek" ?
                 <div className="account-sterotype">
                   <div className="account-sterotype-imgwrapper">
                       <img className="account-sterotype-img" src={two} />
                   </div>
                   <div style={{height:10}}>  </div>
                   <div className="account-sterotype-name">
                     {props.sterotype}
                   </div>
                   <div style={{height:10}}>  </div>
                 </div> : null }

             {props.sterotype == "Blank" ?
               <div className="account-sterotype">
                 Set</div> : null }


       </div>

       <div>&nbsp;</div>

       {/* Profile Firstname and Lastname */}

       <div className="profilename-wrapper">
         <div className="profiledetails-firstname">
           <label htmlFor="email" className="profiledetails-lbl">Firstname</label>
           <div className="lbl-space"></div>
           <input className={props.editFlag ? "profileinpedit":"profileinpsave"} type="text"
           id="email" maxLength="64" readOnly={props.editFlag} value={props.firstname}
           onChange={handleFirstname} ref={myRef} />
         </div>

       <div>&nbsp;</div>

       <div className="profiledetails-lastname">
         <label htmlFor="email" className="profiledetails-lbl">Lastname</label>
         <div className="lbl-space"></div>
         <input className={props.editFlag ? "profileinpedit":"profileinpsave"} type="text"
          id="email" maxLength="64" readOnly={props.editFlag} value={props.lastname}
          onChange={handleLastname} />
       </div>
       </div>

       <div>&nbsp;</div>

      <div className="profilename-wrapper">

        <div className="profiledetails-gender">

          <label htmlFor="username" className="profiledetails-lbl">Gender</label>
            <div className="lbl-space"></div>
            <select name="gender" className={props.editFlag ? "profilegender":"profilegenderedit"}
               disabled={props.editFlag ? "disabled" : ""} onChange={handleGender}
               value={props.gender} >
                 <option  value="">Select Gender</option>
                 <option value="M">Male</option>
                 <option value="F">Female</option>
                 <option value="O">Other</option>
             </select>

          </div>

          <div>&nbsp;</div>

          <div className="profiledetails-username">

            <label htmlFor="username" className="profiledetails-lbl">
              I am from
            </label>
            <div className="lbl-space"></div>
            <input className={props.editFlag ? "profilelocation":"profilelocationedit"} type="text"
             id="location" maxLength="64" readOnly={props.editFlag} value={props.location}
             onChange={handleLocation} placeholder="City , Country" />

          </div>
      </div>

      <div>&nbsp;</div>

      <div onClick={props.editFlag ? null :() => showTimezone()} className={props.editFlag ? "profiledetails-timezone" : "profiledetails-timezone-edit"}>
          <div className="profiledetails-timezone-detail">
            <BiWorld /> Timezone : {props.timezone}
          </div>
         {timezoneFlag ? <Timezone mountTimezone={mountTimezone} hideTimezone={hideTimezone} /> : null }
     </div>

      <div>&nbsp;</div>

      <div className="profilebio-wrapper">
         <div className="profile-bio">
            <label htmlFor="username" className="profiledetails-lbl">Bio</label>
            <div className="lbl-space"></div>
            <div className={props.editFlag ? "profilebioeditoredit" : "profilebioeditorsave"}
             placeholder="Describe yourself here"
             suppressContentEditableWarning={true}
             contentEditable={props.editFlag ? "false" : "true"}
             onKeyDown={onKeyDwn}
             onInput={(event) => handleKeypress(event)}
             onPaste={(e) =>  e.preventDefault()}
             onDrop={(e) =>  e.preventDefault()}
             >{props.bio}</div>
         </div>
      </div>



    {/*
      <div className="profile-btnwrapper">
        <div className="profilebtn">
         {props.editFlag ?
          <button className="profilebtn-edit" onClick={() => handleEdit()}>Edit</button> :
          <button className="profilebtn-save" onClick={() => handleSave()}>Save</button>
         }
        </div>
      </div>
    */}


   </div>

   <div className="profiledetails-bottomspace"></div>

  </div>
  )
}

export default Profiledetails;
