import React, { useState,useEffect } from 'react';
import { Routes,Route,useNavigate,NavLink } from "react-router-dom";
import { useSelector,useDispatch } from "react-redux";
import { BsArrowLeftShort } from 'react-icons/bs';
import { checkUserPassword,changeUserPassword } from '../../../../redux/thunk/setting/fetchdata';
import Classicspiner from '../../../../spinner/classic-spiner/Classicspiner';
import Successnotify from '../../../../notify/Successnotify';
import './Changepassword.css';

function Changepassword() {

  const navigate = useNavigate();

  const userdetails = useSelector((state) => state.userReducer.userdetails);

  const [currentpass,setCurrentpass] = useState("");
  const [verifypass,setVerifypass] = useState(false);
  const [newpass,setNewpass] = useState("");
  const [conpass,setConpass] = useState("");
  const [errormsg,setErrormsg] = useState(false);
  const [errorcmpmsg,setErrorcmpmsg] = useState(false);
  const [errorcon,setErrorcon] = useState(false);
  const [submitflag,setSubmitflag] = useState(false);
  const [displayflashmsg,setDisplayflashmsg] = useState(false);

  const [changepass,setChangepass] = useState(false);

  const handleBackarrow = () => {
     navigate(-1)
  }

  const chkPassword = async () => {

    let cpassword = currentpass.trim();

    if(cpassword.length > 0) {

      let obj =  {
        userid:userdetails.userid,
        password:currentpass
      }

     const response = await checkUserPassword(obj);

      if(response.STATUSCODE == 1) {
        setErrormsg(true);
      } else {
        setErrormsg(false);
      }

    }

  }

  const handleCurrentPassword = (event) => {
      let value = event.target.value.trim();
      setCurrentpass(value);
  }

  const handleNewPassword = (event) => {
     let value = event.target.value.trim();
     setNewpass(value);
  }

  const handleConfirmPassword = (event) => {
    let value = event.target.value.trim();
    setConpass(value);
  }

  const handleSubmit = async () => {

    if(conpass.length > 0 && newpass.length > 0 && errormsg == false) {

        if(newpass.length < 7) {
           setErrorcon(true);
           setErrorcmpmsg(false);
           return;
        } else {
           setErrorcon(false);
        }

        if(newpass == conpass) {

           let sumbitobj = {
             userid:userdetails.userid,
             currentpassword:currentpass,
             newpass:newpass,
             conpass:conpass
           }

          setErrorcmpmsg(false);
          setChangepass(true);

          const response = await changeUserPassword(sumbitobj);

           if(response.STATUSCODE == 0) {
                setDisplayflashmsg(true)
             } else {

            }

           setChangepass(false);
           setCurrentpass("");
           setNewpass("");
           setConpass("");


        } else {
          setErrorcmpmsg(true);
        }

    }

  }

  const closeFlashMsg = () => {
    setDisplayflashmsg(false);
  }

  useEffect(() => {
    if(currentpass.length > 0 && newpass.length > 0 && conpass.length > 0) {
        setSubmitflag(true);
    } else {
       setSubmitflag(false);
    }
  },[currentpass,newpass,conpass])

  return (
     <div className="deactivateaccount">

       <div className="setting-empspace"> </div>

       <div className="changepassword-container">

          <div className="Profiletitle">
             <div className="Profiletitlearrow" onClick={handleBackarrow} ><BsArrowLeftShort/></div>
             <div style={{width:10}}> </div>
             <div className="Profiletitletext">back</div>
         </div>

         <div className="changepassword-title">Change Password</div>
         <div style={{height:30}}> </div>

         <div className="changepassword-body">

          <div className="changepassword-body-inner">

            <div style={{height:10}}> </div>

            <input className="changepassword-inp" type="password" value={currentpass} onBlur={() => chkPassword()} onChange={(event) => handleCurrentPassword(event)} placeholder="Current password" />
            <div className="changepassword-inp-space">{errormsg ? "* Incorrect password." : null}</div>
            <input className="changepassword-inp" type="password" value={newpass} onChange={(event) => handleNewPassword(event)} placeholder="New password" />
            <div className="changepassword-inp-space">{errorcon ? "* Your password needs to be at least 8 characters." : null} </div>
            <input className="changepassword-inp" type="password" value={conpass} onChange={(event) => handleConfirmPassword(event)} placeholder="Confirm password" />
            <div className="changepassword-inp-space">{errorcmpmsg ? "* Password does not match" : null}</div>

             <div style={{height:20}}> </div>

           <div className="changepassword-btn">
            {submitflag ?
             <button className="changepassword-savebtn" onClick={() => handleSubmit()}>
               Save
             </button> :
             <button className="changepassword-savebtn-dis">
               Save
             </button> }

           </div>

          </div>
         </div>

       </div>

      {changepass ?
       <div className="changepassword-spinner">
         <Classicspiner />
       </div> : null }

      {displayflashmsg ?
      <div className="changepassword-msg">
        <Successnotify notifyMsg="Password updated" closeFlashMsg={closeFlashMsg} />
      </div> : null }

     </div>
  );

}

export default Changepassword;
