import * as actionTypes from "../actions/actionTypes";

const initialState = {
  requestConfirm:{
       reqstatus:false
  }
};

const reqconfirmReducer = (state = initialState, action) => {

  switch (action.type) {

    case actionTypes.REQCONFIRM:

      return {
        requestConfirm:action.payload
      };

    default:
      return state;
  }
};

export default reqconfirmReducer;
