import React, { useEffect,useState } from 'react';
import prioryoulogoprint from '../../assets/logo/prioryoulogoprint_dark.png';
import './Aboutprioryou.css';


function Aboutprioryou() {


  return (
     <div className="Aboutprioryou-wrapper">

          <div style={{height:"20px"}}> </div>

           <div><img src={prioryoulogoprint} /></div>
           <div style={{height:"20px"}}> </div>
           <div className="Aboutprioryou-content">Collaborative learning <div className="Aboutprioryou-subtext">&nbsp;platform</div> </div>



           <div className="Aboutprioryou-content-txt">
              <div className="Aboutprioryou-content-line">Our philosophy</div>
              <div className="Aboutprioryou-content-line">&nbsp;due tomorrow <div>&nbsp;≠&nbsp;</div> <div>do tomorrow&nbsp;</div> </div>
              <div className="Aboutprioryou-content-line">To become self <div>taught person.</div><div style={{padding:"4px"}}>&nbsp;self learning</div></div>
              <div className="Aboutprioryou-content-line">Collaborative learning <div style={{padding:"4px"}}>&nbsp;that involves</div></div>
              <div className="Aboutprioryou-content-line">Students working<div style={{padding:"4px"}}>&nbsp;in pairs</div></div>
              <div className="Aboutprioryou-content-line">Small groups to <div style={{padding:"4px"}}>&nbsp;discuss concepts</div><div>&nbsp;or solutions to problems.</div></div>
           </div>

     </div>

  );
}

export default Aboutprioryou;
