import './Timezone.css';
import React, { useEffect,useState,useContext,useRef } from 'react';
import { useParams,useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from "react-redux";
import { MdOutlineCancel } from 'react-icons/md';
import { MdOutlineClear } from 'react-icons/md';
import timezone from '../../../../json/timezone.json';

function Timezone(props) {

  const myref = useRef(null);
  const inpref = useRef(null);

  const [searchvalue,setSearchvalue] = useState("");
  const [cancelflag,setCancelflag] = useState(false);
  const [tmzone,setTmzone] = useState(null);
  const [flag,setFlag] = useState(false);

  const handleSelect = (event,value,gmtoffset) => {
     event.preventDefault();
     event.stopPropagation();
     props.mountTimezone(value,gmtoffset);
  }

  const handleChange = (event) => {

    setFlag(true);

    let value = event.target.value;

    let filtervalue = timezone.filter((item) =>  {
       return (
          item.CountryName.toLowerCase().includes(value.toLowerCase()) ||
          item.TimeZone.toLowerCase().includes(value.toLowerCase())
       );
    });

     setSearchvalue(value);
     setTmzone(filtervalue);
  }

  const handClear = () => {
    setSearchvalue("");
    setTmzone(timezone);
    setFlag(true);
    inpref.current.focus();
  }

  const cancelTimezone = (event) => {
    event.preventDefault();
    event.stopPropagation();
     props.hideTimezone();
  }

  const handleClick = (event) => {
    if (myref.current && !myref.current.contains(event.target)) {
        props.hideTimezone();
      }
   };

  const handleKeyPress = (event,value) => {

    if(tmzone[0] != undefined) {

    let valuetm;

    if(flag == true) {
       valuetm = ` ${tmzone[0].CountryName}  ${tmzone[0].TimeZone} - ${tmzone[0].GMTOffset}`;
    } else {
      valuetm = value;
    }

    if(event.key === 'Enter') {
       props.mountTimezone(valuetm);
    }

  }

  }

   useEffect(() => {
     if(searchvalue.length > 0) {
        setCancelflag(true);
     } else {
        setCancelflag(false);
     }
   },[searchvalue])

  useEffect(() => {

    setTmzone(timezone);

    let value = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if(value === "Asia/Calcutta" || value === "Asia/Kolkata") {

       let finalresult = "India Asia/Mumbai - UTC +05:30";
       setSearchvalue(finalresult);
       inpref.current.focus();

    } else {

       let filtervalue = timezone.filter((item) =>  {
         return (
            item.TimeZone.toLowerCase().includes(value.toLowerCase())
         );
      });

       if(filtervalue.length > 0) {

          let finalresult = "";

          if(filtervalue[0].TimeZone) {
          finalresult = `${filtervalue[0].CountryName} ${filtervalue[0].TimeZone} - ${filtervalue[0].GMTOffset}`;
       }

          setSearchvalue(finalresult);
          inpref.current.focus();
       }

    }

  },[timezone])

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
       document.removeEventListener("mousedown", handleClick, false);
     };
  },[])

  return (
    <div className="Timezone-modal">
      <div className="Timezone-wrapper" ref={myref}>

           <div className="Timezone-header">
              <div> </div>
              <div><h2>Timezone</h2></div>
              <div className="Timezone-cancel" onClick={(event) => cancelTimezone(event)}>
                 <MdOutlineCancel />
              </div>
          </div>

          <div className="Timezone-input-wrapper">
            <input ref={inpref} className="Timezone-input" type="text" onChange={(event) => handleChange(event)} value={searchvalue}
            onKeyPress={(event) => handleKeyPress(event,searchvalue)} />
              {cancelflag ?
            <MdOutlineClear onClick={handClear} className="Timezone-clear" />
              : null }
          </div>

        <div className="Timezone-container">
          {tmzone?.map((element,index) => {

            let value = ` ${element.CountryName}  ${element.TimeZone} - ${element.GMTOffset}`;

            return (
                <div className="Timezone-item" key={index}  onClick={(event) => handleSelect(event,value,element.GMTOffset)}>
                   {value}
                </div>
              )
           })}
        </div>

      </div>
    </div>
   )
}

export default Timezone;
