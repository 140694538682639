import React from 'react';
import { useState, useEffect , useRef } from 'react';
import './PoolEditDelete.css';

function PoolEditDelete(props) {

  const myRef = useRef(null);

  const handleClick = (e) => {

    if (myRef.current.contains(e.target)) {
      // inside click

      if(e.target.id === "edtcmt") {
        props.handleEditCmtlst(props.cmtListid,props.comment_id);
      }

      if(e.target.id === "delcmt") {
        props.handleDelCmtlst(props.cmtListid,props.comment_id,props.loginUser);
      }

      if (e.target.id === "repcmt") {
         props.handleReport(props.comment_id,props.loginUser)
      }

      return;
    }

    // outside click
    props.cancelEditPanel();

  };

  useEffect(() => {

   document.addEventListener("mousedown", handleClick);

   return () => {
      document.removeEventListener("mousedown", handleClick, false);
    };

  }, []);


  return (
      <div className="PoolEditDelete-wrapper" ref={myRef}>

      {props.loginUser === props.userid ?

        <>
          <div className="PoolEditDelete-btn" id="edtcmt" >Edit</div>
          <div className="PoolEditDelete-empty"> </div>
          <div className="PoolEditDelete-btn" id="delcmt" >Delete</div>
        </> :

        <>
        {props.loginUser == props.superuser ?
          <>
            <div className="PoolEditDelete-btn" id="delcmt" >Remove</div>
            <div className="PoolEditDelete-empty"> </div>
            <div className="PoolEditDelete-btn" id="repcmt" >Report</div>
          </> :
            <div className="PoolEditDelete-btn" id="repcmt" >Report</div>
          }
        </>

       }

     </div>
  );
}

export default PoolEditDelete;
