// COMMENT REDUCER

export const ADD_COMMENT = 'ADD_COMMENT';
export const ADD_POST_COMMENT = 'ADD_POST_COMMENT';
export const DELETE_COMMENT = 'DELETE_COMMENT';
export const LIKE_COMMENT = 'LIKE_COMMENT';
export const EDIT_SAVE_COMMENT = 'EDIT_SAVE_COMMENT';
export const UNLIKE_COMMENT = 'UNLIKE_COMMENT';
export const RESET_COMMENT = 'RESET_COMMENT';
export const ADD_COUNT_COMMENT = 'ADD_COUNT_COMMENT';

// REPLY REDUCER

export const RMV_REPLY_COMMENT_ID = 'RMV_REPLY_COMMENT_ID';
export const ADD_REPLY_COMMENT = 'ADD_REPLY_COMMENT';
export const ADD_SUB_REPLY = 'ADD_SUB_REPLY';
export const DELETE_REPLY = 'DELETE_REPLY';
export const LIKE_REPLY = 'LIKE_REPLY';
export const UNLIKE_REPLY = 'UNLIKE_REPLY';
export const EDIT_SAVE_REPLY = 'EDIT_SAVE_REPLY';
export const RESET_REPLY = 'RESET_REPLY';

// PUBLISH TIMELINE
export const CREATE_TIMELINE = 'CREATE_TIMELINE';
export const EDIT_TIMELINE = 'EDIT_TIMELINE';
export const SAVE_TIMELINE = 'SAVE_TIMELINE';
export const DATE_RANGE = 'DATE_RANGE';
export const REMOVE_FILEPATH = 'REMOVE_FILEPATH';
export const RESET_PUBLISH = 'RESET_PUBLISH';

export const DEACT_FLAG = 'DEACT_FLAG';
export const DEACT_ACCN_FLAG = 'DEACT_ACCN_FLAG';

// USER DETAILS
export const ADD_USER_DETAILS = 'ADD_USER_DETAILS';

// POOL Details
export const SET_TOPIC_DETAILS = 'SET_TOPIC_DETAILS';

// ADD NOTIFICATION ID
export const ADD_NOTIFY_ID = 'ADD_NOTIFY_ID';

// ADD NOTIFICATION FLAG
export const NOTIFY_FLAG = 'NOTIFY_FLAG';

// REQUEST POPUP MODEL
export const REQ_DEL = 'REQ_DEL';

// REQUEST NAVIGATE
export const REQNAV = 'REQNAV';

// REFRESH REQUEST
export const REFRESHREQ = 'REFRESHREQ';

// REQUEST CONFIRM
export const REQCONFIRM = 'REQCONFIRM';

// Add Topic Id
export const ADD_TOPIC_ID = 'ADD_TOPIC_ID';

export const SET_TEAM = 'SET_TEAM';

export const DEL_TEAM_MEMBER = 'DEL_TEAM_MEMBER';

export const ADD_MEMBER = 'ADD_MEMBER';

export const REMOVE_MEMBER = 'REMOVE_MEMBER';

export const SET_FLAG = 'SET_FLAG';

// Video

export const START_CALL = 'START_CALL';

export const REQ_GRP_CALL = 'REQ_GRP_CALL';

// device type

export const DEVICE_TYPE = 'DEVICE_TYPE';

export const ADD_TOPIC_ID2 = 'ADD_TOPIC_ID2';

// Add chat message

export const ADD_MSG = 'ADD_MSG';

export const ADD_GRP_MSG = 'ADD_GRP_MSG';

export const CLEAR_GRP_MSG = 'CLEAR_GRP_MSG';

export const ADD_PARTICIPANT = 'ADD_PARTICIPANT';

export const REMOVE_PARTICIPANT = 'REMOVE_PARTICIPANT';


// Call Start

export const CALL_START = 'CALL_START';
export const CALL_CANCEL = 'CALL_CANCEL';

// ROOM STATUS

export const CHANGE_ROOMFLAG = 'CHANGE_ROOMFLAG';

// POOLIST STATUS


// User Post

export const ADD_SMART_TAG = 'ADD_SMART_TAG';
export const DEL_SMART_TAG = 'DEL_SMART_TAG';
export const ADD_USER_POST = 'ADD_USER_POST';
export const ADD_IMAGE_URL = 'ADD_IMAGE_URL';
export const ADD_IMAGE_FLAG = 'ADD_IMAGE_FLAG';
export const DEL_USER_POST = 'DEL_USER_POST';
export const DEL_USER_POST_LIST = 'DEL_USER_POST_LIST';
export const UPDATE_SMART_TAG = 'UPDATE_SMART_TAG';
export const UPDATE_SMART_TAG_USR = 'UPDATE_SMART_TAG_USR';

export const ADD_USER_POST_DB = 'ADD_USER_POST_DB';

export const ADD_POST_PAGE = 'ADD_POST_PAGE';
export const ADD_POST_TEMP = 'ADD_POST_TEMP';
export const ADD_POST_HASMORE = 'ADD_POST_HASMORE';

export const ADD_SEARCH_PAGE = 'ADD_SEARCH_PAGE';
export const ADD_SEARCH_TEMP = 'ADD_SEARCH_TEMP';
export const ADD_SEARCH_HASMORE = 'ADD_SEARCH_HASMORE';

export const ADD_PREVIEWID = 'ADD_PREVIEWID';
export const ADD_PREVIEWPOST = 'ADD_PREVIEWPOST';
export const RMV_USERPOST = 'RMV_USERPOST';
export const ADD_POST_COUNT = 'ADD_POST_COUNT';
export const SET_POST_COUNT = 'SET_POST_COUNT';
export const REST_USER_POST = 'REST_USER_POST';
export const SET_SCROLL_POSITION = 'SET_SCROLL_POSITION';
export const SET_POST_SEARCH = 'SET_POST_SEARCH';
export const SET_POST_SEARCH_TERM = 'SET_POST_SEARCH_TERM';
export const CLEAR_POST_SEARCH = 'CLEAR_POST_SEARCH';

export const ADD_SEARCH_TERM = 'ADD_SEARCH_TERM';

export const ADD_SEARCH_POOL_TERM = 'ADD_SEARCH_POOL_TERM';
export const ADD_SEARCH_POOL_HASMORE = 'ADD_SEARCH_POOL_HASMORE';
export const ADD_SEARCH_POOL_TEMP = 'ADD_SEARCH_POOL_TEMP';
export const ADD_SEARCH_POOL_PAGE = 'ADD_SEARCH_POOL_PAGE';
export const CLEAR_SEARCH_POOL = 'CLEAR_SEARCH_POOL';
export const ADD_SEARCH_POOL = 'ADD_SEARCH_POOL';

export const ADD_PEOPLE_TERM = 'ADD_PEOPLE_TERM';
export const ADD_PEOPLE_POOL = 'ADD_PEOPLE_POOL';
export const ADD_SEARCH_PEOPLE_TERM = 'ADD_SEARCH_PEOPLE_TERM';
export const ADD_SEARCH_PEOPLE_HASMORE = 'ADD_SEARCH_PEOPLE_HASMORE';
export const ADD_SEARCH_PEOPLE_TEMP = 'ADD_SEARCH_PEOPLE_TEMP';
export const ADD_SEARCH_PEOPLE_PAGE = 'ADD_SEARCH_PEOPLE_PAGE';

export const POST_RESET_INITIAL_STATE = 'POST_RESET_INITIAL_STATE';

// Otp

export const SET_OTP_FLAG = 'SET_OTP_FLAG';
export const UPDATE_EMAIL_ID = 'UPDATE_EMAIL_ID';

// Update Username
export const UPDATE_USERNAME = 'UPDATE_USERNAME';

//  Set User Account Details

export const ADD_USER_ACCOUNT_DETAIL = 'ADD_USER_ACCOUNT_DETAIL';

//  Set message

export const ADD_CHAT_USER_LIST = 'ADD_CHAT_USER_LIST';
export const ADD_CURRENT_CHAT_USER = 'ADD_CURRENT_CHAT_USER';
export const ADD_USER_CHAT = 'ADD_USER_CHAT';
export const ADD_NEW_USER_CHAT = 'ADD_NEW_USER_CHAT';
export const DEL_CHAT_MSG = 'DEL_CHAT_MSG';
export const DEL_ALL_CHAT_MSG = 'DEL_ALL_CHAT_MSG';
export const UPDATE_CHAT_MSG = 'UPDATE_CHAT_MSG';
export const UPDATE_ALL_CHAT_MSG = 'UPDATE_ALL_CHAT_MSG';
export const UPDATE_CHAT_USER_LIST = 'UPDATE_CHAT_USER_LIST';

// Resources

export const ADD_RESOURCES = 'ADD_RESOURCES';
export const DEL_RESOURCES = 'DEL_RESOURCES';
export const ADD_ALL_RESOURCES = 'ADD_ALL_RESOURCES';
export const UPDATE_RESOURCES = 'UPDATE_RESOURCES';
export const UPDATE_ADD_RESOURCES = 'UPDATE_ADD_RESOURCES';
export const DELETE_RESOURCES = 'DELETE_RESOURCES';
export const UPDATE_RESOURCES_RECORD = 'UPDATE_RESOURCES_RECORD';
export const UPDATE_DEL_RECORD = 'UPDATE_DEL_RECORD';

// Squad

export const LEAVE_SQUAD = 'LEAVE_SQUAD';
export const REMOVE_SQUAD = 'REMOVE_SQUAD';
export const REPORT_SQUAD = 'REPORT_SQUAD';

// WelCome screen

export const WELCOME_FLAG = 'WELCOME_FLAG';

// Profile

export const SET_STEROTYPE = 'SET_STEROTYPE';
export const STEROTYPE_FLAG = 'STEROTYPE_FLAG';

// takeaway

export const SHOW_TAKEAWAY = 'SHOW_TAKEAWAY';

// sharebox

export const SHOW_SHARE_BOX = 'SHOW_SHARE_BOX';

// Notify Alert

export const ADD_NOTIFY_ALERT = 'ADD_NOTIFY_ALERT';
export const ADD_MSG_ALERT = 'ADD_MSG_ALERT';

// Socket

export const ADD_ROOM_DETAILS = 'ADD_ROOM_DETAILS';

// User Authentication

export const ADD_AUTH_USER = 'ADD_AUTH_USER';

export const ADD_REQUEST_DELETE_ID = 'ADD_REQUEST_DELETE_ID';

export const REST_PEOPLE_POOL = 'REST_PEOPLE_POOL';

export const UPDATE_USER_STATUS = 'UPDATE_USER_STATUS';

export const SETREDIRECTPATH = 'SETREDIRECTPATH';

export const UPDATE_USER_CANCEL_STATUS = 'UPDATE_USER_CANCEL_STATUS';

export const UPDATE_RECOVERYCODE_FLAG = 'UPDATE_RECOVERYCODE_FLAG';

export const UPDATE_EMAIL_VERFICATION = 'UPDATE_EMAIL_VERFICATION';

export const ADD_USER_INFODATA = 'ADD_USER_INFODATA';
