
// Check Image valid
export async function returnPhotoURL(blobURL) {

  return new Promise((resolve, reject) => {

    const img = new Image();
    img.src = blobURL;

    img.onerror =   function () {
      URL.revokeObjectURL(this.src);
      // Image is not valid format  ( Handle Error )
      resolve(true);
    };

      img.onload = function () {
          resolve(false);
      }

 });

}

// Check File Size
export async function fileSize(fileSize) {

  return new Promise((resolve, reject) => {

    let size = Math.round(fileSize / 1024);

       if (size >= 4096) {
          // Image size is greater than 4mb ( Handle Error )
          return resolve(true);
       } else {
          return resolve(false);
       }

   })
}

// Change file name
export async function fileName(file) {

  return new Promise((resolve, reject) => {

    let blob = file.slice(0,file.size, 'image/png');
    let newFile = new File([blob], 'image.png', {type: 'image/png'});

    return resolve(newFile);

   })
}

// Check Mime Type
export async function fileType(filevalue) {

   let file = filevalue.slice(0, 4);

   async function getAsByteArray(file) {
      return new Uint8Array(await readFile(file))
   }

   const byteFile = await getAsByteArray(file);
   const mimeType = fileCheck(byteFile);

   return mimeType;

}

function readFile(file) {

 return new Promise((resolve, reject) => {

    // Create file reader
    let reader = new FileReader()

   // Register event listeners

    reader.addEventListener("loadend", (event) => {
       return resolve(event.target.result)
    });

    reader.addEventListener("error", reject)

    // Read file
    reader.readAsArrayBuffer(file)
  })

}

function fileCheck(byteFile) {

  let header = "";

   for(var i = 0; i < byteFile.length; i++) {
      header += byteFile[i].toString(16);
   }

 switch (header) {

   case "89504e47":
     return("image/png")
     break;

   case "47494638":
      return("image/gif")
      break;

    case "ffd8ffe0":
    case "ffd8ffe1":
    case "ffd8ffe2":
    case "ffd8ffe3":
    case "ffd8ffe8":
      return("image/jpeg");
      break;

    case "47494638":
      return("image/gif");

    case "424d":
       return("image/bmp");
       break;

    case "52494646":
       return("image/GoogleWebP");
       break;

    default:
       return("unknown");
       break;
  }

}
