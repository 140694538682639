import React, { useState,useEffect,useRef } from 'react';
import { useDispatch } from "react-redux";
import * as actionCreators from "../../../redux/actions/actionCreators";
import './Alldone.css';
import alldone from "../../../assets/dashboard/alldone.png";

function Alldone() {

  const myref = useRef(null);
  const dispatch = useDispatch();

  const handleClick = (event) => {

    if (myref.current && !myref.current.contains(event.target)) {

      let obj = {
        userid:"",
        username:"",
        flag:false,
        alldone:false
      }

      dispatch(actionCreators.setWelcomeFlag(obj));

    }
 }

  const handleGetStarted = () => {

    let obj = {
      userid:"",
      username:"",
      flag:false,
      alldone:false
    }

    dispatch(actionCreators.setWelcomeFlag(obj));

  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
       document.removeEventListener("mousedown", handleClick, false);
     };
  },[])

  return (
      <div className="Alldone-wrapper">
         <div ref={myref}  className="Alldone-container">

            <div className="Alldone-container-imgcontainer">
              <img className="Alldone-container-img" src={alldone} />
            </div>

          <div className="Alldone-container-getstarted" onClick={() => handleGetStarted()}>
              DiveIn
           </div>

           <div>&nbsp;</div>

         </div>
      </div>
  );
}

export default Alldone;
