import React, { useEffect,useState } from 'react';
import { useSelector,useDispatch } from "react-redux";
import * as actionCreators from "../../redux/actions/actionCreators";
import './Landingpage.css';

import Topnavigation from '../../components/landingpage/gateway/Topnavigation';
import Mainbody from '../../components/landingpage/gateway/Mainbody';

function Landingpage() {

   const dispatch = useDispatch();




  return (
    <div className="Landing-page">
       <Topnavigation />
       <Mainbody />
    </div>
  );
}

export default Landingpage;
