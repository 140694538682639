import './Updateesources.css';
import * as actionCreators from "../../../../redux/actions/actionCreators";
import React, { useState, useRef, useEffect } from 'react';
import { useSelector,useDispatch } from "react-redux";
import { updateuserResources } from '../../../../redux/thunk/resources/fetchdata';

function Popupdate(props) {

  const myref = useRef(null);
  const dispatch = useDispatch();

  const [linkAddress,setlinkAddress] = useState("");
  const [linkName,setlinkName] = useState("");
  const [confirmflag,setConfirmflag] = useState(false);
  const [updateflag,setUpdateflag] = useState(false);

  const updateResources = useSelector((state) => state.resourcesReducer.updateResources);

  const handleClick = (event) => {
    if (myref.current && !myref.current.contains(event.target)) {
        let updateobj = {
            updateflag:false
         }
        dispatch(actionCreators.updateResources(updateobj));
      }
   };

  const handleClose = () => {
    let updateobj = {
         updateflag:false
      }
    dispatch(actionCreators.updateResources(updateobj));
  }

  const handleConfirm = async () => {

      let  str = linkAddress
      let  result = str.replace(/(^\w+:|^)\/\//, '');
      let  prorstr = "//";
      let finalurl = prorstr.concat(result);

      let link = {
         assetid:updateResources.assetid,
         asseturl:finalurl,
         assetname:linkName
       }

      const response = await updateuserResources(link);

      if(response.STATUSCODE == 0) {

           let linkobj = {
             assetid:updateResources.assetid,
             userid:updateResources.userid,
             topicid:updateResources.topicid,
             asseturl:finalurl,
             assetname:linkName
           }

            dispatch(actionCreators.updateResourcesRecord(linkobj));
      }

       let updateobj = {
          updateflag:false
        }

       dispatch(actionCreators.updateResources(updateobj));

   }

  const handleLinkAddress = (event) => {
      setUpdateflag(true);
      setlinkAddress(event.target.value);
   }

  const handleLinkName = (event) => {
      setUpdateflag(true);
      setlinkName(event.target.value);
   }

   useEffect(() => {
     document.addEventListener("mousedown", handleClick);

     return () => {
        document.removeEventListener("mousedown", handleClick, false);
      };

   }, []);

   useEffect(() => {

    if(updateResources.asseturl && updateResources.assetname) {

       let x = updateResources.asseturl.toString();

        x = x.substring(2);

        setlinkAddress(x);
        setlinkName(updateResources.assetname);
    }

   },[updateResources])

   useEffect(() => {

     if(updateflag == true) {
       if(linkAddress.length > 0 && linkName.length > 0) {
          setConfirmflag(true);
        } else {
         setConfirmflag(false);
       }
      }

   },[linkAddress,linkName])

  return (
    <div  className="Addresources-modal">

      <div className="Addresources-topspace"> </div>

       <div ref={myref} className="Addresources-modal_content">

            <div className="Addresources-header">
               <div className="Addresources-header-title">Update Resources</div>
               <div className="Addresources-close" onClick={() => handleClose()}>
                 &times;
               </div>
            </div>

             <div style={{height:20}}> </div>

       <div>

           <div className="Addresources-container-link">
             <label className="Addresources-link">Link:</label>
             <input className="Addresources-formcontrol" value={linkAddress} name="url" id="url" placeholder="eg: https://prioryou.com" name="link" onChange={(event) => handleLinkAddress(event)}  />
           </div>

           <div style={{height:20}}> </div>

           <div className="Addresources-container-name">
             <label className="Addresources-link">Name:</label>
             <input className="Addresources-formcontrol" type="text" value={linkName} name="name" onChange={(event) => handleLinkName(event)}  />
           </div>

            <div style={{height:20}}> </div>

            <div className="Addresources-container-btn">
             {confirmflag ?
              <button className="Addresources-btn"
                  onClick={() => handleConfirm()}>Confirm
              </button> :
              <button className="Addresources-btn-dis">Confirm</button> }
           </div>

        </div>

       </div>
    </div>
  )
}

export default Popupdate;
