import './Homepagemaincontainer.css';
import { Routes,Route } from "react-router-dom";
import Active from './Active';

function Homepagemaincontainer() {

 return (

   <div className="Homepagemainwrapper">
       <Routes>
          <Route path="/:topicid/*" element={<Active />}></Route>
        </Routes>
    </div>
  )
}

export default Homepagemaincontainer;
