
export const usertakeaway = async(takeawayobj) => {

  try {

    let url = '/usertakeaway';

    const requestOptions = {
         method: 'POST',
         headers: {
           'Content-Type': 'application/json',
           'Accept': 'application/json',
         }, body:JSON.stringify({
              takeawayobj:takeawayobj
          })
    };

     const response = await fetch(url,requestOptions);

     if(!response.ok) {
        throw {
          STATUSCODE:1,
          STATUS:"ERROR",
          MESSAGE:"Something broke!" }
      } else {
         const result = await response.json();
         return result;
      }

  } catch(error) {
      return error;
  }


}
