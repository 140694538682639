import { useEffect,useRef,useState,useContext} from "react";
import { useSelector,useDispatch } from "react-redux";
import { TbMinusVertical } from 'react-icons/tb';
import { RiFullscreenFill } from 'react-icons/ri';
import { BsMicMute } from 'react-icons/bs';
import { BsMic } from 'react-icons/bs';
import { BsCameraVideoOff } from 'react-icons/bs';
import { BsCameraVideo } from 'react-icons/bs';
import { BsFullscreen } from 'react-icons/bs';
import { IoHandRightOutline } from 'react-icons/io5';
import {  SocketContext  } from "../../../../context/SocketContext";
import * as actionCreators from '../../../../redux/actions/actionCreators';

import { getaudioVideoStatus } from '../../../../redux/thunk/meeting/fetchdata';
import raisehand from '../../../../assets/videocall/raisehand.png';
import './Videoroom.css';

const Videoplayer = ({str,stream,pr,dd}) => {

    const videoRef = useRef();
    const dispatch = useDispatch();

    const [cameraflag,setCameraflag] = useState(true);
    const [micflag,setMicflag] = useState(true);
    const [screenflag,setScreenflag] = useState(true);
    const [showHandflag, setShowhandflag] = useState(false);

    const [handStatus,sethandStatus] = useState(false);
    const [micstatus,setmicStatus] = useState(false);
    const [videostatus,setvideoStatus] = useState(false);
    const [name,setName] = useState("");

    const userdetails = useSelector((state) => state.userReducer.userdetails);
    const topicid = useSelector((state) => state.tstReducer.flag);

    const { socket } = useContext(SocketContext);

    const goFullscreen = (id) =>  {

       console.log("id",id);

      var element = document.getElementById(id);
      if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullScreen) {
        element.webkitRequestFullScreen();
      }
    }

    const handleraisehand = (data) => {


       if(pr == data.peerid) {

         let value = data.hand;

         if(value == "false") {
            sethandStatus(false);
         } else {
            sethandStatus(true);
         }


        }
    }

    const handlemichand = (data) => {
       if(pr == data.peerid) {
           setmicStatus(data.mute);
        }
    }

    const handlevideohand = (data) => {
      if(pr == data.peerid) {
          setvideoStatus(data.cam);
       }
    }

   useEffect(() => {
      if(videoRef.current) {
        videoRef.current.srcObject = stream;
      }
   },[stream]);

   useEffect(async () => {

     if(pr) {

       const roomDetails = {
         roomid:topicid,
         peerid:pr
       }

       const result = await getaudioVideoStatus(roomDetails)

        if(result.STATUSCODE == 0) {

           dispatch(actionCreators.addParticipant(result.PAYLOAD.userid));

           sethandStatus(result.PAYLOAD.hand);
           setmicStatus(result.PAYLOAD.audio);
           setvideoStatus(result.PAYLOAD.video);
           setName(result.PAYLOAD.name);

        }
     }

     socket.on("raisehand", handleraisehand);
     socket.on("michand", handlemichand);
     socket.on("videohand", handlevideohand);
   },[pr])


  return (
    <>
      <video className={dd} ref={videoRef} id={pr} autoPlay={true}  />

      <div className="video-details">

        <div className="video-user-hand">
        {handStatus ?
         <div className="video-user-img">
            <img className="videouser-img-hand" src={raisehand} />
             <span className="video-user-txt">{name}</span>
          </div>
        : <div className="video-user-img-off"> </div> }
        </div>

       <div className="video-user-details">

       <div className="video-user-name">

       </div>

       <div className="video-user-fullscreen " onClick={() => goFullscreen(pr)}>
          <BsFullscreen />
       </div>

       <div className="video-user-name">

       </div>

        <div className="video-user-name">
           {name}
        </div>

        <div className="video-user-line">|</div>

          {micstatus ? null :
            <div className="video-user-btn">
              <BsMicMute />
            </div> }

          {videostatus ? null :
            <div className="video-user-btn">
             <BsCameraVideoOff />
           </div> }

       </div>

      </div>

    </>

  );
}


export default Videoplayer;
