import React from 'react';
import { useEffect } from 'react';
import { Routes,Route } from "react-router-dom";

import Accountprofile from './Accountprofile';
import Details from './Details';

function Account() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
     <div>
        <Routes>
            <Route path="/" element={<Accountprofile />}></Route>
            <Route path="/details/*" element={<Details />}></Route>
        </Routes>
     </div>
  )

}

export default Account;
