
// Login api

export const loginPost = async (userEmail,userPassword) => {

     let url =  '/login';

  try {

     const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          body: JSON.stringify({
            email:userEmail,
            password:userPassword
          })
     };

      const response = await fetch(url, requestOptions);

      if(!response.ok) {
         throw {
           STATUSCODE:1,
           STATUS:"ERROR",
           MESSAGE:"Something broke!" }
       } else {
          const result = await response.json();
          return result;
       }

   } catch (error) {
       return error;
    }

}

// User Register

export const registerPost = async (userName,userEmail,userpassword,scode) => {


     let url =  '/register';

  try {

     const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          body: JSON.stringify({
            username:userName,
            email:userEmail,
            password:userpassword,
            pin:'1234',
            scode:scode
          })
     };

     const response = await fetch(url, requestOptions);

     if(!response.ok) {
        throw {
          STATUSCODE:1,
          STATUS:"ERROR",
          MESSAGE:"Something broke!" }
      } else {
         const result = await response.json();
         return result;
      }

  } catch (error) {
      return error;
   }

}

// Logout api

export const logout = async () => {

try {

   let url = '/logout';

   const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        }
   };

   const response = await fetch(url, requestOptions);

    if(!response.ok) {
       throw Error(response.statusText);
   } else {
     const result = await response.json();

      if(result.error == 1) {
         let error = {error:result.error}
         return(error);
      };

     if(result.success == 1) {
        return(result);
      }
 }


  } catch (error) {
      let err = {error:error.message}
      return err;
   }

}

// Check username exist

export const checkUserName = async(userName) => {

  try {

    let url = '/chkusername';

    const requestOptions = {
         method: 'POST',
         headers: {
           'Content-Type': 'application/json',
           'Accept': 'application/json',
         }, body:JSON.stringify({
              userName:userName
          })
    };

     const response = await fetch(url,requestOptions);

     if(!response.ok) {
        throw {
          STATUSCODE:1,
          STATUS:"ERROR",
          MESSAGE:"Something broke!" }
      } else {
         const result = await response.json();
         return result;
      }

  } catch(error) {
      return error;
  }

}

// Send user OTP

export const sendUserOtp = async(userObj) => {

  try {

    let url = '/sendopt';

    const requestOptions = {
         method: 'POST',
         headers: {
           'Content-Type': 'application/json',
           'Accept': 'application/json',
         }, body:JSON.stringify({
              userObj:userObj
          })
    };

     const response = await fetch(url,requestOptions);

     if(!response.ok) {
        throw {
          STATUSCODE:1,
          STATUS:"ERROR",
          MESSAGE:"Something broke!" }
      } else {
         const result = await response.json();
         return result;
      }

  } catch(error) {
      return error;
  }

}

// Check email exist

export const checkEmail = async(userEmail) => {

  try {

    let url = '/chkemail';

    const requestOptions = {
         method: 'POST',
         headers: {
           'Content-Type': 'application/json',
           'Accept': 'application/json',
         }, body:JSON.stringify({
              userEmail:userEmail
          })
    };

     const response = await fetch(url,requestOptions);

     if(!response.ok) {
        throw {
          STATUSCODE:1,
          STATUS:"ERROR",
          MESSAGE:"Something broke!" }
      } else {
         const result = await response.json();
         return result;
      }

  } catch(error) {
      return error;
  }

}

// Check mobile exist

export const checkMobile = async(userMobile) => {

  try {

    let url = '/chkmobile';

    const requestOptions = {
         method: 'POST',
         headers: {
           'Content-Type': 'application/json',
           'Accept': 'application/json',
         }, body:JSON.stringify({
              userMobile:userMobile
          })
    };

     const response = await fetch(url,requestOptions);

     if(!response.ok) {
        throw {
          STATUSCODE:1,
          STATUS:"ERROR",
          MESSAGE:"Something broke!" }
      } else {
         const result = await response.json();
         return result;
      }

  } catch(error) {
      return error;
  }

}

// Resend OTP

export const resendOtp =  async(userObj) => {

  try {

    let url = '/resendopt';

    const requestOptions = {
         method: 'POST',
         headers: {
           'Content-Type': 'application/json',
           'Accept': 'application/json',
         }, body:JSON.stringify({
              userObj:userObj
          })
    };

     const response = await fetch(url,requestOptions);

     if(!response.ok) {
        throw {
          STATUSCODE:1,
          STATUS:"ERROR",
          MESSAGE:"Something broke!" }
      } else {
         const result = await response.json();
         return result;
      }

  } catch(error) {
      return error;
  }

}

// Verify User Otp  userotp

export const verifyuserotp = async(verifyobj) => {

  try {

    let url = '/verifyuseropt';

    const requestOptions = {
         method: 'POST',
         headers: {
           'Content-Type': 'application/json',
           'Accept': 'application/json',
         }, body:JSON.stringify({
              verifyobj:verifyobj
          })
    };

     const response = await fetch(url,requestOptions);

     if(!response.ok) {
        throw {
          STATUSCODE:1,
          STATUS:"ERROR",
          MESSAGE:"Something broke!" }
      } else {
         const result = await response.json();
         return result;
      }

  } catch(error) {
      return error;
  }

}

// Send Email Update Otp

export const sendUserEmailOtp = async(userObj) => {

  try {

    let url = '/senduseremailotp';

    const requestOptions = {
         method: 'POST',
         headers: {
           'Content-Type': 'application/json',
           'Accept': 'application/json',
         }, body:JSON.stringify({
              userObj:userObj
          })
    };

     const response = await fetch(url,requestOptions);

     if(!response.ok) {
        throw {
          STATUSCODE:1,
          STATUS:"ERROR",
          MESSAGE:"Something broke!" }
      } else {
         const result = await response.json();
         return result;
      }

  } catch(error) {
      return error;
  }

}

// get password Info

export const getUserInfo = async(passobj) => {

  try {

    let url = '/getuserpassinfo';

    const requestOptions = {
         method: 'POST',
         headers: {
           'Content-Type': 'application/json',
           'Accept': 'application/json',
         }, body:JSON.stringify({
              passobj:passobj
          })
    };

     const response = await fetch(url,requestOptions);

     if(!response.ok) {
        throw {
          STATUSCODE:1,
          STATUS:"ERROR",
          MESSAGE:"Something broke!" }
      } else {
         const result = await response.json();
         return result;
      }

  } catch(error) {
      return error;
  }

}

// get password mob

export const getUsermobInfo = async(passobj) => {

  try {

    let url = '/getuserpassmobinfo';

    const requestOptions = {
         method: 'POST',
         headers: {
           'Content-Type': 'application/json',
           'Accept': 'application/json',
         }, body:JSON.stringify({
              passobj:passobj
          })
    };

     const response = await fetch(url,requestOptions);

     if(!response.ok) {
        throw {
          STATUSCODE:1,
          STATUS:"ERROR",
          MESSAGE:"Something broke!" }
      } else {
         const result = await response.json();
         return result;
      }

  } catch(error) {
      return error;
  }

}

export const confirmOtp = async(obj) => {

  try {

    let url = '/confirmopt';

    const requestOptions = {
         method: 'POST',
         headers: {
           'Content-Type': 'application/json',
           'Accept': 'application/json',
         }, body:JSON.stringify({
              obj:obj
          })
    };

     const response = await fetch(url,requestOptions);

     if(!response.ok) {
        throw {
          STATUSCODE:1,
          STATUS:"ERROR",
          MESSAGE:"Something broke!" }
      } else {
         const result = await response.json();
         return result;
      }

  } catch(error) {
      return error;
  }

}

export const completeprofile = async(userobj) => {

  try {

    let url = '/completeprofile';

    const requestOptions = {
         method: 'POST',
         headers: {
           'Content-Type': 'application/json',
           'Accept': 'application/json',
         }, body:JSON.stringify({
              userobj:userobj
          })
    };

     const response = await fetch(url,requestOptions);

     if(!response.ok) {
        throw {
          STATUSCODE:1,
          STATUS:"ERROR",
          MESSAGE:"Something broke!" }
      } else {
         const result = await response.json();
         return result;
      }

  } catch(error) {
      return error;
  }

}

export const updateaccstatus =  async(userid) => {

  try {

    let url = '/updateuseraccstatus';

    const requestOptions = {
         method: 'POST',
         headers: {
           'Content-Type': 'application/json',
           'Accept': 'application/json',
         }, body:JSON.stringify({
              userid:userid
          })
    };

     const response = await fetch(url,requestOptions);

     if(!response.ok) {
        throw {
          STATUSCODE:1,
          STATUS:"ERROR",
          MESSAGE:"Something broke!" }
      } else {
         const result = await response.json();
         return result;
      }

  } catch(error) {
      return error;
  }

}

export const updateUserEmailAccount = async(userEmail,userid,username,verifyId) => {

  try {

    let url = '/updateuseremailaccount';

    const requestOptions = {
         method: 'POST',
         headers: {
           'Content-Type': 'application/json',
           'Accept': 'application/json',
         }, body:JSON.stringify({
              userEmail:userEmail,
              userid:userid,
              username:username,
              verifyId:verifyId
          })
    };

     const response = await fetch(url,requestOptions);

     if(!response.ok) {
        throw {
          STATUSCODE:1,
          STATUS:"ERROR",
          MESSAGE:"Something broke!" }
      } else {
         const result = await response.json();
         return result;
      }

  } catch(error) {
      return error;
  }


}


export const userLoginInfo = async(userloginobj) => {

  try {

    let url = '/userLoginInfo';

    const requestOptions = {
         method: 'POST',
         headers: {
           'Content-Type': 'application/json',
           'Accept': 'application/json',
         }, body:JSON.stringify({
              userloginobj:userloginobj
          })
    };

     const response = await fetch(url,requestOptions);

     if(!response.ok) {
        throw {
          STATUSCODE:1,
          STATUS:"ERROR",
          MESSAGE:"Something broke!" }
      } else {
         const result = await response.json();
         return result;
      }

  } catch(error) {
      return error;
  }

}
