export const topicpostwithimage = async (postData) => {

 try {

   let url = '/topicpostwithimage';

   const requestOptions = {
        method: 'POST',
        headers: {
         'Content-Type': 'application/json',
         'Accept': 'application/json',
        },
         body:postData
    };

   const response = await fetch(url,requestOptions);

   if(!response.ok) {
      throw {
        STATUSCODE:1,
        STATUS:"ERROR",
        MESSAGE:"Something broke!" }
   }  else {
       const result = await response.json();
       return result;
    }

  } catch (error) {
      return error;
   }

}

export const getUserpost = async (postId) => {

  try {

    let url = '/getUserpost';

    const requestOptions = {
         method: 'POST',
         headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
         },
         body:JSON.stringify({
             postid:postId
          })
     };

    const response = await fetch(url,requestOptions);

    if(!response.ok) {
       throw {
         STATUSCODE:1,
         STATUS:"ERROR",
         MESSAGE:"Something broke!" }
    }  else {
        const result = await response.json();
        return result;
     }

   } catch (error) {
       return error;
    }

}

export const getUserpostLimit = async (page,limit,topicId) => {

  try {

    let url = '/getUserpostLimit';

    const requestOptions = {
         method: 'POST',
         headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
         },
         body:JSON.stringify({
             topicId:topicId,
             page:page,
             limit:limit
          })
     };

    const response = await fetch(url,requestOptions);

    if(!response.ok) {
       throw {
         STATUSCODE:1,
         STATUS:"ERROR",
         MESSAGE:"Something broke!" }
    }  else {
        const result = await response.json();
        return result;
     }

   } catch (error) {
       return error;
    }

}

export const delUserpost = async (obj) => {

  try {

    let url = '/deluserpost';

    const requestOptions = {
         method: 'POST',
         headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
         },
         body:JSON.stringify({
             obj:obj
           })
      };

    const response = await fetch(url,requestOptions);

    if(!response.ok) {
       throw {
         STATUSCODE:1,
         STATUS:"ERROR",
         MESSAGE:"Something broke!" }
    }  else {
        const result = await response.json();
        return result;
     }

   } catch (error) {
       return error;
    }

}

export const addSmartTag = async (obj)  => {

  try {

    let url = '/addsmarttag';

    const requestOptions = {
         method: 'POST',
         headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
         },
         body:JSON.stringify({
             obj:obj
           })
      };

    const response = await fetch(url,requestOptions);

    if(!response.ok) {
       throw {
         STATUSCODE:1,
         STATUS:"ERROR",
         MESSAGE:"Something broke!" }
    }  else {
        const result = await response.json();
        return result;
     }

   } catch (error) {
       return error;
    }

}

export const rmvUserSmartTag = async (obj)  => {

  try {

    let url = '/rmvusersmarttag';

    const requestOptions = {
         method: 'POST',
         headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
         },
         body:JSON.stringify({
             obj:obj
           })
      };

    const response = await fetch(url,requestOptions);

    if(!response.ok) {
       throw {
         STATUSCODE:1,
         STATUS:"ERROR",
         MESSAGE:"Something broke!" }
    }  else {
        const result = await response.json();
        return result;
     }

   } catch (error) {
       return error;
    }

}

export const getSearchResult = async (searchObj) => {

  try {

    let url = '/getsearchresult';

    const requestOptions = {
         method: 'POST',
         headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
         },
         body:JSON.stringify({
             searchObj:searchObj
           })
      };

    const response = await fetch(url,requestOptions);

    if(!response.ok) {
       throw {
         STATUSCODE:1,
         STATUS:"ERROR",
         MESSAGE:"Something broke!" }
    }  else {
        const result = await response.json();
        return result;
     }

   } catch (error) {
       return error;
    }


}

export const getSearchpostLimit = async (page,limit,topicId,postsearchterm) => {

  try {

    let searchObj = {
      page:page,
      limit:limit,
      topicid:topicId,
      searchterm:postsearchterm
    }

    let url = '/getUserSearchpostLimit';

    const requestOptions = {
         method: 'POST',
         headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
         },
         body:JSON.stringify({
              searchObj:searchObj
          })
     };

    const response = await fetch(url,requestOptions);

    if(!response.ok) {
       throw {
         STATUSCODE:1,
         STATUS:"ERROR",
         MESSAGE:"Something broke!" }
    }  else {
        const result = await response.json();
        return result;
     }

   } catch (error) {
       return error;
    }

}

export const getUserSmarttag = async (smartobj) => {

     try {

       let url = '/getusersmarttag';

       const requestOptions = {
            method: 'POST',
            headers: {
             'Content-Type': 'application/json',
             'Accept': 'application/json',
            },
            body:JSON.stringify({
                 smartobj:smartobj
             })
        };

       const response = await fetch(url,requestOptions);

       if(!response.ok) {
          throw {
            STATUSCODE:1,
            STATUS:"ERROR",
            MESSAGE:"Something broke!" }
       }  else {
           const result = await response.json();
           return result;
        }

     } catch (error) {

     }

}

export const getPostCount = async(topicid) => {

  try {

    let url = '/getpostcount';

    const requestOptions = {
         method: 'POST',
         headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
         },
         body:JSON.stringify({
              topicid:topicid
          })
     };

    const response = await fetch(url,requestOptions);

    if(!response.ok) {
       throw {
         STATUSCODE:1,
         STATUS:"ERROR",
         MESSAGE:"Something broke!" }
    }  else {
        const result = await response.json();
        return result;
     }

  } catch (error) {

  }


}


export const insertUserpost = async (objpost) => {

  try {

    let url = '/userpost';

    const requestOptions = {
         method: 'POST',
         headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
         },
         body:JSON.stringify({
             objpost:objpost
          })
     };

    const response = await fetch(url,requestOptions);

    if(!response.ok) {
       throw {
         STATUSCODE:1,
         STATUS:"ERROR",
         MESSAGE:"Something broke!" }
    }  else {
        const result = await response.json();
        return result;
     }

   } catch (error) {
       return error;
    }

}
