import React, { useEffect,useState } from 'react';
import prioryoulogoprint from '../../assets/logo/prioryoulogoprint_dark.png';
import './Download.css';
import { useSelector,useDispatch } from "react-redux";
import { PiAndroidLogoDuotone } from "react-icons/pi";
import { userappdownload } from '../../redux/thunk/user/fetchdata';
import arrowdown from '../../assets/icons/arrowdown.svg';

function Download() {

  const usersinfodata = useSelector((state) => state.usersinfodataReducer.usersinfodata);

  const handleDownload = async () => {

    let obj = {
       time:usersinfodata.time,
       date:usersinfodata.date,
       gmt:usersinfodata.gmt,
       timezone:usersinfodata.timezone,
       localip:usersinfodata.localip,
       externalip:usersinfodata.externalip,
       browser:usersinfodata.browser,
       deviceinfo:usersinfodata.deviceinfo,
       os:usersinfodata.os,
     }


    const response = await userappdownload(obj);


  }


  return (
     <div className="Download-wrapper">

      <div style={{height:"20px"}}> </div>
         <div><img src={prioryoulogoprint} /></div>
      <div style={{height:"20px"}}> </div>

       <div style={{height:50}} />
       <div className="Download-title">Download App</div>
        <div style={{height:20}} />
        <img className="arrow" src={arrowdown} />
           <div style={{height:20}} />
       <a className="Mainbody-android-link" href='/prioryou.apk' onClick={() => handleDownload()}>
        <div className="Mainbody-android">
          <PiAndroidLogoDuotone size={50} />
          <div>Download</div>
           <div style={{height:5}}></div>
           <div>Android App</div>
        </div>
       </a>
     </div>
  );
}

export default Download;
