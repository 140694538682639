import * as actionTypes from "../actions/actionTypes";

const initialState = {
    comment:[]
};

const commentReducer = (state = initialState, action) => {

  switch (action.type) {

    case actionTypes.ADD_COMMENT:

      return {
        ...state,
        comment: [...state.comment,...action.payload]
      };

    case actionTypes.ADD_POST_COMMENT:

     return {
       ...state,
      comment: [...action.payload,...state.comment]
    };

    case actionTypes.DELETE_COMMENT:

      const delnewArray = [...state.comment];
      let delindex = delnewArray.findIndex(item => item.comment_id == action.payload);

      delnewArray.splice(delindex, 1);

      return {
          ...state,
          comment:delnewArray,
      }

    case actionTypes.LIKE_COMMENT:

      const newlikeArray = [...state.comment];

      let likeindex = newlikeArray.findIndex(item => item.comment_id == action.payload);

      let commentlikecount = newlikeArray[likeindex].commentlikecount + 1;

      newlikeArray[likeindex].likeFlag = true;
      newlikeArray[likeindex].commentlikecount = commentlikecount;

      return {
        ...state,
        comment:newlikeArray,
      };

    case actionTypes.UNLIKE_COMMENT:

        const newunlikeArray = [...state.comment];

        let unlikeindex = newunlikeArray.findIndex(item => item.comment_id == action.payload);

        let commentunlikecount = newunlikeArray[unlikeindex].commentlikecount - 1;

        newunlikeArray[unlikeindex].likeFlag = false;
        newunlikeArray[unlikeindex].commentlikecount = commentunlikecount;

        return {
          ...state,
          comment:newunlikeArray,
        };

    case actionTypes.EDIT_SAVE_COMMENT:

      const editsaveArray = [...state.comment]; //making a new array

      let editindex = editsaveArray.findIndex(item => item.comment_id == action.payload.comment_id);

      editsaveArray[editindex].comment_text = action.payload.userComment;

      editsaveArray[editindex].commentisedited = "1";

       return {
           ...state,
           comment:editsaveArray
        }

    case actionTypes.RESET_COMMENT:

      return {
         comment:[]
      }

    case actionTypes.ADD_COUNT_COMMENT:

    const addcountArray = [...state.comment];

     let addindex = addcountArray.findIndex(item => item.comment_id == action.payload);

     addcountArray[addindex].replycount  =  1;

    return {
      ...state,
      comment:addcountArray
    };



    {/*
    case actionTypes.ADD_REPLY_COMMENT:

      let temprply = [];
      temprply.push(action.payload);

      let temp_reply = [...state.comment];

      let filter_temp_reply = temp_reply.filter(function(item) {
         return item.comment_id == action.payload[0].commentreply_id;
       });


       let temp_reply_index = temp_reply.findIndex(item => item.comment_id == action.payload[0].commentreply_id);

        temp_reply[temp_reply_index].reply = temprply;

        return {
           ...state,
        comment:temp_reply
      };
    */}

    default:
      return state;
  }
};

export default commentReducer;
