import React, { useState,useEffect,useRef } from 'react';
import './Pbsuccess.css';
import thumb from '../../../../assets/dashboard/thumb.png';

function Pbsuccess(props) {

  const myref = useRef(null);

  const handleDone = () => {
    props.done();
  }

  const handleClick = (event) => {

    if (myref.current && !myref.current.contains(event.target)) {
        props.done();
      }
   };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
       document.removeEventListener("mousedown", handleClick, false);
     };
  },[])

  return (
    <div onClick={() => handleDone()} className="Pbsuccess-container">
      <div ref={myref} onClick={() => handleDone()} className="Pbsuccess-wrapper">
         <div className="Pbsuccess-imgwrapper"><img className="Pbsuccess-img" src={thumb} /></div>
         <div><button className="Pbsuccess-btn" onClick={() => handleDone()}>Published</button></div>
      </div>
    </div>
  );
}

export default Pbsuccess;
