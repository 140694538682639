import React from 'react';
import { useState,useEffect } from 'react';
import { useSelector,useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import * as actionCreators from '../../../../redux/actions/actionCreators';
import './Poolnotifydetaildesc.css';
import defaultProfile from '../../../../assets/default/user.png';
import Poolpostbox from '../Pooldetail/Poolpostbox';
import Poolcommentlist from './Poolnotifycommentlist';
import { IoMdShareAlt } from 'react-icons/io';
import Takeaway from '../../homepage-maincontainer/Takeaway/takeaway';


function Poolnotifydetaildesc(props) {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userdetails = useSelector((state) => state.userReducer.userdetails);
  const topicDetails = useSelector((state) => state.poolDetailsReducer.topicDetails);
  const notify = useSelector((state) => state.notifyReducer.notify_id);

  const handleShare = () => {
    dispatch(actionCreators.showShareBox(true));
  }

  const handleAccount = (userid) => {

     if(userdetails.userid) {
         navigate(`/homepage/account/${userid}`)
      } else {
         navigate(`/account/${userid}`)
      }

  }


return (

<div className="Poolnotifydetaildesc-container">

   <div className="Poolnotifydetaildesc-wrapper">

     <div className="Poolnotifydetaildesc-profile" onClick={() => handleAccount(topicDetails.userid)}>
         <div className="Poolnotifydetaildesc-profileimg"
           style={{
              backgroundImage:topicDetails?.profile_image ? `url("${topicDetails.profile_image}")` : `url("${defaultProfile}")`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              maxWidth:'100%',
              maxHeight:'100%'
            }} />

         <div className="Poolnotifydetaildesc-profile-empty">  </div>
         <div  className="Poolnotifydetaildesc-profile-name">
          {topicDetails?.username}
        </div>
     </div>

    <div className="Poolnotifydetaildesc-empty"> </div>

     <div className="Poolnotifydetaildesc-desctable">

        <div className="Poolnotifydetaildesc-topicdetails">
          <div className="Poolnotifydetaildesc-topic">Topic</div>
          <div className="Poolnotifydetaildesc-topicname">{topicDetails?.topic}</div>
        </div>

        <div className="Poolnotifydetaildesc-topicdetails">
          <div className="Poolnotifydetaildesc-topic">With</div>
          <div className="Poolnotifydetaildesc-topicname">{topicDetails?.yourname}</div>
        </div>

        <div className="Poolnotifydetaildesc-topicdetails">
          <div className="Poolnotifydetaildesc-topic">Want</div>
          <div className="Poolnotifydetaildesc-topicname">{topicDetails?.noofpartner} learning partner</div>
        </div>

        <div className="Poolnotifydetaildesc-topicdetails">
          <div className="Poolnotifydetaildesc-topic">For</div>
          <div className="Poolnotifydetaildesc-topicname">{topicDetails?.noofdays} days</div>
        </div>

        <div className="Poolnotifydetaildesc-topicdetails">
          <div className="Poolnotifydetaildesc-topic">Start from</div>
          <div className="Poolnotifydetaildesc-topicname">{topicDetails?.startDate} @ {topicDetails?.startTime}</div>
        </div>

        <div className="Poolnotifydetaildesc-topicdetails">
          <div className="Poolnotifydetaildesc-topic">Timezone</div>
          <div className="Poolnotifydetaildesc-topicname">{topicDetails?.timezone}</div>
        </div>

     </div>

     <div style={{height:10}}> </div>

     {/*
      <div className="Pooldetaildesc-share" onClick={() => handleShare()}>
        <div className="Pooldetaildesc-btn">
          <div className="Pooldetail-shareicon"><IoMdShareAlt/></div>
          <div className="Pooldetail-sharetxt">
             share
          </div>
        </div>
      </div>
      */}

    <div style={{height:10}}> </div>

     <div className="Poolnotifydetaildesc-comment">
        <b>Description:</b>
     </div>

     <div className="Poolnotifydetaildesc-empty"> </div>

     <div className="Poolnotifydetaildesc-desc">
        {topicDetails?.description}
     </div>

     <div className="Poolnotifydetaildesc-empty"> </div>

     <div className="Poolnotifydetaildesc-comment">
        <b>{notify.notify_text}</b>
     </div>

    {/*
     <div className="Poolnotifydetaildesc-empty"> </div>
    */}

     <div className="Poolnotifydetaildesc-pstbox">
         {/*
        <Poolpostbox superuser={topicDetails?.userid} topicId={topicDetails?.topicId} />

          */}

        <div className="Poolnotifydetaildesc-empty"> </div>
        <Poolcommentlist  />
    </div>

   </div>


</div>

  )
}

export default Poolnotifydetaildesc;
