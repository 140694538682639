import './Settings.css';
import React, { useEffect } from 'react';
import { Routes,Route,useNavigate,NavLink } from "react-router-dom";
import { BsArrowLeft } from 'react-icons/bs';
import { BsArrowLeftShort } from 'react-icons/bs';
import { BiKey } from 'react-icons/bi';
import { SlBookOpen } from 'react-icons/sl';
import { SlBan } from 'react-icons/sl';
import { SlKey } from  'react-icons/sl';
import { SlEnvelopeOpen } from  'react-icons/sl';
import { SlPhone } from 'react-icons/sl';
import { SlUser } from 'react-icons/sl';
import { MdPassword } from 'react-icons/md';
import { SiMonkeytie } from 'react-icons/si';

import Deactivateaccount from './Deactivateaccount';
import Changepassword from './Changepassword';
import Accountdetails from './Accountdetails';
import Email from './Email';
import Mobile from './Mobile';
import Username from './Username';
import Changepin from './Changepin';
import Secuirtycode from './Secuirtycode';

function Settings() {

  const navigate = useNavigate();

  const handleBackarrow = () => {
     navigate('/homepage')
  }


  useEffect(() => {
   window.scrollTo(0, 0);
  },[]);


  return (
     <div className="settingwrapper">

        <div className="settingspace"> </div>

        <div className="settingcontainer">

            <div className="Profiletitle">
              <div className="Profiletitlearrow" onClick={() => handleBackarrow()}>
                <BsArrowLeftShort/>
               </div>
              <div style={{width:10}}> </div>
              <div className="Profiletitletext">Settings</div>
            </div>

              <h1>Account Details</h1>

              <div style={{height:20}}></div>


            <div className="settingpanel">

              <NavLink className="settingitem" to="username">
                  <div className="settinglink"><SlUser /></div>
                  <div>Username</div>
                  <div className="settinglinkspace"> </div>
              </NavLink>

              <div style={{height:10}}></div>

              <NavLink className="settingitem" to="email">
                 <div className="settinglink"><SlEnvelopeOpen /></div>
                 <div>Email Update</div>
                 <div className="settinglinkspace"> </div>
              </NavLink>

              <div style={{height:10}}></div>

           {/*
              <NavLink className="settingitem" to="mobile">
                 <div className="settinglink"><SlPhone /></div>
                 <div>Mobile Update</div>
                 <div className="settinglinkspace"> </div>
              </NavLink>
           */}

              <div style={{height:10}}></div>

              <NavLink className="settingitem" to="changepassword">
                 <div className="settinglink"><SlKey /></div>
                 <div className="settinglink">Change Password</div>
                 <div className="settinglinkspace"> </div>
              </NavLink>

              <div style={{height:10}}></div>

          {/*
              <NavLink className="settingitem" to="changepin">
                 <div className="settinglink"><MdPassword /></div>
                 <div className="settinglink">Change Pin</div>
                 <div className="settinglinkspace"> </div>
              </NavLink>

                <div style={{height:10}}></div>
        */}  

                <NavLink className="settingitem" to="secuirtycode">
                   <div className="settinglink"><SiMonkeytie /></div>
                   <div className="settinglink">Secuirty Code</div>
                   <div className="settinglinkspace"> </div>
                </NavLink>

              <div style={{height:10}}></div>

              <NavLink className="settingitem" to="deactivateaccount">
                 <div className="settinglink"><SlBan /></div>
                 <div className="settinglink">Deactivate account</div>
                 <div className="settinglinkspace"> </div>
              </NavLink>

              <div style={{height:10}}></div>
              <div style={{height:10}}></div>

            </div>

             <div>
                <Routes>
                   <Route path="/username" element={<Username />}></Route>
                   <Route path="/Secuirtycode" element={<Secuirtycode />}> </Route>
                   <Route path="/deactivateaccount/*" element={<Deactivateaccount />}> </Route>
                   <Route path="/changepassword" element={<Changepassword />}> </Route>
                   <Route path="/Changepin" element={<Changepin />}> </Route>
                   <Route path="/accountdetails" element={<Accountdetails />}> </Route>
                   <Route path="/email" element={<Email />}> </Route>
                   <Route path="/mobile" element={<Mobile />}> </Route>
                </Routes>
             </div>

        </div>

     </div>
  )
}

export default Settings;
