import React from 'react';
import { useState,useEffect } from 'react';
import { useNavigate,Routes,Route,NavLink } from "react-router-dom";
import { BsArrowLeftShort } from 'react-icons/bs';

import './Details.css';

import Followers from './Followers';
import Following from './Following';

function Details(props) {

  const navigate = useNavigate();

  const handleBack = () => {
    {/*
    navigate(`/homepage/account/${props.userId}`)
    */}

    navigate(-1);
  }


  return (
     <div className="Detailswrapper">

      <div className="Detailstitle">
         <div className="Detailstitlearrow" onClick={handleBack} >
           <BsArrowLeftShort/>
         </div>

         <div>&nbsp;&nbsp;</div>

          <div className="Detailstitletext">
             {props.username}
          </div>

      </div>

     <div className="Detailslink">
       <NavLink className={(navData) => (navData.isActive ? 'Detailsnavitemactive' : 'Detailsnavitem')} to="follower" end>Followers</NavLink>
       <div className="Detailsempspace"> </div>
       <NavLink className={(navData) => (navData.isActive ? 'Detailsnavitemactive' : 'Detailsnavitem')} to="following" end>Following</NavLink>
    </div>

     <div className="Detailsroute">
        <Routes>
           <Route path="follower" element={<Followers clearAccountdetails={props.clearAccountdetails} />}></Route>
           <Route path="following" element={<Following clearAccountdetails={props.clearAccountdetails} />}></Route>
       </Routes>
    </div>


     </div>
  );
}

export default Details;
