import React, { useState,useEffect,useRef } from 'react';
import { MdOutlineCancel } from 'react-icons/md';
import './Sterotype.css';
import Details from './Details';
import prioryou from  '../../../assets/logo/prioryou.jpg';
import one from  '../../../assets/sterotype/1.png';
import two from  '../../../assets/sterotype/2.png';
import three from  '../../../assets/sterotype/3.png';
import four from  '../../../assets/sterotype/4.png';
import five from  '../../../assets/sterotype/5.png';
import six from  '../../../assets/sterotype/6.png';

function Sterotype() {

  const [steroflag,setSteroflag] = useState("Blank");
  const [nextflag,setNextflag] = useState(false);

  const handleGetStarted = () => {
     setNextflag(true);
  }

  const handleSterotype = (value) => {
      console.log("value",value)
      setSteroflag(value);
  }

  const handleClear = () => {
     setSteroflag("Blank");
  }

  const handleBack = () => {
     setNextflag(false);
  }

  useEffect(() => {
    console.log("steroflag",steroflag);
  },[steroflag])

  return (
      <div className="Sterotype-wrapper">

        {nextflag ?

          <Details handleBack={() => handleBack()} steroType={steroflag} /> :

         <div className="Sterotype-container">

            <div className="Sterotype-title">
              Choice Your Sterotype
            </div>

            <div className="Sterotype-inner-wrapper">

         <div className="Sterotype-card-wrapper" onClick={() => handleSterotype("Casual")}>
            <div className={steroflag == "Casual" ? "Sterotype-card-sel" : "Sterotype-card"}>
              <div className="Sterotype-img">
                 <img className="Sterotype-imgsrc" src={one} />
                </div>
               <div>Casual</div>
            </div>
         </div>

         <div className="Sterotype-card-wrapper" onClick={() => handleSterotype("Geek")}>
            <div className={steroflag == "Geek" ? "Sterotype-card-sel" : "Sterotype-card"}>
              <div className="Sterotype-img">
                 <img className="Sterotype-imgsrc" src={two} />
                </div>
               <div>Geek</div>
            </div>
         </div>

         <div className="Sterotype-card-wrapper" onClick={() => handleSterotype("Genius")}>
            <div className={steroflag == "Genius" ? "Sterotype-card-sel" : "Sterotype-card"}>
              <div className="Sterotype-img">
                 <img className="Sterotype-imgsrc" src={three} />
                </div>
               <div>Genius</div>
            </div>
         </div>

         <div className="Sterotype-card-wrapper" onClick={() => handleSterotype("Smart")}>
            <div className={steroflag == "Smart" ? "Sterotype-card-sel" : "Sterotype-card"}>
              <div className="Sterotype-img">
                 <img className="Sterotype-imgsrc" src={four} />
                </div>
               <div>Smart</div>
            </div>
         </div>

         <div className="Sterotype-card-wrapper" onClick={() => handleSterotype("Lazy")}>
            <div className={steroflag == "Lazy" ? "Sterotype-card-sel" : "Sterotype-card"}>
              <div className="Sterotype-img">
                 <img className="Sterotype-imgsrc" src={five} />
                </div>
               <div>Lazy</div>
            </div>
         </div>

         <div className="Sterotype-card-wrapper" onClick={() => handleSterotype("Nerd")}>
            <div className={steroflag == "Nerd" ? "Sterotype-card-sel" : "Sterotype-card"}>
              <div className="Sterotype-img">
                 <img className="Sterotype-imgsrc" src={six} />
                </div>
               <div>Nerd</div>
            </div>
         </div>

            </div>


           <div className="Sterotype-choice" >
               <div>{steroflag == "Blank" ? "Select" : steroflag}</div>
               <div style={{height:8}}> </div>
              {steroflag != "Blank" ?
               <div className="Sterotype-clear" onClick={() => handleClear()}>
                  <MdOutlineCancel />
                </div>  :
                <div className="Sterotype-clear-dis">
                    <MdOutlineCancel />
                 </div>
              }
            </div>


            <div className="Welcome-container-footer">
               <div className="Welcome-container-getstarted" onClick={() => handleGetStarted()}>
                  Next
                </div>
            </div>


         </div> }

      </div>
  );
}

export default Sterotype;
