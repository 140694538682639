import './Tm.css';
import React from 'react';

import { useState, useEffect } from 'react';
import { useParams,useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from "react-redux";

import { HiHashtag } from 'react-icons/hi';
import { BsArrowLeftShort } from 'react-icons/bs';
import { BsArrowRightShort } from 'react-icons/bs';

import { editTimeline,saveTimeline } from '../../../../redux/actions/actionCreators';
import { getTimelineDetails,updateTimeline } from '../../../../redux/thunk/active/fetchdata';



function Timeline(props) {

 const params = useParams();
 const dispatch = useDispatch();
 const navigate = useNavigate();

 const userdetails = useSelector((state) => state.userReducer.userdetails);
 const publish = useSelector((state) => state.publish);

 const [timeline,setTimeline] = useState([]);

 const [timelinetitle,setTimelinetitle] = useState([]);
 const [timelinedesc,setTimelinedesc] = useState([]);

 const [templine,setTempline] = useState("");
 const [editarray,setEditarray] = useState("");
 const [show,setShow] = useState([]);
 const [editflag,setEditflag] = useState(false);

 const noPaste = (event) => {

  var ediv = document.getElementById(event.target.id);

   setTimeout(function() {

      var str1 = ediv.innerText.replace(/\n/g,"");
      var str2 = ediv.innerHTML.replace(/<br>/g,"");

       if (str1 != str2) {
           ediv.innerText = ediv.innerText;
        }

   }, 1);


  if(event.dataTransfer !== undefined ) {

    if(event.dataTransfer.dropEffect === "copy") {
        event.preventDefault();
     }
  }

}

 const handleTitleChange = (event,index) => {
    timelinetitle[index] = event.target.innerHTML;
 }

 const handleDescChange = (event,index) => {
    timelinedesc[index] = event.target.innerHTML;
 }

 const handleEdit = (event,index) => {

   event.preventDefault();
   event.stopPropagation();

   setEditarray([...editarray,index])

   if(show.includes(index)) {

   } else {
       let temp = [];
       temp.push(index);
       setShow([...temp,...show]);
   }

}

 const handleSave = (event,id) => {

   event.preventDefault();
   event.stopPropagation();

   let title = timelinetitle[id] ? timelinetitle[id] : "";
   let desc =  timelinedesc[id] ? timelinedesc[id] : "";

     let payload = {
        id:id,
        title:title,
        description:desc
    }

    dispatch(editTimeline(payload));

    const ind = editarray.indexOf(id);
    const neweditArray = [...editarray];
    neweditArray.splice(ind, 1);
    setEditarray([...neweditArray]);

}

 const handleExpand = (index) => {

  if(!editarray.includes(index)) {

   if(show.includes(index)) {

     const tempindex = show.indexOf(index);

     let temparray = [...show];

      if (tempindex > -1) {
        temparray.splice(tempindex, 1);
      }

      setShow([...temparray]);

   } else {
       let temp = [];
       temp.push(index);
       setShow([...temp,...show]);
   }

  }

}

 const handleBack = () => {
  navigate(-1);
}

 const handleNext = () => {
  props.handlePublish();
}

 useEffect (async() => {

     if(params.topicid) {
       const response = await getTimelineDetails(params.topicid);

       setTimeline(response.PAYLOAD);
       setTempline(response.PAYLOAD);
     }

     window.scrollTo(0, 0);
     document.addEventListener("paste", noPaste);
     document.addEventListener("drop", noPaste);
   return () => {
       document.removeEventListener("paste",noPaste);
       document.removeEventListener("drop",noPaste);
     };
  }, [])

 return (
   <div className="tm-pb-container">
   <div className="tm-pb-wrapper">

   <div>&nbsp;</div>

   <div className="tmp-pooldetails-arrow">

     <div className="tmp-pooldetails-arrowwrapper">
       <div className="tmp-pooldetails-titlearrow" onClick={() => handleBack()}>
         <BsArrowLeftShort/>
       </div>
     <div className="tmp-pooldetails-skip"></div>
     </div>


    <div className="tmp-list-title">Timeline for {props.topic}</div>


     <div className="tmp-pooldetails-arrowwrapper">
     <div className="tmp-pooldetails-titlearrow" onClick={() => handleNext()}>
        <BsArrowRightShort/>
       </div>
     <div className="tmp-pooldetails-skip"></div>
     </div>

   </div>

    <div>&nbsp;</div>
    <div>&nbsp;</div>

    <div className="tm-outerwrapper">

      <div className="tm-outercontainer">

       {publish.timeline && publish.timeline.map((element,index) => {

          let tmstyle;

           tmstyle = "tm-collapsible";

           let value = publish.timeline.length - 1;

           if(value == index && !show.includes(index)) {
              tmstyle = "tm-collapsible-dis";
           }

        return (

        <div className="tm-listitem" key={index} onClick={editflag ? null : () => handleExpand(index)}>

           <div className={tmstyle} >

              <div className="tm-topicday">{index}</div>

              <div className="tm-topicdate">
                <div>{element.date}</div>

                   {editarray.includes(index)
                      ?<><button className="tm-edit-btn" onClick={(event) => handleSave(event,index)}>save</button></>:
                       <><button className="tm-edit-btn" onClick={(event) => handleEdit(event,index)}>edit</button></>
                    }

              </div>



              <div id={`titleId-${index}`} className={
                   editarray.includes(index) ? "tmp-title" : "tmp-title-edit"}
                   contentEditable={editarray.includes(index) ?
                   true : false} data-placeholder="Title"
                   onInput={(event) => handleTitleChange(event,index)}
                   dangerouslySetInnerHTML={{__html: element.title }} >
               </div>

           </div>

           <div className={show.includes(index) ? "tm-content-max":"tm-content"}>

             <div id={`descId-${index}`} className={
                editarray.includes(index) ? "tmp-desc" : "tmp-desc-edit"}
                contentEditable={editarray.includes(index) ?
                true : false} data-placeholder ="Description"
                onInput={(event) => handleDescChange(event,index)}
                dangerouslySetInnerHTML={{__html: element.description }} >
              </div>

              <div style={{height:20}}> </div>

           </div>

        </div>

      );

       })}

      </div>

    </div>

   </div>
   </div>
  )

}

export default Timeline;
