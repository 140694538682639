import React from 'react';
import { useState, useEffect,useRef,useCallback } from 'react';
import { useSelector,useDispatch } from "react-redux";
import { useNavigate, useLocation,useParams } from "react-router-dom";
import { BsHandThumbsUp } from 'react-icons/bs';
import { BsHandThumbsUpFill } from 'react-icons/bs';
import { HiOutlineDotsHorizontal } from 'react-icons/hi';
import { IoIosArrowUp,IoIosArrowDown } from 'react-icons/io';

import { getUserComment,addLikeComment,undoLikeComment } from '../../../../redux/thunk/comment/fetchdata';
import * as actionCreators from "../../../../redux/actions/actionCreators";
import defaultProfile from '../../../../assets/default/user.png';

import Poolcommentbox from './Poolcommentbox';
import Poolreplylist from './Poolreplylist';
import PoolEditDelete from './PoolEditDelete';
import Pooleditcomment from './Pooleditcomment';
import Pooldelete from './Pooldelete';
import Poolreport from './Poolreport';

import Successnotify from '../../../../notify/Successnotify';
import Classicspiner from '../../../../spinner/classic-spiner/Classicspiner';

import './Poolcommentlist.css';

function Poolcommentlist() {

  const authUser = useSelector((state) => state.authReducer.authflag);
  const topicDetails = useSelector((state) => state.poolDetailsReducer.topicDetails);
  const userdetails = useSelector((state) => state.userReducer.userdetails);
  const commentList = useSelector((state) => state.commentReducer.comment);
  const notify = useSelector((state) => state.notifyReducer.notify_id);

  const navigate = useNavigate();
  const currentLocation = useLocation();
  const params = useParams();
  const dispatch = useDispatch();
  const observer = useRef();

  const [replyIndex,setreplyIndex] = useState("");
  const [editId,seteditId] = useState("");
  const [editcmtId,seteditcmtId] = useState("");
  const [reportId,setreportId] = useState(false);
  const [editdelId,seteditdelId] = useState(false);
  const [editIndex,seteditIndex] = useState("");
  const [commentid,setCommentid] = useState("");
  const [loginUser,setLoginUser] = useState("");

  const [flag,setFlag] = useState(false);
  const [replyarr,setReplyarr] = useState([]);
  const [notifyMsg,setNotifyMsg] = useState("");
  const [displayflashmsg,setDisplayflashmsg] = useState(false);
  const [loadingcomm,setLoadingcomm] = useState(true);

  const [page,setPage] = useState(1);
  const [hasMore,setHasMore] = useState(true);
  const [temp,setTemp] = useState();

  const lastMsgElementRef = useCallback(node => {

    if (observer.current) observer.current.disconnect()
       observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasMore) {
          setPage(temp);
       }
    })

      if(node) {
          observer.current.observe(node)
       }
  })

  const handleReply = (index) => {

    if(authUser) {
       setreplyIndex(index)
    } else {
       navigate("/signin", { state: { path: currentLocation.pathname}})
    }

  }

  const handleCancelReply = (name) => {
    setreplyIndex("");
  }

  const cancelEdit = () => {
    seteditcmtId("");
  }

  const cancelEditPanel = () => {
     seteditId("");
  }

  const handLike = async(comment_id,flag) => {

     if(authUser) {
       } else {
         navigate("/signin", { state: { path: currentLocation.pathname}});
         return;
      }

     if(flag == true) {

        const response = await undoLikeComment(comment_id);

         if(response.STATUSCODE == 0) {
              dispatch(actionCreators.unlikeComment(comment_id));
          }

      }

     if(flag == false) {

       const response = await addLikeComment(comment_id);

        if(response.STATUSCODE == 0) {
           dispatch(actionCreators.likeComment(comment_id));
          }

      }

  }

  const handleEdit = (index) => {
     seteditId(index);
  }

  const handleEditCmt = (id,comment_id) => {
    seteditId("");
    seteditcmtId(id);
  }

  const handleDelCmt = (id,comment_id,loginUser) => {
    setCommentid(comment_id);
    setLoginUser(loginUser);
    seteditId("");
    seteditdelId(true)
    seteditIndex(id);
  }

  const handlecancelDelete = (id) => {
      seteditdelId(false);
  }

  const handleShowReply = (index,comment_id) => {
     setReplyarr([...replyarr,index]);
  }

  const handleShowReplycom = (index,comment_id) => {

     if(replyarr.includes(index)) {
         setReplyarr([...replyarr]);
     } else {
         setReplyarr([...replyarr,index]);
    }

  }

  const handleHideReply = (index,comment_id) => {

    dispatch(actionCreators.rmvReplyCommentId(comment_id));

    const ind = replyarr.indexOf(index);
    const newrplArray = [...replyarr];
    newrplArray.splice(ind, 1);
    setReplyarr([...newrplArray]);

  }

  const handleReport = (comment_id,loginUser) => {
      setLoginUser(loginUser);
      setCommentid(comment_id);
      setreportId(true);
      seteditId("");
  }

  const handlecancelReport = () => {
    setreportId(false);
  }

  const handleNotifymsg = (msg) => {
    setNotifyMsg(msg);
    setDisplayflashmsg(true);
  }

  const closeFlashMsg = () => {
    setDisplayflashmsg(false);
  }

  const handleAccount = (userid) => {
    if(userdetails.userid) {
        navigate(`/homepage/account/${userid}`)
     } else {
        navigate(`/account/${userid}`)
     }
  }

  useEffect(() => {
    dispatch(actionCreators.resetComment());
    dispatch(actionCreators.resetReply());
  },[])

  useEffect(async() => {

  if(topicDetails?.topicId) {

    let lim = 5;

    let obj = {
      topicId:topicDetails.topicId,
      userid:userdetails.userid,
      page:page,
      limit:lim
    }

    const result = await getUserComment(obj);

     if(result) {

       let ft = result[0];

       if(ft.next == undefined) {
          dispatch(actionCreators.addComment(result.slice(1)));
          setHasMore(false);
        } else {
          dispatch(actionCreators.addComment(result.slice(1)));
          setTemp(ft.next.page)
       }

    }

  }

  }, [topicDetails,page]);


  return (

   <div>

   {commentList && commentList.map((element,index) => {

    return (

     <div className="Poolcommentlist-wrapper" ref={lastMsgElementRef} key={element.comment_id}>

          <div className="Poolcommentlist-details">

            <div className="Poolcommentlist-profile-wrapper" onClick={() => handleAccount(element.userid)}>
               <div className="Poolcommentlist-profile-img" style={{
                  backgroundImage:element.profile_image ? `url("${element.profile_image}")` : `url("${defaultProfile}")`,
                  backgroundPosition:'center',
                  backgroundSize:'cover',
                  backgroundRepeat:'no-repeat',
                  maxWidth:'100%',
                  maxHeight:'100%'
                }}>
               </div>
            </div>

            <div className="Poolcommentlist-emptyspace"> </div>

             <div className="Poolcommentlist-cmt">
                <div className="Poolcommentlist-cmt-name">
                  <b className="Poolcommentlist-cmt-act" onClick={() => handleAccount(element.userid)}>
                      {element.username}
                   </b>
                  <p className="Poolcommentlist-cmt-time">&nbsp;&nbsp;
                   <small>{element.currentTime}&nbsp;&nbsp;{element.commentisedited ? "(edited)" : null}</small></p>
                </div>
                <div className="Poolcommentlist-emptycml"> </div>

               {editcmtId === index ?  null :
                  <div id={`usrCmt-${index}`} className="Poolcommentlist-cmt-comment"
                  dangerouslySetInnerHTML={{__html: `${element.comment_text}` }} / >
                }

                { editcmtId === index ?
                   <Pooleditcomment userComment={element.comment_text}
                     cancelEdit={cancelEdit}
                     editcmtId={`main-${index}`}
                     mainIndex={index} typeCmt="maincomment"
                     comment_id={element.comment_id}
                     topicId={element.topicId}
                     userid={element.userid}
                   />
                  : null
                }


             </div>

          </div>

      {editcmtId === index ?  null :
         <div className="Poolcommentlist-panel">
            <div className="Poolcommentlist-profile-ept"></div>
            <div className="Poolcommentlist-emptyspace"></div>

            <div className="Poolcommentlist-panel-btn">

              <div className="Pooldetails-up" onClick={() => handLike(element.comment_id,element.likeFlag)}>
                {element.likeFlag ?
                    <span className="Pooldetails-tflag">
                      <BsHandThumbsUpFill />
                    </span> :
                    <span className="Pooldetails-fflag">
                      <BsHandThumbsUp />
                   </span>
                }
              </div>


                <div>
                  {element.commentlikecount === 0 ?
                     <span> </span> :
                      <span>
                       {element.commentlikecount}
                     </span>
                   }
                </div>

              <div className="Poolcommentlist-emptyspace"> </div>
              <div className="Poolcommentlist-emptyspace"> </div>
              <div className="Poolcommentlist-edit" onClick={() => handleReply(index)}>Reply</div>
              <div className="Poolcommentlist-profile-ept"></div>

            {element.loginUser ?
              <div className="Poolcommentlist-edit"
                onClick={() =>  handleEdit(index)}>
                 <HiOutlineDotsHorizontal />

                 {editId === index ?
                        <div>
                          <PoolEditDelete cancelEditPanel={cancelEditPanel}
                            handleEditCmtlst={handleEditCmt}
                            handleDelCmtlst={handleDelCmt}
                            handleReport={handleReport}
                            userid={element.userid}
                            superuser={element.superuser}
                            cmtListid={index}
                            comment_id={element.comment_id}
                            loginUser={element.loginUser}
                            topicId={element.topicId}
                          />
                        </div>
                      : null
                   }

              </div> : null }

            </div>

         </div>
      }


         <div className="Poolcommentlist-emptyhg"></div>

         {replyIndex === index ?
             <div className="Poolcommentlist-reply">
               <div className="Poolcommentlist-emptysp"> </div>
               <Poolcommentbox
                 cancelReply={handleCancelReply}
                 superuser={element.superuser}
                 comment_id={element.comment_id}
                 loginUser={element.loginUser}
                 rpindex={index}
                 rplyIndex={ replyarr.includes(index) ? true : false }
                 replycount={element.replycount ? true : false}
                 Listname={"Commentlist"}
                 handleShowReplycom={handleShowReplycom}
                 handleHideReply={handleHideReply}
                 replyto={element.username}
                 notifyto={element.userid}
                 topicId={element.topicId}
                 comment_id={element.comment_id}
                 />
             </div> : null
          }

         <div className="Poolcommentlist-emptyhg"></div>

          {element.replycount ?

           <div className="Poolcommentlist-showreply">

             {element.replycount == 1 ?
                <div>
                 {replyarr.includes(index) ?
                   <div className="Poolcommentlist-replybtn" onClick={() => handleHideReply(index,element.comment_id)}><IoIosArrowUp/> Hide reply</div>
                   : <div className="Poolcommentlist-replybtn" onClick={() => handleShowReply(index,element.comment_id)}>
                      <IoIosArrowDown/>
                      Show reply
                  </div>
                 }
             </div> :

             <div>
              {replyarr.includes(index) ?
                <div className="Poolcommentlist-replybtn" onClick={() => handleHideReply(index,element.comment_id)}><IoIosArrowUp/> Hide {element.replycount} replies</div>
                : <div className="Poolcommentlist-replybtn" onClick={() => handleShowReply(index,element.comment_id)}><IoIosArrowDown/> Show {element.replycount} replies</div>
              }
          </div>  }

            </div> : null }

          {replyarr.includes(index) ?
             <div className="Poolcommentlist-showreply">
               <Poolreplylist
                 commentreply_id={element.comment_id}
                 loginUser={element.loginUser}
                 superuser={element.superuser}
                 userid={element.userid}
                 rplyIndex={index}
                 handleShowReplycom={handleShowReplycom}
                 handleHideReply={handleHideReply}
                 topicId={element.topicId}
                 />
             </div> : null}

          <div className="Poolcommentlist-reply-cmt"></div>
          <div className="Poolcommentlist-emptyhg"></div>

    </div>

  )})}


     {hasMore ?
       <div className="Poolcommentlist-loading">
         <Classicspiner />
      </div> : null }

     {/* Delete Comment */}

     {editdelId === true ?
       <div className="Poolcommentlist-del">
         <Pooldelete handlecancelDelete={handlecancelDelete}
          mainIndex={editIndex}
          loginUser={loginUser}
          comment_id={commentid}
          topicId={topicDetails.topicId}
          userid={topicDetails.userid}
          listName={"Commentlist"} />
       </div>
      : null
     }

     {/* Report Comment */}

     {reportId === true ?
       <div className="Poolcommentlist-del">
         <Poolreport
           handlecancelReport={handlecancelReport}
           handleNotifymsg={handleNotifymsg}
           commentid={commentid}
           loginUser={loginUser}
           listName={"Commentlist"} />
       </div>
      : null  }

     {/* Display Flash Message */}

     {displayflashmsg ?
      <div className="Poolcommentlist-notify">
         <Successnotify notifyMsg={notifyMsg} closeFlashMsg={closeFlashMsg}  />
      </div> : null }

</div>
  );
}

export default Poolcommentlist;
