import * as actionTypes from "../actions/actionTypes";

const initialState = {
    participant:[]
};

const participantReducer = (state = initialState, action) => {

  switch (action.type) {

    case actionTypes.ADD_PARTICIPANT:

       let temp = [];
       temp.push(action.payload);

        return {
          ...state,
          participant:[ ...state.participant, ...temp]
       };

    case actionTypes.REMOVE_PARTICIPANT:

        const temprmv = [...state.participant];

        const filteredArray = temprmv.filter(function(e) {
             return e !== action.payload
           })

        return {
          ...state,
          participant:filteredArray
        };

    default:
      return state;
  }
};

export default participantReducer;
