import React from 'react';
import { useState,useEffect,useRef } from 'react';
import { useDispatch } from "react-redux";
import { MdOutlineCancel } from 'react-icons/md';
import './Recoverycode.css'
import { registerPost } from '../../../../redux/thunk/gateway/fetchdata';
import { verifyusersecuirtycode } from '../../../../redux/thunk/security/fetchdata';
import Fpasswordchange from '../../../landingpage/fpassword/Fpasswordchange';

function Recoverycode(props) {

  const [link,setLink] = useState("");
  const [linkflag,setLinkflag] = useState(false);
  const [recoverycode,setRecoverycode] = useState(false);

  const [pinflag,setpinflag] = useState(false);
  const [errflag,seterrflag] = useState(false);

  const [reccode,setReccode] = useState("");

  const shareflag = useRef(null);

  const dispatch = useDispatch();

  const handleRecclose = () => {
     props.handleRecclose();
  }

  const handleConfirm = async () => {

    console.log("reccode",reccode);
    console.log("props.useremail",props.useremail);

     const response = await verifyusersecuirtycode(reccode,props.useremail);

     if(response.STATUSCODE == 0) {
        setpinflag(true);
     } else {
        seterrflag(true)
     }

  }

  const handleReovercode = (event) => {
     setReccode(event.target.value);
  }


 return (
  <div  className="sharewrapper">
   {pinflag ? <Fpasswordchange  useremail={props.useremail} />  :
      <div ref={shareflag} className="share-container">

        <div className="share-canel">
            <div className="share-canel-ico" onClick={() => handleRecclose()}>
              <MdOutlineCancel/>
            </div>
        </div>

          <div style={{height:10}}> </div>

        <div className="share-title">
            <h3>Recovery Code</h3>
        </div>

        <div style={{height:30}}> </div>

         <div className="share-input-wrapper" >
           <input id="secuirtycode" className="share-formcontrol" type="text" onChange={(event) => handleReovercode(event)} value={reccode} />
         </div>

        <div style={{height:30}}> </div>

        <div>
          <div className="recovery-confirm-btn" onClick={() => handleConfirm()}>
             Confirm
          </div>
        </div>



          {errflag ?
           <div className="pinerrormsg" >
             Recovery code does not match
           </div> : <div className="pinerrormsg" >
             &nbsp;
           </div> }

      </div>
    }
  </div>
 )

}

export default Recoverycode;
