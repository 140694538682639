import './Spinner.css';

function Spinner() {

 return (
  <div className="wrapper-loading">
   <div className="loading">
    {/* <h2>Loading....</h2> */}
     <span></span>&nbsp;
     <span></span>&nbsp;
     <span></span>&nbsp;
     <span></span>&nbsp;
     <span></span>&nbsp;
   </div>
   </div>
 )

}

export default Spinner;
