import './Buttonspiner.css';

function Buttonspiner() {

  return (
     <div>
        <span className="loader"></span>
    </div>
  )
}

export default Buttonspiner;
