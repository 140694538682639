
// Get User Followers

export const getFollower = async(page,limit,followerObj) => {

    try {

      let url = `/getuserfollower?page=${page}&limit=${limit}`;

      const requestOptions = {
           method: 'POST',
           headers: {
             'Content-Type': 'application/json',
             'Accept': 'application/json',
           }, body:JSON.stringify({
                followerObj:followerObj
            })
      };

      const response = await fetch(url,requestOptions);

      if(!response.ok) {
         throw {
           STATUSCODE:1,
           STATUS:"ERROR",
           MESSAGE:"Something broke!" }
       } else {
          const result = await response.json();
          return result;
       }

    } catch(error) {
        return error;
    }

}

// Get User Following

export const getFollwing = async(page,limit,followerObj) => {

  try {

    let url = `/getuserfollowing?page=${page}&limit=${limit}`;

    const requestOptions = {
         method: 'POST',
         headers: {
           'Content-Type': 'application/json',
           'Accept': 'application/json',
         }, body:JSON.stringify({
              followerObj:followerObj
          })
    };

     const response = await fetch(url,requestOptions);

     if(!response.ok) {
        throw {
          STATUSCODE:1,
          STATUS:"ERROR",
          MESSAGE:"Something broke!" }
      } else {
         const result = await response.json();
         return result;
      }

  } catch(error) {
      return error;
  }

}

// Add Follower

export const addFollower = async(followerObj) => {

   try {

     let url = '/addfollower';

     const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          }, body:JSON.stringify({
               followerobject:followerObj
           })
     };

      const response = await fetch(url,requestOptions);

      if(!response.ok) {
         throw {
           STATUSCODE:1,
           STATUS:"ERROR",
           MESSAGE:"Something broke!" }
       } else {
          const result = await response.json();
          return result;
       }

   } catch(error) {
       return error;
   }

}

// Remover followers

export const removeFollower = async(followerObj) => {

  try {

    let url = '/removefollower';

    const requestOptions = {
         method: 'POST',
         headers: {
           'Content-Type': 'application/json',
           'Accept': 'application/json',
         }, body:JSON.stringify({
              followerobject:followerObj
          })
    };

     const response = await fetch(url,requestOptions);

     if(!response.ok) {
        throw {
          STATUSCODE:1,
          STATUS:"ERROR",
          MESSAGE:"Something broke!" }
      } else {
         const result = await response.json();
         return result;
      }

  } catch(error) {
      return error;
  }

}
