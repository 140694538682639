import React, { useState,useEffect,useRef } from 'react';
import { RiCloseFill } from 'react-icons/ri';
import {useNavigate} from 'react-router-dom';
import './Popupmenu.css';

function Popupmenu(props) {

  const menuRef = useRef();
  const navigate = useNavigate();

  const handleClose = () => {
     props.closeMenu();
  }

  const handleClickout = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      props.closeMenu();
     }
  };

  const handleWork = () => {
     navigate('/howitwork');
     props.closeMenu();
  }

  const handlePool = () => {
    navigate('/pool');
    props.closeMenu();
  }

  useEffect(() => {

    document.addEventListener("mousedown", handleClickout);
    return () => {
       document.removeEventListener("mousedown", handleClickout, false);
     };

  }, []);

  return (
    <div className="Popupmenu-wrapper">
      <div style={{height:50}}> </div>
       <div ref={menuRef} className="Popupmenu-container">
           <div className="Popupmenu-menu">
             <div className="Popupmenu-menu-opt-dis"><RiCloseFill /></div>
             <div className="Popupmenu-menu-o">Menu</div>
             <div className="Popupmenu-menu-opt" onClick={() => handleClose()}>
               <RiCloseFill />
              </div>
          </div>
           <div style={{height:10}}> </div>
          <div className="Popupmenu-item" onClick={() => handleWork()}>How It Work</div>
          <div style={{height:10}}> </div>
          <div className="Popupmenu-item" onClick={() => handlePool()}>Pool</div>
       </div>
    </div>
  )
}

export default Popupmenu;
