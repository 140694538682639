import './Classicspiner.css';

function Classicspiner() {

  return (
    <div className="ispinner ispinner--animating ispinner--gray">
        <div className="ispinner__blade"></div>
        <div className="ispinner__blade"></div>
        <div className="ispinner__blade"></div>
        <div className="ispinner__blade"></div>
        <div className="ispinner__blade"></div>
        <div className="ispinner__blade"></div>
        <div className="ispinner__blade"></div>
        <div className="ispinner__blade"></div>
        <div className="ispinner__blade"></div>
        <div className="ispinner__blade"></div>
        <div className="ispinner__blade"></div>
        <div className="ispinner__blade"></div>
    </div>
  )
}

export default Classicspiner;
