import * as actionTypes from "../actions/actionTypes";

const initialState = {
  requestid:null
};

const requestdeleteReduce = (state = initialState, action) => {

  switch (action.type) {

    case actionTypes.ADD_REQUEST_DELETE_ID:

      return {
        requestid:action.payload
      };

    default:
      return state;
  }
};

export default requestdeleteReduce;
