
export const getGlobalSearchResult = async (searchterm) => {

  try {

    let url = '/getglobalsearchresult';

    const requestOptions = {
         method: 'POST',
         headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
         },
         body:JSON.stringify({
             searchterm:searchterm
           })
      };

    const response = await fetch(url,requestOptions);

    if(!response.ok) {
       throw {
         STATUSCODE:1,
         STATUS:"ERROR",
         MESSAGE:"Something broke!" }
    }  else {
        const result = await response.json();
        return result;
     }

   } catch (error) {
       return error;
    }

}

export const getpoolSearch = async (page,limit,searchterm) => {

  try {

    let poolsearchobj = {
        page:page,
        limit:limit,
        searchterm:searchterm
    }

    let url = '/getpoolsearch';

    const requestOptions = {
         method: 'POST',
         headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
         },
         body:JSON.stringify({
             poolsearchobj:poolsearchobj
           })
      };

    const response = await fetch(url,requestOptions);

    if(!response.ok) {
       throw {
         STATUSCODE:1,
         STATUS:"ERROR",
         MESSAGE:"Something broke!" }
    }  else {
        const result = await response.json();
        return result;
     }

   } catch (error) {
       return error;
    }

}

export const getPeopleSearch = async (page,limit,searchterm) => {

  try {

    let poolsearchobj = {
        page:page,
        limit:limit,
        searchterm:searchterm
    }

    let url = '/getpeoplesearch';

    const requestOptions = {
         method: 'POST',
         headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
         },
         body:JSON.stringify({
             poolsearchobj:poolsearchobj
           })
      };

    const response = await fetch(url,requestOptions);

    if(!response.ok) {
       throw {
         STATUSCODE:1,
         STATUS:"ERROR",
         MESSAGE:"Something broke!" }
    }  else {
        const result = await response.json();
        return result;
     }

   } catch (error) {
       return error;
    }

}

export const getPeopleSearchTopic = async (page,limit,searchterm,topicId) => {

  try {

    let poolsearchobj = {
        page:page,
        limit:limit,
        searchterm:searchterm,
        topicId:topicId
    }

    let url = '/getpeoplesearchtopic';

    const requestOptions = {
         method: 'POST',
         headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
         },
         body:JSON.stringify({
             poolsearchobj:poolsearchobj
           })
      };

    const response = await fetch(url,requestOptions);

    if(!response.ok) {
       throw {
         STATUSCODE:1,
         STATUS:"ERROR",
         MESSAGE:"Something broke!" }
    }  else {
        const result = await response.json();
        return result;
     }

   } catch (error) {
       return error;
    }

}

export const getFriendList = async (searchterm,topicId) => {

  try {

    let url = '/getFriendList';

    const requestOptions = {
         method: 'POST',
         headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
         },
         body:JSON.stringify({
             searchterm:searchterm,
             topicId:topicId
           })
      };

    const response = await fetch(url,requestOptions);

    if(!response.ok) {
       throw {
         STATUSCODE:1,
         STATUS:"ERROR",
         MESSAGE:"Something broke!" }
    }  else {
        const result = await response.json();
        return result;
     }

   } catch (error) {
       return error;
    }


}

export const updateUserRequest = async (requestId) => {

  try {

    let url = '/updateuserrequest';

    const requestOptions = {
         method: 'POST',
         headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
         },
         body:JSON.stringify({
             requestId:requestId
           })
      };

    const response = await fetch(url,requestOptions);

    if(!response.ok) {
       throw {
         STATUSCODE:1,
         STATUS:"ERROR",
         MESSAGE:"Something broke!" }
    }  else {
        const result = await response.json();
        return result;
     }

   } catch (error) {
       return error;
    }



}
