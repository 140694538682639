import './Removesqaud.css';
import React, { useEffect,useState,useRef } from 'react';
import { useSelector,useDispatch } from "react-redux";
import {useNavigate} from 'react-router-dom';
import { RiCloseFill } from 'react-icons/ri';
import * as actionCreators from '../../../../redux/actions/actionCreators';
import { getUserSmarttag } from '../../../../redux/thunk/post/fetchdata';
import { removesquadupdate } from '../../../../redux/thunk/squad/fetchdata';

function Removesqaud() {

  const myref = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userdetails = useSelector((state) => state.userReducer.userdetails);
  const squaddetails = useSelector((state) => state.squadReducer.removesquad);

  const handleClick = (event) => {
    if (myref.current && !myref.current.contains(event.target)) {

      let removeSquadObj = {
         removesquadflag:false
      }

      dispatch(actionCreators.removefromSquad(removeSquadObj));

     }
  };

  const confirmRemove = async () => {

    let squadObj = {
      userid:squaddetails.userid,
      topicid:squaddetails.topicid,
      removed_by:squaddetails.removed_by
    }

    const response = await removesquadupdate(squadObj);

      if(response.STATUSCODE === 0) {
          dispatch(actionCreators.delTeamMember(squaddetails.userid));
       }

       let removeSquadObj = {
          removesquadflag:false
       }

     dispatch(actionCreators.removefromSquad(removeSquadObj));
  }

  const handleClose = () => {

    let removeSquadObj = {
       removesquad:false
    }

    dispatch(actionCreators.removefromSquad(removeSquadObj));
  }

  useEffect(async() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick, false);
    };
  },[]);

  return (
    <div className="Removeqaud-wrapper" >
       <div ref={myref} className="Removeqaud-container">
          <div className="Removeqaud-title">
             <div className="Removeqaud-close-text"><strong>Remove Squad ?</strong></div>
             <div className="Removeqaud-close" onClick={() => handleClose()}>
               <RiCloseFill />
             </div>
           </div>
          <div className="Removeqaud-name">
             <p>Are you sure you want to remove user <b>{squaddetails.username}</b> ?</p>
           </div>
           <div className="Removeqaud-btn-height"> </div>
          <div className="Removeqaud-btn-wrapper">
             <div className="Removeqaud-btn" onClick={() => confirmRemove()}>Remove</div>
          </div>
       </div>
    </div>

  )
}



export default Removesqaud;
