import React from 'react';
import { useEffect,useState,useRef,useContext } from 'react';
import { useSelector,useDispatch } from 'react-redux';
import {  SocketContext  } from "../../../../context/SocketContext";

import './Requestmodel.css';
import * as actionCreators from '../../../../redux/actions/actionCreators';
import { delEnrolledUser } from '../../../../redux/thunk/topic/fetchdata';

function Requestmodel(props) {

  const myref = useRef(null);
  const dispatch = useDispatch();

  const {requestDelete,handleRequestDel} = props;
  const { socket } = useContext(SocketContext);

  const handleClick = (event) => {
    if (myref.current && !myref.current.contains(event.target)) {
        handleRequestDel();
     }
  };

  const handlecancelDelete = () => {
        handleRequestDel();
  }

  const handleDeleteRequest = async() => {

      const response = await delEnrolledUser(requestDelete.requestid);

      if(response.STATUSCODE == 0) {

         dispatch(actionCreators.addRequestdeleteId(requestDelete.requestid));

         const replyNoti = (notiFy) => {
           socket?.emit("replyNoti",notiFy);
         }

         const delNoti = (requestId) => {
           socket?.emit("delNotireq",requestId);
         }

         if(socket) {
            await replyNoti(requestDelete)
          }

         if(socket) {
            await delNoti(requestDelete.requestid);
         }

         let object = {
           refreshFlag:true,
           reqmessage:"User request is deleted"
         }

         dispatch(actionCreators.refreshReq(object));
         dispatch(actionCreators.reqNav(""));
         handleRequestDel();

     }

  }

  useEffect(() => {
   document.addEventListener("mousedown", handleClick);

   return () => {
      document.removeEventListener("mousedown", handleClick, false);
    };

 }, []);

  return (
    <div className="modelwrapper">
      <div ref={myref} className="modelcontainer">

       <div className="request-title"><strong>Delete Request ?</strong></div>
       <div className="request-note">Are you sure you want to delete this request ?</div>
       <div>&nbsp;</div>

       <div className="request-btn">
         <div className="request-cancel" onClick={() => handlecancelDelete()}>Cancel</div>
         <div className="request-empty"></div>
         <div className="request-del" onClick={() => handleDeleteRequest()}>Delete</div>
       </div>

      </div>
    </div>
  );
}

export default Requestmodel;
