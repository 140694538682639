import * as actionTypes from "../actions/actionTypes";

const initialState = {
   searchPage:1,
   seahasMore:true,
   searchTerm:"",
   searchTemp:null,
   searchPool:[]
};

const searchReducer = (state = initialState, action) => {

  switch (action.type) {

    case actionTypes.ADD_SEARCH_TERM:

      return {
        ...state,
        searchPage:1,
        seahasMore:true,
        searchTerm:action.payload,
        searchTemp:null,
        searchPool:[]
      }

    case actionTypes.ADD_SEARCH_POOL:

       return {
         ...state,
         searchPool:[...state.searchPool,...action.payload]
       }

    case actionTypes.ADD_SEARCH_POOL_TERM:

      return {
        ...state,
        searchTerm:action.payload
      };

    case actionTypes.ADD_SEARCH_POOL_HASMORE:

      return {
        ...state,
        seahasMore:action.payload
      };

    case actionTypes.ADD_SEARCH_POOL_TEMP:

      return {
        ...state,
        searchTemp:action.payload
      };

    case actionTypes.CLEAR_SEARCH_POOL:

      let tempSearchpool = [];

       return {
          ...state,
          searchPage:1,
          seahasMore:true,
          searchTerm:"",
          searchTemp:null,
          searchPool:[]
       }

    case actionTypes.ADD_SEARCH_POOL_PAGE:

        return {
          ...state,
          searchPage:action.payload
        }

    default:
      return state;
  }
};

export default searchReducer;
