import './Browserpool.css';
import React from 'react';
import { Routes,Route } from "react-router-dom";
import Useractivepoollist from './Useractivepoollist';
import Pooldetails from './Pooldetails';

function Useractivepool() {

  return (
     <div className="browserpoolcontainer">
          <Routes>
              <Route path="/" element={<Useractivepoollist />}> </Route>
              <Route path="/pooldetails/:picId/" element={<Pooldetails />}></Route>
         </Routes>
     </div>
  )
}

export default Useractivepool;
