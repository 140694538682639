
export function rangeDate(dateValue,stepValue) {

  let startDate = new Date(dateValue);
  let nextDay = new Date(startDate);

  nextDay.setDate(startDate.getDate() + parseInt(stepValue-1));

  function dateRange(startDate, endDate, steps = 1) {

    const dateArray = [];
    let currentDate = new Date(startDate);

     while (currentDate <= new Date(endDate)) {
       dateArray.push(new Date(currentDate));
       currentDate.setUTCDate(currentDate.getUTCDate() +steps);
     }

     return dateArray;
   }

   const dates = dateRange(startDate,nextDay);
   return dates;

}
