export const byCountrytimezone =  [
  {
    CountryCode: "AF",
    CountryName: "Afghanistan",
    TimeZone: "Asia/Kabul",
    GMTOffset: "+04:30"
  },
  {
    CountryCode: "AL",
    CountryName: "Albania",
    TimeZone: "Europe/Tirane",
    GMTOffset: "+01:00"
  },
  {
    CountryCode: "DZ",
    CountryName: "Algeria",
    TimeZone: "Africa/Algiers",
    GMTOffset: "+01:00"
  },
  {
    CountryCode: "AS",
    CountryName: "American Samoa",
    TimeZone: "Pacific/Pago_Pago",
    GMTOffset: "-11:00"
  },
  {
    CountryCode: "AD",
    CountryName: "Andorra",
    TimeZone: "Europe/Andorra",
    GMTOffset: "+01:00"
  },
  {
    CountryCode: "AO",
    CountryName: "Angola",
    TimeZone: "Africa/Luanda",
    GMTOffset: "+01:00"
  },
  {
    CountryCode: "AI",
    CountryName: "Anguilla",
    TimeZone: "America/Anguilla",
    GMTOffset: "-04:00"
  },
  {
    CountryCode: "AQ",
    CountryName: "Antarctica",
    TimeZone: "Antarctica/Casey",
    GMTOffset: "+11:00"
  },
  {
    CountryCode: "AQ",
    CountryName: "Antarctica",
    TimeZone: "Antarctica/Davis",
    GMTOffset: "+07:00"
  },
  {
    CountryCode: "AQ",
    CountryName: "Antarctica",
    TimeZone: "Antarctica/DumontDUrville",
    GMTOffset: "+10:00"
  },
  {
    CountryCode: "AQ",
    CountryName: "Antarctica",
    TimeZone: "Antarctica/Mawson",
    GMTOffset: "+05:00"
  },
  {
    CountryCode: "AQ",
    CountryName: "Antarctica",
    TimeZone: "Antarctica/McMurdo",
    GMTOffset: "+13:00"
  },
  {
    CountryCode: "AQ",
    CountryName: "Antarctica",
    TimeZone: "Antarctica/Palmer",
    GMTOffset: "-03:00"
  },
  {
    CountryCode: "AQ",
    CountryName: "Antarctica",
    TimeZone: "Antarctica/Rothera",
    GMTOffset: "-03:00"
  },
  {
    CountryCode: "AQ",
    CountryName: "Antarctica",
    TimeZone: "Antarctica/Syowa",
    GMTOffset: "+03:00"
  },
  {
    CountryCode: "AQ",
    CountryName: "Antarctica",
    TimeZone: "Antarctica/Troll",
    GMTOffset: "UTC"
  },
  {
    CountryCode: "AQ",
    CountryName: "Antarctica",
    TimeZone: "Antarctica/Vostok",
    GMTOffset: "+06:00"
  },
  {
    CountryCode: "AG",
    CountryName: "Antigua and Barbuda",
    TimeZone: "America/Antigua",
    GMTOffset: "-04:00"
  },
  {
    CountryCode: "AR",
    CountryName: "Argentina",
    TimeZone: "America/Argentina/Buenos_Aires",
    GMTOffset: "-03:00"
  },
  {
    CountryCode: "AR",
    CountryName: "Argentina",
    TimeZone: "America/Argentina/Catamarca",
    GMTOffset: "-03:00"
  },
  {
    CountryCode: "AR",
    CountryName: "Argentina",
    TimeZone: "America/Argentina/Cordoba",
    GMTOffset: "-03:00"
  },
  {
    CountryCode: "AR",
    CountryName: "Argentina",
    TimeZone: "America/Argentina/Jujuy",
    GMTOffset: "-03:00"
  },
  {
    CountryCode: "AR",
    CountryName: "Argentina",
    TimeZone: "America/Argentina/La_Rioja",
    GMTOffset: "-03:00"
  },
  {
    CountryCode: "AR",
    CountryName: "Argentina",
    TimeZone: "America/Argentina/Mendoza",
    GMTOffset: "-03:00"
  },
  {
    CountryCode: "AR",
    CountryName: "Argentina",
    TimeZone: "America/Argentina/Rio_Gallegos",
    GMTOffset: "-03:00"
  },
  {
    CountryCode: "AR",
    CountryName: "Argentina",
    TimeZone: "America/Argentina/Salta",
    GMTOffset: "-03:00"
  },
  {
    CountryCode: "AR",
    CountryName: "Argentina",
    TimeZone: "America/Argentina/San_Juan",
    GMTOffset: "-03:00"
  },
  {
    CountryCode: "AR",
    CountryName: "Argentina",
    TimeZone: "America/Argentina/San_Luis",
    GMTOffset: "-03:00"
  },
  {
    CountryCode: "AR",
    CountryName: "Argentina",
    TimeZone: "America/Argentina/Tucuman",
    GMTOffset: "-03:00"
  },
  {
    CountryCode: "AR",
    CountryName: "Argentina",
    TimeZone: "America/Argentina/Ushuaia",
    GMTOffset: "-03:00"
  },
  {
    CountryCode: "AM",
    CountryName: "Armenia",
    TimeZone: "Asia/Yerevan",
    GMTOffset: "+04:00"
  },
  {
    CountryCode: "AW",
    CountryName: "Aruba",
    TimeZone: "America/Aruba",
    GMTOffset: "-04:00"
  },
  {
    CountryCode: "AU",
    CountryName: "Australia",
    TimeZone: "Antarctica/Macquarie",
    GMTOffset: "+11:00"
  },
  {
    CountryCode: "AU",
    CountryName: "Australia",
    TimeZone: "Australia/Adelaide",
    GMTOffset: "+10:30"
  },
  {
    CountryCode: "AU",
    CountryName: "Australia",
    TimeZone: "Australia/Brisbane",
    GMTOffset: "+10:00"
  },
  {
    CountryCode: "AU",
    CountryName: "Australia",
    TimeZone: "Australia/Broken_Hill",
    GMTOffset: "+10:30"
  },
  {
    CountryCode: "AU",
    CountryName: "Australia",
    TimeZone: "Australia/Darwin",
    GMTOffset: "+09:30"
  },
  {
    CountryCode: "AU",
    CountryName: "Australia",
    TimeZone: "Australia/Eucla",
    GMTOffset: "+08:45"
  },
  {
    CountryCode: "AU",
    CountryName: "Australia",
    TimeZone: "Australia/Hobart",
    GMTOffset: "+11:00"
  },
  {
    CountryCode: "AU",
    CountryName: "Australia",
    TimeZone: "Australia/Lindeman",
    GMTOffset: "+10:00"
  },
  {
    CountryCode: "AU",
    CountryName: "Australia",
    TimeZone: "Australia/Lord_Howe",
    GMTOffset: "+11:00"
  },
  {
    CountryCode: "AU",
    CountryName: "Australia",
    TimeZone: "Australia/Melbourne",
    GMTOffset: "+11:00"
  },
  {
    CountryCode: "AU",
    CountryName: "Australia",
    TimeZone: "Australia/Perth",
    GMTOffset: "+08:00"
  },
  {
    CountryCode: "AU",
    CountryName: "Australia",
    TimeZone: "Australia/Sydney",
    GMTOffset: "+11:00"
  },
  {
    CountryCode: "AT",
    CountryName: "Austria",
    TimeZone: "Europe/Vienna",
    GMTOffset: "+01:00"
  },
  {
    CountryCode: "AZ",
    CountryName: "Azerbaijan",
    TimeZone: "Asia/Baku",
    GMTOffset: "+04:00"
  },
  {
    CountryCode: "BS",
    CountryName: "Bahamas",
    TimeZone: "America/Nassau",
    GMTOffset: "-05:00"
  },
  {
    CountryCode: "BH",
    CountryName: "Bahrain",
    TimeZone: "Asia/Bahrain",
    GMTOffset: "+03:00"
  },
  {
    CountryCode: "BD",
    CountryName: "Bangladesh",
    TimeZone: "Asia/Dhaka",
    GMTOffset: "+06:00"
  },
  {
    CountryCode: "BB",
    CountryName: "Barbados",
    TimeZone: "America/Barbados",
    GMTOffset: "-04:00"
  },
  {
    CountryCode: "BY",
    CountryName: "Belarus",
    TimeZone: "Europe/Minsk",
    GMTOffset: "+03:00"
  },
  {
    CountryCode: "BE",
    CountryName: "Belgium",
    TimeZone: "Europe/Brussels",
    GMTOffset: "+01:00"
  },
  {
    CountryCode: "BZ",
    CountryName: "Belize",
    TimeZone: "America/Belize",
    GMTOffset: "-06:00"
  },
  {
    CountryCode: "BJ",
    CountryName: "Benin",
    TimeZone: "Africa/Porto-Novo",
    GMTOffset: "+01:00"
  },
  {
    CountryCode: "BM",
    CountryName: "Bermuda",
    TimeZone: "Atlantic/Bermuda",
    GMTOffset: "-04:00"
  },
  {
    CountryCode: "BT",
    CountryName: "Bhutan",
    TimeZone: "Asia/Thimphu",
    GMTOffset: "+06:00"
  },
  {
    CountryCode: "BO",
    CountryName: "Bolivia",
    TimeZone: " Plurinational State of",
    GMTOffset: "America/La_Paz"
  },
  {
    CountryCode: "BQ",
    CountryName: "Bonaire",
    TimeZone: " Sint Eustatius and Saba",
    GMTOffset: "America/Kralendijk"
  },
  {
    CountryCode: "BA",
    CountryName: "Bosnia and Herzegovina",
    TimeZone: "Europe/Sarajevo",
    GMTOffset: "+01:00"
  },
  {
    CountryCode: "BW",
    CountryName: "Botswana",
    TimeZone: "Africa/Gaborone",
    GMTOffset: "+02:00"
  },
  {
    CountryCode: "BR",
    CountryName: "Brazil",
    TimeZone: "America/Araguaina",
    GMTOffset: "-03:00"
  },
  {
    CountryCode: "BR",
    CountryName: "Brazil",
    TimeZone: "America/Bahia",
    GMTOffset: "-03:00"
  },
  {
    CountryCode: "BR",
    CountryName: "Brazil",
    TimeZone: "America/Belem",
    GMTOffset: "-03:00"
  },
  {
    CountryCode: "BR",
    CountryName: "Brazil",
    TimeZone: "America/Boa_Vista",
    GMTOffset: "-04:00"
  },
  {
    CountryCode: "BR",
    CountryName: "Brazil",
    TimeZone: "America/Campo_Grande",
    GMTOffset: "-04:00"
  },
  {
    CountryCode: "BR",
    CountryName: "Brazil",
    TimeZone: "America/Cuiaba",
    GMTOffset: "-04:00"
  },
  {
    CountryCode: "BR",
    CountryName: "Brazil",
    TimeZone: "America/Eirunepe",
    GMTOffset: "-05:00"
  },
  {
    CountryCode: "BR",
    CountryName: "Brazil",
    TimeZone: "America/Fortaleza",
    GMTOffset: "-03:00"
  },
  {
    CountryCode: "BR",
    CountryName: "Brazil",
    TimeZone: "America/Maceio",
    GMTOffset: "-03:00"
  },
  {
    CountryCode: "BR",
    CountryName: "Brazil",
    TimeZone: "America/Manaus",
    GMTOffset: "-04:00"
  },
  {
    CountryCode: "BR",
    CountryName: "Brazil",
    TimeZone: "America/Noronha",
    GMTOffset: "-02:00"
  },
  {
    CountryCode: "BR",
    CountryName: "Brazil",
    TimeZone: "America/Porto_Velho",
    GMTOffset: "-04:00"
  },
  {
    CountryCode: "BR",
    CountryName: "Brazil",
    TimeZone: "America/Recife",
    GMTOffset: "-03:00"
  },
  {
    CountryCode: "BR",
    CountryName: "Brazil",
    TimeZone: "America/Rio_Branco",
    GMTOffset: "-05:00"
  },
  {
    CountryCode: "BR",
    CountryName: "Brazil",
    TimeZone: "America/Santarem",
    GMTOffset: "-03:00"
  },
  {
    CountryCode: "BR",
    CountryName: "Brazil",
    TimeZone: "America/Sao_Paulo",
    GMTOffset: "-03:00"
  },
  {
    CountryCode: "IO",
    CountryName: "British Indian Ocean Territory",
    TimeZone: "Indian/Chagos",
    GMTOffset: "+06:00"
  },
  {
    CountryCode: "BN",
    CountryName: "Brunei Darussalam",
    TimeZone: "Asia/Brunei",
    GMTOffset: "+08:00"
  },
  {
    CountryCode: "BG",
    CountryName: "Bulgaria",
    TimeZone: "Europe/Sofia",
    GMTOffset: "+02:00"
  },
  {
    CountryCode: "BF",
    CountryName: "Burkina Faso",
    TimeZone: "Africa/Ouagadougou",
    GMTOffset: "UTC"
  },
  {
    CountryCode: "BI",
    CountryName: "Burundi",
    TimeZone: "Africa/Bujumbura",
    GMTOffset: "+02:00"
  },
  {
    CountryCode: "KH",
    CountryName: "Cambodia",
    TimeZone: "Asia/Phnom_Penh",
    GMTOffset: "+07:00"
  },
  {
    CountryCode: "CM",
    CountryName: "Cameroon",
    TimeZone: "Africa/Douala",
    GMTOffset: "+01:00"
  },
  {
    CountryCode: "CA",
    CountryName: "Canada",
    TimeZone: "America/Atikokan",
    GMTOffset: "-05:00"
  },
  {
    CountryCode: "CA",
    CountryName: "Canada",
    TimeZone: "America/Blanc-Sablon",
    GMTOffset: "-04:00"
  },
  {
    CountryCode: "CA",
    CountryName: "Canada",
    TimeZone: "America/Cambridge_Bay",
    GMTOffset: "-07:00"
  },
  {
    CountryCode: "CA",
    CountryName: "Canada",
    TimeZone: "America/Creston",
    GMTOffset: "-07:00"
  },
  {
    CountryCode: "CA",
    CountryName: "Canada",
    TimeZone: "America/Dawson",
    GMTOffset: "-07:00"
  },
  {
    CountryCode: "CA",
    CountryName: "Canada",
    TimeZone: "America/Dawson_Creek",
    GMTOffset: "-07:00"
  },
  {
    CountryCode: "CA",
    CountryName: "Canada",
    TimeZone: "America/Edmonton",
    GMTOffset: "-07:00"
  },
  {
    CountryCode: "CA",
    CountryName: "Canada",
    TimeZone: "America/Fort_Nelson",
    GMTOffset: "-07:00"
  },
  {
    CountryCode: "CA",
    CountryName: "Canada",
    TimeZone: "America/Glace_Bay",
    GMTOffset: "-04:00"
  },
  {
    CountryCode: "CA",
    CountryName: "Canada",
    TimeZone: "America/Goose_Bay",
    GMTOffset: "-04:00"
  },
  {
    CountryCode: "CA",
    CountryName: "Canada",
    TimeZone: "America/Halifax",
    GMTOffset: "-04:00"
  },
  {
    CountryCode: "CA",
    CountryName: "Canada",
    TimeZone: "America/Inuvik",
    GMTOffset: "-07:00"
  },
  {
    CountryCode: "CA",
    CountryName: "Canada",
    TimeZone: "America/Iqaluit",
    GMTOffset: "-05:00"
  },
  {
    CountryCode: "CA",
    CountryName: "Canada",
    TimeZone: "America/Moncton",
    GMTOffset: "-04:00"
  },
  {
    CountryCode: "CA",
    CountryName: "Canada",
    TimeZone: "America/Rankin_Inlet",
    GMTOffset: "-06:00"
  },
  {
    CountryCode: "CA",
    CountryName: "Canada",
    TimeZone: "America/Regina",
    GMTOffset: "-06:00"
  },
  {
    CountryCode: "CA",
    CountryName: "Canada",
    TimeZone: "America/Resolute",
    GMTOffset: "-06:00"
  },
  {
    CountryCode: "CA",
    CountryName: "Canada",
    TimeZone: "America/St_Johns",
    GMTOffset: "-03:30"
  },
  {
    CountryCode: "CA",
    CountryName: "Canada",
    TimeZone: "America/Swift_Current",
    GMTOffset: "-06:00"
  },
  {
    CountryCode: "CA",
    CountryName: "Canada",
    TimeZone: "America/Toronto",
    GMTOffset: "-05:00"
  },
  {
    CountryCode: "CA",
    CountryName: "Canada",
    TimeZone: "America/Vancouver",
    GMTOffset: "-08:00"
  },
  {
    CountryCode: "CA",
    CountryName: "Canada",
    TimeZone: "America/Whitehorse",
    GMTOffset: "-07:00"
  },
  {
    CountryCode: "CA",
    CountryName: "Canada",
    TimeZone: "America/Winnipeg",
    GMTOffset: "-06:00"
  },
  {
    CountryCode: "CA",
    CountryName: "Canada",
    TimeZone: "America/Yellowknife",
    GMTOffset: "-07:00"
  },
  {
    CountryCode: "CV",
    CountryName: "Cape Verde",
    TimeZone: "Atlantic/Cape_Verde",
    GMTOffset: "-01:00"
  },
  {
    CountryCode: "KY",
    CountryName: "Cayman Islands",
    TimeZone: "America/Cayman",
    GMTOffset: "-05:00"
  },
  {
    CountryCode: "CF",
    CountryName: "Central African Republic",
    TimeZone: "Africa/Bangui",
    GMTOffset: "+01:00"
  },
  {
    CountryCode: "TD",
    CountryName: "Chad",
    TimeZone: "Africa/Ndjamena",
    GMTOffset: "+01:00"
  },
  {
    CountryCode: "CL",
    CountryName: "Chile",
    TimeZone: "America/Punta_Arenas",
    GMTOffset: "-03:00"
  },
  {
    CountryCode: "CL",
    CountryName: "Chile",
    TimeZone: "America/Santiago",
    GMTOffset: "-03:00"
  },
  {
    CountryCode: "CL",
    CountryName: "Chile",
    TimeZone: "Pacific/Easter",
    GMTOffset: "-05:00"
  },
  {
    CountryCode: "CN",
    CountryName: "China",
    TimeZone: "Asia/Shanghai",
    GMTOffset: "+08:00"
  },
  {
    CountryCode: "CN",
    CountryName: "China",
    TimeZone: "Asia/Urumqi",
    GMTOffset: "+06:00"
  },
  {
    CountryCode: "CX",
    CountryName: "Christmas Island",
    TimeZone: "Indian/Christmas",
    GMTOffset: "+07:00"
  },
  {
    CountryCode: "CC",
    CountryName: "Cocos (Keeling) Islands",
    TimeZone: "Indian/Cocos",
    GMTOffset: "+06:30"
  },
  {
    CountryCode: "CO",
    CountryName: "Colombia",
    TimeZone: "America/Bogota",
    GMTOffset: "-05:00"
  },
  {
    CountryCode: "KM",
    CountryName: "Comoros",
    TimeZone: "Indian/Comoro",
    GMTOffset: "+03:00"
  },
  {
    CountryCode: "CG",
    CountryName: "Congo",
    TimeZone: "Africa/Brazzaville",
    GMTOffset: "+01:00"
  },
  {
    CountryCode: "CD",
    CountryName: "Congo",
    TimeZone: " the Democratic Republic of the",
    GMTOffset: "Africa/Kinshasa"
  },
  {
    CountryCode: "CD",
    CountryName: "Congo",
    TimeZone: " the Democratic Republic of the",
    GMTOffset: "Africa/Lubumbashi"
  },
  {
    CountryCode: "CK",
    CountryName: "Cook Islands",
    TimeZone: "Pacific/Rarotonga",
    GMTOffset: "-10:00"
  },
  {
    CountryCode: "CR",
    CountryName: "Costa Rica",
    TimeZone: "America/Costa_Rica",
    GMTOffset: "-06:00"
  },
  {
    CountryCode: "HR",
    CountryName: "Croatia",
    TimeZone: "Europe/Zagreb",
    GMTOffset: "+01:00"
  },
  {
    CountryCode: "CU",
    CountryName: "Cuba",
    TimeZone: "America/Havana",
    GMTOffset: "-05:00"
  },
  {
    CountryCode: "CW",
    CountryName: "Curaçao",
    TimeZone: "America/Curacao",
    GMTOffset: "-04:00"
  },
  {
    CountryCode: "CY",
    CountryName: "Cyprus",
    TimeZone: "Asia/Famagusta",
    GMTOffset: "+02:00"
  },
  {
    CountryCode: "CY",
    CountryName: "Cyprus",
    TimeZone: "Asia/Nicosia",
    GMTOffset: "+02:00"
  },
  {
    CountryCode: "CZ",
    CountryName: "Czech Republic",
    TimeZone: "Europe/Prague",
    GMTOffset: "+01:00"
  },
  {
    CountryCode: "CI",
    CountryName: "Côte d'Ivoire",
    TimeZone: "Africa/Abidjan",
    GMTOffset: "UTC"
  },
  {
    CountryCode: "DK",
    CountryName: "Denmark",
    TimeZone: "Europe/Copenhagen",
    GMTOffset: "+01:00"
  },
  {
    CountryCode: "DJ",
    CountryName: "Djibouti",
    TimeZone: "Africa/Djibouti",
    GMTOffset: "+03:00"
  },
  {
    CountryCode: "DM",
    CountryName: "Dominica",
    TimeZone: "America/Dominica",
    GMTOffset: "-04:00"
  },
  {
    CountryCode: "DO",
    CountryName: "Dominican Republic",
    TimeZone: "America/Santo_Domingo",
    GMTOffset: "-04:00"
  },
  {
    CountryCode: "EC",
    CountryName: "Ecuador",
    TimeZone: "America/Guayaquil",
    GMTOffset: "-05:00"
  },
  {
    CountryCode: "EC",
    CountryName: "Ecuador",
    TimeZone: "Pacific/Galapagos",
    GMTOffset: "-06:00"
  },
  {
    CountryCode: "EG",
    CountryName: "Egypt",
    TimeZone: "Africa/Cairo",
    GMTOffset: "+02:00"
  },
  {
    CountryCode: "SV",
    CountryName: "El Salvador",
    TimeZone: "America/El_Salvador",
    GMTOffset: "-06:00"
  },
  {
    CountryCode: "GQ",
    CountryName: "Equatorial Guinea",
    TimeZone: "Africa/Malabo",
    GMTOffset: "+01:00"
  },
  {
    CountryCode: "ER",
    CountryName: "Eritrea",
    TimeZone: "Africa/Asmara",
    GMTOffset: "+03:00"
  },
  {
    CountryCode: "EE",
    CountryName: "Estonia",
    TimeZone: "Europe/Tallinn",
    GMTOffset: "+02:00"
  },
  {
    CountryCode: "ET",
    CountryName: "Ethiopia",
    TimeZone: "Africa/Addis_Ababa",
    GMTOffset: "+03:00"
  },
  {
    CountryCode: "FK",
    CountryName: "Falkland Islands (Malvinas)",
    TimeZone: "Atlantic/Stanley",
    GMTOffset: "-03:00"
  },
  {
    CountryCode: "FO",
    CountryName: "Faroe Islands",
    TimeZone: "Atlantic/Faroe",
    GMTOffset: "UTC"
  },
  {
    CountryCode: "FJ",
    CountryName: "Fiji",
    TimeZone: "Pacific/Fiji",
    GMTOffset: "+12:00"
  },
  {
    CountryCode: "FI",
    CountryName: "Finland",
    TimeZone: "Europe/Helsinki",
    GMTOffset: "+02:00"
  },
  {
    CountryCode: "FR",
    CountryName: "France",
    TimeZone: "Europe/Paris",
    GMTOffset: "+01:00"
  },
  {
    CountryCode: "GF",
    CountryName: "French Guiana",
    TimeZone: "America/Cayenne",
    GMTOffset: "-03:00"
  },
  {
    CountryCode: "PF",
    CountryName: "French Polynesia",
    TimeZone: "Pacific/Gambier",
    GMTOffset: "-09:00"
  },
  {
    CountryCode: "PF",
    CountryName: "French Polynesia",
    TimeZone: "Pacific/Marquesas",
    GMTOffset: "-09:30"
  },
  {
    CountryCode: "PF",
    CountryName: "French Polynesia",
    TimeZone: "Pacific/Tahiti",
    GMTOffset: "-10:00"
  },
  {
    CountryCode: "TF",
    CountryName: "French Southern Territories",
    TimeZone: "Indian/Kerguelen",
    GMTOffset: "+05:00"
  },
  {
    CountryCode: "GA",
    CountryName: "Gabon",
    TimeZone: "Africa/Libreville",
    GMTOffset: "+01:00"
  },
  {
    CountryCode: "GM",
    CountryName: "Gambia",
    TimeZone: "Africa/Banjul",
    GMTOffset: "UTC"
  },
  {
    CountryCode: "GE",
    CountryName: "Georgia",
    TimeZone: "Asia/Tbilisi",
    GMTOffset: "+04:00"
  },
  {
    CountryCode: "DE",
    CountryName: "Germany",
    TimeZone: "Europe/Berlin",
    GMTOffset: "+01:00"
  },
  {
    CountryCode: "DE",
    CountryName: "Germany",
    TimeZone: "Europe/Busingen",
    GMTOffset: "+01:00"
  },
  {
    CountryCode: "GH",
    CountryName: "Ghana",
    TimeZone: "Africa/Accra",
    GMTOffset: "UTC"
  },
  {
    CountryCode: "GI",
    CountryName: "Gibraltar",
    TimeZone: "Europe/Gibraltar",
    GMTOffset: "+01:00"
  },
  {
    CountryCode: "GR",
    CountryName: "Greece",
    TimeZone: "Europe/Athens",
    GMTOffset: "+02:00"
  },
  {
    CountryCode: "GL",
    CountryName: "Greenland",
    TimeZone: "America/Danmarkshavn",
    GMTOffset: "UTC"
  },
  {
    CountryCode: "GL",
    CountryName: "Greenland",
    TimeZone: "America/Nuuk",
    GMTOffset: "-03:00"
  },
  {
    CountryCode: "GL",
    CountryName: "Greenland",
    TimeZone: "America/Scoresbysund",
    GMTOffset: "-01:00"
  },
  {
    CountryCode: "GL",
    CountryName: "Greenland",
    TimeZone: "America/Thule",
    GMTOffset: "-04:00"
  },
  {
    CountryCode: "GD",
    CountryName: "Grenada",
    TimeZone: "America/Grenada",
    GMTOffset: "-04:00"
  },
  {
    CountryCode: "GP",
    CountryName: "Guadeloupe",
    TimeZone: "America/Guadeloupe",
    GMTOffset: "-04:00"
  },
  {
    CountryCode: "GU",
    CountryName: "Guam",
    TimeZone: "Pacific/Guam",
    GMTOffset: "+10:00"
  },
  {
    CountryCode: "GT",
    CountryName: "Guatemala",
    TimeZone: "America/Guatemala",
    GMTOffset: "-06:00"
  },
  {
    CountryCode: "GG",
    CountryName: "Guernsey",
    TimeZone: "Europe/Guernsey",
    GMTOffset: "UTC"
  },
  {
    CountryCode: "GN",
    CountryName: "Guinea",
    TimeZone: "Africa/Conakry",
    GMTOffset: "UTC"
  },
  {
    CountryCode: "GW",
    CountryName: "Guinea-Bissau",
    TimeZone: "Africa/Bissau",
    GMTOffset: "UTC"
  },
  {
    CountryCode: "GY",
    CountryName: "Guyana",
    TimeZone: "America/Guyana",
    GMTOffset: "-04:00"
  },
  {
    CountryCode: "HT",
    CountryName: "Haiti",
    TimeZone: "America/Port-au-Prince",
    GMTOffset: "-05:00"
  },
  {
    CountryCode: "VA",
    CountryName: "Holy See (Vatican City State)",
    TimeZone: "Europe/Vatican",
    GMTOffset: "+01:00"
  },
  {
    CountryCode: "HN",
    CountryName: "Honduras",
    TimeZone: "America/Tegucigalpa",
    GMTOffset: "-06:00"
  },
  {
    CountryCode: "HK",
    CountryName: "Hong Kong",
    TimeZone: "Asia/Hong_Kong",
    GMTOffset: "+08:00"
  },
  {
    CountryCode: "HU",
    CountryName: "Hungary",
    TimeZone: "Europe/Budapest",
    GMTOffset: "+01:00"
  },
  {
    CountryCode: "IS",
    CountryName: "Iceland",
    TimeZone: "Atlantic/Reykjavik",
    GMTOffset: "UTC"
  },
  {
    CountryCode: "IN",
    CountryName: "India",
    TimeZone: "Asia/Kolkata",
    GMTOffset: "+05:30"
  },
  {
    CountryCode: "IN",
    CountryName: "India",
    TimeZone: "Asia/Calcutta",
    GMTOffset: "+05:30"
  },
  {
    CountryCode: "ID",
    CountryName: "Indonesia",
    TimeZone: "Asia/Jakarta",
    GMTOffset: "+07:00"
  },
  {
    CountryCode: "ID",
    CountryName: "Indonesia",
    TimeZone: "Asia/Jayapura",
    GMTOffset: "+09:00"
  },
  {
    CountryCode: "ID",
    CountryName: "Indonesia",
    TimeZone: "Asia/Makassar",
    GMTOffset: "+08:00"
  },
  {
    CountryCode: "ID",
    CountryName: "Indonesia",
    TimeZone: "Asia/Pontianak",
    GMTOffset: "+07:00"
  },
  {
    CountryCode: "IR",
    CountryName: "Iran",
    TimeZone: " Islamic Republic of",
    GMTOffset: "Asia/Tehran"
  },
  {
    CountryCode: "IQ",
    CountryName: "Iraq",
    TimeZone: "Asia/Baghdad",
    GMTOffset: "+03:00"
  },
  {
    CountryCode: "IE",
    CountryName: "Ireland",
    TimeZone: "Europe/Dublin",
    GMTOffset: "UTC"
  },
  {
    CountryCode: "IM",
    CountryName: "Isle of Man",
    TimeZone: "Europe/Isle_of_Man",
    GMTOffset: "UTC"
  },
  {
    CountryCode: "IL",
    CountryName: "Israel",
    TimeZone: "Asia/Jerusalem",
    GMTOffset: "+02:00"
  },
  {
    CountryCode: "IT",
    CountryName: "Italy",
    TimeZone: "Europe/Rome",
    GMTOffset: "+01:00"
  },
  {
    CountryCode: "JM",
    CountryName: "Jamaica",
    TimeZone: "America/Jamaica",
    GMTOffset: "-05:00"
  },
  {
    CountryCode: "JP",
    CountryName: "Japan",
    TimeZone: "Asia/Tokyo",
    GMTOffset: "+09:00"
  },
  {
    CountryCode: "JE",
    CountryName: "Jersey",
    TimeZone: "Europe/Jersey",
    GMTOffset: "UTC"
  },
  {
    CountryCode: "JO",
    CountryName: "Jordan",
    TimeZone: "Asia/Amman",
    GMTOffset: "+03:00"
  },
  {
    CountryCode: "KZ",
    CountryName: "Kazakhstan",
    TimeZone: "Asia/Almaty",
    GMTOffset: "+06:00"
  },
  {
    CountryCode: "KZ",
    CountryName: "Kazakhstan",
    TimeZone: "Asia/Aqtau",
    GMTOffset: "+05:00"
  },
  {
    CountryCode: "KZ",
    CountryName: "Kazakhstan",
    TimeZone: "Asia/Aqtobe",
    GMTOffset: "+05:00"
  },
  {
    CountryCode: "KZ",
    CountryName: "Kazakhstan",
    TimeZone: "Asia/Atyrau",
    GMTOffset: "+05:00"
  },
  {
    CountryCode: "KZ",
    CountryName: "Kazakhstan",
    TimeZone: "Asia/Oral",
    GMTOffset: "+05:00"
  },
  {
    CountryCode: "KZ",
    CountryName: "Kazakhstan",
    TimeZone: "Asia/Qostanay",
    GMTOffset: "+06:00"
  },
  {
    CountryCode: "KZ",
    CountryName: "Kazakhstan",
    TimeZone: "Asia/Qyzylorda",
    GMTOffset: "+05:00"
  },
  {
    CountryCode: "KE",
    CountryName: "Kenya",
    TimeZone: "Africa/Nairobi",
    GMTOffset: "+03:00"
  },
  {
    CountryCode: "KI",
    CountryName: "Kiribati",
    TimeZone: "Pacific/Kanton",
    GMTOffset: "+13:00"
  },
  {
    CountryCode: "KI",
    CountryName: "Kiribati",
    TimeZone: "Pacific/Kiritimati",
    GMTOffset: "+14:00"
  },
  {
    CountryCode: "KI",
    CountryName: "Kiribati",
    TimeZone: "Pacific/Tarawa",
    GMTOffset: "+12:00"
  },
  {
    CountryCode: "KP",
    CountryName: "Korea",
    TimeZone: " Democratic People's Republic of",
    GMTOffset: "Asia/Pyongyang"
  },
  {
    CountryCode: "KR",
    CountryName: "Korea",
    TimeZone: " Republic of",
    GMTOffset: "Asia/Seoul"
  },
  {
    CountryCode: "KW",
    CountryName: "Kuwait",
    TimeZone: "Asia/Kuwait",
    GMTOffset: "+03:00"
  },
  {
    CountryCode: "KG",
    CountryName: "Kyrgyzstan",
    TimeZone: "Asia/Bishkek",
    GMTOffset: "+06:00"
  },
  {
    CountryCode: "LA",
    CountryName: "Lao People's Democratic Republic",
    TimeZone: "Asia/Vientiane",
    GMTOffset: "+07:00"
  },
  {
    CountryCode: "LV",
    CountryName: "Latvia",
    TimeZone: "Europe/Riga",
    GMTOffset: "+02:00"
  },
  {
    CountryCode: "LB",
    CountryName: "Lebanon",
    TimeZone: "Asia/Beirut",
    GMTOffset: "+02:00"
  },
  {
    CountryCode: "LS",
    CountryName: "Lesotho",
    TimeZone: "Africa/Maseru",
    GMTOffset: "+02:00"
  },
  {
    CountryCode: "LR",
    CountryName: "Liberia",
    TimeZone: "Africa/Monrovia",
    GMTOffset: "UTC"
  },
  {
    CountryCode: "LY",
    CountryName: "Libya",
    TimeZone: "Africa/Tripoli",
    GMTOffset: "+02:00"
  },
  {
    CountryCode: "LI",
    CountryName: "Liechtenstein",
    TimeZone: "Europe/Vaduz",
    GMTOffset: "+01:00"
  },
  {
    CountryCode: "LT",
    CountryName: "Lithuania",
    TimeZone: "Europe/Vilnius",
    GMTOffset: "+02:00"
  },
  {
    CountryCode: "LU",
    CountryName: "Luxembourg",
    TimeZone: "Europe/Luxembourg",
    GMTOffset: "+01:00"
  },
  {
    CountryCode: "MO",
    CountryName: "Macao",
    TimeZone: "Asia/Macau",
    GMTOffset: "+08:00"
  },
  {
    CountryCode: "MK",
    CountryName: "Macedonia",
    TimeZone: " the Former Yugoslav Republic of",
    GMTOffset: "Europe/Skopje"
  },
  {
    CountryCode: "MG",
    CountryName: "Madagascar",
    TimeZone: "Indian/Antananarivo",
    GMTOffset: "+03:00"
  },
  {
    CountryCode: "MW",
    CountryName: "Malawi",
    TimeZone: "Africa/Blantyre",
    GMTOffset: "+02:00"
  },
  {
    CountryCode: "MY",
    CountryName: "Malaysia",
    TimeZone: "Asia/Kuala_Lumpur",
    GMTOffset: "+08:00"
  },
  {
    CountryCode: "MY",
    CountryName: "Malaysia",
    TimeZone: "Asia/Kuching",
    GMTOffset: "+08:00"
  },
  {
    CountryCode: "MV",
    CountryName: "Maldives",
    TimeZone: "Indian/Maldives",
    GMTOffset: "+05:00"
  },
  {
    CountryCode: "ML",
    CountryName: "Mali",
    TimeZone: "Africa/Bamako",
    GMTOffset: "UTC"
  },
  {
    CountryCode: "MT",
    CountryName: "Malta",
    TimeZone: "Europe/Malta",
    GMTOffset: "+01:00"
  },
  {
    CountryCode: "MH",
    CountryName: "Marshall Islands",
    TimeZone: "Pacific/Kwajalein",
    GMTOffset: "+12:00"
  },
  {
    CountryCode: "MH",
    CountryName: "Marshall Islands",
    TimeZone: "Pacific/Majuro",
    GMTOffset: "+12:00"
  },
  {
    CountryCode: "MQ",
    CountryName: "Martinique",
    TimeZone: "America/Martinique",
    GMTOffset: "-04:00"
  },
  {
    CountryCode: "MR",
    CountryName: "Mauritania",
    TimeZone: "Africa/Nouakchott",
    GMTOffset: "UTC"
  },
  {
    CountryCode: "MU",
    CountryName: "Mauritius",
    TimeZone: "Indian/Mauritius",
    GMTOffset: "+04:00"
  },
  {
    CountryCode: "YT",
    CountryName: "Mayotte",
    TimeZone: "Indian/Mayotte",
    GMTOffset: "+03:00"
  },
  {
    CountryCode: "MX",
    CountryName: "Mexico",
    TimeZone: "America/Bahia_Banderas",
    GMTOffset: "-06:00"
  },
  {
    CountryCode: "MX",
    CountryName: "Mexico",
    TimeZone: "America/Cancun",
    GMTOffset: "-05:00"
  },
  {
    CountryCode: "MX",
    CountryName: "Mexico",
    TimeZone: "America/Chihuahua",
    GMTOffset: "-06:00"
  },
  {
    CountryCode: "MX",
    CountryName: "Mexico",
    TimeZone: "America/Ciudad_Juarez",
    GMTOffset: "-07:00"
  },
  {
    CountryCode: "MX",
    CountryName: "Mexico",
    TimeZone: "America/Hermosillo",
    GMTOffset: "-07:00"
  },
  {
    CountryCode: "MX",
    CountryName: "Mexico",
    TimeZone: "America/Matamoros",
    GMTOffset: "-06:00"
  },
  {
    CountryCode: "MX",
    CountryName: "Mexico",
    TimeZone: "America/Mazatlan",
    GMTOffset: "-07:00"
  },
  {
    CountryCode: "MX",
    CountryName: "Mexico",
    TimeZone: "America/Merida",
    GMTOffset: "-06:00"
  },
  {
    CountryCode: "MX",
    CountryName: "Mexico",
    TimeZone: "America/Mexico_City",
    GMTOffset: "-06:00"
  },
  {
    CountryCode: "MX",
    CountryName: "Mexico",
    TimeZone: "America/Monterrey",
    GMTOffset: "-06:00"
  },
  {
    CountryCode: "MX",
    CountryName: "Mexico",
    TimeZone: "America/Ojinaga",
    GMTOffset: "-06:00"
  },
  {
    CountryCode: "MX",
    CountryName: "Mexico",
    TimeZone: "America/Tijuana",
    GMTOffset: "-08:00"
  },
  {
    CountryCode: "FM",
    CountryName: "Micronesia",
    TimeZone: " Federated States of",
    GMTOffset: "Pacific/Chuuk"
  },
  {
    CountryCode: "FM",
    CountryName: "Micronesia",
    TimeZone: " Federated States of",
    GMTOffset: "Pacific/Kosrae"
  },
  {
    CountryCode: "FM",
    CountryName: "Micronesia",
    TimeZone: " Federated States of",
    GMTOffset: "Pacific/Pohnpei"
  },
  {
    CountryCode: "MD",
    CountryName: "Moldova",
    TimeZone: " Republic of",
    GMTOffset: "Europe/Chisinau"
  },
  {
    CountryCode: "MC",
    CountryName: "Monaco",
    TimeZone: "Europe/Monaco",
    GMTOffset: "+01:00"
  },
  {
    CountryCode: "MN",
    CountryName: "Mongolia",
    TimeZone: "Asia/Choibalsan",
    GMTOffset: "+08:00"
  },
  {
    CountryCode: "MN",
    CountryName: "Mongolia",
    TimeZone: "Asia/Hovd",
    GMTOffset: "+07:00"
  },
  {
    CountryCode: "MN",
    CountryName: "Mongolia",
    TimeZone: "Asia/Ulaanbaatar",
    GMTOffset: "+08:00"
  },
  {
    CountryCode: "ME",
    CountryName: "Montenegro",
    TimeZone: "Europe/Podgorica",
    GMTOffset: "+01:00"
  },
  {
    CountryCode: "MS",
    CountryName: "Montserrat",
    TimeZone: "America/Montserrat",
    GMTOffset: "-04:00"
  },
  {
    CountryCode: "MA",
    CountryName: "Morocco",
    TimeZone: "Africa/Casablanca",
    GMTOffset: "+01:00"
  },
  {
    CountryCode: "MA",
    CountryName: "Morocco",
    TimeZone: "Africa/El_Aaiun",
    GMTOffset: "+01:00"
  },
  {
    CountryCode: "MZ",
    CountryName: "Mozambique",
    TimeZone: "Africa/Maputo",
    GMTOffset: "+02:00"
  },
  {
    CountryCode: "MM",
    CountryName: "Myanmar",
    TimeZone: "Asia/Yangon",
    GMTOffset: "+06:30"
  },
  {
    CountryCode: "NA",
    CountryName: "Namibia",
    TimeZone: "Africa/Windhoek",
    GMTOffset: "+02:00"
  },
  {
    CountryCode: "NR",
    CountryName: "Nauru",
    TimeZone: "Pacific/Nauru",
    GMTOffset: "+12:00"
  },
  {
    CountryCode: "NP",
    CountryName: "Nepal",
    TimeZone: "Asia/Kathmandu",
    GMTOffset: "+05:45"
  },
  {
    CountryCode: "NL",
    CountryName: "Netherlands",
    TimeZone: "Europe/Amsterdam",
    GMTOffset: "+01:00"
  },
  {
    CountryCode: "NC",
    CountryName: "New Caledonia",
    TimeZone: "Pacific/Noumea",
    GMTOffset: "+11:00"
  },
  {
    CountryCode: "NZ",
    CountryName: "New Zealand",
    TimeZone: "Pacific/Auckland",
    GMTOffset: "+13:00"
  },
  {
    CountryCode: "NZ",
    CountryName: "New Zealand",
    TimeZone: "Pacific/Chatham",
    GMTOffset: "+13:45"
  },
  {
    CountryCode: "NI",
    CountryName: "Nicaragua",
    TimeZone: "America/Managua",
    GMTOffset: "-06:00"
  },
  {
    CountryCode: "NE",
    CountryName: "Niger",
    TimeZone: "Africa/Niamey",
    GMTOffset: "+01:00"
  },
  {
    CountryCode: "NG",
    CountryName: "Nigeria",
    TimeZone: "Africa/Lagos",
    GMTOffset: "+01:00"
  },
  {
    CountryCode: "NU",
    CountryName: "Niue",
    TimeZone: "Pacific/Niue",
    GMTOffset: "-11:00"
  },
  {
    CountryCode: "NF",
    CountryName: "Norfolk Island",
    TimeZone: "Pacific/Norfolk",
    GMTOffset: "+12:00"
  },
  {
    CountryCode: "MP",
    CountryName: "Northern Mariana Islands",
    TimeZone: "Pacific/Saipan",
    GMTOffset: "+10:00"
  },
  {
    CountryCode: "NO",
    CountryName: "Norway",
    TimeZone: "Europe/Oslo",
    GMTOffset: "+01:00"
  },
  {
    CountryCode: "OM",
    CountryName: "Oman",
    TimeZone: "Asia/Muscat",
    GMTOffset: "+04:00"
  },
  {
    CountryCode: "PK",
    CountryName: "Pakistan",
    TimeZone: "Asia/Karachi",
    GMTOffset: "+05:00"
  },
  {
    CountryCode: "PW",
    CountryName: "Palau",
    TimeZone: "Pacific/Palau",
    GMTOffset: "+09:00"
  },
  {
    CountryCode: "PS",
    CountryName: "Palestine",
    TimeZone: " State of",
    GMTOffset: "Asia/Gaza"
  },
  {
    CountryCode: "PS",
    CountryName: "Palestine",
    TimeZone: " State of",
    GMTOffset: "Asia/Hebron"
  },
  {
    CountryCode: "PA",
    CountryName: "Panama",
    TimeZone: "America/Panama",
    GMTOffset: "-05:00"
  },
  {
    CountryCode: "PG",
    CountryName: "Papua New Guinea",
    TimeZone: "Pacific/Bougainville",
    GMTOffset: "+11:00"
  },
  {
    CountryCode: "PG",
    CountryName: "Papua New Guinea",
    TimeZone: "Pacific/Port_Moresby",
    GMTOffset: "+10:00"
  },
  {
    CountryCode: "PY",
    CountryName: "Paraguay",
    TimeZone: "America/Asuncion",
    GMTOffset: "-03:00"
  },
  {
    CountryCode: "PE",
    CountryName: "Peru",
    TimeZone: "America/Lima",
    GMTOffset: "-05:00"
  },
  {
    CountryCode: "PH",
    CountryName: "Philippines",
    TimeZone: "Asia/Manila",
    GMTOffset: "+08:00"
  },
  {
    CountryCode: "PN",
    CountryName: "Pitcairn",
    TimeZone: "Pacific/Pitcairn",
    GMTOffset: "-08:00"
  },
  {
    CountryCode: "PL",
    CountryName: "Poland",
    TimeZone: "Europe/Warsaw",
    GMTOffset: "+01:00"
  },
  {
    CountryCode: "PT",
    CountryName: "Portugal",
    TimeZone: "Atlantic/Azores",
    GMTOffset: "-01:00"
  },
  {
    CountryCode: "PT",
    CountryName: "Portugal",
    TimeZone: "Atlantic/Madeira",
    GMTOffset: "UTC"
  },
  {
    CountryCode: "PT",
    CountryName: "Portugal",
    TimeZone: "Europe/Lisbon",
    GMTOffset: "UTC"
  },
  {
    CountryCode: "PR",
    CountryName: "Puerto Rico",
    TimeZone: "America/Puerto_Rico",
    GMTOffset: "-04:00"
  },
  {
    CountryCode: "QA",
    CountryName: "Qatar",
    TimeZone: "Asia/Qatar",
    GMTOffset: "+03:00"
  },
  {
    CountryCode: "RO",
    CountryName: "Romania",
    TimeZone: "Europe/Bucharest",
    GMTOffset: "+02:00"
  },
  {
    CountryCode: "RU",
    CountryName: "Russian Federation",
    TimeZone: "Asia/Anadyr",
    GMTOffset: "+12:00"
  },
  {
    CountryCode: "RU",
    CountryName: "Russian Federation",
    TimeZone: "Asia/Barnaul",
    GMTOffset: "+07:00"
  },
  {
    CountryCode: "RU",
    CountryName: "Russian Federation",
    TimeZone: "Asia/Chita",
    GMTOffset: "+09:00"
  },
  {
    CountryCode: "RU",
    CountryName: "Russian Federation",
    TimeZone: "Asia/Irkutsk",
    GMTOffset: "+08:00"
  },
  {
    CountryCode: "RU",
    CountryName: "Russian Federation",
    TimeZone: "Asia/Kamchatka",
    GMTOffset: "+12:00"
  },
  {
    CountryCode: "RU",
    CountryName: "Russian Federation",
    TimeZone: "Asia/Khandyga",
    GMTOffset: "+09:00"
  },
  {
    CountryCode: "RU",
    CountryName: "Russian Federation",
    TimeZone: "Asia/Krasnoyarsk",
    GMTOffset: "+07:00"
  },
  {
    CountryCode: "RU",
    CountryName: "Russian Federation",
    TimeZone: "Asia/Magadan",
    GMTOffset: "+11:00"
  },
  {
    CountryCode: "RU",
    CountryName: "Russian Federation",
    TimeZone: "Asia/Novokuznetsk",
    GMTOffset: "+07:00"
  },
  {
    CountryCode: "RU",
    CountryName: "Russian Federation",
    TimeZone: "Asia/Novosibirsk",
    GMTOffset: "+07:00"
  },
  {
    CountryCode: "RU",
    CountryName: "Russian Federation",
    TimeZone: "Asia/Omsk",
    GMTOffset: "+06:00"
  },
  {
    CountryCode: "RU",
    CountryName: "Russian Federation",
    TimeZone: "Asia/Sakhalin",
    GMTOffset: "+11:00"
  },
  {
    CountryCode: "RU",
    CountryName: "Russian Federation",
    TimeZone: "Asia/Srednekolymsk",
    GMTOffset: "+11:00"
  },
  {
    CountryCode: "RU",
    CountryName: "Russian Federation",
    TimeZone: "Asia/Tomsk",
    GMTOffset: "+07:00"
  },
  {
    CountryCode: "RU",
    CountryName: "Russian Federation",
    TimeZone: "Asia/Ust-Nera",
    GMTOffset: "+10:00"
  },
  {
    CountryCode: "RU",
    CountryName: "Russian Federation",
    TimeZone: "Asia/Vladivostok",
    GMTOffset: "+10:00"
  },
  {
    CountryCode: "RU",
    CountryName: "Russian Federation",
    TimeZone: "Asia/Yakutsk",
    GMTOffset: "+09:00"
  },
  {
    CountryCode: "RU",
    CountryName: "Russian Federation",
    TimeZone: "Asia/Yekaterinburg",
    GMTOffset: "+05:00"
  },
  {
    CountryCode: "RU",
    CountryName: "Russian Federation",
    TimeZone: "Europe/Astrakhan",
    GMTOffset: "+04:00"
  },
  {
    CountryCode: "RU",
    CountryName: "Russian Federation",
    TimeZone: "Europe/Kaliningrad",
    GMTOffset: "+02:00"
  },
  {
    CountryCode: "RU",
    CountryName: "Russian Federation",
    TimeZone: "Europe/Kirov",
    GMTOffset: "+03:00"
  },
  {
    CountryCode: "RU",
    CountryName: "Russian Federation",
    TimeZone: "Europe/Moscow",
    GMTOffset: "+03:00"
  },
  {
    CountryCode: "RU",
    CountryName: "Russian Federation",
    TimeZone: "Europe/Samara",
    GMTOffset: "+04:00"
  },
  {
    CountryCode: "RU",
    CountryName: "Russian Federation",
    TimeZone: "Europe/Saratov",
    GMTOffset: "+04:00"
  },
  {
    CountryCode: "RU",
    CountryName: "Russian Federation",
    TimeZone: "Europe/Ulyanovsk",
    GMTOffset: "+04:00"
  },
  {
    CountryCode: "RU",
    CountryName: "Russian Federation",
    TimeZone: "Europe/Volgograd",
    GMTOffset: "+03:00"
  },
  {
    CountryCode: "RW",
    CountryName: "Rwanda",
    TimeZone: "Africa/Kigali",
    GMTOffset: "+02:00"
  },
  {
    CountryCode: "RE",
    CountryName: "Réunion",
    TimeZone: "Indian/Reunion",
    GMTOffset: "+04:00"
  },
  {
    CountryCode: "BL",
    CountryName: "Saint Barthélemy",
    TimeZone: "America/St_Barthelemy",
    GMTOffset: "-04:00"
  },
  {
    CountryCode: "SH",
    CountryName: "Saint Helena",
    TimeZone: " Ascension and Tristan da Cunha",
    GMTOffset: "Atlantic/St_Helena"
  },
  {
    CountryCode: "KN",
    CountryName: "Saint Kitts and Nevis",
    TimeZone: "America/St_Kitts",
    GMTOffset: "-04:00"
  },
  {
    CountryCode: "LC",
    CountryName: "Saint Lucia",
    TimeZone: "America/St_Lucia",
    GMTOffset: "-04:00"
  },
  {
    CountryCode: "MF",
    CountryName: "Saint Martin (French part)",
    TimeZone: "America/Marigot",
    GMTOffset: "-04:00"
  },
  {
    CountryCode: "PM",
    CountryName: "Saint Pierre and Miquelon",
    TimeZone: "America/Miquelon",
    GMTOffset: "-03:00"
  },
  {
    CountryCode: "VC",
    CountryName: "Saint Vincent and the Grenadines",
    TimeZone: "America/St_Vincent",
    GMTOffset: "-04:00"
  },
  {
    CountryCode: "WS",
    CountryName: "Samoa",
    TimeZone: "Pacific/Apia",
    GMTOffset: "+13:00"
  },
  {
    CountryCode: "SM",
    CountryName: "San Marino",
    TimeZone: "Europe/San_Marino",
    GMTOffset: "+01:00"
  },
  {
    CountryCode: "ST",
    CountryName: "Sao Tome and Principe",
    TimeZone: "Africa/Sao_Tome",
    GMTOffset: "UTC"
  },
  {
    CountryCode: "SA",
    CountryName: "Saudi Arabia",
    TimeZone: "Asia/Riyadh",
    GMTOffset: "+03:00"
  },
  {
    CountryCode: "SN",
    CountryName: "Senegal",
    TimeZone: "Africa/Dakar",
    GMTOffset: "UTC"
  },
  {
    CountryCode: "RS",
    CountryName: "Serbia",
    TimeZone: "Europe/Belgrade",
    GMTOffset: "+01:00"
  },
  {
    CountryCode: "SC",
    CountryName: "Seychelles",
    TimeZone: "Indian/Mahe",
    GMTOffset: "+04:00"
  },
  {
    CountryCode: "SL",
    CountryName: "Sierra Leone",
    TimeZone: "Africa/Freetown",
    GMTOffset: "UTC"
  },
  {
    CountryCode: "SG",
    CountryName: "Singapore",
    TimeZone: "Asia/Singapore",
    GMTOffset: "+08:00"
  },
  {
    CountryCode: "SX",
    CountryName: "Sint Maarten (Dutch part)",
    TimeZone: "America/Lower_Princes",
    GMTOffset: "-04:00"
  },
  {
    CountryCode: "SK",
    CountryName: "Slovakia",
    TimeZone: "Europe/Bratislava",
    GMTOffset: "+01:00"
  },
  {
    CountryCode: "SI",
    CountryName: "Slovenia",
    TimeZone: "Europe/Ljubljana",
    GMTOffset: "+01:00"
  },
  {
    CountryCode: "SB",
    CountryName: "Solomon Islands",
    TimeZone: "Pacific/Guadalcanal",
    GMTOffset: "+11:00"
  },
  {
    CountryCode: "SO",
    CountryName: "Somalia",
    TimeZone: "Africa/Mogadishu",
    GMTOffset: "+03:00"
  },
  {
    CountryCode: "ZA",
    CountryName: "South Africa",
    TimeZone: "Africa/Johannesburg",
    GMTOffset: "+02:00"
  },
  {
    CountryCode: "GS",
    CountryName: "South Georgia and the South Sandwich Islands",
    TimeZone: "Atlantic/South_Georgia",
    GMTOffset: "-02:00"
  },
  {
    CountryCode: "SS",
    CountryName: "South Sudan",
    TimeZone: "Africa/Juba",
    GMTOffset: "+02:00"
  },
  {
    CountryCode: "ES",
    CountryName: "Spain",
    TimeZone: "Africa/Ceuta",
    GMTOffset: "+01:00"
  },
  {
    CountryCode: "ES",
    CountryName: "Spain",
    TimeZone: "Atlantic/Canary",
    GMTOffset: "UTC"
  },
  {
    CountryCode: "ES",
    CountryName: "Spain",
    TimeZone: "Europe/Madrid",
    GMTOffset: "+01:00"
  },
  {
    CountryCode: "LK",
    CountryName: "Sri Lanka",
    TimeZone: "Asia/Colombo",
    GMTOffset: "+05:30"
  },
  {
    CountryCode: "SD",
    CountryName: "Sudan",
    TimeZone: "Africa/Khartoum",
    GMTOffset: "+02:00"
  },
  {
    CountryCode: "SR",
    CountryName: "Suriname",
    TimeZone: "America/Paramaribo",
    GMTOffset: "-03:00"
  },
  {
    CountryCode: "SJ",
    CountryName: "Svalbard and Jan Mayen",
    TimeZone: "Arctic/Longyearbyen",
    GMTOffset: "+01:00"
  },
  {
    CountryCode: "SZ",
    CountryName: "Swaziland",
    TimeZone: "Africa/Mbabane",
    GMTOffset: "+02:00"
  },
  {
    CountryCode: "SE",
    CountryName: "Sweden",
    TimeZone: "Europe/Stockholm",
    GMTOffset: "+01:00"
  },
  {
    CountryCode: "CH",
    CountryName: "Switzerland",
    TimeZone: "Europe/Zurich",
    GMTOffset: "+01:00"
  },
  {
    CountryCode: "SY",
    CountryName: "Syrian Arab Republic",
    TimeZone: "Asia/Damascus",
    GMTOffset: "+03:00"
  },
  {
    CountryCode: "TW",
    CountryName: "Taiwan",
    TimeZone: " Province of China",
    GMTOffset: "Asia/Taipei"
  },
  {
    CountryCode: "TJ",
    CountryName: "Tajikistan",
    TimeZone: "Asia/Dushanbe",
    GMTOffset: "+05:00"
  },
  {
    CountryCode: "TZ",
    CountryName: "Tanzania",
    TimeZone: " United Republic of",
    GMTOffset: "Africa/Dar_es_Salaam"
  },
  {
    CountryCode: "TH",
    CountryName: "Thailand",
    TimeZone: "Asia/Bangkok",
    GMTOffset: "+07:00"
  },
  {
    CountryCode: "TL",
    CountryName: "Timor-Leste",
    TimeZone: "Asia/Dili",
    GMTOffset: "+09:00"
  },
  {
    CountryCode: "TG",
    CountryName: "Togo",
    TimeZone: "Africa/Lome",
    GMTOffset: "UTC"
  },
  {
    CountryCode: "TK",
    CountryName: "Tokelau",
    TimeZone: "Pacific/Fakaofo",
    GMTOffset: "+13:00"
  },
  {
    CountryCode: "TO",
    CountryName: "Tonga",
    TimeZone: "Pacific/Tongatapu",
    GMTOffset: "+13:00"
  },
  {
    CountryCode: "TT",
    CountryName: "Trinidad and Tobago",
    TimeZone: "America/Port_of_Spain",
    GMTOffset: "-04:00"
  },
  {
    CountryCode: "TN",
    CountryName: "Tunisia",
    TimeZone: "Africa/Tunis",
    GMTOffset: "+01:00"
  },
  {
    CountryCode: "TR",
    CountryName: "Turkey",
    TimeZone: "Europe/Istanbul",
    GMTOffset: "+03:00"
  },
  {
    CountryCode: "TM",
    CountryName: "Turkmenistan",
    TimeZone: "Asia/Ashgabat",
    GMTOffset: "+05:00"
  },
  {
    CountryCode: "TC",
    CountryName: "Turks and Caicos Islands",
    TimeZone: "America/Grand_Turk",
    GMTOffset: "-05:00"
  },
  {
    CountryCode: "TV",
    CountryName: "Tuvalu",
    TimeZone: "Pacific/Funafuti",
    GMTOffset: "+12:00"
  },
  {
    CountryCode: "UG",
    CountryName: "Uganda",
    TimeZone: "Africa/Kampala",
    GMTOffset: "+03:00"
  },
  {
    CountryCode: "UA",
    CountryName: "Ukraine",
    TimeZone: "Europe/Kyiv",
    GMTOffset: "+02:00"
  },
  {
    CountryCode: "UA",
    CountryName: "Ukraine",
    TimeZone: "Europe/Simferopol",
    GMTOffset: "+03:00"
  },
  {
    CountryCode: "AE",
    CountryName: "United Arab Emirates",
    TimeZone: "Asia/Dubai",
    GMTOffset: "+04:00"
  },
  {
    CountryCode: "GB",
    CountryName: "United Kingdom",
    TimeZone: "Europe/London",
    GMTOffset: "UTC"
  },
  {
    CountryCode: "US",
    CountryName: "United States",
    TimeZone: "America/Adak",
    GMTOffset: "-10:00"
  },
  {
    CountryCode: "US",
    CountryName: "United States",
    TimeZone: "America/Anchorage",
    GMTOffset: "-09:00"
  },
  {
    CountryCode: "US",
    CountryName: "United States",
    TimeZone: "America/Boise",
    GMTOffset: "-07:00"
  },
  {
    CountryCode: "US",
    CountryName: "United States",
    TimeZone: "America/Chicago",
    GMTOffset: "-06:00"
  },
  {
    CountryCode: "US",
    CountryName: "United States",
    TimeZone: "America/Denver",
    GMTOffset: "-07:00"
  },
  {
    CountryCode: "US",
    CountryName: "United States",
    TimeZone: "America/Detroit",
    GMTOffset: "-05:00"
  },
  {
    CountryCode: "US",
    CountryName: "United States",
    TimeZone: "America/Indiana/Indianapolis",
    GMTOffset: "-05:00"
  },
  {
    CountryCode: "US",
    CountryName: "United States",
    TimeZone: "America/Indiana/Knox",
    GMTOffset: "-06:00"
  },
  {
    CountryCode: "US",
    CountryName: "United States",
    TimeZone: "America/Indiana/Marengo",
    GMTOffset: "-05:00"
  },
  {
    CountryCode: "US",
    CountryName: "United States",
    TimeZone: "America/Indiana/Petersburg",
    GMTOffset: "-05:00"
  },
  {
    CountryCode: "US",
    CountryName: "United States",
    TimeZone: "America/Indiana/Tell_City",
    GMTOffset: "-06:00"
  },
  {
    CountryCode: "US",
    CountryName: "United States",
    TimeZone: "America/Indiana/Vevay",
    GMTOffset: "-05:00"
  },
  {
    CountryCode: "US",
    CountryName: "United States",
    TimeZone: "America/Indiana/Vincennes",
    GMTOffset: "-05:00"
  },
  {
    CountryCode: "US",
    CountryName: "United States",
    TimeZone: "America/Indiana/Winamac",
    GMTOffset: "-05:00"
  },
  {
    CountryCode: "US",
    CountryName: "United States",
    TimeZone: "America/Juneau",
    GMTOffset: "-09:00"
  },
  {
    CountryCode: "US",
    CountryName: "United States",
    TimeZone: "America/Kentucky/Louisville",
    GMTOffset: "-05:00"
  },
  {
    CountryCode: "US",
    CountryName: "United States",
    TimeZone: "America/Kentucky/Monticello",
    GMTOffset: "-05:00"
  },
  {
    CountryCode: "US",
    CountryName: "United States",
    TimeZone: "America/Los_Angeles",
    GMTOffset: "-08:00"
  },
  {
    CountryCode: "US",
    CountryName: "United States",
    TimeZone: "America/Menominee",
    GMTOffset: "-06:00"
  },
  {
    CountryCode: "US",
    CountryName: "United States",
    TimeZone: "America/Metlakatla",
    GMTOffset: "-09:00"
  },
  {
    CountryCode: "US",
    CountryName: "United States",
    TimeZone: "America/New_York",
    GMTOffset: "-05:00"
  },
  {
    CountryCode: "US",
    CountryName: "United States",
    TimeZone: "America/Nome",
    GMTOffset: "-09:00"
  },
  {
    CountryCode: "US",
    CountryName: "United States",
    TimeZone: "America/North_Dakota/Beulah",
    GMTOffset: "-06:00"
  },
  {
    CountryCode: "US",
    CountryName: "United States",
    TimeZone: "America/North_Dakota/Center",
    GMTOffset: "-06:00"
  },
  {
    CountryCode: "US",
    CountryName: "United States",
    TimeZone: "America/North_Dakota/New_Salem",
    GMTOffset: "-06:00"
  },
  {
    CountryCode: "US",
    CountryName: "United States",
    TimeZone: "America/Phoenix",
    GMTOffset: "-07:00"
  },
  {
    CountryCode: "US",
    CountryName: "United States",
    TimeZone: "America/Sitka",
    GMTOffset: "-09:00"
  },
  {
    CountryCode: "US",
    CountryName: "United States",
    TimeZone: "America/Yakutat",
    GMTOffset: "-09:00"
  },
  {
    CountryCode: "US",
    CountryName: "United States",
    TimeZone: "Pacific/Honolulu",
    GMTOffset: "-10:00"
  },
  {
    CountryCode: "UM",
    CountryName: "United States Minor Outlying Islands",
    TimeZone: "Pacific/Midway",
    GMTOffset: "-11:00"
  },
  {
    CountryCode: "UM",
    CountryName: "United States Minor Outlying Islands",
    TimeZone: "Pacific/Wake",
    GMTOffset: "+12:00"
  },
  {
    CountryCode: "UY",
    CountryName: "Uruguay",
    TimeZone: "America/Montevideo",
    GMTOffset: "-03:00"
  },
  {
    CountryCode: "UZ",
    CountryName: "Uzbekistan",
    TimeZone: "Asia/Samarkand",
    GMTOffset: "+05:00"
  },
  {
    CountryCode: "UZ",
    CountryName: "Uzbekistan",
    TimeZone: "Asia/Tashkent",
    GMTOffset: "+05:00"
  },
  {
    CountryCode: "VU",
    CountryName: "Vanuatu",
    TimeZone: "Pacific/Efate",
    GMTOffset: "+11:00"
  },
  {
    CountryCode: "VE",
    CountryName: "Venezuela",
    TimeZone: " Bolivarian Republic of",
    GMTOffset: "America/Caracas"
  },
  {
    CountryCode: "VN",
    CountryName: "Viet Nam",
    TimeZone: "Asia/Ho_Chi_Minh",
    GMTOffset: "+07:00"
  },
  {
    CountryCode: "VG",
    CountryName: "Virgin Islands",
    TimeZone: " British",
    GMTOffset: "America/Tortola"
  },
  {
    CountryCode: "VI",
    CountryName: "Virgin Islands",
    TimeZone: " U.S.",
    GMTOffset: "America/St_Thomas"
  },
  {
    CountryCode: "WF",
    CountryName: "Wallis and Futuna",
    TimeZone: "Pacific/Wallis",
    GMTOffset: "+12:00"
  },
  {
    CountryCode: "YE",
    CountryName: "Yemen",
    TimeZone: "Asia/Aden",
    GMTOffset: "+03:00"
  },
  {
    CountryCode: "ZM",
    CountryName: "Zambia",
    TimeZone: "Africa/Lusaka",
    GMTOffset: "+02:00"
  },
  {
    CountryCode: "ZW",
    CountryName: "Zimbabwe",
    TimeZone: "Africa/Harare",
    GMTOffset: "+02:00"
  },
  {
    CountryCode: "AX",
    CountryName: "Åland Islands",
    TimeZone: "Europe/Mariehamn",
    GMTOffset: "+02:00"
  }
]
