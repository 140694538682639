import React, { useState,useEffect } from 'react';
import { useSelector,useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { getuserResources } from '../../../../redux/thunk/resources/fetchdata';

import { MdAdd } from 'react-icons/md';
import { BiTrashAlt } from 'react-icons/bi';
import { BiPencil } from 'react-icons/bi';
import { HiHashtag } from 'react-icons/hi';

import Classicspiner from '../../../../spinner/classic-spiner/Classicspiner';
import * as actionCreators from "../../../../redux/actions/actionCreators";
import './Resources.css';

function Resources() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const allResources = useSelector((state) => state.resourcesReducer.allResources);
  const userdetails = useSelector((state) => state.userReducer.userdetails);
  const topicobj = useSelector((state) => state.activeReducer.topicid);

  const [linkName,setlinkName] = useState('');
  const [linkAdd,setlinkAdd] = useState('');
  const [recNum,setrecNum] = useState('');
  const [loading,setLoading] = useState(false);

  const handleaddResources = () => {
     dispatch(actionCreators.addResources(true));
  }

  const updateResources = (asseturl,assetname,assetid) => {

     let updateobj = {
        updateflag:true,
        assetid:assetid,
        asseturl:asseturl,
        assetname:assetname,
        userid:userdetails.userid,
        topicid:topicobj.topicid
     }

     dispatch(actionCreators.updateResources(updateobj));

  }

  const deleteResources = (assetid,assetname) => {

    let deleteobj = {
       assetid:assetid,
       assetname:assetname,
       deleteflag:true
    }

    dispatch(actionCreators.delResources(deleteobj));

  }

  const handleTopic = (topicid) => {
     navigate(`/homepage/pool/pooldetails/${topicid}`)
  }

  useEffect(async () => {

    window.scrollTo(0, 0);

    setLoading(true);

   if(userdetails.userid && topicobj.topicid) {

     let obj = {
       userid:userdetails.userid,
       topicid:topicobj.topicid
     }

     const response = await getuserResources(obj);

     if(response.STATUSCODE == 0) {
       dispatch(actionCreators.addAllResources(response.PAYLOAD));
    }

     setLoading(false);

  }

  },[userdetails,topicobj])

  return (
    <>
    {topicobj != null ?
    <div className="ac-wrapper">

        <div className="smart-tag-header-wrapper">
          <div className="smart-tag-hash"><HiHashtag /></div>
          <div className="smart-tag-header smart-tag-text" onClick={() => handleTopic(topicobj.topicid)}>
            {topicobj.topic}
          </div>
        </div>



         <div className="Resources-btn">
         <div onClick={handleaddResources}  className="Resources-btn-wrapper">
           <div>&nbsp;</div>
           <div className="Resources-btn-icon"><MdAdd /></div>
           <div className="Resources-btn-txt">&nbsp;Add resources</div>
        </div>
         </div>


         {loading ?
           <div className="Resources-loading">
            <Classicspiner />
          </div>:
          <div className="Resources-loading">

         </div> }

        <div className="Resources-link-list">


          {allResources?.map((element,index) => {

        return (
          <>
             <div className="Resources-list">
               <div className="Resources-item">

                 <div className="Resources-item-link">
                    <a className="Resources-link" href={element.asseturl}  target="_blank">{element.assetname}</a>
                 </div>

                  <div className="Resources-item-icon">

                    <div className="Resources-icon" onClick={() => deleteResources(element.assetid,element.assetname)} >
                      <BiTrashAlt />
                   </div>

                    <div className="Resources-icon" onClick={() => updateResources(element.asseturl,element.assetname,element.assetid)}>
                     <BiPencil />
                    </div>

                  </div>

                </div>
              </div>
            </>
           )
         })}

        </div>


     </div>  : null }
     </>
  )
}

export default Resources;
