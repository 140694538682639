import * as actionTypes from "../actions/actionTypes";

const initialState = {
   userroom:null
};

const socketReducer = (state = initialState, action) => {

  switch (action.type) {

    case actionTypes.ADD_ROOM_DETAILS:

      return {
        userroom:action.payload
      };

    default:
      return state;
  }
};

export default socketReducer;
