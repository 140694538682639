// Send user email OTP

export const sendEmailOtp = async(userObj) => {

  try {

    let url = '/sendemailotp';

    const requestOptions = {
         method: 'POST',
         headers: {
           'Content-Type': 'application/json',
           'Accept': 'application/json',
         }, body:JSON.stringify({
              userObj:userObj
          })
    };

     const response = await fetch(url,requestOptions);

     if(!response.ok) {
        throw {
          STATUSCODE:1,
          STATUS:"ERROR",
          MESSAGE:"Something broke!" }
      } else {
         const result = await response.json();
         return result;
      }

  } catch(error) {
      return error;
  }

}

export const verifyemailotp = async(verifyobj) => {

  try {

    let url = '/verifyemailotp';

    const requestOptions = {
         method: 'POST',
         headers: {
           'Content-Type': 'application/json',
           'Accept': 'application/json',
         }, body:JSON.stringify({
              verifyobj:verifyobj
          })
    };

     const response = await fetch(url,requestOptions);

     if(!response.ok) {
        throw {
          STATUSCODE:1,
          STATUS:"ERROR",
          MESSAGE:"Something broke!" }
      } else {
         const result = await response.json();
         return result;
      }

  } catch(error) {
      return error;
  }
}

export const verifyuserupdatemailopt = async(verifyobj) => {

  try {

    let url = '/verifyuserupdatemailopt';

    const requestOptions = {
         method: 'POST',
         headers: {
           'Content-Type': 'application/json',
           'Accept': 'application/json',
         }, body:JSON.stringify({
              verifyobj:verifyobj
          })
    };

     const response = await fetch(url,requestOptions);

     if(!response.ok) {
        throw {
          STATUSCODE:1,
          STATUS:"ERROR",
          MESSAGE:"Something broke!" }
      } else {
         const result = await response.json();
         return result;
      }

  } catch(error) {
      return error;
  }
}

export const checkUserPassword = async(usrpasswordobj) => {

  try {

    let url = '/checkuserpassword';

    const requestOptions = {
         method: 'POST',
         headers: {
           'Content-Type': 'application/json',
           'Accept': 'application/json',
         }, body:JSON.stringify({
              usrpasswordobj:usrpasswordobj
          })
    };

     const response = await fetch(url,requestOptions);

     if(!response.ok) {
        throw {
          STATUSCODE:1,
          STATUS:"ERROR",
          MESSAGE:"Something broke!" }
      } else {
         const result = await response.json();
         return result;
      }

  } catch(error) {
      return error;
  }

}

export const changeUserPassword = async(usrpassword) => {

  try {

    let url = '/changeuserpassword';

    const requestOptions = {
         method: 'POST',
         headers: {
           'Content-Type': 'application/json',
           'Accept': 'application/json',
         }, body:JSON.stringify({
              usrpassword:usrpassword
          })
    };

     const response = await fetch(url,requestOptions);

     if(!response.ok) {
        throw {
          STATUSCODE:1,
          STATUS:"ERROR",
          MESSAGE:"Something broke!" }
      } else {
         const result = await response.json();
         return result;
      }

  } catch(error) {
      return error;
  }

}

export const deactivateAccount = async(obj) => {

  try {

    let url = '/deactivateaccount';

    const requestOptions = {
         method: 'POST',
         headers: {
           'Content-Type': 'application/json',
           'Accept': 'application/json',
         }, body:JSON.stringify({
              obj:obj
          })
    };

     const response = await fetch(url,requestOptions);

     if(!response.ok) {
        throw {
          STATUSCODE:1,
          STATUS:"ERROR",
          MESSAGE:"Something broke!" }
      } else {
         const result = await response.json();
         return result;
      }

  } catch(error) {
      return error;
  }

}

export const updateUserName= async(userobj) => {

  try {

    let url = '/updateusername';

    const requestOptions = {
         method: 'POST',
         headers: {
           'Content-Type': 'application/json',
           'Accept': 'application/json',
         }, body:JSON.stringify({
              userobj:userobj
          })
    };

     const response = await fetch(url,requestOptions);

     if(!response.ok) {
        throw {
          STATUSCODE:1,
          STATUS:"ERROR",
          MESSAGE:"Something broke!" }
      } else {
         const result = await response.json();
         return result;
      }

  } catch(error) {
      return error;
  }

}

export const resetPassword = async(passobj) => {

  try {

    let url = '/resetpassword';

    const requestOptions = {
         method: 'POST',
         headers: {
           'Content-Type': 'application/json',
           'Accept': 'application/json',
         }, body:JSON.stringify({
              passobj:passobj
          })
    };

     const response = await fetch(url,requestOptions);

     if(!response.ok) {
        throw {
          STATUSCODE:1,
          STATUS:"ERROR",
          MESSAGE:"Something broke!" }
      } else {
         const result = await response.json();
         return result;
      }

  } catch(error) {
      return error;
  }


}
