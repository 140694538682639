
export const leavesquadupdate = async(squadObj) => {

  try {

    let url = '/leavesquad';

    const requestOptions = {
         method: 'POST',
         headers: {
           'Content-Type': 'application/json',
           'Accept': 'application/json',
         }, body:JSON.stringify({
              squadObj:squadObj
          })
    };

     const response = await fetch(url,requestOptions);

     if(!response.ok) {
        throw {
          STATUSCODE:1,
          STATUS:"ERROR",
          MESSAGE:"Something broke!" }
      } else {
         const result = await response.json();
         return result;
      }

  } catch(error) {
      return error;
  }

}

export const removesquadupdate = async(squadObj) => {

  try {

    let url = '/removesquad';

    const requestOptions = {
         method: 'POST',
         headers: {
           'Content-Type': 'application/json',
           'Accept': 'application/json',
         }, body:JSON.stringify({
              squadObj:squadObj
          })
    };

     const response = await fetch(url,requestOptions);

     if(!response.ok) {
        throw {
          STATUSCODE:1,
          STATUS:"ERROR",
          MESSAGE:"Something broke!" }
      } else {
         const result = await response.json();
         return result;
      }

  } catch(error) {
      return error;
  }


}

export const reportsquad = async(squadObj) => {

  try {

    let url = '/reportsquad';

    const requestOptions = {
         method: 'POST',
         headers: {
           'Content-Type': 'application/json',
           'Accept': 'application/json',
         }, body:JSON.stringify({
              squadObj:squadObj
          })
    };

     const response = await fetch(url,requestOptions);

     if(!response.ok) {
        throw {
          STATUSCODE:1,
          STATUS:"ERROR",
          MESSAGE:"Something broke!" }
      } else {
         const result = await response.json();
         return result;
      }

  } catch(error) {
      return error;
  }


}
