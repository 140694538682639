import React from 'react';
import * as actionCreators from "../../../../redux/actions/actionCreators";
import { getUserComment,deleteUserComment,delUserReply } from '../../../../redux/thunk/comment/fetchdata';
import { useRef,useEffect } from 'react';
import { useDispatch,useSelector } from "react-redux";
import './Pooldelete.css';

function Pooldelete(props) {

  const userdetails = useSelector((state) => state.userReducer.userdetails);

  const myRef = useRef(null);
  const dispatch = useDispatch();


  const handleClick = (e) => {

    var source = document.getElementById('pd');

     if(source === null) {
       return;
     }


    if (myRef.current.contains(e.target)) {
      // inside click
      return;
    }

    if (myRef.current.contains(e.target) === false) {
      props.handlecancelDelete();
    }

    // outside click

    // props.handlecancelDelete();

  };


  const handlecancelDelete = () => {
    props.handlecancelDelete();
  }

  const handleDeletecmt = async () => {

    if(props.listName === "Replylist") {

      const response = await delUserReply(props.rplyIndex,props.loginUser);

       if(response.STATUSCODE == 0) {
         dispatch(actionCreators.delreplyComment(props.rplyIndex));
          // props.refReplylist(props.commentreply_id);
       }

    }

    if(props.listName === "Commentlist") {

      const response = await deleteUserComment(props.comment_id,props.loginUser);

       if(response.STATUSCODE == 0) {
          dispatch(actionCreators.delComment(props.comment_id));
        }

    }

    props.handlecancelDelete();

  }

  useEffect(() => {

     document.addEventListener("mousedown", handleClick);

   return () => {
      document.removeEventListener("mousedown", handleClick, false);
    };

  }, []);

  return (
     <div  className="Pooldelete-wrapper">

      <div ref={myRef} id="pd" className="Pooldelete-container">

        <div className="Pooldelete-title"><strong>Delete Comment ?</strong></div>

        <div className="Pooldelete-note">Are you sure you want to delete this comment ?</div>

        <div>&nbsp;</div>

        <div className="Pooldelete-btn">
          <div className="Pooldelete-cancel" onClick={() => handlecancelDelete()}>Cancel</div>
          <div className="Pooldelete-empty"></div>
          <div id="del" className="Pooldelete-del" onClick={() => handleDeletecmt()}>Delete</div>
        </div>

    </div>

    </div>
  );
}

export default Pooldelete;
