import './Browserpool.css';
import React, { useState,useEffect,useRef,useCallback } from 'react';
import { Routes, Link, Route } from "react-router-dom";
import { BsArrowLeftShort } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { poolExplore } from '../../../redux/thunk/pool/fetchdata';

import pool from '../../../assets/dashboard/rideinpool.png';
import Doublebounce from '../../../spinner/double-bounce/Doublebounce';
import Pbimg from '../homepage-maincontainer/Poolrequest/Pbimg';

function Browselist() {

  const [count,setCount] = useState(100);
  const [limit,setlimit] = useState(500);
  const [page,setPage] = useState(1);
  const [hasMore,setHasMore] = useState(true);
  const [aa,setAa] = useState([]);
  const [temp,setTemp] = useState();

  const navigate = useNavigate();
  const observer = useRef();

  const lastBookElementRef = useCallback(node => {

    if (observer.current) observer.current.disconnect()
       observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasMore) {
          setPage(temp);
       }
    })

      if(node) {
          observer.current.observe(node)
       }
  })

  const handleBack = () => {
     navigate('/');
  }

  useEffect(() => {
     if(count == limit) {
          setHasMore(false)
     }
  }, [count]);


  useEffect(async () => {

    let lim = 5;

    const result = await poolExplore(page,lim)

     if(result) {
       let ft = result[0];

      if(ft.next == undefined) {
        setAa([...aa,...result.slice(1)]);
        setHasMore(false);
      } else {
        setAa([...aa,...result.slice(1)]);
        setTemp(ft.next.page)
      }

     }

  }, [page]);


  return (

    <div className="browselist-body">

      <div className="browselist-title">
         <div className="browselist-img">
            <img className="browselist-poolimg" src={pool} />
          </div>

          <div className="call-list-wrapper">
           <h4 className="call-list-title1">Discussion</h4>
           <h4 className="call-list-title1">&</h4>
           <h4 className="call-list-title1">Learning</h4>
        </div>

      </div>


      {aa && aa.map((element,index) => {
          return (
             <div key={index} ref={lastBookElementRef} className="browselist-wrapper">
            <div>&nbsp;</div>
            <Link className="browselist-link" to={`pooldetails/${element.topicId}`} >
               <div className="browselist-wrapperimg">
                {element.imagename ?
                 <img className="browselist-poolscrollimg" src={element.imagename}  />
                : <Pbimg /> }
              </div>

               <div className="browselist-desctable">
                  <div className="browselist-topicdetails">
                    <div className="browselist-topic">Topic</div>
                    <div>&nbsp;</div>
                    <div id="ptid" className="browselist-desc">{element.topic}</div>
                  </div>

                   <div className="browselist-topicdetails">
                     <div className="browselist-topic">With</div>
                     <div>&nbsp;</div>
                     <div className="browselist-desc">{element.yourname}</div>
                   </div>

                   <div className="browselist-topicdetails">
                     <div className="browselist-topic">Want</div>
                     <div>&nbsp;</div>
                     <div className="browselist-desc">{element.noofpartner} friends</div>
                   </div>

                   <div className="browselist-topicdetails">
                     <div className="browselist-topic">For</div>
                     <div>&nbsp;</div>
                     <div className="browselist-desc">{element.noofdays} days</div>
                   </div>

                   <div className="browselist-topicdetails">
                     <div className="browselist-topic">Start</div>
                     <div>&nbsp;</div>
                     <div className="browselist-desc">{element.startDate}@{element.startTime}</div>
                   </div>

                </div>
              </Link>
             </div>
           )
       })}


      {hasMore ?
        <div className="browselist-spinner">
          <Doublebounce />
        </div> : null }

     </div>

  )
}

export default Browselist;
