import * as actionTypes from "../actions/actionTypes";

const initialState = {
  requestDelete:{
   reqstatus:false
  }
};

const popupReducer = (state = initialState, action) => {

  switch (action.type) {

    case actionTypes.REQ_DEL:

      return {
        requestDelete:action.payload
      };

    default:
      return state;
  }
};

export default popupReducer;
