import React, { useState,useEffect,useRef } from 'react';
import { useSelector,useDispatch } from "react-redux";
import { MdOutlineCancel } from 'react-icons/md';
import * as actionCreators from "../../../../redux/actions/actionCreators";
import './Sterotypesel.css';

import prioryou from  '../../../../assets/logo/prioryou.jpg';
import one from  '../../../../assets/sterotype/1.png';
import two from  '../../../../assets/sterotype/2.png';
import three from  '../../../../assets/sterotype/3.png';
import four from  '../../../../assets/sterotype/4.png';
import five from  '../../../../assets/sterotype/5.png';
import six from  '../../../../assets/sterotype/6.png';

function Sterotypesel() {

  const [steroflag,setSteroflag] = useState("Select");
  const [nextflag,setNextflag] = useState(false);
  const dispatch = useDispatch();

  const handleGetStarted = () => {
    if(steroflag != "Select") {
      let obj = {
        sterotypeflag:false,
        sterotype:steroflag
      }
      dispatch(actionCreators.setSterotype(obj));
    }
  }

  const handleSteroBlank = (value) =>  {
      let obj = {
        sterotypeflag:false,
        sterotype:value
      }
      dispatch(actionCreators.setSterotype(obj));
  }

  const handleSterotype = (value) => {
     setSteroflag(value);
  }

  const handleClear = () => {
     setSteroflag("Select");
  }

  const handleClose = () => {
    dispatch(actionCreators.setSterotypeFlag(false));
  }


  return (
      <div className="Sterotypesel-wrapper">

         <div style={{height:50}}> </div>

         <div className="Sterotypesel-container">

            <div className="Sterotypesel-container-close">
              <div className="Sterotypesel-container-closeicon" onClick={() => handleClose()}>
                <MdOutlineCancel/>
               </div>
            </div>

            <div className="Sterotypesel-title">
              Choice Your Sterotype
            </div>

            <div className="Sterotypesel-inner-wrapper">

         <div className="Sterotypesel-card-wrapper" onClick={() => handleSterotype("Casual")}>
            <div className={steroflag == "Casual" ? "Sterotypesel-card-sel" : "Sterotypesel-card"}>
              <div className="Sterotypesel-img">
                 <img className="Sterotypeimage-img" src={one} />
                </div>
               <div>Casual</div>
            </div>
         </div>

         <div className="Sterotypesel-card-wrapper" onClick={() => handleSterotype("Geek")}>
            <div className={steroflag == "Geek" ? "Sterotypesel-card-sel" : "Sterotypesel-card"}>
              <div className="Sterotypesel-img">
                 <img className="Sterotypeimage-img" src={two} />
                </div>
               <div>Geek</div>
            </div>
         </div>

         <div className="Sterotypesel-card-wrapper" onClick={() => handleSterotype("Genius")}>
            <div className={steroflag == "Genius" ? "Sterotypesel-card-sel" : "Sterotypesel-card"}>
              <div className="Sterotypesel-img">
                 <img className="Sterotypeimage-img" src={three} />
                </div>
               <div>Genius</div>
            </div>
         </div>

         <div className="Sterotypesel-card-wrapper" onClick={() => handleSterotype("Smart")}>
            <div className={steroflag == "Smart" ? "Sterotypesel-card-sel" : "Sterotypesel-card"}>
              <div className="Sterotypesel-img">
                 <img className="Sterotypeimage-img" src={four} />
                </div>
               <div>Smart</div>
            </div>
         </div>

         <div className="Sterotypesel-card-wrapper" onClick={() => handleSterotype("Lazy")}>
            <div className={steroflag == "Lazy" ? "Sterotypesel-card-sel" : "Sterotypesel-card"}>
              <div className="Sterotypesel-img">
                 <img className="Sterotypeimage-img" src={five} />
                </div>
               <div>Lazy</div>
            </div>
         </div>

         <div className="Sterotypesel-card-wrapper" onClick={() => handleSterotype("Nerd")}>
            <div className={steroflag == "Nerd" ? "Sterotypesel-card-sel" : "Sterotypesel-card"}>
              <div className="Sterotypesel-img">
                 <img className="Sterotypeimage-img" src={six} />
                </div>
               <div>Nerd</div>
            </div>
         </div>

            </div>

            <div className="Sterotypesel-choice" >
               <div>{steroflag}</div>
               <div style={{height:8}}> </div>
              {steroflag != "Select" ?
               <div className="Sterotypesel-clear" onClick={() => handleClear()}>
                  <MdOutlineCancel />
                </div>  : null }
            </div>

            <div className="Sterotypesel-nostrotype" onClick={() => handleSteroBlank("Blank")}>
               No SteroType
            </div>

            <div style={{height:10}}> </div>

            <div className="Sterotypesel-container-footer">

               <div className="Sterotypesel-container-getstarted" onClick={() => handleGetStarted()}>
                  Select
                </div>

            </div>

            <div>&nbsp;</div>

         </div>

      </div>
  );
}

export default Sterotypesel;
