import './Smarttag.css';
import React, { useEffect,useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from "react-redux";
import { HiHashtag } from 'react-icons/hi';
import * as actionCreators from '../../../../redux/actions/actionCreators';
import { getUserSmarttag } from '../../../../redux/thunk/post/fetchdata';


function Smarttag() {

  const userdetails = useSelector((state) => state.userReducer.userdetails);
  const topicobj = useSelector((state) => state.activeReducer.topicid);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [taglist,setTaglist] = useState(null);

  const handleSmarttag = (postid) => {
     dispatch(actionCreators.restUserPost());
     dispatch(actionCreators.addUserpreviewid(postid));
     navigate(`/homepage/active/${topicobj.topicid}/preview/${postid}`);
  }

  const handleTopic = (topicid) => {
     navigate(`/homepage/pool/pooldetails/${topicid}`)
  }


  useEffect(async() => {

    if(userdetails && topicobj) {

     let smartobj = {
      topicid:topicobj.topicid,
      userid:userdetails.userid
    }

     const response = await getUserSmarttag(smartobj);

     if(response.STATUSCODE == 0) {
          setTaglist(response.PAYLOAD);
      }

   }

  },[topicobj,userdetails]);


  return (
    <>
    {topicobj != null ?
     <div className="ac-wrapper">

        <div className="smart-tag-header-wrapper">
          <div className="smart-tag-hash"><HiHashtag /></div>
          <div className="smart-tag-header smart-tag-text" onClick={() => handleTopic(topicobj.topicid)}>
            {topicobj.topic}
          </div>
        </div>

       <div className="smart-tag-body">
          <div className="smart-tag-title">
             Smart Tag
          </div>

         {taglist?.map((element,index) => {

           return (
             <div key={element.postid} className="smart-tag-list-wrapper">
               <div className="smart-tag-list" onClick={() => handleSmarttag(element.postid)}>
                 <p className="smart-tag-list-text">{element.tagname}</p>
               </div>
             </div>
           );
         })}

      </div>

     </div> : null }
    </> 
  )
}



export default Smarttag;
