import * as actionTypes from "../actions/actionTypes";

const initialState = {
    callstart:true
};

const videoReducer = (state = initialState, action) => {

  switch (action.type) {

    case actionTypes.START_CALL:

      return {
        callstart:action.payload
      };

    default:
      return state;
  }
};

export default videoReducer;
