import React from 'react';
import { useState, useEffect,useCallback,useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { Routes, Link, Route } from "react-router-dom";
import Pbimg from '../../homepage-maincontainer/Poolrequest/Pbimg';

function Poollist(props) {

   const {element} = props;

   const navigate = useNavigate();

   const handlePoollink = (element) => {
     navigate(`/homepage/pool/pooldetails/${element.topicId}`);
   }

  return (
      <div className="browselist-link" onClick={() => handlePoollink(element)}>
         <div className="browselist-wrapperimg">
          {element.imagename ?
           <img className="browselist-poolscrollimg" src={element.imagename}  />
          : <Pbimg /> }
         </div>

          <div className="browselist-desctable">
            <div className="browselist-topicdetails">
              <div className="browselist-topic">Topic</div>
              <div>&nbsp;</div>
              <div className="browselist-desc">{element.topic}</div>
            </div>

             <div className="browselist-topicdetails">
               <div className="browselist-topic">With</div>
               <div>&nbsp;</div>
               <div className="browselist-desc">{element.yourname}</div>
             </div>

             <div className="browselist-topicdetails">
               <div className="browselist-topic">Want</div>
               <div>&nbsp;</div>
               <div className="browselist-desc">{element.noofpartner} friends</div>
             </div>

             <div className="browselist-topicdetails">
               <div className="browselist-topic">For</div>
               <div>&nbsp;</div>
               <div className="browselist-desc">{element.noofdays} days</div>
             </div>

             <div className="browselist-topicdetails">
               <div className="browselist-topic">Start</div>
               <div>&nbsp;</div>
               <div className="browselist-desc">{element.startDate}@{element.startTime}</div>
             </div>

          </div>
      </div>
   )
}

export default Poollist;
