import React, { useState,useEffect,useRef  } from 'react';
import { useSelector,useDispatch } from "react-redux";
import './Pooldelete.css';

function Pooldelete(props) {

 const [delpool,setDelpool] = useState([]);
 const myref = useRef(null);
 const dispatch = useDispatch();

  const handleClickout = (event) => {
    if (myref.current && !myref.current.contains(event.target)) {
        props.toggledel();
     }
  };

  const handleConfirmdel = (usertopicId) => {
     props.submitdelRequest(usertopicId);
  }

  const handleClose = () => {
    props.toggledel();
  }

  useEffect(() => {

    document.addEventListener("mousedown", handleClickout);
    return () => {
       document.removeEventListener("mousedown", handleClickout, false);
     };

  },[]);

 return (
    <div className="pooldelete-delete">

      <div ref={myref} className="pooldelete_content-delete">

        <div className="pooldelete-delete-header">
          <div>Delete Topic ?</div>
          <div className="pooldelete-delete-cancel" onClick={handleClose}>&times;</div>
       </div>

       <div>&nbsp;</div>

        <div className="pooldelete-delete-body">
           <div className="pooldelete-desc"><strong>{props.itemDel}</strong></div>
        </div>

        <div>&nbsp;</div>

        <div className="pooldelete-delete-footer">
            <button className="pooldelete-btn-delete" onClick={() => handleConfirmdel(props.usertopicId)} >&nbsp;Confirm&nbsp;</button>
        </div>

     </div>

    </div>
 )
}


export default Pooldelete;
