import React, { useEffect,useState,useRef } from 'react';
import { Routes, Link, Route,useParams  } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { useSelector,useDispatch } from "react-redux";
import { BsArrowLeftShort } from 'react-icons/bs';
import { getUserpost } from '../../../../redux/thunk/post/fetchdata';
import { RxDotsHorizontal } from 'react-icons/rx';
import { TbSquareRotated } from 'react-icons/tb';
import { MdOutlineReply } from 'react-icons/md';
import defaultprofile from '../../../../assets/default/user.png';
import * as actionCreators from '../../../../redux/actions/actionCreators';
import Replybox from './Replybox';

import './Postpriview.css';

function Postpriview() {

  const myref = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const previewid = useSelector((state) => state.postlistReducer.previewpostid);
  const previewpost = useSelector((state) => state.postlistReducer.previewpost);
  const userdetails = useSelector((state) => state.userReducer.userdetails);
  const team = useSelector((state) => state.teamReducer.team);
  const topicobj = useSelector((state) => state.activeReducer.topicid);

  const [userpost,setUserpost] = useState([]);
  const [menuFlag,setMenuflag] = useState(null);
  const [currentid,setCurrentid] = useState(null);
  const [replyFlag,setReplyflag] = useState(false);

  const [replyUser,setReplyuser] = useState(null);
  const [replypostid,setReplypostid] = useState(null);
  const [replyuserid,setReplyuserid] = useState(null);
  const [replyImgarray,setReplyimgarray] = useState(null);
  const [postreply,setPostreply] = useState(null);

  const cancelReply = () => {
     setReplyflag(null);
  }

  const handleScroll = (event) => {
     setMenuflag(null);
  }

  const handleBack = () => {
    navigate(-1);
  }

  const handleReply = (username,postid,userid,post,imgArray,index) => {

      let name = "@"+username;
      setReplyuser(name);
      setReplypostid(postid);
      setReplyuserid(userid);
      setPostreply(post);
      setReplyimgarray(imgArray);
      setReplyflag(true);

  {/*
    props.handleReply(username,postid,userid,post,imgArray);
  */}

  }

  const hanleMenuopt = (index) => {
      setMenuflag(index)
  }

  const handleDelete = (postid,userid,currentuser) => {

    let usertype;

    if(userid == currentuser) {
       usertype = "normaluser";
    } else if (topicobj.superuser == userid) {
       usertype = "superuser";
    }

    let obj = {
      delflag:true,
      postId:postid,
      userId:userid,
      userType:usertype
    }

    dispatch(actionCreators.delUserpost(obj));
    setMenuflag(null);
  }

  const handelFocusReply = (item) => {
     dispatch(actionCreators.addUserpreviewid(item.postid));
   }

  const handleRmvtag = (postid,userid,tag) => {

      let tname = tag.smarttag.filter(item => item.userid == userid);

     let obj = {
       smartflag:true,
       postId:postid,
       userId:userid,
       tagname:tname[0].tagname
     }

     dispatch(actionCreators.rmvSmarttag(obj));
     setMenuflag(null);
   }

  const handleSmarttag = (postid,userid) => {

     let obj = {
       smartflag:true,
       postId:postid,
       userId:userid
     }

     dispatch(actionCreators.addSmartTag(obj));
     setMenuflag(null);
   }

  useEffect(async () => {
      window.scrollTo(0, 0);

      let ispostID = previewpost.filter(function (item) {
        return item.postid == previewid;
      });

    if(ispostID.length == 0) {

      if(previewid) {
        let response_postid = await getUserpost(previewid);

          if(response_postid.STATUSCODE == 0) {
             dispatch(actionCreators.addUserpreviewpost(response_postid.PAYLOAD));
          }
       }
     }
  },[previewid])

  useEffect(() => {
    if(previewpost.length > 0) {
      setUserpost(previewpost);
    }
  },[previewpost])

  useEffect(() => {
    setCurrentid(params.topicid);
  },[params])

  return (

  <div>

    {userpost?.map((element,index) => {

      let usrdetails = team.filter(function (el) {
        return el.enrolleduserId == element.userid;
      });

       return (
        <>
        {currentid && currentid == element.postid ?
        <div className="Postpriview-wrapper">

           <div className="Postpriview-header">
             <div className="Postpriview-titlearrow" onClick={handleBack}>
                <BsArrowLeftShort/>
              </div>
              <div style={{width:4}}> </div>
              <span className="Postpriview-titlearrow-tile">back</span>
           </div>

           <div className="Postpriview-body">
              <div  className="postlist-wrapper">

               <div className="postlist-outwrapper">

                    <div className="postlist-outprofile">

                     <div className="postlist-profile"
                     style={{
                        backgroundImage:usrdetails[0] && usrdetails[0].profileImage ? `url("${usrdetails[0].profileImage}")` : `url("${defaultprofile}")`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        maxWidth:'100%',
                        maxHeight:'100%',
                      }}
                    />

                     <span className="postlist-space"></span>
                     <span className="postlist-name">{usrdetails[0] && usrdetails[0].username}
                       <span style={{width:10}} />
                       <span style={{color:"#909090"}}>{element.currentTime}</span>
                     </span>
                    </div>

                       <button className="postlist-tag" onClick={() => hanleMenuopt(index)}>
                          <RxDotsHorizontal />
                       </button>

                  <div>

                 </div>
               </div>

                <div className="postlist-empty">
                 {menuFlag == index ?
                   <div id="scrollpop" className="postlist-menu" onScroll={(event) => handleScroll(event)} ref={myref}>

                      {(userdetails.userid == element.userid) || (topicobj.superuser == userdetails.userid) ?
                         <div className="postlist-menu-btn" onClick={() => handleDelete(element.postid,userdetails.userid,element.userid)}>Delete</div>
                       : null }

                       <div className="postlist-menu-ht"></div>

                   {element.smarttag && element.smarttag.some(item => item.userid == userdetails.userid) ?

                        <div className="postlist-menu-btn" onClick={() => handleRmvtag(element.postid,userdetails.userid,element)}>
                          Remove Smart Tag
                        </div> :
                        <div className="postlist-menu-btn" onClick={() => handleSmarttag(element.postid,userdetails.userid)}>
                           Add Smart Tag
                        </div>
                      }

                   </div> : null }
                </div>

                {element.topicpost && element.topicpost ?
                  <div className="postlist-text" dangerouslySetInnerHTML={{__html: element.topicpost }} >
                 </div> : null }

                 <div className="postlist-img">
                   {element.imgArray && element.imgArray.map((item,index) => {

                     let imgurl

                     if(item.postimage) {
                       imgurl = item.postimage;
                     } else {
                        const b64 = new Buffer(item).toString('base64')
                         imgurl  = "data:image/png;base64,"+b64;
                      }

                    return (
                       <img className="postlistimg" key={index} src={imgurl} />
                    )

                   })}

                 </div>

                 {element.replydetails && element.replydetails.map((item,index) => {

                     let replyusrdetails = team.filter(function (el) {
                       return el.enrolleduserId == item.userid;
                     });

                      return (
                       <Link to={`/homepage/active/${topicobj.topicid}/preview/${item.postid}`}  style={{textDecoration:"none"}} key={index} className="postlist-reply-details" onClick={() => handelFocusReply(item)}>

                        <div className="postlist-outprofile">
                          <div className="postlist-reply-profile"
                         style={{
                            backgroundImage:replyusrdetails[0] && replyusrdetails[0].profileImage ? `url("${replyusrdetails[0].profileImage}")` : `url("${defaultprofile}")`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            maxWidth:'100%',
                            maxHeight:'100%',
                          }}
                        />

                           <span className="postlist-space"></span>
                           <span className="postlist-name">{replyusrdetails[0] && replyusrdetails[0].username }
                          <span style={{width:10}} />
                            <span style={{color:"#909090"}}>{item.currentTime}</span>
                           </span>
                         </div>

                        {item.topicpost && item.topicpost ?
                          <div className="postlist-text" dangerouslySetInnerHTML={{__html: item.topicpost }} >
                         </div> : null }

                        <div style={{height:10}}> </div>

                        <div className="postlist-item-wrapper-img">
                          {item.imgArray	&& item.imgArray.map((imgitem,index) => {
                            let imgurl;

                            if(imgitem.postimage) {
                              imgurl = imgitem.postimage;
                            } else {
                               const b64 = new Buffer(imgitem).toString('base64')
                                imgurl  = "data:image/png;base64,"+b64;
                             }
                             return (
                                 <div key={index} className="postlist-item-img">
                                   <img src={imgurl} />
                                 </div>
                             )

                         })}
                         </div>
                       </Link>
                    )
                   })}

                 <div className="postlist-reply">
                  <div className="postlist-reply-btn-wrapper" onClick={() => handleReply(usrdetails[0].username,element.postid,element.userid,element.topicpost,element.imgArray)}>
                    <div className="postlist-reply-text">Reply</div>
                    <div className="postlist-reply-text" style={{width:10}}></div>
                    <div className="postlist-reply-icon"><MdOutlineReply /></div>
                 </div>
                 </div>

              </div>
            </div>

            {replyFlag ?
               <div className="Postpriview-replybox">
                 <Replybox replyUser={replyUser}
                    replypostid={replypostid}
                    replyuserid={replyuserid}
                    replyImgarray={replyImgarray}
                    postreply={postreply}
                    cancelReply={cancelReply} />
               </div> : null }

          </div> : null }

        </>
      )


     })}


  </div>
)


}

export default Postpriview;
