import React from 'react';
import { useState, useEffect,useCallback,useRef } from 'react';
import { useParams,useNavigate } from "react-router-dom";
import { Routes, Link, Route } from "react-router-dom";
import { useSelector,useDispatch } from "react-redux";
import * as actionCreators from '../../../redux/actions/actionCreators';
import { getPeopleSearch } from '../../../redux/thunk/search/fetchdata';
import Doublebounce from '../../../spinner/double-bounce/Doublebounce';
import defaultProfile from '../../../assets/default/user.png';

function Searchpeople(props) {

  const observer = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const authUser = useSelector((state) => state.authReducer.authflag);

  const peoplesearchTerm = useSelector((state) => state.searchpeopleReducer.peoplesearchTerm);
  const peoplePage = useSelector((state) => state.searchpeopleReducer.peoplePage);
  const peoplehasMore = useSelector((state) => state.searchpeopleReducer.peoplehasMore);
  const peopleTemp = useSelector((state) => state.searchpeopleReducer.peopleTemp);
  const peoplePool = useSelector((state) => state.searchpeopleReducer.peoplePool);

  const lastPoolElementRef = useCallback(node => {

    if (observer.current) observer.current.disconnect()

       observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && peoplehasMore) {
           dispatch(actionCreators.addPeoplePoolPage(peopleTemp));
       }
    })

      if(node) {
          observer.current.observe(node)
       }
  })

  const handleProfile = (userId) => {

     const url =  window.location.href;

     if(authUser) {
       navigate(`/homepage/account/${userId}`);
     } else {
       navigate(`/account/${userId}`);
     }

  }

  useEffect(async () => {

    let lim = 5;
    let limit;

    limit = peoplePage * lim;

    if(limit > peoplePool.length && peoplehasMore == true) {

    const result = await getPeopleSearch(peoplePage,lim,peoplesearchTerm);

    if(result) {
      let ft = result.PAYLOAD[0];

      if(ft.next == undefined) {
         let payload = result.PAYLOAD.slice(1);
         dispatch(actionCreators.addPeoplePool(payload));
         dispatch(actionCreators.addPeoplePoolHasmore(false));
      } else {

        let payload = result.PAYLOAD.slice(1);

         if(payload.length > 0) {
            dispatch(actionCreators.addPeoplePool(payload));
            dispatch(actionCreators.addPeoplePoolTemp(ft.next.page));
         } else {
            dispatch(actionCreators.addPeoplePoolHasmore(false));
         }

      }
     }
  }

},[peoplesearchTerm,peoplePage,peopleTemp]);


  return (
     <div className="Searchpool-wrapper">

       {peoplePool?.map((element,index) => {

         return (
            <div  key={index} ref={lastPoolElementRef} className="followerouter" onClick={() => handleProfile(element.userid)}>

              <div className="followerscontainer">

                <div className="followersprfwrapper">
                 <div className="followers-profileimg"
                    style={{
                      backgroundImage:element && element.profile_image ? `url("${element.profile_image}")` : `url("${defaultProfile}")`,
                      backgroundPosition:'center',
                      backgroundSize:'cover',
                      backgroundRepeat:'no-repeat',
                      maxWidth:'100%',
                      maxHeight:'100%'
                   }}></div>

                 <div>&nbsp;&nbsp;</div>

                    <div className="followers-username">
                       {element.username}
                    </div>

                </div>

                {element.isFollowing !== "self" ?
               <>
               <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>

               </>
             : null }

              </div>

            </div>
          );
       })}

       {peoplehasMore ?
       <div className="browselist-spinner">
         <Doublebounce />
       </div> : null }

     </div>
   )

}

export default Searchpeople;
