import React, { useState,useEffect,useContext,useCallback,useRef } from "react";
import { useSelector,useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as actionCreators from '../../../../redux/actions/actionCreators';
import defaultProfile from '../../../../assets/default/user.png';
import { MdOutlineCancel } from 'react-icons/md';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import './Requestdisplay.css';

function Requestdisplay() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userdetails = useSelector((state) => state.userReducer.userdetails);
  const topicdetails = useSelector((state) => state.reqnavReducer.topicId);
  const [request,setRequest] = useState([]);

  const handleClose = () => {
     dispatch(actionCreators.reqNav(""));
   }

  const handleProfile = (userid) => {
    dispatch(actionCreators.reqNav(""));
    navigate(`/homepage/account/${userid}`);
  }

  const handleDelete = (requestid,topicid,userid,fromuserid,commentTxt,topicrequestid) => {

    let notify = {
       notifyto:userid,
       notifyfrom:fromuserid,
       commentTxt:commentTxt,
       notify_topicid:topicid,
       notify_type:"Deleted",
       notify_comment_id:"12",
       notify_reply_id:"",
       topicrequestid:topicrequestid,
       requestid:requestid,
       reqstatus:true
    }

    dispatch(actionCreators.reqDel(notify));

  }

  const handleConfirm = (requestid,topicid,userid,fromuserid,commentTxt,topicrequestid) => {

    let notify = {
       notifyto:userid,
       notifyfrom:fromuserid,
       commentTxt:commentTxt,
       notify_topicid:topicid,
       notify_type:"Accpeted",
       notify_comment_id:"12",
       notify_reply_id:"",
       topicrequestid:topicrequestid,
       requestid:requestid,
       reqstatus:true
    }

   dispatch(actionCreators.confirmReq(notify));

  }

  const handleTopic = (topicid) => {
    dispatch(actionCreators.reqNav(""));
    navigate(`request/pooldetails/${topicid}`);
  }


   return (
     <div className="requestdisplaywrapper">
       <div className="request-container-outer">

             <div className="request-container-header">
               <div style={{fontSize:30,color:'#ffff',padding:4}}>&times;</div>
                <div><h3>Topic Request</h3></div>
               <div className="request-closebtn" onClick={() => handleClose()} >
                 <IoIosCloseCircleOutline size={30} />
               </div>
             </div>


            <div key={topicdetails.topicrequestid} className="request-wrapper">
              <div  className="request-container">

               <div className="requestbox-profilepanel">

                <div className="request-persondetails" onClick={() => handleProfile(topicdetails.notifyfromdetails.userid)}>
                  <div className="requestbox-profileimg"
                     onClick={() => handleProfile(topicdetails.notifyfromdetails.userid)}
                     style={{
                        backgroundImage:topicdetails.notifyfromdetails.profile_image ? `url("${topicdetails.notifyfromdetails.profile_image}")` : `url("${defaultProfile}")`,
                        backgroundPosition:'center',
                        backgroundSize:'cover',
                        backgroundRepeat:'no-repeat',
                        maxWidth:'100%',
                        maxHeight:'100%'
                     }}></div>

                     <div className="requestbox-name"
                       onClick={() => handleProfile(topicdetails.notifyfromdetails.userid)}>
                        {topicdetails.notifyfromdetails.username}
                     </div>
                 </div>

                     <div className="requestbox-topictitle" onClick={() => handleTopic(topicdetails.topicId)}>
                       {topicdetails.replytxt}
                     </div>

               </div>


              <div className="requestbox-buttonpanel">
                     <div>
                        <button className="requestboxdelbtn"
                          onClick={() => handleDelete(topicdetails.requestid,topicdetails.topicId,topicdetails.notifyfromdetails.userid,userdetails.userid,topicdetails.replytxt,topicdetails.topicrequestid)}>
                           Delete
                         </button>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                         <button className="requestboxconbtn"
                           onClick={() => handleConfirm(topicdetails.requestid,topicdetails.topicId,topicdetails.notifyfromdetails.userid,userdetails.userid,topicdetails.replytxt,topicdetails.topicrequestid)}>
                           Confirm
                         </button>
                     </div>
               </div>

              </div>
           </div>


       </div>
     </div>
   )

}

 export default Requestdisplay;
