// Add Comment to topic

export const addUserComment = async (userCommentobj) => {

  try {

     let url = '/addcomment';

     const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
          body:JSON.stringify({
             userCommentobj:userCommentobj
          })
     };

     const response = await fetch(url, requestOptions);

     if(!response.ok) {
        throw {
          STATUSCODE:1,
          STATUS:"ERROR",
          MESSAGE:"Something broke!" }
      } else {
         const result = await response.json();
         return result;
      }

    } catch (error) {
        let err = {error:error.message}
        return err;
     }

}

// Get User Comment

export const getUserComment = async (obj) => {

  try {

     let url = '/getUserComment';

     const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
          body:JSON.stringify({
            obj:obj
          })
     };

     const response = await fetch(url, requestOptions);

     if(!response.ok) {
        throw {
          STATUSCODE:1,
          STATUS:"ERROR",
          MESSAGE:"Something broke!" }
      } else {
         const result = await response.json();
         return result;
      }

    } catch (error) {
        return error;
     }

}

// Get User Comment from Comment id

export const getUserNotifyComment = async(obj) => {

  try {

     let url = '/getusernotifycomment';

     const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
          body:JSON.stringify({
            obj:obj
          })
     };

     const response = await fetch(url, requestOptions);

     if(!response.ok) {
        throw {
          STATUSCODE:1,
          STATUS:"ERROR",
          MESSAGE:"Something broke!" }
      } else {
         const result = await response.json();
         return result;
      }

    } catch (error) {
        return error;
     }


}

// Add like to Comment

export const addLikeComment = async (comment_id) => {

  try {

     let url = '/addlikecomment';

     const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          }, body:JSON.stringify({
              comment_id:comment_id
           })
     };

     const response = await fetch(url,requestOptions);

      if(!response.ok) {
         throw {
           STATUSCODE:1,
           STATUS:"ERROR",
           MESSAGE:"Something broke!" }
       } else {
          const result = await response.json();
          return result;
       }

    } catch (error) {
        return error;
     }

}

// Add like to Reply

export const addLikeReply = async (reply_id) => {

  try {

     let url = '/addlikereply';

     const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          }, body:JSON.stringify({
              reply_id:reply_id
           })
     };

     const response = await fetch(url,requestOptions);

      if(!response.ok) {
         throw {
           STATUSCODE:1,
           STATUS:"ERROR",
           MESSAGE:"Something broke!" }
       } else {
          const result = await response.json();
          return result;
       }

    } catch (error) {
        return error;
     }

}

// Undo like to Comment

export const undoLikeComment = async (comment_id) => {

  try {

     let url = '/undolikecomment';

     const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          }, body:JSON.stringify({
              comment_id:comment_id
           })
     };

     const response = await fetch(url,requestOptions);

      if(!response.ok) {
         throw {
           STATUSCODE:1,
           STATUS:"ERROR",
           MESSAGE:"Something broke!" }
       } else {
          const result = await response.json();
          return result;
       }

    } catch (error) {
        return error;
     }

}

// Undo like reply

export const undoLikeReply = async (reply_id) => {

  try {

     let url = '/undolikereply';

     const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          }, body:JSON.stringify({
              reply_id:reply_id
           })
     };

     const response = await fetch(url,requestOptions);

      if(!response.ok) {
         throw {
           STATUSCODE:1,
           STATUS:"ERROR",
           MESSAGE:"Something broke!" }
       } else {
          const result = await response.json();
          return result;
       }

    } catch (error) {
        return error;
     }

}

// Edit and Save User Comment

export const editSaveComment = async (comment_id,editcmtTxt) => {

  try {

     let url = '/editcomment';

     const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          }, body:JSON.stringify({
              comment_id:comment_id,
              comment_text:editcmtTxt
           })
     };

     const response = await fetch(url,requestOptions);

      if(!response.ok) {
         throw {
           STATUSCODE:1,
           STATUS:"ERROR",
           MESSAGE:"Something broke!" }
       } else {
          const result = await response.json();
          return result;
       }

    } catch (error) {
        return error;
     }

}

// Delete User Comment

export const deleteUserComment = async (comment_id,userid) => {

  try {

     let url = '/deleteusercomment';

     const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          }, body:JSON.stringify({
              comment_id:comment_id,
              userid:userid
           })
     };

     const response = await fetch(url,requestOptions);

      if(!response.ok) {
         throw {
           STATUSCODE:1,
           STATUS:"ERROR",
           MESSAGE:"Something broke!" }
       } else {
          const result = await response.json();
          return result;
       }

    } catch (error) {
        return error;
     }

}

// Add User Reply

export const addUserReply = async(object) => {

  try {

     let url = '/adduserreply';

     const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          }, body:JSON.stringify({
              replyObject:object
           })
     };

     const response = await fetch(url,requestOptions);

      if(!response.ok) {
         throw {
           STATUSCODE:1,
           STATUS:"ERROR",
           MESSAGE:"Something broke!" }
       } else {
          const result = await response.json();
          return result;
       }

    } catch (error) {
        return error;
     }

}

// Get User Reply

export const getUserReply = async(obj) => {

  try {

     let url = '/getuserreply';

     const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          }, body:JSON.stringify({
              obj:obj
           })
     };

     const response = await fetch(url,requestOptions);

      if(!response.ok) {
         throw {
           STATUSCODE:1,
           STATUS:"ERROR",
           MESSAGE:"Something broke!" }
       } else {
          const result = await response.json();
          return result;
       }

    } catch (error) {
        return error;
     }

}

// Get User Notify Replylist

export const getUserNotifyReply = async(obj) => {

  try {

     let url = '/getusernotifyreply';

     const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          }, body:JSON.stringify({
              obj:obj
           })
     };

     const response = await fetch(url,requestOptions);

      if(!response.ok) {
         throw {
           STATUSCODE:1,
           STATUS:"ERROR",
           MESSAGE:"Something broke!" }
       } else {
          const result = await response.json();
          return result;
       }

    } catch (error) {
        return error;
     }


}

// Edit User Reply

export const editUserReply = async(obj) => {

  try {

     let url = '/edituserreply';

     const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          }, body:JSON.stringify({
              reply_obj:obj
           })
     };

     const response = await fetch(url,requestOptions);

      if(!response.ok) {
         throw {
           STATUSCODE:1,
           STATUS:"ERROR",
           MESSAGE:"Something broke!" }
       } else {
          const result = await response.json();
          return result;
       }

    } catch (error) {
        return error;
     }

}

// Delete User Reply

export const delUserReply = async(rplyid,userid) => {

  try {

     let url = '/deluserreply';

     const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          }, body:JSON.stringify({
              reply_id:rplyid,
              replyuserid:userid
           })
     };

     const response = await fetch(url,requestOptions);

      if(!response.ok) {
         throw {
           STATUSCODE:1,
           STATUS:"ERROR",
           MESSAGE:"Something broke!" }
       } else {
          const result = await response.json();
          return result;
       }

    } catch (error) {
        return error;
     }

}

// Report User Comment

export const reptUserComment = async(commentid,loginUser,reportcomment) => {

  try {

     let url = '/reptusercomment';

     const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          }, body:JSON.stringify({
              comment_id:commentid,
              report_userId:loginUser,
              report_text:reportcomment
           })
     };

     const response = await fetch(url,requestOptions);

      if(!response.ok) {
         throw {
           STATUSCODE:1,
           STATUS:"ERROR",
           MESSAGE:"Something broke!" }
       } else {
          const result = await response.json();
          return result;
       }

    } catch (error) {
        return error;
     }

}

// Report User Reply

export const reptUserReply = async(commentid,loginUser,reportcomment) => {

  try {

     let url = '/reptuserreply';

     const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          }, body:JSON.stringify({
              reply_id:commentid,
              report_userId:loginUser,
              report_text:reportcomment
           })
     };

     const response = await fetch(url,requestOptions);

      if(!response.ok) {
         throw {
           STATUSCODE:1,
           STATUS:"ERROR",
           MESSAGE:"Something broke!" }
       } else {
          const result = await response.json();
          return result;
       }

    } catch (error) {
        return error;
     }

}
