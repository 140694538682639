import React, { useState,useEffect,useRef } from 'react';
import { RiCloseFill } from 'react-icons/ri';
import { BiCheckCircle } from 'react-icons/bi';
import { BiCircle } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import Fpasswordcode from './Fpasswordcode';
import { loginPost } from '../../../redux/thunk/gateway/fetchdata';
import prioryou from '../../../assets/logo/prioryou.jpg';
import './Fpasswordnext.css';

function Fpasswordnext(props) {

  const navigate = useNavigate();

  const [toogleflag,setToogleflag] = useState(true);

  const [emailflag,setEmailflag] = useState(false);
  const [mobileflag,setMobileflag] = useState(false);
  const [nextflag,setNextflag] = useState(true);

  const handleCancel = () => {
    navigate(-1);
  }

  const handleConfirmNext = async () => {

    const responseLogin = await loginPost(props.useremail,props.password);

    if(responseLogin.STATUSCODE == 0) {
          let url = '/homepage';
          navigate(url);
     }


  }

  const handleBack = () => {
    setNextflag(true);
  }

  const handleConfirmMobile = () => {

  }

  const handleEmailCheck = () => {
      setEmailflag(true);
      setMobileflag(false);
  }

  const handleMobileCheck = () => {
    setEmailflag(false);
    setMobileflag(true);
  }

  useEffect(() => {

  },[])

  return (
    <div className="Fpassword-wrapper">
       <div className="Fpassword-container">

          <div className="Fpassword-header">
             <div className="Fpassword-header-right">

             </div>
             <div className="Fpasswordnext-header-center">
                All Done
             </div>
             <div className="Fpassword-header-left" >

             </div>
          </div>

          <div className="Fpassword-body">

             <div className="Fpassword-body-up">

                <div className="Fpassword-body-text">
                    Successfully Changed Password
                </div>

                <div style={{height:30}}> </div>

                <div className="Fpasswordnext-option">
                    <img src={prioryou} />



                {props.usermobile ?
                  <div className="Fpasswordnext-mobile" onClick={() => handleMobileCheck()}>
                     <div className="Fpasswordnext-text"><small>{props.usermobile}</small></div>
                      <div className="Fpasswordnext-icon">
                      {mobileflag ? <BiCheckCircle/> : <BiCircle /> }
                       </div>
                  </div> : null }

                </div>

               <div style={{height:20}}> </div>

             </div>


               <div className="Fpassword-body-down">
                <div className="Fpassword-next" onClick={() => handleConfirmNext()}>
                  Continue
                </div>
               </div>


          </div>

          <div className="Fpassword-footer"> </div>

       </div>
    </div>
  );
}

export default Fpasswordnext;
