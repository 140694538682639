import * as actionTypes from "../actions/actionTypes";

const initialState = {
   roomflag:false
};

const roomReducer = (state = initialState, action) => {

  switch (action.type) {

    case actionTypes.CHANGE_ROOMFLAG:

      return {
        roomflag:action.payload
      };

    default:
      return state;
  }
};

export default roomReducer;
