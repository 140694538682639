import * as actionTypes from "../actions/actionTypes";

const initialState = {
    startgrpcall:false
};

const reqgrpcallReducer = (state = initialState, action) => {

  switch (action.type) {

    case actionTypes.REQ_GRP_CALL:

      return {
        startgrpcall:action.payload
      };

    default:
      return state;
  }
};

export default reqgrpcallReducer;
