import React, { useEffect,useState,useContext } from 'react';
import { useSelector,useDispatch } from "react-redux";
import { useNavigate,useLocation } from 'react-router-dom';

import {  SocketContext  } from "../../context/SocketContext";
import { getUserDetails } from '../../redux/thunk/user/fetchdata';

import { reqDel,confirmReq } from '../../redux/actions/actionCreators';
import * as actionCreators from "../../redux/actions/actionCreators";

import NavBar from '../../components/homepage/homepage-topnavbar/Homepage-topnavbar';
import Homepagewrapper from '../../components/homepage/homepage-maincontainer/Homepagewrapper';
import Requestmodel from '../../components/homepage/profile&settings/request/Requestmodel';
import Requestconfirm from '../../components/homepage/profile&settings/request/Requestconfirm';

import './Homepage.css';

function Homepage() {

   const [displayFlag,setdisplayFlag] = useState(false);
   const dispatch = useDispatch();
   const { socket,socketid } = useContext(SocketContext);

   const navigate = useNavigate();
   let location = useLocation()


   const userdetails = useSelector((state) => state.userReducer.userdetails);
   const requestDelete = useSelector((state) => state.popupReducer.requestDelete);
   const reqconfirmReducer = useSelector((state) => state.reqconfirmReducer.requestConfirm);
   const authUser = useSelector((state) => state.authReducer.authflag);
   const redirectpath = useSelector((state) => state.redirectReducer.redirectpath);
   const usersinfodata = useSelector((state) => state.usersinfodataReducer.usersinfodata);


   const handleRequestDel = () => {

     let object = {
        reqstatus:false
     }

      dispatch(reqDel(object));

   }

   const handleRequestconfrim = () => {

     let object = {
        reqstatus:false
     }
     dispatch(confirmReq(object));

   }

   useEffect(async () => {

      const responseData = await getUserDetails();

       if(responseData.STATUSCODE == 3) {
         dispatch(actionCreators.addAuthUser(responseData.PAYLOAD));
       }

       if(responseData.STATUSCODE == 0) {

        let userDetails = {
          firstname:responseData && responseData.PAYLOAD.firstname,
          lastname:responseData && responseData.PAYLOAD.lastname,
          profile_image:responseData && responseData.PAYLOAD.profile_image,
          userid:responseData && responseData.PAYLOAD.userid,
          username:responseData && responseData.PAYLOAD.username,
          useremail:responseData && responseData.PAYLOAD.useremail,
          mobile:responseData && responseData.PAYLOAD.mobile,
          isEmailVerified:responseData && responseData.PAYLOAD.isEmailVerified,
       }

           dispatch(actionCreators.addUserDetails(userDetails));
           dispatch(actionCreators.addnotifyAlert(responseData.PAYLOAD.notify));
           dispatch(actionCreators.addmsgAlert(responseData.PAYLOAD.msgnotify));

      }

  },[])

   useEffect(() => {

    if(socketid) {

      if(Object.entries(userdetails).length !== 0) {

         if(socketid) {
            socket?.emit("newUser",socketid,userdetails.userid);
            setdisplayFlag(true);
          }

        }

    }

    },[socketid,userdetails,socket])

  useEffect (() => {


      if(authUser == true) {

          let urladdress = location.pathname

          if(urladdress.includes("homepage")) {
              if(redirectpath != null) {
                navigate(`/homepage${redirectpath}`)
              }

          } else {
              navigate(`homepage/${location.pathname}`)
           }


        } else {
          var urladd = location.pathname.replace('/homepage','');

       }


     },[])

  useEffect(() => {
    console.log("usersinfodata",usersinfodata);
  },[])


  return (
    <div className="homepagecontainer">

       {requestDelete.reqstatus ?
          <Requestmodel handleRequestDel={handleRequestDel} requestDelete={requestDelete} />
       : null }

       {reqconfirmReducer.reqstatus ?
          <Requestconfirm handleRequestconfrim={handleRequestconfrim} reqconfirmReducer={reqconfirmReducer} />
        : null }

      {displayFlag && <Homepagewrapper /> }
    </div>
  );
}

export default Homepage;
