import './Squadpicker.css';
import React, { useEffect,useState,useContext,useRef } from 'react';
import { useParams,useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from "react-redux";
import { MdOutlineCancel } from 'react-icons/md';
import Dot from '../../../../assets/squad/Dot.png';
import Binary from '../../../../assets/squad/Binary.png';
import Tri from '../../../../assets/squad/Tri.png';
import Quad from '../../../../assets/squad/Quad.png';
import Quint from '../../../../assets/squad/Quint.png';

function Squadpicker(props) {

  const myref = useRef(null);

  const handleSelect = (squadnumber) => {

    if(squadnumber == 1) {
       let str = "Only You";
       props.squadSelect(squadnumber,str);
    }

    if(squadnumber == 2) {
      let str = "You + 1";
      props.squadSelect(squadnumber,str);
    }

    if(squadnumber == 3) {
      let str = "You + 2";
      props.squadSelect(squadnumber,str);
    }

    if(squadnumber == 4) {
       let str = "You + 3";
       props.squadSelect(squadnumber,str);
    }

    if(squadnumber == 5) {
       let str = "You + 4";
       props.squadSelect(squadnumber,str);
    }

  }

  const cancelSqaud = () => {
    props.hideSquad();
  }

  const handleClick = (event) => {
    if (myref.current && !myref.current.contains(event.target)) {
       props.hideSquad();
      }
   };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
       document.removeEventListener("mousedown", handleClick, false);
     };
  },[])

  return (
    <div className="Squadpicker-modal">
    <div className="Squadpicker-wrapper" ref={myref}>

        <div className="Squadpicker-header">
           <div> </div>
           <div>Pick Squad</div>
           <div className="Squadpicker-cancel" onClick={cancelSqaud}>
             <MdOutlineCancel />
           </div>
         </div>


           <div style={{height:10}}> </div>
         <div className="Squadpicker-container">

          <div className="Squadpicker-box-wrapper">

             <div className="Squadname">Dot</div>
             <div style={{height:5}}> </div>
              <div className="Squadpicker-box" onClick={() => handleSelect(1)}>
                 <div><img className="Squadimg" src={Dot} /></div>
              </div>
              <div className="Squadtitle">One Person</div>
              <div className="Squadsubtitle">( Only You )</div>

              <div className="Squadline"> </div>

          </div>

            <div style={{width:10}}> </div>

           <div className="Squadpicker-box-wrapper">

               <div className="Squadname">Binary</div>


             <div style={{height:5}}> </div>

              <div className="Squadpicker-box" onClick={() => handleSelect(2)}>
                <div><img className="Squadimg" src={Binary} /></div>
              </div>

              <div className="Squadtitle">Two Person</div>
              <div className="Squadsubtitle">( You & One )</div>
              <div className="Squadline"> </div>


           </div>

            <div style={{width:10}}> </div>

          <div className="Squadpicker-box-wrapper">

           <div className="Squadname">Tri</div>


            <div style={{height:5}}> </div>

            <div className="Squadpicker-box" onClick={() => handleSelect(3)}>
               <div><img className="Squadimg" src={Tri} /></div>
            </div>

            <div className="Squadtitle">Three Person</div>
            <div className="Squadsubtitle">( You & Two )</div>
            <div className="Squadline"> </div>

          </div>

           <div style={{width:10}}> </div>

          <div className="Squadpicker-box-wrapper">

            <div className="Squadname">Quad</div>

            <div style={{height:5}}> </div>

            <div className="Squadpicker-box" onClick={() => handleSelect(4)}>
             <div><img className="Squadimg" src={Quad} /></div>
            </div>

            <div className="Squadtitle">Four Person</div>
            <div className="Squadsubtitle">( You & Three )</div>
            <div className="Squadline"> </div>

          </div>

           <div style={{width:10}}> </div>

          <div className="Squadpicker-box-wrapper">

              <div className="Squadname">Quint</div>

            <div style={{height:5}}> </div>

            <div className="Squadpicker-box" onClick={() => handleSelect(5)}>
              <div><img className="Squadimg" src={Quint} /></div>
            </div>

            <div className="Squadtitle">Five Person</div>
            <div className="Squadsubtitle">( You & Four )</div>
            <div className="Squadline"> </div>

          </div>


        </div>
    </div>
    </div>
   )
}

export default Squadpicker;
