import React from 'react';
import { useRef,useEffect,useContext } from 'react';
import { useSelector,useDispatch } from "react-redux";
import { RiCloseFill } from 'react-icons/ri';
import { delUserpost } from '../../../../redux/thunk/post/fetchdata';
import * as actionCreators from '../../../../redux/actions/actionCreators';
import {  SocketContext  } from "../../../../context/SocketContext";
import './Delpost.css';

function Delpost(props) {

   const myref = useRef(null);
   const dispatch = useDispatch();
   const delFlag = useSelector((state) => state.postlistReducer.delpost);
   const { socket } = useContext(SocketContext);

   const handleClick = (event) => {

     let obj = {
       delflag:false,
     }

     if (myref.current && !myref.current.contains(event.target)) {
          dispatch(actionCreators.delUserpost(obj));
      }
   };

   const handleClose = () => {

     let obj = {
       delflag:false,
     }

     dispatch(actionCreators.delUserpost(obj));
   }

   const handleDel = async() => {

      let sendobj = {
         postId:delFlag.postId,
         userId:delFlag.userId,
         userType:delFlag.userType
      }


      let obj = {
        delflag:false,
      }

       dispatch(actionCreators.delUserpost(obj));

       const response = await delUserpost(sendobj);

       if(response.STATUSCODE === 0) {

           let delusrobj = {
             postid:sendobj.postId,
             topicid:delFlag.topicid
           }

         socket.emit("deluserpost", {
              delusrobj
          })

         dispatch(actionCreators.delUserpostlist(sendobj.postId));

       {/*
         socket.on("del-userpost", (data) => {
           console.log("Inside del-userpost");
            console.log("data",data);
            console.log("data",data.postId);
           dispatch(actionCreators.delUserpostlist(data.postId));
         })
        */}

       }
   }

   useEffect(() => {

     document.addEventListener("mousedown", handleClick);

     return () => {
       document.removeEventListener("mousedown", handleClick, false);
     };

  }, []);

  return (
     <div className="Delpost-wrapper" >
        <div ref={myref} className="Delpost-container">
           <div className="Delpost-title">
              <div className="Delpost-close-text"><strong>Delete Post ?</strong></div>
              <div className="Delpost-close" onClick={handleClose}>
                <RiCloseFill/>
              </div>
            </div>
           <div className="Delpost-name">
              <p>Are you sure you want to delete post ?</p>
            </div>
            <div className="Delpost-btn-height"> </div>
           <div className="Delpost-btn-wrapper">
              <div className="Delpost-btn" onClick={handleDel}>Delete</div>
           </div>
        </div>
     </div>
  );
}

export default Delpost;
