import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { useSelector,useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { HiOutlineSearch } from 'react-icons/hi';
import { MdOutlineClear } from 'react-icons/md';
import { BsArrowLeftShort } from 'react-icons/bs';
import { getGlobalSearchResult } from '../../../../redux/thunk/search/fetchdata';
import * as actionCreators from '../../../../redux/actions/actionCreators';
import './SearchBar.css';

let timer;

function SearchBar(props) {

  const [searchclearflag, setSearchclearflag] = useState(false);
  const [searchvalue, setSearchvalue] = useState("");

  const [searchTerm,setSearchTerm] = useState(null);
  const [searchResult,setSearchReult] = useState(null);

  const searchPage = useSelector((state) => state.searchReducer.searchPage);

  const inputRef = useRef(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleOnChange = (event) => {

    let searchQuery = event.target.value.trim().toLowerCase();

     if(event.target.value === "") {
        setSearchclearflag(false);
        setSearchvalue(event.target.value);
     } else {
        setSearchclearflag(true);
        setSearchvalue(event.target.value);

        if(searchQuery.length >= 2) {
            setSearchTerm(searchQuery);
         } else {
           if(searchResult !== null) {
             setSearchReult(null);
           }
         }

     }

  }

  const handleNavigate = (element) => {

    if(element.userid) {
        props.handleSearchClose();
        navigate(`/homepage/account/${element.userid}`);
     }

    if(element.topicId) {
      props.handleSearchClose();
      navigate(`/homepage/pool/pooldetails/${element.topicId}`)
    }
  }

  const handleClear = () => {
    setSearchvalue("");
    setSearchReult(null);
    setSearchclearflag(false);
    inputRef.current.focus();
  }

  const handleBack = () => {
    props.handleSearchClose();
  }

  const handleAllSearch = () => {

     dispatch(actionCreators.addSearchTerm(searchTerm));
     dispatch(actionCreators.addPeoplePoolTerm(searchTerm));

    if(searchResult != null) {

      if(searchResult[0].topicId != undefined && searchResult[0].userid != undefined) {
       navigate(`/homepage/searchresult`);
     }

      if(searchResult[0].topicId != undefined) {
       navigate(`/homepage/searchresult`);
     }

      if(searchResult[0].userid != undefined) {
        navigate(`/homepage/searchresult/people`);
     }

      props.handleSearchClose();

    }

  }

  useEffect (() => {
     inputRef.current.focus();
    }, [])

   useEffect(async () => {

    if(searchTerm !== null) {

       clearTimeout(timer);

       timer = setTimeout(async () => {
        const searchResponse = await getGlobalSearchResult(searchTerm);

       if(searchResponse.STATUSCODE == 0) {
           setSearchReult(searchResponse.PAYLOAD);
       }

     }, 500);

    }

   },[searchTerm])

  return (
      <div className="SearchbarWrapper">
        <div className="SearchbarContainer">

          <div className="Searchbar">

            <div className="Closebtn" onClick={() => handleBack()}><BsArrowLeftShort/></div>

             <div className="Searchbtn">
                <div className="Searcharea">
                  <input type="text" className="Searchareainput" ref={inputRef}
                   placeholder="Search" onChange={(event) => handleOnChange(event)} value={searchvalue} />
                    <div className={searchclearflag ? "Clearicon" : null } onClick={() => handleClear()} >
                     {searchclearflag ? <MdOutlineClear /> : null }
                    </div>
                </div>

                <div className={searchclearflag ? "Searchiconeb" : "Searchicon" } onClick={() => handleAllSearch()}>
                  <HiOutlineSearch />
                </div>
              </div>

          </div>

          <div className="SearchresultWrapper">

            <div className="Searchresult">
               <div className="SearchresultTitle">
                 Search Result
               </div>
                <div className="Wrapperresult">

                {searchResult?.map((element,index) =>  {

                  let value ;

                  if(element.topic != undefined) {
                     value = element.topic;
                  } else {

                  if(element.firstname != null || element.lastname != null ) {

                     if(element.firstname.length > 0 || element.lastname.length > 0) {
                        value = element.firstname+" "+element.lastname;
                     } else {
                        value = element.username;
                     }

                   } else {
                        value = element.username;
                    }

                 }

                  return  (
                      <div key={index} onClick={() => handleNavigate(element)}>
                        <div className="Result">{value}</div>
                        <div className="Result-emptyspace"></div>
                      </div>
                    )
                  })}

                {searchResult ?
                 <div className="topicsearch-line-all" onClick={() => handleAllSearch()}>Show All Search</div>
                 : null }
                </div>
             </div>

          </div>

       </div>
    </div>
   )

}

export default SearchBar;
