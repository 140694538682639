import React, { useEffect,useState,useRef,useCallback } from 'react';
import { Routes, Link, Route,useParams,useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from "react-redux";
import { BsArrowLeftShort } from 'react-icons/bs';
import { getUserpost } from '../../../../redux/thunk/post/fetchdata';
import { RxDotsHorizontal } from 'react-icons/rx';
import { TbSquareRotated } from 'react-icons/tb';
import { MdOutlineReply } from 'react-icons/md';
import defaultprofile from '../../../../assets/default/user.png';
import * as actionCreators from '../../../../redux/actions/actionCreators';
import { getSearchpostLimit } from '../../../../redux/thunk/post/fetchdata';
import Doublebounce from '../../../../spinner/double-bounce/Doublebounce';
import Replybox from './Replybox';

import './Postsearch.css';

function Postsearch() {

  const userdetails = useSelector((state) => state.userReducer.userdetails);
  const team = useSelector((state) => state.teamReducer.team);
  const topicobj = useSelector((state) => state.activeReducer.topicid);
  const postsearch = useSelector((state) => state.postlistReducer.postsearch);
  const postsearchterm = useSelector((state) => state.postlistReducer.postsearchterm);

  const page = useSelector((state) => state.postlistReducer.searchpage);
  const temp = useSelector((state) => state.postlistReducer.searchtemp);
  const hasMore = useSelector((state) => state.postlistReducer.searchhasMore);

  const myref = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const observer = useRef();

  const [userpost,setUserpost] = useState([]);
  const [menuFlag,setMenuflag] = useState(null);
  const [replyFlag,setReplyflag] = useState(null);

  const [replyUser,setReplyuser] = useState(null);
  const [replypostid,setReplypostid] = useState(null);
  const [replyuserid,setReplyuserid] = useState(null);
  const [replyImgarray,setReplyimgarray] = useState(null);
  const [postreply,setPostreply] = useState(null);

    const handleBack = () => {
      let tempempty = [];
      dispatch(actionCreators.clearPostSearchTerm(tempempty));
      navigate(-1);
    }

    const handleScroll = (event) => {
       setMenuflag(null);
    }

    const cancelReply = () => {
       setReplyflag(null);
    }

    const handleReply = (username,postid,userid,post,imgArray,index) => {

           let name = "@"+username;
           setReplyuser(name);
           setReplypostid(postid);
           setReplyuserid(userid);
           setPostreply(post);
           setReplyimgarray(imgArray);
           setReplyflag(index);
    }

    const hanleMenuopt = (index) => {
        setMenuflag(index)
    }

    const handleDelete = (postid,userid,currentuser) => {

      let usertype;

      if(userid == currentuser) {
         usertype = "normaluser";
      } else if (topicobj.superuser == userid) {
         usertype = "superuser";
      }

      let obj = {
        delflag:true,
        postId:postid,
        userId:userid,
        userType:usertype
      }

      dispatch(actionCreators.delUserpost(obj));
      setMenuflag(null);
    }

    const handelFocusReply = (item) => {
       dispatch(actionCreators.addUserpreviewid(item.postid));
     }

    const handleRmvtag = (postid,userid,tag) => {

        let tname = tag.smarttag.filter(item => item.userid == userid);

       let obj = {
         smartflag:true,
         postId:postid,
         userId:userid,
         tagname:tname[0].tagname
       }

       dispatch(actionCreators.rmvSmarttag(obj));
       setMenuflag(null);
     }

    const handleSmarttag = (postid,userid) => {

       let obj = {
         smartflag:true,
         postId:postid,
         userId:userid
       }

       dispatch(actionCreators.addSmartTag(obj));
       setMenuflag(null);
     }

    const lastpostElementRef = useCallback(node => {

       if(observer.current) observer.current.disconnect();

       observer.current = new IntersectionObserver(entries => {
         if (entries[0].isIntersecting && hasMore) {
           dispatch(actionCreators.addSearchpage(temp));
         }
       })

       if(node) {
          observer.current.observe(node)
        }

     })

    useEffect(async () => {

       if(postsearchterm.length > 2) {

           let lim = 1;
           let limit;

           limit = page * lim;


      if(limit > postsearch.length && hasMore == true) {

           let result = await getSearchpostLimit(page,lim,topicobj.topicid,postsearchterm);

           dispatch(actionCreators.addSearchhasmore(true));

           if(result.STATUSCODE == 0) {

             let ft = result.PAYLOAD[0];

              if(ft.next == undefined) {
                 dispatch(actionCreators.setPostSearchResult(result.PAYLOAD.slice(1)));
                 dispatch(actionCreators.addSearchhasmore(false));
             }  else {
                 dispatch(actionCreators.setPostSearchResult(result.PAYLOAD.slice(1)));
                 dispatch(actionCreators.addSearchtemp(ft.next.page));
              }


           } else {
             dispatch(actionCreators.addSearchhasmore(false));
          }

        }

       }

    },[postsearchterm,page,temp])

  return (

      <div>

           return (
            <div className="Postsearch-wrapper">

              {postsearch.length > 0 ?
               <div className="Postsearch-header">
                 <div className="Postsearch-titlearrow" onClick={handleBack}>
                    <BsArrowLeftShort/>
                  </div>
                  <div style={{width:4}}> </div>
                  <span className="Postsearch-titlearrow-tile">back</span>
               </div> : null }

                {postsearch?.map((element,index) => {

                  let usrdetails = team.filter(function (el) {
                    return el.enrolleduserId == element.userid;
                  });

                  return (

                   <div ref={lastpostElementRef} className="Postsearch-body">
                  <div  className="Postsearch-listwrapper">

                   <div className="postlist-outwrapper">

                        <div className="postlist-outprofile">

                         <div className="postlist-profile"
                         style={{
                            backgroundImage:usrdetails[0] && usrdetails[0].profileImage ? `url("${usrdetails[0].profileImage}")` : `url("${defaultprofile}")`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            maxWidth:'100%',
                            maxHeight:'100%',
                          }}
                        />

                         <span className="postlist-space"></span>
                         <span className="postlist-name">{usrdetails[0] && usrdetails[0].username}
                           <span style={{width:10}} />
                           <span style={{color:"#909090"}}>{element.currentTime}</span>
                         </span>
                        </div>

                           <button className="postlist-tag" onClick={() => hanleMenuopt(index)}>
                              <RxDotsHorizontal />
                           </button>


                      <div>

                     </div>
                   </div>

                    <div className="postlist-empty">
                     {menuFlag == index ?
                       <div id="scrollpop" className="postlist-menu" onScroll={(event) => handleScroll(event)} ref={myref}>

                          {(userdetails.userid == element.userid) || (topicobj.superuser == userdetails.userid) ?
                             <div className="postlist-menu-btn" onClick={() => handleDelete(element.postid,userdetails.userid,element.userid)}>Delete</div>
                           : null }

                           <div className="postlist-menu-ht"></div>

                       {element.smarttag && element.smarttag.some(item => item.userid == userdetails.userid) ?

                            <div className="postlist-menu-btn" onClick={() => handleRmvtag(element.postid,userdetails.userid,element)}>
                              Remove Smart Tag
                            </div> :
                            <div className="postlist-menu-btn" onClick={() => handleSmarttag(element.postid,userdetails.userid)}>
                               Add Smart Tag
                            </div>
                          }

                       </div> : null }
                    </div>

                    {element.topicpost && element.topicpost ?
                      <div className="postlist-text" dangerouslySetInnerHTML={{__html: element.topicpost }} >
                     </div> : null }

                     <div className="postlist-img">
                       {element.imgArray && element.imgArray.map((item,itemindex) => {

                         let imgurl

                         if(item.postimage) {
                           imgurl = item.postimage;
                         } else {
                            const b64 = new Buffer(item).toString('base64')
                             imgurl  = "data:image/png;base64,"+b64;
                          }

                        return (
                           <img className="postlistimg" key={itemindex} src={imgurl} />
                        )

                       })}

                     </div>

                     {element.replydetails && element.replydetails.map((replyitem,replyindex) => {

                         let replyusrdetails = team.filter(function (el) {
                           return el.enrolleduserId == replyitem.userid;
                         });

                          return (
                           <Link to={`/homepage/active/${topicobj.topicid}/preview/${replyitem.postid}`}  style={{textDecoration:"none"}} key={replyindex} className="postlist-reply-details" onClick={() => handelFocusReply(replyitem)}>

                            <div className="postlist-outprofile">
                              <div className="postlist-reply-profile"
                             style={{
                                backgroundImage:replyusrdetails[0] && replyusrdetails[0].profileImage ? `url("${replyusrdetails[0].profileImage}")` : `url("${defaultprofile}")`,
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                maxWidth:'100%',
                                maxHeight:'100%',
                              }}
                            />

                               <span className="postlist-space"></span>
                               <span className="postlist-name">{replyusrdetails[0] && replyusrdetails[0].username }
                              <span style={{width:10}} />
                                <span style={{color:"#909090"}}>{replyitem.currentTime}</span>
                               </span>
                             </div>

                            {replyitem.topicpost && replyitem.topicpost ?
                              <div className="postlist-text" dangerouslySetInnerHTML={{__html: replyitem.topicpost }} >
                             </div> : null }

                            <div style={{height:10}}> </div>

                            <div className="postlist-item-wrapper-img">
                              {replyitem.imgArray	&& replyitem.imgArray.map((imgitem,imgindex) => {
                                let imgurl;

                                if(imgitem.postimage) {
                                  imgurl = imgitem.postimage;
                                } else {
                                   const b64 = new Buffer(imgitem).toString('base64')
                                    imgurl  = "data:image/png;base64,"+b64;
                                 }
                                 return (
                                     <div key={imgindex} className="postlist-item-img">
                                       <img src={imgurl} />
                                     </div>
                                 )

                             })}
                             </div>
                           </Link>
                        )
                       })}

                     <div className="postlist-reply">
                      <div className="postlist-reply-btn-wrapper" onClick={() => handleReply(usrdetails[0].username,element.postid,element.userid,element.topicpost,element.imgArray,index)}>
                        <div className="postlist-reply-text">Reply</div>
                        <div className="postlist-reply-text" style={{width:10}}></div>
                        <div className="postlist-reply-icon"><MdOutlineReply /></div>
                     </div>
                     </div>

                  </div>

                  {replyFlag == index ?
                     <div className="Postsearch-replybox">
                       <Replybox replyUser={replyUser}
                          replypostid={replypostid}
                          replyuserid={replyuserid}
                          replyImgarray={replyImgarray}
                          postreply={postreply}
                          cancelReply={cancelReply} />
                     </div> : null }

                   </div>

                 );

                })}

                {hasMore ?
                  <div className="Postsearch-spinner">
                    <Doublebounce />
                  </div> : null }

            </div>
          )



      </div>
  )
}

export default Postsearch;
