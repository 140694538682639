//  Add User Pin

export const addUserPin = async (pin) => {

 try {

   let url = '/adduserpin';

   const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }, body:JSON.stringify({
          pin:pin
       })
    };

   const response = await fetch(url,requestOptions);

    if(!response.ok) {
      throw {
        STATUSCODE:1,
        STATUS:"ERROR",
        MESSAGE:"Something broke!" }
    }  else {
        const result = await response.json();
        return result;
     }

 } catch (error) {
     return error;
  }

}

// Update Profile details

export const addUsesScecuirtyCode = async (scode) => {

 try {

   let url = '/addusersecuritycode';

   const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body:JSON.stringify({
          scode:scode
        })
    };

   const response = await fetch(url,requestOptions);

    if(!response.ok) {
       throw {
         STATUSCODE:1,
         STATUS:"ERROR",
         MESSAGE:"Something broke!" }
     } else {
        const result = await response.json();
        return result;
     }

 } catch (error) {
     return error;
  }

}

// Check user pinflag

export const checkuserpin = async(obj) => {

  try {

    let url = '/checkuserpin';

    const requestOptions = {
         method: 'POST',
         headers: {
           'Content-Type': 'application/json',
           'Accept': 'application/json',
         },
         body:JSON.stringify({
           obj:obj
         })
     };

    const response = await fetch(url,requestOptions);

     if(!response.ok) {
        throw {
          STATUSCODE:1,
          STATUS:"ERROR",
          MESSAGE:"Something broke!" }
      } else {
         const result = await response.json();
         return result;
      }

  } catch (error) {
      return error;
   }


}

//  Get Secruity Code

export const getSecruityCode = async (userid) => {

 try {

   let url = '/getsecruitycode';

   const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }, body:JSON.stringify({
          userid:userid
       })
    };

   const response = await fetch(url,requestOptions);

    if(!response.ok) {
      throw {
        STATUSCODE:1,
        STATUS:"ERROR",
        MESSAGE:"Something broke!" }
    }  else {
        const result = await response.json();
        return result;
     }

 } catch (error) {
     return error;
  }

}

//  Verify user pin

export const verifyuserpin = async (userpin,userid) => {

  try {

    let url = '/verifyuserpin';

    const requestOptions = {
       method: 'POST',
       headers: {
         'Content-Type': 'application/json',
         'Accept': 'application/json',
       }, body:JSON.stringify({
           userpin:userpin,
           userid:userid
        })
     };

    const response = await fetch(url,requestOptions);

     if(!response.ok) {
       throw {
         STATUSCODE:1,
         STATUS:"ERROR",
         MESSAGE:"Something broke!" }
     }  else {
         const result = await response.json();
         return result;
      }

  } catch (error) {
      return error;
   }

}

// Update user pin

export const updatepin = async (pin,userid) => {


  try {

    let url = '/updateuserpin';

    const requestOptions = {
       method: 'POST',
       headers: {
         'Content-Type': 'application/json',
         'Accept': 'application/json',
       }, body:JSON.stringify({
            pin:pin,
            userid:userid
        })
     };

    const response = await fetch(url,requestOptions);

     if(!response.ok) {
       throw {
         STATUSCODE:1,
         STATUS:"ERROR",
         MESSAGE:"Something broke!" }
     }  else {
         const result = await response.json();
         return result;
      }

  } catch (error) {
      return error;
   }


}


//  Verify user secuirty code

export const verifyusersecuirtycode = async (secuirtycode,useremail) => {

  try {

    let url = '/verifysecuirtycode';

    const requestOptions = {
       method: 'POST',
       headers: {
         'Content-Type': 'application/json',
         'Accept': 'application/json',
       }, body:JSON.stringify({
           secuirtycode:secuirtycode,
           useremail:useremail
        })
     };

    const response = await fetch(url,requestOptions);

     if(!response.ok) {
       throw {
         STATUSCODE:1,
         STATUS:"ERROR",
         MESSAGE:"Something broke!" }
     }  else {
         const result = await response.json();
         return result;
      }

  } catch (error) {
      return error;
   }

}
