import React, { useState,useRef,useEffect,useCallback} from 'react';
import { Routes, Link, Route, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TiStarFullOutline } from 'react-icons/ti';
import './Homepage-topnavbar-msgnotification.css';
import { getUserMsgNotifications,updateUserMsgNotifications } from '../../../../redux/thunk/message/fetchdata';
import defaultProfile from '../../../../assets/default/user.png';
import * as actionCreators from "../../../../redux/actions/actionCreators";
import Classicspiner from '../../../../spinner/classic-spiner/Classicspiner';

function Homepagetopnavbarmsgnotification(props) {

  const userdetails = useSelector((state) => state.userReducer.userdetails);

  const [animFlag,setAnimFlag] = useState(false);
  const [flag,setFlag] = useState(false);
  const [notification,setNotification] = useState([]);
  const [loading,setLoading] = useState(false);

  const [page,setPage] = useState(1);
  const [hasMore,setHasMore] = useState(true);
  const [temp,setTemp] = useState();

  const dispatch = useDispatch();
  const observer = useRef();
  const navigate = useNavigate();

  const lastMsgElementRef = useCallback(node => {

    if (observer.current) observer.current.disconnect()
       observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasMore) {
          setPage(temp);
       }
    })

      if(node) {
          observer.current.observe(node)
       }
  })

  const notiRef = useRef(null);

  const closeMenu = () => {
    setAnimFlag(false);
    setFlag(true);
  }

  const handleNoti = (event) => {

    if(notiRef.current.contains(event.target)) {

     } else {
      props.closeMessage();
     }
  }

  const showallmsg = (event) => {
    event.preventDefault();
    event.stopPropagation();
    props.closeMessage();
    navigate(`/homepage/message`);
  }

  const handleNavgate = async(event,element) => {

    event.preventDefault();
    event.stopPropagation();

     const response = await updateUserMsgNotifications(element.msgnotify_id);

    let accobj = {
      firstname:element.msgnotifyfrom.PAYLOAD.firstname,
      lastname:element.msgnotifyfrom.PAYLOAD.lastname,
      mobile:element.msgnotifyfrom.PAYLOAD.mobile,
      profile_image:element.msgnotifyfrom.PAYLOAD.profile_image,
      useremail:element.msgnotifyfrom.PAYLOAD.useremail,
      userid:element.msgnotifyfrom.PAYLOAD.userid,
      username:element.msgnotifyfrom.PAYLOAD.username
    }

    dispatch(actionCreators.addUserAccountDetails(accobj));

    navigate('/homepage/message');
    props.closeMessage();


  }

    useEffect(() => {
     setAnimFlag(true);
     document.addEventListener("mousedown", handleNoti);
      return () => {
        document.removeEventListener("mousedown", handleNoti, false);
      };
  }, []);

    useEffect(() => {
      if(flag) {
        setTimeout(function(){
         props.closeMessage();
         }, 500);
      }
    },[flag])

    useEffect(async () => {

      let lim = 5;

      let obj = {
        userid:userdetails.userid,
        page:page,
        limit:lim
      }

    if(userdetails.userid) {

      const result = await getUserMsgNotifications(obj);

       if(result) {

         let ft = result[0];

         if(ft.next == undefined) {
            setNotification([...notification,...result.slice(1)]);
            setHasMore(false);
          } else {
            setNotification([...notification,...result.slice(1)]);
            setTemp(ft.next.page)
         }

       }

     }

    }, [page,userdetails]);


  return (
    <div ref={notiRef} className={animFlag ? "Homepage-topnavbar-notification-notificationpanel-op" :
    "Homepage-topnavbar-notification-notificationpanel-cl"}>

       <div className="Homepage-topnavbar-notification-notificationHeader">
         <div className="Homepage-topnavbar-notiheader">Message</div>

         <div onClick={() => closeMenu()} className="Homepage-topnavbar-menu-close">
              &times;
         </div>
       </div>


       <div className="Homepage-topnavbar-notification-notificationHeader" onClick={(event) => showallmsg(event)}>
         <div className="Homepage-topnavbar-notiheader-msg">
           Show all Message
         </div>
       </div>


        <div className="Homepage-topnavbar-notification-notificationList">

          {notification && notification.map((element,index) => {

               return (
                <>
                {element ?
                  <div key={index} ref={lastMsgElementRef}
                       className="Homepage-topnavbar-notification-notificationFeed"
                     onClick={(event) => handleNavgate(event,element)}>

                    <div className="Homepage-topnavbar-notification-notificationFeed-wrapper">

                    <div className="Homepage-topnavbar-profile-wrapperaa">

                      <div className="Homepage-topnavbar-profile-wrapper">

                       <div className="Homepage-topnavbar-profile-img" style={{
                          backgroundImage:element.msgnotifyfrom.PAYLOAD.profile_image ? `url("${element.msgnotifyfrom.PAYLOAD.profile_image}")` : `url("${defaultProfile}")`,
                          backgroundPosition:'center',
                          backgroundSize:'cover',
                          backgroundRepeat:'no-repeat',
                          maxWidth:'100%',
                          maxHeight:'100%'
                        }}>
                       </div>

                      </div>

                       <div className="Homepage-topnavbar-wrapper-text">

                           <div className="Homepage-topnavbar-notification-text">
                              <div className="Homepage-topnavbar-notification-msgtxt">
                                <p className="Homepage-topnavbar-noti-msg">{element.msgnotifyfrom.PAYLOAD.username}</p>
                                <div className="Homepage-topnavbar-noti-msgtxt">{element.msgtext}</div>
                               </div>
                            </div>

                       </div>
                    </div>

                    </div>

                    <div>&nbsp;&nbsp;</div>
                    {element.msgnotifiycheck == 0 ?
                     <div className="Homepage-topnavbar-notification-notificationFeed-check">
                     </div> : <div className="Homepage-topnavbar-notification-notificationFeed-checked">
                     </div> }


                  </div> : null }
                 </>
               )
            })}

          {hasMore ?
            <div className="Homepage-topnavbar-notification-spinner">
              <Classicspiner />
            </div> : null }

        </div>

 </div>

  )
}

export default Homepagetopnavbarmsgnotification;
