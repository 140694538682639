import { Routes, Route,Navigate } from "react-router-dom";

import Poolpage from '../pages/poolpage/Poolpage';
import Accountpage from '../pages/account/Accountpage';
import Searchpage from '../pages/searchpage/Searchpage';
import LandingPage from '../pages/landingpage/Landingpage';
import Homepage from '../pages/homepage/Homepage';
import Howitwork from '../pages/howitwork/Howitwork';
import Aboutprioryou from '../pages/about/Aboutprioryou';
import Contactprioryou from '../pages/about/Contactprioryou';
import Deactivatedpage from '../pages/deactivated/Deactivatedpage';
import NoMatch from '../pages/errpage/Nomatch';
import Logo from '../pages/logo/Logo';
import Download from '../pages/download/Download';
import Privacy from '../pages/privacy/Privacy';

import Login from '../components/landingpage/login/Login';
import Register from '../components/landingpage/register/Register';
import Searchbar from '../components/homepage/homepage-maincontainer/SearchBar/SearchBar';
import Forgetpassword from '../components/landingpage/fpassword/Fpassword';
import Requestdisplay from '../components/homepage/profile&settings/request/Requestdisplay';
import Searchrst from '../components/homepage/searchresult/Searchresult';

import PrivateRoute from './PrivateRoute';
import DashboardRoute from './DashboardRoute';

const InitialRoutes = (isLoggedIn) => [

  {
    path:'/logo',
    element: isLoggedIn ? <Logo /> : <Logo />
  },

  {
    path:'/Searchrst/*',
    element: isLoggedIn ? <Searchpage /> : <Searchpage />
  },

  {
    path: '/homepage/*',
    element: isLoggedIn ? <Homepage /> : <Navigate to="/" />
  },

 {
   path: '/',
   element: !isLoggedIn ?  <LandingPage />  : <Navigate to="/homepage" />,
 },

 {
   path: '/signin',
   element: !isLoggedIn ?  <Login />  : <Navigate to="/homepage" />,
 },

 {
   path: '/register',
   element: !isLoggedIn ?  <Register />  : <Navigate to="/homepage" />,
 },

 {
   path: '/pool/*',
   element: !isLoggedIn ?  <Poolpage />  : <Navigate to="/homepage" />,
 },

 {
   path: '/howitwork',
   element: !isLoggedIn ?  <Howitwork />  : <Navigate to="/homepage" />,
 },

 {
  path: '/Aboutprioryou',
  element: !isLoggedIn ?  <Aboutprioryou />  : <Navigate to="/Aboutprioryou" />,
 },

 {
  path: '/Contactprioryou',
  element: !isLoggedIn ?  <Contactprioryou />  : <Navigate to="/Contactprioryou" />,
 },

 {
  path: '/Download',
  element: !isLoggedIn ?  <Download />  : <Navigate to="/Download" />,
 },

 {
  path: '/Privacy',
  element: !isLoggedIn ?  <Privacy />  : <Navigate to="/Privacy" />,
 },

 {
   path: '/deactivatedpage',
   element: !isLoggedIn ?  <Deactivatedpage/>  : <Navigate to="/homepage" />,
 },

 {
   path: '/account/:accId',
   element: !isLoggedIn ?  <Accountpage />  : <Navigate to="/homepage" />,
 },

 {
   path:'/forgetpassword',
   element: !isLoggedIn ?  <Forgetpassword />  : <Navigate to="/homepage" />,
 },

 {
   path: '*',
   element: <NoMatch />,
 },

];


export default InitialRoutes;
