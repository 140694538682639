import './Requestnotify.css';
import defaultProfile from '../../../../assets/default/user.png';
import React, { useState,useEffect,useRef,useCallback } from 'react';
import { useSelector,useDispatch } from "react-redux";
import { useNavigate,useLocation } from "react-router-dom";
import { getUserRequestSent } from '../../../../redux/thunk/topic/fetchdata';

function Requestnotify() {

  const userdetails = useSelector((state) => state.userReducer.userdetails);

  const navigate = useNavigate();
  const observer = useRef();
  const [requestsent,setRequestsent] = useState([]);

  const [page,setPage] = useState(1);
  const [hasMore,setHasMore] = useState(true);
  const [nextpage,setNextpage] = useState("");
  const [loading,setLoading] = useState(false);

    const lastfollowerElementRef = useCallback(node => {

      if (observer.current)  {
         observer.current.disconnect()
      }

      observer.current = new IntersectionObserver(entries => {
          if (entries[0].isIntersecting && hasMore) {
              setPage(nextpage);
          }
     })

      if(node) {
          observer.current.observe(node)
       }
  })

  useEffect(async () => {

   if(page) {

     let lim = 5;

     let userObj = {
        userid:userdetails.userid,
        page:page,
        limit:lim
     }

     console.log("userObj",userObj);

     setLoading(true);

     const response = await getUserRequestSent(userObj);

     console.log("response",response);

     if(response.STATUSCODE == 0) {

      if(response.NEXT.next == undefined) {
          setRequestsent([...requestsent,...response.PAYLOAD]);
          setHasMore(false);
          setLoading(false);
      } else {
          setRequestsent([...requestsent,...response.PAYLOAD]);
          setNextpage(response.NEXT.next.page)
      }

     } else {
        setLoading(false);
    }


  }

  },[page])

  const handleTopic = (topicid) => {
     navigate(`pooldetails/${topicid}`);
  }

  const handleProfile = (userid) => {
     navigate(`/homepage/account/${userid}`);
  }

 return (
  <div>
   {requestsent && requestsent.map((element,index) =>  {

    return (

   <div className="requestbox-wrapper" ref={lastfollowerElementRef}>

    <div tabindex={index} id={element.topicid} className="requestbox-container">

        <div className="requestbox-profilepanel">

           <div className="requestbox-profileimg"
             onClick={() => handleProfile(element.userDetails.PAYLOAD.userid)}
              style={{
                 backgroundImage:element.userDetails.PAYLOAD.profile_image ? `url("${element.userDetails.PAYLOAD.profile_image}")` : `url("${defaultProfile}")`,
                 backgroundPosition:'center',
                 backgroundSize:'cover',
                 backgroundRepeat:'no-repeat',
                 maxWidth:'100%',
                 maxHeight:'100%'
              }}></div>

            <div className="requestbox-name"
             onClick={() => handleProfile(element.userDetails.PAYLOAD.userid)}>
               {element.userDetails.PAYLOAD.username}
            </div>
             <div className="requestbox-topictitle" onClick={() => handleTopic(element.topicid)}>
                {element.topic}
              </div>
            <div>&nbsp;</div>

        </div>


    </div>

   </div>
  )

  })}

  </div>

   )

}

export default Requestnotify;
