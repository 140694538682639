import React from 'react';
import * as actionCreators from "../../../redux/actions/actionCreators";
import { useRef,useEffect,useState,useCallback } from 'react';
import { Routes,Route,useNavigate,NavLink } from "react-router-dom";
import { useDispatch,useSelector } from "react-redux";
import { HiOutlineSearch } from 'react-icons/hi';
import { getuserfreindlist } from '../../../redux/thunk/user/fetchdata';
import { getPeopleSearch } from '../../../redux/thunk/search/fetchdata';
import { getGlobalSearchResult,getFriendList } from '../../../redux/thunk/search/fetchdata';
import Doublebounce from '../../../spinner/double-bounce/Doublebounce';
import defaultprofile from '../../../assets/default/user.png';
import './Request.css';
import Requestpeoplelist from './Requestpeoplelist';

function Requestpeople(props) {

  let timer;

  const observer = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userdetails = useSelector((state) => state.userReducer.userdetails);
  const topicDetails = useSelector((state) => state.poolDetailsReducer.topicDetails);

  const [searchclearflag, setSearchclearflag] = useState(false);
  const [searchvalue, setSearchvalue] = useState("");
  const [searchTerm,setSearchTerm] = useState(null);
  const [searchResult,setSearchReult] = useState(null);
  const [searcResultflag,setSearcResultflag] = useState(false);

  const [page,setPage] = useState(1);
  const [temp,setTemp] = useState()
  const [hasMore,setHasMore] = useState(false);

  const handleNavigate = (element) => {
   
    let temp = [];
    temp.push(element);

    dispatch(actionCreators.addPeoplePool(temp));
    setSearchReult(null);
    setSearchclearflag(false);
    setSearchvalue("");
    setSearchTerm(null);

 }

  const handleAllSearch = () => {
    dispatch(actionCreators.restUserPool());
    dispatch(actionCreators.addSearchTerm(searchTerm));
    dispatch(actionCreators.addPeoplePoolTerm(searchTerm));
    setSearchReult(null);
    setSearchclearflag(false);
    setSearchvalue("");
    setSearchTerm(null);
 }

  const handleOnChange = (event) => {

    dispatch(actionCreators.addPeoplePoolTerm(""));

    let searchQuery = event.target.value.trim().toLowerCase();

    if(event.target.value === "") {
       setSearcResultflag(false);
       setSearchclearflag(false);
       setSearchvalue(event.target.value);
    } else {
       setSearchclearflag(true);
       setSearchvalue(event.target.value);

      if(searchQuery.length >= 2) {
          setSearchTerm(searchQuery);
       } else {
         if(searchResult !== null) {
           setSearchReult(null);
           setSearchTerm(null);
         }
       }

   }

}

  const handleAccount = (userid) => {
    navigate(`/homepage/account/${userid}`)
  }

  const lastFriendElementRef = useCallback(node => {

    if (observer.current) observer.current.disconnect()
       observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasMore) {
          setPage(temp);
       }
    })

      if(node) {
          observer.current.observe(node)
       }
  })

   useEffect(async () => {

    if(searchTerm !== null) {

      clearTimeout(timer);

      timer = setTimeout(async () => {

      const searchResponse = await getFriendList(searchTerm,topicDetails.topicId);

      if(searchResponse.STATUSCODE == 0) {
          setSearchReult(searchResponse.PAYLOAD);
      } else {
          setSearchReult(null);
      }

  }, 500);

  }

   },[searchTerm])


  return (
      <div className="Request-container">

      <div className="Request-header">{topicDetails?.topic}</div>
      <div className="Request-header">Send Pool Request</div>

      <div className="Request-search">
        <div className="Request-bar"><input className="Request-bar-input" value={searchvalue} onChange={(event) => handleOnChange(event)}  type="text" placeholder="Search"  /></div>
         <div className="Request-search-icon"><HiOutlineSearch size={30}/></div>
      </div>

      <div style={{height:20}}></div>

      {searchclearflag ?
        <div className="Request-searchbarContainer">

         <div className="Searchresult">
          <div className="SearchresultTitle">
            Search Result
          </div>
           <div className="Wrapperresult">

            {searchResult?.map((element,index) =>  {

                let value ;

               if(element.topic != undefined) {
                  value = element.topic;
               } else {

             if(element.firstname != null || element.lastname != null ) {

                if(element.firstname.length > 0 || element.lastname.length > 0) {
                   value = element.firstname+" "+element.lastname;
                } else {
                   value = element.username;
                }

              } else {
                   value = element.username;
               }

            }

              if (index < 4) {

             return  (
                 <div key={index} onClick={() => handleNavigate(element)}>
                   <div className="Result">{value}</div>
                   <div className="Result-emptyspace"></div>
                 </div>
               )

             }

             })}
              {searchResult ?
               <div className="topicsearch-line-all" onClick={() => handleAllSearch()}>Show All Search</div>
              : null }
           </div>
         </div>

        </div> :
        null }

      <Requestpeoplelist />


     </div>
  );
}

export default Requestpeople;
