import React, { useState,useEffect,useRef } from 'react';
import { RiCloseFill } from 'react-icons/ri';
import { IoIosArrowDown } from 'react-icons/io';
import './Fpassword.css';
import { useNavigate } from 'react-router-dom';
import { byCountryCode } from '../../../object/callcode';
import { byCountrytimezone } from '../../../object/timezone';
import { emailvalidation,mobilevalidation } from '../../../utils/Validation';
import { getUserInfo,getUsermobInfo,checkEmail } from '../../../redux/thunk/gateway/fetchdata';
import Classicspiner from '../../../spinner/classic-spiner/Classicspiner';
import Fpasswordcode from './Fpasswordcode';
import Fpasswordchange from './Fpasswordchange';
import Pinscreen from '../../homepage/profile&settings/pin/Pinscreen';
import Opscreen from '../../homepage/profile&settings/pin/Opscreen';

function Fpassword() {

  const navigate = useNavigate();

  const [emailValue,setEmailValue] = useState("");
  const [mobileValue,setMobileValue] = useState("");

  const [useremail,setUseremail] = useState(null);
  const [usermobile,setUsermobile] = useState(null);
  const [verifyid,setVerifyid] = useState(null);

  const [checkflag, setcheckflag] = useState(true);
  const [toogleflag,setToogleflag] = useState(true);
  const [searchResult, setsearchResult] = useState([]);
  const [countryName, setcountryName] = useState("");
  const [countryCode,setCountrycode] = useState("");
  const [dropFlag, setdropFlag] = useState(false);

  const [errorflag,setErrorflag] = useState(false);
  const [errormflag,setErrormflag] = useState(false);
  const [resultflag,setResultflag] = useState(false);
  const [nextflag,setNextflag] = useState(true);
  const [loadingflag,setLaodingflag] = useState(false);

  const inputref = useRef(null);
  const emailref = useRef(null);

  const handleDropdown = (event) => {
      event.preventDefault();
      event.stopPropagation();
      setdropFlag(!dropFlag)
      setsearchResult("");
  }

  const handeEmailToogle = () =>  {
    setToogleflag(true);
    setEmailValue("");
    setErrorflag(false);
    setErrormflag(false);
  }

  const handeMobileToogle = () =>  {
    setToogleflag(false);
    setMobileValue("");
    setErrorflag(false);
    setErrormflag(false);
  }

  const handleCode = (dialcode,code) => {
      setcountryName(dialcode);
      setCountrycode(code);
      setdropFlag(!dropFlag);
  }

  const filterFunction = (e) => {
    let searchString = e.target.value;
    let result = byCountryCode.filter(x => x.name.toLowerCase().includes(searchString.toLowerCase()))
    setsearchResult(result);
  }

  const handleEmailbox = () => {
   setcheckflag(true);
  }

  const handleMobilebox = () => {
   setcheckflag(false);
  }

  const handleMobileChange = (event) => {
    const rstStr = (event.target.validity.valid) ? event.target.value : mobileValue;
    setMobileValue(rstStr);
  }

  const handleConfirmEmail = async () => {

    if(emailValue.length > 0) {

      setLaodingflag(true);

      let result = emailvalidation(emailValue);

      if(result === true) {

        const response = await checkEmail(emailValue);

        if(response.PAYLOAD == 1) {
           setNextflag(false);
        } else {
           setResultflag(true);
           setErrorflag(false);
        }


    {/*
        const response = await getUserInfo(passobj);

        if(response.STATUSCODE === 0) {

           if(response.PAYLOAD.result != null) {

               setResultflag(false);
               setErrorflag(false);

               if(response.PAYLOAD.useremail == 'NA') {
                    setUseremail("");
                } else {
                    setUseremail(response.PAYLOAD.result.useremail);
                    setVerifyid(response.PAYLOAD.result.verifyid);
                }


           } else {
              setErrorflag(false);
              setResultflag(true);
           }

        } else  {
            setErrorflag(true);
        }

    */}

     } else {
        setResultflag(false);
        setErrorflag(true);
     }

    }

    setLaodingflag(false);

  }

  const handleConfirmMobile = async () => {

    let value = `${countryCode}${mobileValue}`;

    if(mobileValue.length > 0) {

      let result = mobilevalidation(value);

      if(result === true) {

        let passobj = {
          usermobile:value
        }

        const response = await getUsermobInfo(passobj);

        if(response.STATUSCODE === 0) {
           if(response.PAYLOAD.result != null) {

               setResultflag(false);
               setErrorflag(false);

               if(response.PAYLOAD.result.mobile == 'NA') {
                   setUsermobile("");
                } else {
                   setUsermobile(response.PAYLOAD.result.mobile)
                }


           } else {
              setErrorflag(false);
              setResultflag(true);
           }

        } else  {
            setErrorflag(true);
        }

     } else {
        setResultflag(false);
        setErrorflag(true);
     }

    }

  }

  const handleEmailChange = (event) => {
     setEmailValue(event.target.value);
  }

  const handleFocus  = ()  => {
    setdropFlag(false);
  }

  const handleEmailfocus = () => {
    emailref.current.focus();
  }

  const handleOption = () => {
    inputref.current.focus();
  }

  const handleBack = () => {
    setEmailValue("");
    setNextflag(true);
    setUseremail(null);
    setUsermobile(null);
  }

  const handleCancel = () => {
      navigate(-1);
  }

  useEffect(() => {

     function getTimeZone() {
       var offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
       return (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) + ":" + ("00" + (o % 60)).slice(-2);
    }

      let timezonename = Intl.DateTimeFormat().resolvedOptions().timeZone;
      let timez =  getTimeZone();
      let result = byCountrytimezone.filter(x => x.TimeZone == timezonename);

      if(result.length > 0) {

        let res = byCountryCode.filter(x => x.code == result[0].CountryCode);

         if(res.length > 0) {
           setcountryName(res[0].code);
           setCountrycode(res[0].dial_code);
         }
      } else {
         setcountryName("US");
         setCountrycode("+1");
      }


  },[])

  useEffect(() => {
    if(useremail != null || usermobile != null) {
       setNextflag(false);
    }
  },[useremail,usermobile])

  return (

  <div className="Fpassword-backdrop">

   {loadingflag ?
    <div className="Fpassword-spinner">
      <Classicspiner />
    </div>  : null }

   {nextflag ?
    <div className="Fpassword-wrapper">
       <div className="Fpassword-container">

          <div className="Fpassword-header">
             <div className="Fpassword-header-right">
              <RiCloseFill />
             </div>
             <div className="Fpassword-header-center">
                Identify Your Account
             </div>
             <div className="Fpassword-header-left" onClick={() => handleCancel()}>
              <RiCloseFill />
             </div>
          </div>

          <div className="Fpassword-body">

             <div className="Fpassword-body-up">

                <div className="Fpassword-body-text">
                  <div>Enter the email to identify</div>
                  <div className="Fpassword-body-text-txt"><div>your account to&nbsp;</div><div>change your password.</div></div>
                </div>

                <div style={{height:30}}> </div>

                <div>
                  <div className={toogleflag ? "Fpassword-btn" : "Fpassword-btn-dis"} onClick={() => handeEmailToogle()} >
                    Email
                    </div>
                  <div style={{width:20}}>  </div>

                {/*
                  <div className={toogleflag ? "Fpassword-btn-dis" :"Fpassword-btn"} onClick={() => handeMobileToogle()}>
                    Mobile
                  </div>
                */}


                </div>

              <div style={{height:20}}> </div>

              {toogleflag ?
                <>
                 <div className="Fpassword-option-input" onClick={() => handleEmailfocus()} >
                   <input ref={emailref} type="text" className="Fpassword-input" placeholder="Email"
                    value={emailValue} onChange={(event) => handleEmailChange(event)} />
                 </div>
                 <div className="Fpassword-option-error">
                  {errorflag ? <div>* Please enter valid email address.</div> : <div>&nbsp;</div> }
                 </div>
                </>
                :
                <>
                 <div className="Fpassword-option-input" onClick={() => handleOption()}>
                    <div className="Fpassword-option-drop" onClick={(event) => handleDropdown(event)}>
                      <div>{countryName}&nbsp;{countryCode}</div>
                      <div><IoIosArrowDown/></div>
                    </div>

                    <input type="text" className="Fpassword-input" placeholder="Mobile"
                      pattern="[0-9]*" value={mobileValue} ref={inputref} onChange={(event) => handleMobileChange(event)}
                      onFocus={() => handleFocus()} />
                 </div>

                  <div className="Fpassword-option-error">
                    {errormflag ? <div>* Please enter valid mobile number.</div> : <div>&nbsp;</div> }
                  </div>

               {dropFlag ?
                 <div className="Fpassword-list-wrapper">
                   <div style={{height:20}}> </div>
                  <div className="Register-list">
                   <input className="mobilenuminp"  type="text"
                     placeholder="Search" id="myInput"
                     onChange={(event) => filterFunction(event)} />
                  </div>

                   <div className="Fpassword-list">
                     {searchResult.length > 0 ?
                       <>
                        {searchResult.map((element,index) => {
                        return (
                       <div className="itemlist" key={index}>
                         <span className="item" href="#about" onClick={() => handleCode(element.code,element.dial_code)}>{element.emoji} &nbsp;{element.name}&nbsp;({element.dial_code})</span>
                       </div>
                     )
                    })}
                       </> :
                       <>
                        {byCountryCode.map((element,index) => {
                          return (
                          <div className="itemlist" key={index}>
                            <span className="item" href="#about"
                              onClick={() => handleCode(element.code,element.dial_code)}>
                              {element.emoji} &nbsp;{element.name}
                              &nbsp;({element.dial_code})</span>
                          </div>
                          )
                        })}
                       </>
                     }
                   </div>
                 </div> : null }
               </>
               }

              <div style={{height:10}}> </div>

             </div>

            {toogleflag ?
               <div className="Fpassword-body-down">
                <div className="Fpassword-next" onClick={() => handleConfirmEmail()}>
                  Next
                </div>
               </div> :
               <div className="Fpassword-body-down">
                 <div className="Fpassword-next" onClick={() => handleConfirmMobile()}>
                   Next
                 </div>
               </div>
             }

             <div className="Fpassword-footer">
                  {resultflag ? <div>No Record Found !!</div> : <div>&nbsp;</div> }
             </div>

          </div>

       </div>
    </div> :

      <Opscreen useremail={emailValue} usermobile={usermobile} verifyid={verifyid} handleBack={handleBack} /> }

  </div>
  );
}

export default Fpassword;
