import React, { useEffect,useState,useContext,useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from "react-redux";
import { v4 as uuidV4 } from "uuid";
import * as actionCreators from '../../../../redux/actions/actionCreators';
import { returnPhotoURL,fileSize,fileName } from '../../../../services/FileType';
import { handleCompress } from '../../../../utils/CompressImage';
import { SocketContext } from "../../../../context/SocketContext";

import { BiSmile } from 'react-icons/bi';
import { BiImage } from 'react-icons/bi';
import { BiRightArrowAlt } from 'react-icons/bi';
import { MdOutlineCancel } from 'react-icons/md';

import './Replybox.css';

function Replybox(props) {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const myText = useRef(null);

  const team = useSelector((state) => state.teamReducer.team);
  const topicobj = useSelector((state) => state.activeReducer.topicid);
  const userdetails = useSelector((state) => state.userReducer.userdetails);
  const { socket } = useContext(SocketContext);

  const [replyUser,setReplyuser] = useState(null);
  const [postreply,setPostreply] = useState(null);
  const [replyImgarray,setReplyimgarray] = useState(null);
  const [dragFlag,setDragflag] =  useState(false);
  const [myDivheight,setmyDivheight] = useState(120);
  const [teamusers,setTeamusers] = useState(null);
  const [file, setFile] = useState();
  const [replypostid,setReplypostid] = useState(null);
  const [replyuserid,setReplyuserid] = useState(null);
  const [imgFlag,setImgflag] = useState(false);
  const [searchflag,setSearchflag] = useState(false);
  const [imgArray,setImgArray] = useState([]);

  function replaceURLWithHTMLLinks(text) {

   var re = /(\(.*?)?\b((?:https?|ftp|file):\/\/[-a-z0-9+&@#\/%?=~_()|!:,.;]*[-a-z0-9+&@#\/%=~_()|])/ig;
   return text.replace(re, function(match, lParens, url) {
       var rParens = '';
       lParens = lParens || '';

       var lParenCounter = /\(/g;
       while (lParenCounter.exec(lParens)) {
           var m;

           if (m = /(.*)(\.\).*)/.exec(url) ||
                   /(.*)(\).*)/.exec(url)) {
               url = m[1];
               rParens = m[2] + rParens;
           }
       }
       return lParens + "<a target=_blank href='" + url + "'>" + url + "</a>" + rParens;
    });
 }

  const handlePost = async () => {

    let data = document.getElementById("Homepage-myDiv").innerHTML;
    let userPostline = data.replace(/^(\ ?<div><br><(\/)?div>\ ?)+|(\ ?<div><br><(\/)?div>\ ?)+$/, '');

    if(userPostline.length > 64000) {
      return alert("maxium char is allowed 64000")
    }

    let datatext = document.getElementById("Homepage-myDiv").textContent;
    let pdata = datatext.trim();

    let finalpost = replaceURLWithHTMLLinks(userPostline);

    if(imgArray.length > 0 || pdata.length > 0) {

      const poId = uuidV4();
      const timestamp =  Date.now();
      const postId = userdetails.userid+timestamp+poId;

      let userpostobj = {
        postid:postId,
        userid:userdetails.userid,
        topicid:topicobj.topicid,
        topictxt:pdata,
        userPostline:finalpost,
        teamusers:teamusers,
        imgArray:imgArray,
        replyto:replyuserid,
        replypostid:replypostid,
        replyPost:postreply,
        replyImgarray:replyImgarray
      }

      socket.emit("userpost1", {
           userpostobj
       })

       document.getElementById("Homepage-myDiv").innerHTML = "";

       let tempArray = [];

       setImgArray(tempArray);
       setImgflag(false);
       setmyDivheight(130);

       setReplyuser(null);
       setReplypostid(null);
       setReplyuserid(null);
       setPostreply(null);
       setReplyimgarray(null);
       props.cancelReply();

    }

  }

  const handlePostChange = (event) => {

    var offsetHeight = document.getElementById('Homepage-myDiv').offsetHeight;

         /* Setting myDivheight height */

     if (offsetHeight <= 19) {
        setmyDivheight(offsetHeight + 71);
     } else {
       if(!imgFlag) {
         setmyDivheight(offsetHeight + 90);
       } else {
          setmyDivheight(offsetHeight + 210);
       }
     }

  }

  const cancelReply = () => {
    setReplyuser(null);
    setReplypostid(null);
    setReplyuserid(null);
    setPostreply(null);
    setReplyimgarray(null);
    document.getElementById("Homepage-myDiv").innerHTML = "";

    let tempArray = [];
    setImgArray(tempArray);
    setImgflag(false);
    setmyDivheight(130);
    props.cancelReply();
  }

  const handlePaste = async (e) => {

     var clipboardData, pastedData;

     clipboardData = e.clipboardData || window.clipboardData;
     pastedData = clipboardData.getData('Text');

     var item = e.clipboardData.items[0];

     if(pastedData.length < 1) {
       e.preventDefault();

       if(item.type.indexOf("image") === 0) {

         var blob = item.getAsFile();

         let result = await returnPhotoURL(URL.createObjectURL(blob));

         // Check image is valid or not
         if(result === true) {
            alert("Image is not valid format");
            return;
        }

         if(result === false) {
          let newFile = await fileName(blob);
            setFile(newFile);
         }
       }
     }

 }

  const handleDrop = async (e) => {

   e.preventDefault();
   e.stopPropagation();

   let size = await fileSize(e.dataTransfer.files[0].size);

   if(size === true) {
     alert("File too Big,Select a file less than 4mb");
     return;
   }

   if(size === false) {

     let file =  e.dataTransfer.files[0];
     let result = await returnPhotoURL(URL.createObjectURL(e.dataTransfer.files[0]));

     if(result === true) {
          alert("Image is not valid format");
          return;
        }

     if(result === false) {
         let newFile = await fileName(file);
         setFile(newFile);
     }

}

   setDragflag(false);

};

  const handleDragOver = async (e) => {
     setDragflag(true);
  }

  const handleDragLeave = (e) => {
    setDragflag(false);
}

  const handlePostbox = () => {
    myText.current.focus();
 }

  const onImageUpload = async (event) => {

  if(event.target.files.length <= 0) {
    return;
  }

  let size = await fileSize(event.target.files[0].size);

   // Check file size
   if(size === true) {
       alert("File too Big,Select a file less than 4mb");
       //setImageError(true);
       return;
   }

   if(size === false) {

     let result = await returnPhotoURL(URL.createObjectURL(event.target.files[0]));

      // Check image is valid or not
      if(result === true) {
          alert("Image is not valid format");
          return;
      }

      if(result === false) {
        let newFile = await fileName(event.target.files[0]);

         var offsetHeight = document.getElementById('Homepage-myDiv').offsetHeight;
         setmyDivheight(offsetHeight + 210);
         setFile(newFile);
       }
    }

}

  const removeDiv = (index) => {

  let tempArray =  [...imgArray]

  tempArray.splice(index,1);
  setImgArray(tempArray);

  if(tempArray.length == 0) {
     setImgflag(false);
     setmyDivheight(130);
   }

}

  const clearImageSel = () => {

    var offsetHeight = document.getElementById('Homepage-myDiv').offsetHeight;
    setmyDivheight(130);

    let tempArray = [];
    setImgArray(tempArray);
    setImgflag(false);
}

  useEffect(() => {
    if(props) {
      setReplyuser(props.replyUser);
      setReplypostid(props.replypostid);
      setReplyuserid(props.replyuserid);
      setPostreply(props.postreply);
      setReplyimgarray(props.replyImgarray);
      myText.current.focus();
    }
  },[props])

  return (
    <div className="Replybox-postbox-outer">
      <div className="ac-postbox" onClick={handlePostbox}>

       <div className="ac-message-input-wrapper">

      {replyUser ?
        <div className="ac-replyuser">

          <div className="ac-replyuser-name">
             {replyUser}
             <span className="ac-replyuser-cancel" onClick={cancelReply}>
            <MdOutlineCancel/>
             </span>
          </div>

          <div className="ac-reply-details">
            <div className="ac-reply-text" dangerouslySetInnerHTML={{__html: postreply }} >
            </div>
            <div className="ac-item-wrapper-img">

              {replyImgarray	&& replyImgarray.map((imgitem,index) => {

                let imgurl;

                if(imgitem.postimage) {
                  imgurl = imgitem.postimage;
                } else {
                   const b64 = new Buffer(imgitem).toString('base64')
                    imgurl  = "data:image/png;base64,"+b64;
                 }
                 return (
                     <div key={index} className="ac-item-img">
                       <img src={imgurl} />
                     </div>
                 )

             })}
             </div>
          </div>

       </div> : null }

    <div id="Homepage-myDiv" contentEditable="true"
     className={dragFlag ? "ac-postform-drag" : "ac-postform" }

     data-placeholder="Text Now . . . "
       onInput={(event) => handlePostChange(event)} suppressContentEditableWarning={true} ref={myText}
       onPaste={(event) => handlePaste(event)}
       onDrop={e => handleDrop(e)}
       onDragOver={e => handleDragOver(e)}
       onDragLeave={e => handleDragLeave(e)}
        ></div>

      {imgFlag ? <div className="ac-himg-clear" >
          <span className="ac-himg-clear-btn" onClick={clearImageSel}>
            <MdOutlineCancel />
          </span>
        </div> : null }

    <div onDrop={e => handleDrop(e)} contentEditable={imgFlag ? "true" : "false"}
       suppressContentEditableWarning={true}  className={imgFlag ? "ac-himg-container" : ""}>
          {imgArray && imgArray.map((element,index) => {
             let imageurl = URL.createObjectURL(element);
             return (
                 <div key={index} style={{ backgroundImage: `url(${imageurl})`}} className="ac-imagecontainer">
                    <span className="ac-remove-image" onClick={() => removeDiv(index)}><MdOutlineCancel/></span>
                 </div>
             )
          })}
    </div>

   <div className="ac-button-panel">

     <div className="ac-post-panel-left">
      <div className="ac-emoji-button">
         <BiSmile />
      </div>

        <div style={{width:10}}></div>

        <label className="ac-emoji-button">
          <BiImage />
          <input type="file" className="ac-upbtn" onClick={(event) => (event.target.value = null)} onChange={onImageUpload}
          accept="image/png,image/jpeg,image/jpg,image/bmp" multiple />
        </label>
     </div>

      <div className="ac-post-panel-right">
       <div className="ac-post-button">
          <button className="ac-btncancel" onClick={cancelReply}>cancel</button>
          <div style={{width:20}}> </div>
          <button className="ac-btnpost" onClick={handlePost}>
            <BiRightArrowAlt />
         </button>
       </div>
      </div>

   </div>

       </div>

      </div>
    </div>
  );

}


export default Replybox;
