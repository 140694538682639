import * as actionTypes from "../actions/actionTypes";

const initialState = {
   refreshFlag:false,
   reqmessage:"",
   error:false
};

const refreqReducer = (state = initialState, action) => {

  switch (action.type) {

    case actionTypes.REFRESHREQ:

      return {
        refreshFlag:action.payload.refreshFlag,
        reqmessage:action.payload.reqmessage,
        error:action.payload.error
      };

    default:
      return state;
  }
};

export default refreqReducer;
