import React from 'react';
import './Publish.css';
import { useState } from 'react';
import { Routes, Link, Route, useNavigate, Navigate } from "react-router-dom";
import Prequest from './Prequest';
import Tm from './Tm';
import Pb from './Publisherr';
import NoMatch from '../../../../pages/errpage/Nomatch';
// import Yourpool from '../../profile&settings/profile/Yourpool';

function Publish() {

  const navigate = useNavigate();
  const [tmFlag, setTmFlag] = useState(true);
  const [publishdate,setPublishdate] = useState("");
  const [numberDays,setnumberDays] = useState("");
  const [topic,setTopic] = useState("");

  const handleTimeline = (pdate,ndays,ptopic) => {
    setPublishdate(pdate);
    setnumberDays(ndays);
    setTmFlag(false);
    setTopic(ptopic);
    navigate('tm');
  }

  const handlePublish = () => {
     navigate('pb');
  }

  const PrivateRoute = ({ children }) => {
      return tmFlag ? <Navigate to="/homepage/publish" /> : children;
  }

   return (

     <div className="publish-wrapper">

     <Link to=""> </Link>
     <Link to="tm"> </Link>
     <Link to="Yourpool"> </Link>

       <Routes>
            <Route path="/" element={<Prequest navTimeline={handleTimeline} />}></Route>

            <Route path="/tm" element={
                <PrivateRoute>
                  <Tm publishdate={publishdate} numberDays={numberDays} topic={topic} handlePublish={handlePublish} />
                </PrivateRoute>
              }>
            </Route>

            <Route path="/pb" element={
                <PrivateRoute>
                  <Pb />
                </PrivateRoute>
              }>
            </Route>

            <Route path="/*" element={<NoMatch />}></Route>

       </Routes>

     </div>
   )
}

export default Publish;
