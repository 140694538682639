import React from 'react';
import { useEffect,useState,useRef,useContext } from 'react';
import { useSelector,useDispatch } from 'react-redux';
import {  SocketContext  } from "../../../../context/SocketContext";
import { getTopicDetails } from '../../../../redux/thunk/topic/fetchdata';

import './Requestconfirm.css';
import { delEnrolledUser,addEnrolledUser } from '../../../../redux/thunk/topic/fetchdata';
import * as actionCreators from '../../../../redux/actions/actionCreators';


function Requestconfirm(props) {

    const myref = useRef(null);
   const dispatch = useDispatch();

    const [superuser,setSuperuser] = useState(null);

   const userdetails = useSelector((state) => state.userReducer.userdetails);
   const topicDetails = useSelector((state) => state.poolDetailsReducer.topicDetails);

  const {reqconfirmReducer,handleRequestconfrim} = props;
  const { socket } = useContext(SocketContext);

  const handleClick = (event) => {
    if (myref.current && !myref.current.contains(event.target)) {
        handleRequestconfrim();
     }
  };

  const handlecancelRequest = () => {
        handleRequestconfrim();
  }

  const handleConfirmRequest = async() => {


    if(superuser != null) {

      let enrolluser;

      if(reqconfirmReducer.notifyto == superuser) {
            enrolluser = userdetails.userid;
        } else {
            enrolluser = reqconfirmReducer.notifyto;
         }

      let enrolledObj = {
           requestid:reqconfirmReducer.requestid,
           topicid:reqconfirmReducer.notify_topicid,
           userid:enrolluser
       }

      const response = await addEnrolledUser(enrolledObj);

      if(response.STATUSCODE == 0) {

         dispatch(actionCreators.addRequestdeleteId(reqconfirmReducer.requestid));

         const replyNoti = (notiFy) => {
           socket?.emit("replyNoti",notiFy);
         }

         const delNoti = (requestId) => {
           socket?.emit("delNotireq",requestId);
         }

         if(socket) {
            await replyNoti(reqconfirmReducer)
          }

         if(socket) {
            await delNoti(reqconfirmReducer.requestid);
         }

         let object = {
           refreshFlag:true,
           reqmessage:"User request is confirmed",
           error:false
         }

         dispatch(actionCreators.refreshReq(object));
         dispatch(actionCreators.reqNav(""));
         handleRequestconfrim();

     }

      if(response.STATUSCODE == 3) {

        let object = {
          refreshFlag:true,
          reqmessage:"User has Canceled Request !!",
          error:true
        }

         dispatch(actionCreators.refreshReq(object));
         handleRequestconfrim();
      }

    }


  }

  useEffect(() => {
   document.addEventListener("mousedown", handleClick);

   return () => {
      document.removeEventListener("mousedown", handleClick, false);
    };

 }, []);

  useEffect(async () => {

   if(reqconfirmReducer.notify_topicid) {
     const response = await getTopicDetails(reqconfirmReducer.notify_topicid);
      setSuperuser(response.PAYLOAD.userid);
   }

 },[reqconfirmReducer.notify_topicid]);

  return (
    <div className="reqconfirmwrapper">
      <div ref={myref} className="reqconfirmcontainer">

       <div className="reqconfirtitle"><strong>Confirm Request !!</strong></div>
       <div>&nbsp;</div>

       <div className="reqconfirbtn">
         <div className="reqconfircancel" onClick={() => handlecancelRequest()}>Cancel</div>
         <div className="reqconfirempty"></div>
         <div className="reqconfirmbutton" onClick={() => handleConfirmRequest()}>Confirm</div>
       </div>

      </div>
    </div>
  );
}

export default Requestconfirm;
