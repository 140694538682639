import React, { useState,useEffect,useRef } from 'react';
import { RiCloseFill } from 'react-icons/ri';
import { BiCheckCircle } from 'react-icons/bi';
import { BiCircle } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { confirmOtp } from '../../../redux/thunk/gateway/fetchdata';
import Classicspiner from '../../../spinner/classic-spiner/Classicspiner';
import Fpasswordchange from './Fpasswordchange';
import './Fpasswordcode.css';

function Fpasswordcode(props) {

  const navigate = useNavigate();

  const [otpValue,setOtpvalue] = useState("");
  const [otpflag,setOtpflag] = useState(false);
  const [otpErr,setOtpErr] = useState(null);

  const handleCancel = () => {
    props.handlePinclose();
  }

  const handleOtp = (event) => {
    setOtpvalue(event.target.value);
  }

  const handleConfirm = async() => {

     let obj = {
       verifyid:props.verifyid,
       otpValue:otpValue
     }

    const response = await confirmOtp(obj);

    if(response.STATUSCODE === 0) {
        setOtpflag(true);
    }

    if(response.STATUSCODE === 1) {
         setOtpErr(response.MESSAGE)
    }


  }

  const handleprev = () => {
     props.handlePinclose();
  }

  useEffect(() => {
    console.log("props",props);
  },[props])


  return (
    <div className="Fpasswordcode-backdrop">
     {otpflag ? <Fpasswordchange  useremail={props.useremail} /> :
      <div className="Otpscreen-wrapper">
         <div className="Otpscreen-container">
            <div className="Otpscreen-header">
               <div className="Otpscreen-header-right">
                <RiCloseFill />
               </div>
               <div className="Otpscreen-header-center">
                  Enter confirmation code
               </div>
               <div className="Otpscreen-header-left" onClick={() => handleCancel()}>
                <RiCloseFill />
               </div>
            </div>

            <div className="Otpscreen-body">

              <div className="Otpscreen-body-up">
                <div className="Otpscreen-body-text">
                  Enter confirmation code that has been sent to your
                  <strong>
                  {props.useremail ? ` Email : ${props.useremail}` : null }
                  {props.usermobile ? ` Mobile : ${props.usermobile}` : null }
                  </strong>
                 </div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                   <div>
                     <input className="Otpscreen-input" type="text" placeholder="OTP"
                        onChange={(event) => handleOtp(event)}  />
                  </div>
              </div>

               <div className="Otpscreen-body-down">
                 <div className="Otpscreen-error">
                      {otpErr ? <div>{otpErr}</div> : <div>&nbsp;</div>}
                 </div>
                 <div>&nbsp;</div>
                 <div className="Otp-confirm-btn" onClick={() => handleConfirm()}>
                    Confirm
                  </div>

                 <div style={{height:10}}> </div>
                 <div>Didn't receive OTP ? go back resend </div>

                 <div className="Fpasswordcode-back">
                    <div className="Fpasswordcode-back-btn" onClick={() => handleprev()}>
                      back
                     </div>
                  </div>


               </div>

            </div>
            <div className="Otpscreen-footer">
               <div></div>
            </div>
         </div>

       {/*
          <div className="Otpscreen-spinner">
             <Classicspiner />
          </div>
       */}

      </div>  }
    </div>
  );
}

export default Fpasswordcode;
